import { ChangeEvent, FC, useState } from "react";
import { Bar } from "react-chartjs-2";
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from "chart.js";
import moment from "moment/moment";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";

type sellsTimeProps = {
  dataChart: Array<any>;
  changePeriod?: (period: string) => void;
  months: Array<any>;
};
export const SellsTime: FC<sellsTimeProps> = ({
  dataChart = [],
  changePeriod = () => {},
  months = [],
}) => {
  const [selected, setSelected] = useState("days");
  const change = (event: ChangeEvent<HTMLInputElement>) => {
    setSelected(event.target.value);
    changePeriod(event.target.value);
  };

  const filterSectionProps = [
    {
      title: "Día",
      id: "days",
    },
    // {
    //   title: "Semana",
    //   id: "week",
    // },
    {
      title: "Mes",
      id: "month",
    },
  ];

  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );
  const labels = dataChart.map((d) =>
    moment(d["_id"]).format(selected == "month" ? "MMM" : "DD MMM")
  );
  const datasets = [
    {
      label: "Tickets vendidos",
      data: dataChart.map((d) => d["qty"]),
      backgroundColor: "rgba(0, 0, 0, 0.9)",
      borderWidth: 2,
      borderRadius: Number.MAX_VALUE,
      borderSkipped: false,
      maxBarThickness: 14,
    },
  ];
  const dataLocal = {
    labels,
    datasets,
  };

  const options = {
    maintainAspectRatio: false,
    aspectRatio: 1,
    responsive: false,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
          drawBorder: false,
        },
      },
      y: {
        grid: {
          display: false,
          drawBorder: false,
        },
        ticks: {
          display: false,
        },
      },
    },
  };

  return (
    <div className="flex flex-col rounded-xl outline outline-2 outline-colorBorder 2xl:h-full">
      <Accordion type="single" collapsible>
        <AccordionItem value="item-1">
          <AccordionTrigger>
            <div className="flex h-16 w-full items-end justify-between border-b border-colorBorder px-5">
              <h3 className="pb-4 text-xl font-bold">
                Gráfico de ventas en el tiempo
              </h3>
              <form className="flex justify-end font-medium ">
                {filterSectionProps.map((section, i) => (
                  <label
                    key={i}
                    htmlFor={section.id}
                    className="h-full cursor-pointer select-none  "
                  >
                    <input
                      type="radio"
                      name="filterTable"
                      id={section.id}
                      value={section.id}
                      className="peer hidden"
                      checked={section.id === selected}
                      onChange={change}
                    />
                    <div className="h-10 w-20 text-center  capitalize peer-checked:border-b-[2px] peer-checked:border-black-200 peer-checked:text-black-100">
                      {section.title}
                    </div>
                  </label>
                ))}
              </form>
            </div>
          </AccordionTrigger>
          <AccordionContent>
            <div className="my-auto flex w-full flex-col items-center justify-center px-10 py-2">
              {dataLocal.labels.length > 0 ? (
                <Bar
                  data={dataLocal}
                  width={"800px"}
                  height={"150px"}
                  options={options}
                />
              ) : (
                <p className="my-6">No existen datos disponibles</p>
              )}
              {/* <div className="border-t-1 flex w-full gap-20 border-t-2 border-colorBorder font-medium">
          {months.map((item, index)=>(
              <p key={index} className={`py-5 capitalize ${index === 0 ? 'text-gray-100':''}`}>{item}</p>
              )
          )}
        </div> */}
            </div>
          </AccordionContent>
        </AccordionItem>
      </Accordion>
    </div>
  );
};

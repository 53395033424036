import React from "react";
import { IAuthRequest, IAuthResponse } from "../types/IAuth";
import { IProducerResponse } from "../types/IProducer";
import { IPermissions } from "../types/IPermissions";

export interface AuthContextInterface {
  user: IAuthResponse;
  token: string | null;
  menu: string;
  role: string;
  producer?: IProducerResponse;
  login: (credential: IAuthRequest) => Promise<any>;
  googleLogin: (google_token: string, coordinates: any) => Promise<any>;
  logout: () => void;
  changeClient: () => void;
  changeProducer: (producer: IProducerResponse) => void;
  getUser: () => void;
  clientProducerId: string | null;
  permissions: IPermissions;
}

const permissionsValues = {
  admin: false,
  events: false,
  reports: false,
  admin_access: "",
};

const userValues = {
  id: "",
  first_name: "",
  last_name: "",
  email: "",
  user_role: "",
  access_token: "",
  profile_picture: "",
  producer_id: "",
  permissions: permissionsValues,
  permitted_producers: [],
  first_time_login: true,
};

const producerValues = {
  id: "",
  name: "",
  client_id: "",
  client: [],
  tickets_sold: 0,
  tickets_reserved: 0,
  events_free_count: 0,
  active_events: 0,
  events: [],
  createdAt: "",
  updatedAt: "",
  events_valids_count: 0,
};

export const authContextDefaults: AuthContextInterface = {
  user: userValues,
  token: "",
  menu: "",
  role: "",
  producer: producerValues,
  login: async (credential: IAuthRequest) => {},
  googleLogin: async (google_token: string, coordinates: any) => {},
  logout: () => null,
  changeClient: () => {},
  changeProducer: (producer: IProducerResponse) => {},
  getUser: () => {},
  clientProducerId: "",
  permissions: permissionsValues,
};

export const AuthContext =
  React.createContext<AuthContextInterface>(authContextDefaults);

import React, { useEffect, FC, useContext, useState } from "react";
import { Back } from "../Back";
import FormInput from "../FormElement/FormInput";
import FormUploadImage from "../FormElement/FormUploadImage";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, FormProvider } from "react-hook-form";
import * as yup from "yup";
import { IRrppRequest } from "../../types/IRrpp";
import jsonToFormData from "@ajoelp/json-to-formdata";
import { useMutation } from "@tanstack/react-query";
import UserService from "../../services/userService";
import FormSelect from "../FormElement/FormSelect";
import { StateContext } from "../../context/StateProvider";
import { AuthContext } from "../../context/AuthContext";
import { roles } from "../../utils/roles";
import FormRadio from "../FormElement/FormRadio";
import FormToggle from "../FormToggle";

let verifyAddForm = {
  first_name: yup.string().required("El nombre es requerido"),
  last_name: yup.string(),
  email: yup
    .string()
    .required("El email es requerido")
    .email("El correo electrónico no es válido"),
  password: yup.string().required("El password es requerido"),
  profile_picture: yup
    .mixed()
    .test("size", "Tamaño permitido menor a 20MB", (value) => {
      if (value instanceof File) {
        return value.size <= 20000000;
      }
      return true;
    }),
};

let verifyEditForm = {
  first_name: yup.string().required("El nombre es requerido"),
  last_name: yup.string().required("El apellido es requerido"),
  email: yup
    .string()
    .required("El email es requerido")
    .email("El correo electrónico no es válido"),
  profile_picture: yup
    .mixed()
    .test("size", "Tamaño permitido menor a 20MB", (value) => {
      if (value instanceof File) {
        return value.size <= 20000000;
      }
      return true;
    }),
  is_blacklisted: yup.boolean(),
};

const defaultValues = {
  first_name: "",
  last_name: "",
  email: "",
  password: "",
  profile_picture: "",
  is_blacklisted: undefined,
};

const statusOptions: Array<object> = [
  /*{value: 'pending', text: 'Pendiente'},*/
  { value: "active", text: "Activo" },
  { value: "paused", text: "Pausado" },
];

type typeProps = {
  handleCreateorEdit: (id: string) => void;
  id: string;
};
export const FormUser: FC<typeProps> = ({ handleCreateorEdit, id }) => {
  const { role } = useContext(AuthContext);
  const [userRole, setUserRole] = useState("");
  let schema;
  if (id) {
    schema = yup.object().shape(verifyEditForm);
  } else {
    schema = yup.object().shape(verifyAddForm);
  }

  const createItems = useMutation((item: FormData) => UserService.create(item));
  const updateItems = useMutation((item: FormData) =>
    UserService.update(id, item)
  );
  const getUserApi = useMutation((id: string) => UserService.get(id));

  const form = useForm<IRrppRequest>({
    resolver: yupResolver(schema),
    defaultValues: defaultValues,
  });

  useEffect(() => {
    if (id) {
      getUserApi.mutateAsync(id).then((res) => {
        setUserRole(res.user_role);
        form.reset(res);
      });
    }
  }, [form.reset]);
  /** */
  const { state, dispatch } = useContext(StateContext);
  const onSubmit = async (data: IRrppRequest) => {
    let formData = jsonToFormData(data, {
      arrayIndexes: true,
      excludeNull: true,
    });
    dispatch({ type: "showLoaderScreen", payload: true });
    if (id) {
      await updateItems.mutateAsync(formData).then((res) => {
        dispatch({ type: "showLoaderScreen", payload: false });
        dispatch({ type: "toastSuccess", payload: "Registro actualizado" });
      });
    } else {
      await createItems.mutateAsync(formData).then((res) => {
        dispatch({ type: "showLoaderScreen", payload: false });
        dispatch({
          type: "toastSuccess",
          payload: "Registro creado correctamente",
        });
      });
    }
    handleCreateorEdit("");
  };

  const onError = (error: any) => {
    console.log("error ", error);
  };

  const userRoleLabel = (role: string) => {
    const roles: any = { leader: "Líder", seller: "Vendedor" };
    try {
      return roles[role];
    } catch (e) {
      return role;
    }
  };

  const genderOptions = [
    { value: "Male", label: "Hombre" },
    { value: "Female", label: "Mujer" },
    { value: "Other", label: "Otro" },
  ];

  const teamRoles = [
    { value: "seller", text: "Vendedor" },
    { value: "leader", text: "Líder" },
  ];

  return (
    <div className="">
      <Back onClick={() => handleCreateorEdit("")} />
      <div className="flex flex-col gap-10 xl:px-[23rem]">
        <h1 className="text-center text-3xl font-bold">
          {id ? "Editar Usuario" : "Crear nuevo Usuario"}
        </h1>
        <FormProvider {...form}>
          <form onSubmit={form.handleSubmit(onSubmit, onError)}>
            <FormInput name="first_name" type="text" label="Nombre" />
            <FormInput name="last_name" type="text" label="Apellido" />
            <FormInput name="email" type="text" label="Correo" />
            <FormInput name="password" type="password" label="Contraseña" />

            {role == "admin" ? (
              <FormSelect
                name="user_role"
                options={[...roles, ...teamRoles]}
                label="Rol"
              />
            ) : (
              <div></div>
            )}
            {id ? (
              <FormSelect
                name="status"
                options={statusOptions}
                label="Estado del user"
              />
            ) : null}
            <FormRadio
              name="gender"
              label="Género"
              options={genderOptions}
              align="start"
            />
            <div className="my-10 flex justify-center gap-10 ">
              <FormToggle
                name="is_blacklisted"
                label="Bloqueado?"
                color="red"
              />
            </div>
            <div className="flex justify-center gap-10 ">
              <FormUploadImage name="profile_picture" label="Picture" />
            </div>
            <div className="mb-16 flex justify-center gap-10 pt-10">
              <button
                type="submit"
                className="rounded-full border-[2px] border-black-100 bg-black-100 px-[5.4rem] py-2 font-medium  text-white"
              >
                {id ? "Guardar Usuario" : "Publicar Usuario"}
              </button>
            </div>
          </form>
        </FormProvider>
      </div>
    </div>
  );
};

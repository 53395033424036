import { useEffect, useState, useContext } from "react";
import { useMutation } from "@tanstack/react-query";
import { GrClose } from "react-icons/gr";
import { useForm, FormProvider, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { toast } from "react-toastify";
import { motion } from "framer-motion";

import {
  ICreateOrderLogs,
  IOrderRequest,
  IOrderResponse,
  IOrdersResponse,
} from "../../../types/IOrder";
import { IUserResponse } from "../../../types/IUser";
import { ITicketsResponse } from "../../../types/ITicket";
import { IFilterRequest } from "../../../types/IFilter";
import FormInput from "../../FormElement/FormInput";
import FormSelectFull from "../../FormElement/FormSelectFull";
import TicketService from "../../../services/ticketService";
import EventService from "../../../services/eventService";
import OrderService from "../../../services/orderService";
import UserService from "../../../services/userService";
import { StateContext } from "../../../context/StateProvider";
import { AuthContext } from "../../../context/AuthContext";
import circleCheck from "../../../assets/circle-check.png";
import { SearchAutocomplete } from "../../rrpp/newSeller/components/SearchAutocomplete/SearchAutocomplete";
import { PictureAndName } from "../../rrpp/components/PictureAndName/PictureAndName";
import { Title } from "../Title";
import { FilterPage } from "../../table/FilterPage";
import { FilterQtyPage } from "../../table/FilterQtyPage";
import { formatDateTime } from "../../../utils/format-date";
import config from "../../../config/variables";

type SendTicketsProps = {
  eventId: string;
  teamMembers: any;
};

const schema = yup.object().shape({
  user_id: yup.string().required("Campo requerido"),
  ticket_id: yup.string().required("Campo requerido"),
  quantity: yup.number().min(1, "Campo requerido").required("Campo requerido"),
  seller_id: yup.string(),
  external_reference: yup.string().notRequired(),
});

const defaultValues = {
  total: 0,
  subtotal: 0,
  status: "",
  service: 0,
  mp_payment_id: "",
  user: {},
  seller: {},
  external_reference: "",
};

const defaultFilters = {
  limit: config.filter_default_limit,
  page: 1,
  sortBy: "id",
  sortOrder: "desc",
};

const defaultResult = {
  total: 0,
  pages: 0,
  rows: [],
};

export const CreateOrderEvent = ({
  eventId,
  teamMembers,
}: SendTicketsProps) => {
  const [showSuccess, setShowSuccess] = useState<boolean>(false);
  const auth = useContext(AuthContext);
  const [text, setText] = useState("");
  const [textSeller, setTextSeller] = useState("");
  const [filters, setFilters] = useState<IFilterRequest>({ ...defaultFilters });
  const [users, setUsers] = useState(defaultResult);
  const [selectData, setSelectData] = useState<IUserResponse | null>(null);
  const [selectDataSeller, setSelectDataSeller] =
    useState<IUserResponse | null>(null);
  const [tickets, setTickets] = useState<ITicketsResponse>({
    rows: [],
    total: 0,
    pages: 0,
  });

  const filteredTeamMembers =
    textSeller !== ""
      ? teamMembers.filter((member: any) => member?.email?.includes(textSeller))
      : [];

  const [emailUsers, setEmailsUsers] = useState<{ [key: string]: string }>({});
  const [ticketTypes, setTicketTypes] = useState<{ [key: string]: string }>({});

  const columnData = [
    "Orden",
    "Admin creador",
    "Usuario",
    "Fecha de creacion",
    "Tipo de Ticket",
    "Cantidades",
  ];
  const [logs, setLogs] = useState<ICreateOrderLogs>({
    rows: [],
    total: 0,
    pages: 0,
  });

  const [createdOrders, setCreatedOrders] = useState<IOrdersResponse>({
    rows: [],
    total: 0,
    pages: 0,
  });

  const { dispatch } = useContext(StateContext);

  const getTickets = useMutation(() =>
    TicketService.getAll({ event_id: eventId, type: "Estandar" })
  );
  const getUsers = useMutation(() => UserService.getAll(filters));

  const purchase = useMutation((formPurchase: IOrderRequest) =>
    OrderService.purchase(formPurchase)
  );
  useMutation(() => OrderService.getAllORdersInvitation({ event_id: eventId }));
  useMutation((item: FormData) => EventService.sendInvitationsToSeller(item));
  useMutation((filters: IFilterRequest) =>
    EventService.getSendTicketsLogs(filters)
  );
  useEffect(() => {
    getTickets.mutateAsync().then((res) => {
      setTickets(res);
    });
  }, []);
  useEffect(() => {
    if (text != "") {
      const delayDebounceFn = setTimeout(() => {
        dispatch({ type: "showLoaderScreen", payload: true });
        getUsers.mutateAsync().then((res) => {
          res.rows = res.rows.filter(
            (_: any) => _.full_name.trim() != "undefined"
          );
          setUsers(res);
          dispatch({ type: "showLoaderScreen", payload: false });
        });
      }, 1500);
      return () => clearTimeout(delayDebounceFn);
    }
  }, [text]);

  const form = useForm<IOrderRequest>({
    resolver: yupResolver(schema),
    defaultValues: defaultValues,
  });

  const onSubmit = async (data: IOrderRequest) => {
    data.tickets = [{ ticket_id: data.ticket_id, qty: data.quantity }];
    const ticketFinded = tickets.rows.find((t) => t.id == data.ticket_id);
    if (ticketFinded && data.quantity) {
      data.subtotal = ticketFinded.price * data.quantity;
    }
    data.service = 1;
    data.total = data.subtotal + data.service;
    data.adminCreator = auth.user.email;

    if (!data.external_reference || data.external_reference.length === 0) {
      delete data.external_reference;
    }

    // let formData = jsonToFormData(data, {
    //   arrayIndexes: true,
    //   excludeNull: true,
    // });

    dispatch({ type: "showLoaderScreen", payload: true });
    purchase
      .mutateAsync(data)
      .then((res) => {
        setShowSuccess(true);
        dispatch({ type: "showLoaderScreen", payload: false });
        dispatch({
          type: "toastSuccess",
          payload: "El Ticket se creo correctamente",
        });
        setFilters({ ...defaultFilters });
      })
      .catch((e) => {
        console.log(e);
        dispatch({ type: "showLoaderScreen", payload: false });
        if (e.response.data.message && e.response.data.message[0]) {
          toast.error(e.response.message[0]);
        } else {
          if (e.response.data.error) toast.error(e.response.data.error);
        }
      });
  };

  const handleChange = async (option: string) => {
    setText(option);
    await setFilters({ ...filters, page: 1, query: option });
  };

  const handleChangeSeller = async (option: string) => {
    setTextSeller(option);
  };

  const handleSelect = (data: any) => {
    setSelectData(data);
    form.setValue("user_id", data.id);
  };

  const handleSelectSeller = (data: any) => {
    setSelectDataSeller(data);
    form.setValue("seller_id", data.id);
  };

  const getCreatedOrders = useMutation(() =>
    OrderService.getCreateOrderHistory(eventId, filters.limit, filters.page)
  );

  const getUserInfo = useMutation((user_id: string) =>
    UserService.get(user_id)
  );

  const getTicketInfo = useMutation((order_id: string) =>
    OrderService.get(order_id)
  );

  const getTicketsIds = async (orders: IOrderResponse[]) => {
    const aux: { [key: string]: string } = {};
    await Promise.all(
      orders.map(async (order: IOrderResponse) => {
        if (!aux[order.user_id]) {
          const ticket = await getTicketInfo.mutateAsync(order.id);
          aux[order.id] = ticket.order_tickets[0].ticket[0].name;
        }
      })
    );
    return aux;
  };

  const getEmails = async (orders: IOrderResponse[]) => {
    const aux: { [key: string]: string } = {};
    // Use Promise.all to wait for all email retrieval promises to complete
    await Promise.all(
      orders.map(async (order: IOrderResponse) => {
        if (!aux[order.user_id]) {
          const user = await getUserInfo.mutateAsync(order.user_id);
          aux[order.user_id] = user.email;
        }
      })
    );
    return aux;
  };

  useEffect(() => {
    getCreatedOrders.mutateAsync().then((res) => {
      setCreatedOrders(res);
      if (res !== undefined) {
        getEmails(res.rows).then((emails) => {
          setEmailsUsers(emails);
        });
        getTicketsIds(res.rows).then((tickets) => {
          setTicketTypes(tickets);
        });
      }
    });
  }, [filters.limit, filters.page]);

  const handleFilterLogs = async (filter: any) => {
    const newFilters = { ...filters, ...filter };
    await setFilters(newFilters);
    await getCreatedOrders.mutateAsync().then((res) => {
      setCreatedOrders(res);
    });
  };

  const propMethodsSearch = {
    onChange: handleChange,
    handleSelect,
  };

  const propMethodsSearchSeller = {
    onChange: handleChangeSeller,
    handleSelect: handleSelectSeller,
  };

  const onError = (error: any) => {
    console.log("error ", error);
  };

  const handleCloseSelectSeller = () => {
    setSelectDataSeller(null);
    form.setValue("seller_id", undefined);
  };

  return (
    <div>
      {showSuccess ? (
        <div className="flex flex-col gap-10 xl:px-[23rem]">
          <div>
            <img
              src={circleCheck}
              alt="check"
              className="m-auto mb-4 text-center"
            />
            <h1 className="mb-4 text-center text-[20px] font-bold">
              Felicitaciones
            </h1>
            <p className="text-center">Orden creada exitosamente</p>
          </div>

          <div className="mb-16 flex justify-center gap-10 pt-10">
            <button
              onClick={() => {
                form.reset();
                setText("");
                setSelectData(null);
                setShowSuccess(false);
              }}
              className="rounded-full border-[2px] border-black-100 bg-black-100 px-[5.4rem] py-2 font-medium text-white"
            >
              Crear otra Orden
            </button>
          </div>
        </div>
      ) : (
        <div>
          <div className="flex flex-col gap-10">
            <h1 className="text-center text-3xl font-bold">Nueva Orden</h1>
            <FormProvider {...form}>
              <form onSubmit={form.handleSubmit(onSubmit, onError)}>
                <div className="mb-6 w-full">
                  <h2 className="pb-2 text-base font-medium">Elegir Usuario</h2>
                  <SearchAutocomplete
                    value={text}
                    options={users.rows}
                    {...propMethodsSearch}
                  />
                  {selectData && (
                    <div className="mx-auto mt-[20px] w-[75%]">
                      <table id="SellerContainer" className="w-full table-auto">
                        <thead>
                          <tr>
                            <th align="left">
                              <p className="ml-2 font-sans text-[14px] font-[500] leading-[21px] text-[#8083A3]">
                                Nombre
                              </p>
                            </th>
                            <th align="left">
                              <p className="font-sans text-[14px] font-[500] leading-[21px] text-[#8083A3]">
                                Email
                              </p>
                            </th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              <div className="ml-2">
                                {selectData.user_role == "artist" &&
                                !!selectData.nickname ? (
                                  <PictureAndName
                                    profilePicture={selectData.profile_picture}
                                    firstName={selectData.nickname}
                                    lastName={""}
                                  />
                                ) : (
                                  <PictureAndName
                                    profilePicture={selectData.profile_picture}
                                    firstName={selectData.first_name}
                                    lastName={selectData.last_name}
                                  />
                                )}
                              </div>
                            </td>
                            <td>
                              <span className="text-[#7B7B7B]">
                                {selectData.email}
                              </span>{" "}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  )}
                  <Controller
                    name="user_id"
                    render={({ field }) => (
                      <div className="relative mb-6 w-full">
                        <input readOnly type="hidden" {...field} />
                        {form.formState.errors["user_id"] && (
                          <p className="text-red-600 dark:text-red-500 mt-2 text-sm text-red">
                            {String(
                              form.formState.errors["user_id"]
                                ? form.formState.errors["user_id"]?.message
                                : ""
                            )}
                          </p>
                        )}
                      </div>
                    )}
                  />
                </div>

                <div className="flex gap-10">
                  <FormSelectFull
                    name="ticket_id"
                    options={tickets.rows.map((l) => {
                      return { text: l.name, value: l.id };
                    })}
                    label="Seleccione un Ticket"
                    placeholder="..."
                  />
                  <FormInput
                    type="number"
                    name="quantity"
                    label="Cantidad de tickets"
                    min="1"
                  />
                </div>

                <div className="flex gap-10"></div>
                <div className="mx-2 flex w-full justify-between pb-10"></div>

                <div className="mb-6 w-full">
                  <h2 className="pb-2 text-base font-medium">
                    Elegir Vendedor
                  </h2>
                  <SearchAutocomplete
                    value={textSeller}
                    options={filteredTeamMembers}
                    {...propMethodsSearchSeller}
                  />
                  {selectDataSeller && (
                    <div className="mx-auto mt-[20px] w-[75%]">
                      <table id="SellerContainer" className="w-full table-auto">
                        <thead>
                          <tr>
                            <th align="left">
                              <p className="ml-2 font-sans text-[14px] font-[500] leading-[21px] text-[#8083A3]">
                                Nombre
                              </p>
                            </th>
                            <th align="left">
                              <p className="font-sans text-[14px] font-[500] leading-[21px] text-[#8083A3]">
                                Email
                              </p>
                            </th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              <div className="ml-2">
                                <PictureAndName
                                  profilePicture={
                                    selectDataSeller.profile_picture
                                  }
                                  firstName={selectDataSeller.first_name}
                                  lastName={selectDataSeller.last_name}
                                />
                              </div>
                            </td>
                            <td>
                              <span className="text-[#7B7B7B]">
                                {selectDataSeller.email}
                              </span>{" "}
                            </td>
                            <td>
                              <button onClick={handleCloseSelectSeller}>
                                <GrClose />
                              </button>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  )}
                  <Controller
                    name="seller_id"
                    render={({ field }) => (
                      <div className="relative mb-6 w-full">
                        <input readOnly type="hidden" {...field} />
                        {form.formState.errors["seller_id"] && (
                          <p className="text-red-600 dark:text-red-500 mt-2 text-sm text-red">
                            {String(
                              form.formState.errors["seller_id"]
                                ? form.formState.errors["seller_id"]?.message
                                : ""
                            )}
                          </p>
                        )}
                      </div>
                    )}
                  />
                </div>

                <FormInput
                  name="external_reference"
                  type="text"
                  label="External Reference"
                  placeholder="Si dejás vacío se creará una aleatoriamente"
                />

                <div className="mb-16 flex justify-center gap-10 pt-10">
                  <button className="rounded-full border-[2px] border-black-100 bg-black-100 px-[5.4rem] py-2 font-medium  text-white">
                    Crear Orden
                  </button>
                </div>
              </form>
            </FormProvider>

            <div>
              <Title>Historial</Title>
              <table className="my-table-spacing h-full w-full border-separate border-spacing-y-2 gap-5 overflow-x-auto pt-5 text-left">
                <thead className=" divide-solid border ">
                  <tr className=" pb-4 text-sm font-bold text-gray-100">
                    {columnData.map((column, index) => (
                      <th
                        className="border-b-[1px] border-colorBorder pb-4"
                        key={column + index}
                      >
                        {column}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {createdOrders.rows.map((_, i) => (
                    <motion.tr
                      key={i}
                      layout
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      initial={{ opacity: 0.2 }}
                      transition={{ type: "spring", stiffness: 100, bounce: 0 }}
                      className={`h-16  rounded-xl text-start font-medium outline outline-[1px] outline-colorBorder`}
                    >
                      <td className="pl-4 capitalize">{`#${_.id}`}</td>
                      <td className="capitalize">
                        {_.adminCreator ? _.adminCreator : "-"}
                        {/*  {_.user?.status == 'deleted' && <small className="font-light"> (eliminado)</small>} */}
                      </td>
                      <td className="capitalize">
                        {emailUsers[_.user_id] ? emailUsers[_.user_id] : " - "}
                      </td>
                      <td className="pl-4 capitalize">
                        {formatDateTime(_.date)}
                      </td>
                      <td className="pl-6 capitalize">
                        {ticketTypes[_.id] ? ticketTypes[_.id] : " - "}
                      </td>
                      <td className="pl-4 capitalize">
                        {_.quantity} ticket(s)
                      </td>
                    </motion.tr>
                  ))}
                  {!!logs.rows.length && (
                    <motion.tr
                      key={"total"}
                      layout
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      initial={{ opacity: 0.2 }}
                      transition={{ type: "spring", stiffness: 100, bounce: 0 }}
                      className={`h-16 rounded-xl text-start font-extrabold`}
                    >
                      <td className="pr-4 text-right font-bold" colSpan={3}>
                        TOTAL:{" "}
                      </td>
                      <td className="">{logs.rows[0].total} ticket(s)</td>
                    </motion.tr>
                  )}
                </tbody>
              </table>
              {createdOrders.rows.length === 0 ? (
                <p className="text-center">No existen registros disponibles</p>
              ) : null}
              <div className="flex justify-between pt-10">
                <FilterPage
                  handleFilter={handleFilterLogs}
                  total={createdOrders.total}
                  limit={filters.limit}
                  currentPage={filters.page}
                />
                <FilterQtyPage handleFilter={handleFilterLogs} />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

interface RadioButtons {
  toRender: any[];
  selected: string;
  label?: string;
  onChange: (e: any) => void;
}

const RadioModule = ({ toRender, selected, onChange, label }: RadioButtons) => {
  return (
    <div className="mt-4 flex flex-col">
      <h2 className="pb-2 text-base font-medium text-gray-100">{label}</h2>
      {/* <div className={ `flex w-full justify-${ align }` }> */}
      <div className="mt-1 flex w-full flex-row">
        {toRender?.map((option) => (
          <label className="flex w-32 items-center gap-2 " key={option.value}>
            <input
              type="radio"
              /*  name={name} */
              /*   onBlur={onBlur} */
              value={option.value}
              /*  ref={ref} */
              checked={selected === option.value}
              onChange={() => onChange(option.value)}
              /*  disabled={disabled}  */
              className="rounded-full p-[.60rem] checked:bg-black-100 focus:ring-transparent active:bg-black-100"
            />
            <p className="font-bold">{option.label}</p>
          </label>
        ))}
      </div>
    </div>
    /*  {errors[name]&&
              <p className="mt-2 text-sm text-red text-red-600 dark:text-red-500">
                {String(errors[name] ? errors[name]?.message : '')}
              </p>
            } */
    /*  </div> */
  );
};

export default RadioModule;

import React, { FC, useEffect, useState, useContext } from "react";
import { GoGraph } from "react-icons/go";
import { motion } from "framer-motion";
import { PictureAndName } from "../../../rrpp/components/PictureAndName/PictureAndName";
import { FilterDataRadio } from "../../../table/FilterDataRadio";
import ISortOption from "../../../../types/ISortOption";
import { IFilterRequest } from "../../../../types/IFilter";
import { useNavigate } from "react-router-dom";
import {
  IReportPublicSaleResponse,
  IReportRequest,
  IReportTicketsFreeResponse,
  IReportTicketsFreeSellerResponse,
  IReportTicketsFreeTotalsResponse,
} from "../../../../types/IReport";
import ReportService from "../../../../services/reportService";
import { useQuery, useMutation } from "@tanstack/react-query";
import moment from "moment/moment";
import { IEventResponse } from "../../../../types/IEvent";
import EventService from "../../../../services/eventService";
import { StateContext } from "../../../../context/StateProvider";

import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";

type reportProps = {
  producer_id?: string;
  event_id?: string;
};

const defaultFilters = {
  page: 1,
  /* limit: 5,
    status: 'all',
    user_role: 'producer',
    sortBy: 'data1',
    sortOrder: 'desc', */
};

export const DetailsTicketsFree: FC<reportProps> = ({
  producer_id,
  event_id,
}) => {
  const navigate = useNavigate();
  const [data, setData] = useState<IReportTicketsFreeResponse>({
    data: [],
    tickets: [],
    leaders: [],
    sellers: [],
    event: {} as IEventResponse,
    totals: {} as IReportTicketsFreeTotalsResponse,
  });
  const [filters, setFilters] = useState<IFilterRequest>({ ...defaultFilters });
  const [filterTableValue, setFilterTableValue] = useState("");
  const [filterLeaderTableValue, setFilterLeaderTableValue] = useState("");
  const { state, dispatch } = useContext(StateContext);
  const getItems = useMutation((f: IFilterRequest) =>
    ReportService.ticketsFree(f)
  );

  //const getEvent = useMutation(() => EventService.get(event_id || ""));

  const event = useQuery({
    queryKey: ["event", event_id || ""],
    queryFn: () => EventService.get(event_id || ""),
  });

  const applyFilters = async () => {
    let parameters: any = {};
    if (producer_id) parameters.producer_id = producer_id;
    if (event_id) parameters.event_id = event_id;
    await setFilters({ ...filters, ...parameters });
    await getItems.mutateAsync({ ...filters, ...parameters }).then((res) => {
      setData(res);
    });
  };

  useEffect(() => {
    if (event_id) {
      applyFilters();
    }
  }, [event_id]);

  const handleSortLeader = async (leader_id: string) => {
    setFilterTableValue("");
    setFilterLeaderTableValue(leader_id);
    const paramsFilters = {
      ...filters,
      page: 1,
      leader_id: leader_id,
      rrpp_id: null,
      ticket_id: null,
    };
    await setFilters(paramsFilters);
    await getItems.mutateAsync(paramsFilters).then((res) =>
      setData({
        ...data,
        data: res.data,
        totals: res.totals,
        sellers: res.sellers,
      })
    );
  };

  const handleSortSeller = async (rrpp_id: string) => {
    setFilterTableValue(rrpp_id);
    const paramsFilters = {
      ...filters,
      page: 1,
      rrpp_id: rrpp_id,
      ticket_id: null,
    };
    await setFilters(paramsFilters);
    await getItems
      .mutateAsync(paramsFilters)
      .then((res) => setData({ ...data, data: res.data, totals: res.totals }));
  };

  const handleSortTicket = async (ticket_id: string) => {
    setFilterTableValue(ticket_id);
    const paramsFilters = {
      ...filters,
      page: 1,
      ticket_id: ticket_id,
      rrpp_id: null,
    };
    await setFilters(paramsFilters);
    await getItems
      .mutateAsync(paramsFilters)
      .then((res) => setData({ ...data, data: res.data, totals: res.totals }));
  };

  const getCsv = useMutation(() => ReportService.getTicketsFreeCsv(filters));

  const handleCsv = async () => {
    if (!event.data) {
      dispatch({
        type: "toastError",
        payload: "Elegí un evento primero.",
      });
      return;
    }

    try {
      await getCsv.mutateAsync().then((response) => {
        const nameFile = `TICKETS_FREE_ASIGNADOS_X_BO-${event.data.name}-${event.data.producer.name}.csv`;
        const url = window.URL.createObjectURL(new Blob([response]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", nameFile);
        document.body.appendChild(link);
        link.click();
      });
    } catch (error) {
      dispatch({
        type: "toastError",
        payload: "No pudimos generar el CSV.",
      });
    }
  };

  const defaultFilterAll = { value: "", text: "TODOS" } as ISortOption;
  const sellersGetFirstNames = (
    items: Array<IReportTicketsFreeSellerResponse>
  ) => {
    return items.map((i) => {
      return { value: i.value, text: i.text } as ISortOption;
    });
  };
  const sellersGetLastNames = (
    items: Array<IReportTicketsFreeSellerResponse>
  ): Array<ISortOption> => {
    items = items.filter((i) => !!i.text2 && i.text2.trim().length);
    return items.map((i) => {
      return { value: i.value, text: i.text2 + ` (${i.text})` } as ISortOption;
    });
  };
  return (
    <div className="rounded-xl outline outline-2 outline-colorBorder 2xl:h-full ">
      <Accordion type="single" collapsible>
        <AccordionItem value="item-1">
          <AccordionTrigger>
            <div className="flex h-16 w-full items-center justify-between border-b border-colorBorder px-10">
              <h3 className="text-xl font-bold">Tickets Free Asignados x BO</h3>
              <div className="flex gap-5">
                <button
                  className="flex items-center justify-center"
                  onClick={handleCsv}
                >
                  <GoGraph className="mr-1" />
                  <span className="text-xs font-bold">Exportar a CSV</span>
                </button>
              </div>
            </div>
          </AccordionTrigger>
          <AccordionContent>
            <table className="my-table-spacing  w-full border-separate border-spacing-y-2 gap-5 overflow-x-auto px-6 text-left">
              <thead className="divide-solid border">
                <tr className="pb-4 text-sm font-bold text-gray-100">
                  <td className="py-2">
                    <FilterDataRadio
                      setSort={handleSortLeader}
                      options={[defaultFilterAll, ...data.leaders]}
                      title="Team Leader"
                      externalCheckedValue={filterLeaderTableValue}
                    />
                  </td>
                  <td className="py-2">
                    <FilterDataRadio
                      setSort={handleSortSeller}
                      options={[
                        defaultFilterAll,
                        ...sellersGetFirstNames(data.sellers),
                      ]}
                      title="Nombre"
                      externalCheckedValue={filterTableValue}
                    />
                  </td>
                  <td className="py-2">
                    <FilterDataRadio
                      setSort={handleSortSeller}
                      options={[
                        defaultFilterAll,
                        ...sellersGetLastNames(data.sellers),
                      ]}
                      title="Apellido"
                      externalCheckedValue={filterTableValue}
                    />
                  </td>
                  <td className="py-4">Mail</td>
                  <td className="py-2">
                    <FilterDataRadio
                      setSort={handleSortTicket}
                      options={[defaultFilterAll, ...data.tickets]}
                      title="Tipo de Free"
                      externalCheckedValue={filterTableValue}
                    />
                  </td>
                  <td className="px-1 py-4 text-center">Asignados x BO</td>
                  <td className="px-1 py-4">Asignados</td>
                  <td className="px-1 py-4">Enviados</td>
                  <td className="px-1 py-4">Ingresados</td>
                  <td className="px-1 py-4 text-center">
                    Ingresados
                    <br />
                    por TL
                  </td>
                  <td className="px-1 py-4 text-center">
                    Resto
                    <br />
                    en su poder
                  </td>
                </tr>
              </thead>
              <tbody>
                {!!event_id &&
                  data.data.map((row, i) =>
                    row.order_tickets.map((ot, i2) => (
                      <motion.tr
                        key={ot.id}
                        layout
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        initial={{ opacity: 0.2 }}
                        transition={{
                          type: "spring",
                          stiffness: 100,
                          bounce: 0,
                        }}
                        className={`h-16 rounded-xl text-start font-medium outline outline-[1px] outline-colorBorder`}
                      >
                        <td className="pl-4 text-sm font-extrabold">
                          {row.leader
                            ? `${row.leader.first_name} ${row.leader.last_name}`
                            : "-"}
                          {row.leader?.status == "deleted" && (
                            <small className="font-light"> (eliminado)</small>
                          )}
                        </td>
                        <td className="pl-4 text-sm font-normal">
                          {row.rrpp ? row.rrpp.first_name : "-"}
                        </td>
                        <td className="pl-4 text-sm font-normal">
                          {row.rrpp ? row.rrpp.last_name : "-"}
                          {row.rrpp?.status == "deleted" && (
                            <small className="font-light"> (eliminado)</small>
                          )}
                        </td>
                        <td className="pl-4 text-sm font-normal">
                          {row.rrpp ? row.rrpp.email : "-"}
                        </td>
                        <td className="pl-4 text-sm font-normal">
                          {ot.ticket
                            ? `${ot.ticket.name} H/${data.event?.time_start}HS`
                            : "-"}
                        </td>
                        <td className="pl-4 text-sm font-normal">
                          {ot.bo_order_tickets_count}
                        </td>
                        <td className="pl-4 text-sm font-normal">
                          {ot.order_tickets_size - ot.bo_order_tickets_count}
                        </td>
                        <td className="pl-4 text-sm font-normal">{ot.sent}</td>
                        <td className="pl-4 text-sm font-normal">
                          {row.leader ? ot.used : "0"}
                        </td>
                        <td className="pl-4 text-sm font-normal">
                          {!row.leader ? ot.used : "0"}
                        </td>
                        <td className="pl-4 text-sm font-normal">
                          {ot.order_tickets_size - ot.sent}
                        </td>
                      </motion.tr>
                    ))
                  )}

                {!!event_id && !!data.data.length && (
                  <motion.tr
                    key={"total"}
                    layout
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    initial={{ opacity: 0.2 }}
                    transition={{ type: "spring", stiffness: 100, bounce: 0 }}
                    className={`h-16  rounded-xl text-start font-medium outline outline-[1px] outline-colorBorder`}
                  >
                    <td className="pl-4 text-center font-extrabold" colSpan={5}>
                      TOTAL:
                    </td>
                    <td className="pl-4 font-bold">{data.totals?.ab}</td>
                    <td className="pl-4 font-bold">{data.totals?.a}</td>
                    <td className="pl-4 font-bold">{data.totals?.e}</td>
                    <td className="pl-4 font-bold">{data.totals?.i}</td>
                    <td className="pl-4 font-bold">{data.totals?.itl}</td>
                    <td className="pl-4 font-bold">{data.totals?.r}</td>
                  </motion.tr>
                )}
              </tbody>
            </table>
            {(data.data.length == 0 || !event_id) && (
              <p className="text-center">No existen datos disponibles</p>
            )}
          </AccordionContent>
        </AccordionItem>
      </Accordion>
    </div>
  );
};

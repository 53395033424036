import React from "react";
import { useContext, useState } from "react";
import { StateContext } from "../../../context/StateProvider";
import { FormProvider, useForm } from "react-hook-form";
import { IProducerRequest, IProducerResponse } from "../../../types/IProducer";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import * as yup from "yup";
import FormInput from "../../FormElement/FormInput";
import FormCropperImage from "../../FormElement/FormCropperImage";
import { useEffect } from "react";
import jsonToFormData from "@ajoelp/json-to-formdata";
import { AuthContext } from "../../../context/AuthContext";
import { blobToFile } from "../../../utils/form";
import { useMutation } from "@tanstack/react-query";
import ProducerService from "../../../services/producerService";

export const PopUpEditCard = ({}) => {
  const { user } = useContext(AuthContext);
  const [id, setId] = useState("");
  const updateItems = useMutation((item: FormData) =>
    ProducerService.update(id, item)
  );

  const defaultValues = {
    id: "",
    name: "",
    picture: "",
  };

  const schema = yup.object().shape({
    name: yup.string().required("El nombre es requerido"),
    picture: yup.mixed(),
  });

  type typeProps = {
    handleSelectItem: (data: IProducerRequest) => void;
    data: IProducerResponse;
  };

  const form = useForm<IProducerRequest>({
    resolver: yupResolver(schema),
    defaultValues: defaultValues,
  });

  const { state, dispatch } = useContext(StateContext);
  const { id: idProducer, name, picture } = state.producerSelected;

  const handleExit = () => {
    dispatch({ type: "EditCard", payload: null });
    dispatch({ type: "setProducerUpdated", payload: null });
  };

  const handleSubmit = async (dataSubmit: IProducerRequest) => {
    dataSubmit.client_id = user.id;

    if (dataSubmit.picture && dataSubmit.picture instanceof Blob) {
      dataSubmit.picture = blobToFile(dataSubmit.picture);
    }

    let formData = jsonToFormData(dataSubmit, {
      arrayIndexes: true,
      excludeNull: true,
    });

    dispatch({ type: "showLoaderScreen", payload: true });

    await updateItems.mutateAsync(formData).then((res) => {
      dispatch({ type: "showLoaderScreen", payload: false });
      dispatch({ type: "EditCard", payload: false });
      setId("");

      dispatch({ type: "setProducerUpdated", payload: res });
    });
  };

  useEffect(() => {
    form.setValue("name", name);
    form.setValue("picture", picture);
    setId(idProducer);
  }, []);

  const handleError = (error: any) => {
    console.log("error ", error);
  };

  return (
    <div className="fixed z-50 flex h-screen w-screen items-center justify-center bg-black-100/30 backdrop-blur-md">
      <div className="h-150 absolute top-20 m-auto mx-6 flex w-96 flex-col justify-center rounded-2xl bg-white">
        <div className="grid grid-cols-1 gap-5 border-y border-colorBorder px-8 pb-4 pt-5">
          <h2 className="text-center text-lg">Editar Productora</h2>
          <FormProvider {...form}>
            <form onSubmit={form.handleSubmit(handleSubmit, handleError)}>
              <FormInput name="name" type="text" label="Nombre" />
              <FormCropperImage
                name="picture"
                label="Foto de la productora"
                accept="image/png, image/gif, image/jpeg"
                initialAspectRatio={4 / 4}
                cropBoxResizable={false}
                dragMode="move"
              />
              <div className="m-4 flex-col">
                <button
                  type="submit"
                  className="font-mediun mx-auto w-72 rounded-full border-[2px] border-black-100 bg-black-100 px-12 py-2 text-white"
                >
                  Guardar
                </button>
                <button
                  onClick={handleExit}
                  type="submit"
                  className="text-dark m-auto my-2 w-72 rounded-full border-[2px] border-black-100 bg-white px-12 py-2 font-medium"
                >
                  Cancelar
                </button>
              </div>
            </form>
          </FormProvider>
        </div>
      </div>
    </div>
  );
};

import { ContextProps } from "./StateProvider";
import { toast } from "react-toastify";

type ActionType =
  | { type: "addTeamMember"; payload: {} }
  | { type: "showTeam"; payload: null }
  | { type: "setMembers"; payload: [] }
  | { type: "setAvailableMembers"; payload: [] }
  | { type: "removeMember"; payload: null }
  | { type: "showAssignLeader"; payload: null }
  | { type: "membersToAssign"; payload: null }
  | { type: "membersAssign"; payload: null }
  | { type: "showReview"; payload: null }
  | { type: "setReviewData"; payload: null }
  | { type: "addNewsSection"; payload: null }
  | { type: "addEventSection"; payload: null }
  | { type: "showEvents"; payload: null }
  | { type: "setEvents"; payload: [] }
  | { type: "selectedEvents"; payload: [] }
  | { type: "removeSelectedEvent"; payload: null }
  | { type: "addArtistEvent"; payload: null }
  | { type: "showArtists"; payload: null }
  | { type: "setArtists"; payload: [] }
  | { type: "selectedArtists"; payload: [] }
  | { type: "removeSelectedArtist"; payload: null }
  | { type: "addValidatorEvent"; payload: null }
  | { type: "showValidators"; payload: null }
  | { type: "setValidators"; payload: [] }
  | { type: "selectedValidators"; payload: [] }
  | { type: "removeSelectedValidator"; payload: null }
  | { type: "addUsersDetailTicket"; payload: null }
  | { type: "showUsers"; payload: null }
  | { type: "setUsers"; payload: [] }
  | { type: "selectedUsers"; payload: [] }
  | { type: "setOrderTicket"; payload: null }
  | { type: "removeSelectedUser"; payload: null }
  | { type: "showLoaderScreen"; payload: null }
  | { type: "showCropper"; payload: null }
  | { type: "cropperOptions"; payload: null }
  | { type: "currentInputImageCropper"; payload: null }
  | { type: "reloadEvent"; payload: null }
  | { type: "currentInputOnChangeCropper"; payload: null }
  | { type: "message"; payload: null }
  | { type: "toastError"; payload: null }
  | { type: "toastSuccess"; payload: null }
  | { type: "setProducerSelected"; payload: null }
  | { type: "setProducerUpdated"; payload: null }
  | { type: "EditCard"; payload: null }
  | { type: "addProducerEvent"; payload: null }
  | { type: "listProducersByUser"; payload: [] }
  | { type: "selectedProducers"; payload: [] }
  | { type: "removeSelectedProducer"; payload: null }
  | { type: "showProducers"; payload: null }
  | { type: "showRequestRefund"; payload: null }
  | { type: "setOrder"; payload: [] }
  | { type: "setOrdersTickets"; payload: [] }
  | { type: "setPermittedProducers"; payload: [] }
  | { type: "importCsv"; payload: [] }
  | { type: "reloadOrderTicket"; payload: null }
  | { type: "showHistory"; payload: null }
  | { type: "typeHistory"; payload: null }
  | { type: "eventIDHistory"; payload: null }
  | { type: "inviteViaCSV"; payload: [] }
  | { type: "selectedNews"; payload: [] }
  | { type: "removeSelectedNews"; payload: null }
  | { type: "selectedSections"; payload: null };

export const stateReducer = (state: ContextProps, action: ActionType) => {
  switch (action.type) {
    case "addTeamMember":
      return {
        ...state,
        showAddTeam: !state.showAddTeam,
        ...(action.payload ? action.payload : {}),
      };
    case "showTeam":
      return { ...state, showTeam: !state.showTeam };
    case "setAvailableMembers":
      return { ...state, availableMembers: action.payload };
    case "setMembers":
      const givenArray = action.payload as Array<string>;
      const newMembers = state.availableMembers.filter((item: any) => {
        return givenArray.indexOf(item.id) > -1;
      });
      return { ...state, members: newMembers };
    case "removeMember":
      const sliceMembers = state.members.filter((item: any) => {
        return item.id != action.payload;
      });
      return { ...state, members: sliceMembers };
    case "showAssignLeader":
      return { ...state, showAssignLeader: !state.showAssignLeader };
    case "membersToAssign":
      return { ...state, membersToAssign: action.payload };
    case "membersAssign":
      return { ...state, membersAssign: action.payload };
    case "showReview":
      return { ...state, showReview: !state.showReview };
    case "setReviewData":
      return { ...state, reviewData: action.payload };
    case "addNewsSection":
      console.log("addNewsSection");
      return { ...state, showAddNews: !state.showAddNews };
    case "addEventSection":
      return { ...state, showAddEvent: !state.showAddEvent };
    case "showEvents":
      return { ...state, showEvent: !state.showEvent };
    case "setEvents":
      return { ...state, events: action.payload };
    case "selectedNews":
      return { ...state, selectedNews: action.payload };
    case "selectedSections":
      return { ...state, selectedSections: action.payload };
    case "removeSelectedNews":
      const sliceNews = state.selectedNews.filter((item: any) => {
        if (item != null && item.id != action.payload) {
          return item;
        }
      });
      return { ...state, selectedNews: sliceNews };
    case "selectedEvents":
      return { ...state, selectedEvents: action.payload };
    case "removeSelectedEvent":
      const sliceEvents = state.selectedEvents.filter((item: any) => {
        if (item != null && item.id != action.payload) {
          return item;
        }
      });
      return { ...state, selectedEvents: sliceEvents };
    case "addArtistEvent":
      return { ...state, showAddArtist: !state.showAddArtist };
    case "showArtists":
      return { ...state, showArtist: !state.showArtist };
    case "setArtists":
      return { ...state, artists: action.payload };
    case "selectedArtists":
      return { ...state, selectedArtists: action.payload };
    case "removeSelectedArtist":
      const sliceArtists = state.selectedArtists.filter((item: any) => {
        return item.id != action.payload;
      });
      return { ...state, selectedArtists: sliceArtists };
    case "addValidatorEvent":
      return { ...state, showAddValidator: !state.showAddValidator };
    case "showValidators":
      return { ...state, showValidator: !state.showValidator };
    case "setValidators":
      return { ...state, validators: action.payload };
    case "selectedValidators":
      return { ...state, selectedValidators: action.payload };
    case "removeSelectedValidator":
      const sliceValidators = state.selectedValidators.filter((item: any) => {
        return item.id != action.payload;
      });
      return { ...state, selectedValidators: sliceValidators };
    case "addUsersDetailTicket":
      return { ...state, showAddUser: !state.showAddUser };
    case "showUsers":
      return { ...state, showUser: !state.showUser };
    case "setUsers":
      return { ...state, users: action.payload };
    case "selectedUsers":
      return { ...state, selectedUsers: action.payload };
    case "setOrderTicket":
      return { ...state, selectedOrderTicket: action.payload };
    case "removeSelectedUser":
      const sliceUsers = state.selectedUsers.filter((item: any) => {
        return item.id != action.payload;
      });
      return { ...state, selectedUsers: sliceUsers };
    case "showLoaderScreen":
      return { ...state, showLoaderScreen: !!action.payload };
    case "showCropper":
      return { ...state, showCropper: !!action.payload };
    case "cropperOptions":
      return { ...state, cropperOptions: action.payload };
    case "currentInputImageCropper":
      return { ...state, currentInputImageCropper: action.payload };
    case "currentInputOnChangeCropper":
      return { ...state, currentInputOnChangeCropper: action.payload };
    case "reloadEvent":
      return { ...state, reloadEvent: action.payload };
    case "message":
      return { ...state, showMessage: !state.showMessage };
    case "toastError":
      return { ...state, alert: toast.error(action.payload) };
    case "toastSuccess":
      return { ...state, alert: toast.success(action.payload) };
    case "EditCard":
      return { ...state, showEditCard: !state.showEditCard };
    case "setProducerSelected":
      return { ...state, producerSelected: action.payload };
    case "setProducerUpdated":
      return { ...state, producerUpdated: action.payload };
    case "addProducerEvent":
      return { ...state, showAddProducer: !state.showAddProducer };
    case "listProducersByUser":
      return { ...state, listProducersByUser: action.payload };
    case "selectedProducers":
      return { ...state, selectedProducers: action.payload };
    case "removeSelectedProducer":
      const sliceProducers = state.selectedProducers.filter((item: any) => {
        return item.id != action.payload;
      });
      return { ...state, selectedProducers: sliceProducers };
    case "showProducers":
      return { ...state, showProducer: !state.showProducer };
    case "showRequestRefund":
      return { ...state, showRequestRefund: !state.showRequestRefund };
    case "setOrdersTickets":
      return { ...state, orders_ticket: action.payload };
    case "setOrder":
      return { ...state, order: action.payload };
    case "setPermittedProducers":
      return { ...state, permittedProducers: action.payload };
    case "importCsv":
      return {
        ...state,
        showImportCsv: !state.showImportCsv,
        params: action.payload,
      };
    case "reloadOrderTicket":
      return { ...state, reloadOrderTicket: action.payload };
    case "showHistory":
      return { ...state, showHistory: !state.showHistory };
    case "typeHistory":
      return { ...state, typeHistory: action.payload };
    case "eventIDHistory":
      return { ...state, eventIDHistory: action.payload };
    case "inviteViaCSV":
      return {
        ...state,
        showInviteViaCSV: !state.showInviteViaCSV,
        params: action.payload,
      };
    default:
      return state;
  }
};

import React, { FC, useState } from "react";
import { Controller, useFormState, useFormContext } from "react-hook-form";
import { FormInputProps } from "../../../types/FormInputsProps";
import icon from "../../../assets/imgIcon.svg";
import { FaTrashAlt } from "react-icons/fa";

const FormUploadImage: FC<FormInputProps> = ({ name, label, accept }) => {
  const formState = useFormState();
  const { setValue } = useFormContext();
  const [img, setImg] = useState<string>();
  const handleDeleteImage = (onChange: any) => {
    onChange("");
    setImg("");
  };

  return (
    <Controller
      name={name}
      render={({ field: { name, value, onBlur, ref, onChange } }) => (
        <div className="relative flex w-full flex-col justify-center">
          <h3 className="pb-3 font-medium text-gray-100">{label}</h3>
          <label htmlFor={`upload_${name}`}>
            <div className="flex h-56 cursor-pointer items-center justify-center border border-dashed border-[#E4E6E8] bg-[#F5F5FA]">
              <img
                src={img || value || icon}
                width={img || value ? "auto" : "10%"}
                style={{ maxWidth: "100%", maxHeight: "100%" }}
              />
            </div>
            <input
              type="file"
              className={"hidden"}
              id={`upload_${name}`}
              name={name}
              onBlur={onBlur}
              ref={ref}
              onChange={(e: React.SyntheticEvent<EventTarget>) => {
                const target = e.target as HTMLInputElement;
                if (!target.files) return;
                const newFile = Object.values(target.files).map(
                  (file: File) => file
                );
                setImg(URL.createObjectURL(newFile[0]));
                onChange(newFile[0]);
                setValue(name, newFile[0]);
                onBlur();
              }}
              accept={accept ? accept : ""}
            />
          </label>
          {formState.errors[name] && (
            <p className="text-red-600 dark:text-red-500 mt-2 text-sm text-red">
              {String(
                formState.errors[name] ? formState.errors[name]?.message : ""
              )}
            </p>
          )}
          {(!!img || !!value) && (
            <div
              className="absolute right-4 top-12 cursor-pointer"
              onClick={(e) => handleDeleteImage(onChange)}
            >
              <FaTrashAlt size={23} />
            </div>
          )}
        </div>
      )}
    />
  );
};

export default FormUploadImage;

import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import { motion } from "framer-motion";

import TableCellLink from "../../../FormElement/TableCellLink";
import { Title } from "../../../events/Title";
import { IOrderTicketsResponse } from "../../../../types/IOrderTicket";
import { IFilterRequest } from "../../../../types/IFilter";
import { FilterPage } from "../../../table/FilterPage";
import { FilterSection } from "../../../table/FilterSection";
import { FilterQtyPage } from "../../../table/FilterQtyPage";
import RrppService from "../../../../services/rrppService";
import { formatDateTime } from "../../../../utils/format-date";
import { formatMoney } from "../../../../utils/format-money";
import { getTicketStatusFromValue } from "../../../../utils/status";
import config from "../../../../config/variables";
import LoaderCircle from "../../../FormElement/LoaderCircle";

const defaultFilters = {
  limit: config.filter_default_limit,
  page: 1,
  status: "all",
  sortBy: "createdAt",
  sortOrder: "desc",
  group_order: true,
};

const filterSectionProps = [
  { title: "Todas las órdenes", id: "all" },
  { title: "Órdenes Free", id: "Free" },
];

export const OrdersUser = () => {
  const { user_id } = useParams();
  const [result, setResult] = useState<IOrderTicketsResponse | null>(null);
  const [filters, setFilters] = useState<IFilterRequest>(defaultFilters);
  const [search, setSearch] = useState("");
  let paramsFilters: any = { status: "all", sortBy: "createdAt" };

  const getItems = useMutation(() =>
    RrppService.getOrderTickets(user_id || "", filters)
  );

  useEffect(() => {
    getItems.mutateAsync().then((res) => setResult(res));
  }, []);

  const handleFilter = async (filter: IFilterRequest) => {
    await setFilters({ ...filters, ...filter });
    await getItems.mutateAsync().then((res) => setResult(res));
  };

  const columnData = [
    "Nombre",
    "Fecha y hora",
    "Locación",
    "Tipo de ticket",
    "Precio",
    "Status",
  ];

  const searcher = async (e: any) => {
    setSearch(e.target.value);
    paramsFilters = { ...filters, query: e.target.value };
    await setFilters({ ...filters, page: 1, query: e.target.value });
    await getItems.mutateAsync().then((res) => setResult(res));
  };

  const handleFilterSection = async (filter: string) => {
    await setFilters({ ...filters, page: 1, status: filter });
    getItems.mutateAsync().then((res) => setResult(res));
  };

  const getOrderDetailUrl = (orderDetailID: string): string => {
    if (window.location.pathname.includes("admin/"))
      return `/admin/users/orderTicket/${orderDetailID}/${user_id}`;
    else return `/client/users/orderTicket/${orderDetailID}/${user_id}`;
  };

  return (
    <div>
      <Title>Órdenes</Title>
      <div className="mb-7 flex justify-between border-b-[1px] border-colorBorder pr-5 pt-10  ">
        <FilterSection
          filterSectionProps={filterSectionProps}
          handleFilter={handleFilterSection}
        />
        <div className="flex items-center gap-5 ">
          <input
            type="text"
            placeholder="Search..."
            className="hover:text-black rounded-full border-[2px] border-black-100 px-4 py-1 font-bold  text-black-100"
            value={search}
            onChange={searcher}
          />
        </div>
      </div>

      <table className="my-table-spacing h-full w-full border-separate border-spacing-y-2 gap-5 overflow-x-auto pt-5 text-left">
        <thead className=" divide-solid border ">
          <tr className=" pb-4 text-sm font-bold text-gray-100">
            {columnData.map((column, index) => (
              <th
                className="border-b-[1px] border-colorBorder pb-4"
                key={column + index}
              >
                {column}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {result?.rows.map((row, i) => (
            <motion.tr
              key={i}
              layout
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              initial={{ opacity: 0.2 }}
              transition={{ type: "spring", stiffness: 100, bounce: 0 }}
              className={`h-16  rounded-xl text-start font-medium outline outline-[1px] outline-colorBorder`}
            >
              <TableCellLink
                href={getOrderDetailUrl(row.order_id)}
                className="pl-4"
              >
                {row.ticket?.event?.name}
              </TableCellLink>
              <TableCellLink
                href={getOrderDetailUrl(row.order_id)}
                className=""
              >
                {formatDateTime(row.ticket?.event?.date_start)}
              </TableCellLink>
              <TableCellLink
                href={getOrderDetailUrl(row.order_id)}
                className="flex h-full items-center gap-2 "
              >
                {row.ticket?.event?.place?.name}
              </TableCellLink>
              <TableCellLink href={getOrderDetailUrl(row.order_id)}>
                {row.ticket?.name}
              </TableCellLink>
              <TableCellLink href={getOrderDetailUrl(row.order_id)}>
                {formatMoney(row.order?.total)}
              </TableCellLink>
              <td>
                <div className="w-34 flex items-center justify-center gap-3 rounded-full border-[1px] border-colorBorder  py-2 text-center ">
                  <div
                    className={`rounded-full  ${
                      ["approved", "success"].includes(row.order?.status)
                        ? "bg-green"
                        : "bg-red"
                    } p-[.35rem]`}
                  />
                  <p className="capitalize">
                    {getTicketStatusFromValue(row.order?.status || "pending")}
                  </p>
                </div>
              </td>
            </motion.tr>
          ))}
        </tbody>
      </table>
      {!result && (
        <div className="mt-4 flex justify-center">
          <LoaderCircle width={32} height={32} />
        </div>
      )}
      {result?.rows.length == 0 && (
        <p className="text-center">No existen órdenes disponibles</p>
      )}
      <div className="flex justify-between pt-10">
        <FilterPage
          handleFilter={handleFilter}
          total={result?.total}
          limit={filters.limit}
          currentPage={filters.page}
        />
        <FilterQtyPage handleFilter={handleFilter} />
      </div>
    </div>
  );
};

export const updateUrl = (
  params: Record<string, string | number | undefined>
) => {
  const urlParams = new URLSearchParams(window.location.search);

  Object.entries(params).forEach(([key, value]) => {
    value ? urlParams.set(key, value.toString()) : urlParams.delete(key);
  });

  const newUrl = `${document.location.pathname}?${urlParams}`;

  window.history.pushState({ path: newUrl }, "", newUrl);
};

import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";

import { NewSellerView } from "./newSeller/newSellerView";
import { AddSellerView } from "./addSeller/addSellerView";
import { SelectLeaderView } from "./selectLeader/selectLeaderView";
import { IUserResponse } from "../../types/IUser";
import { IRrppResponse } from "../../types/IRrpp";
import RrppService from "../../services/rrppService";
import { AuthContext } from "../../context/AuthContext";

export const NewSeller = () => {
  const { producer } = useContext(AuthContext);
  const { seller_id, producer_producer_id } = useParams();

  const [currentView, setCurrentView] = useState<string>("search");
  const [selectedRrpp, setSelectedRrpp] = useState<IRrppResponse>(
    {} as IRrppResponse
  );

  const getItem = useMutation(() => RrppService.get(seller_id || ""));

  const handleSelectRrpp = (item: IRrppResponse) => {
    setSelectedRrpp(item);
    setCurrentView("detail");
  };

  const handleSetSection = (section: string) => {
    setCurrentView(section);
  };

  const handleSetLeader = (item: IUserResponse) => {
    selectedRrpp.team_leader = item;
    setSelectedRrpp(selectedRrpp);
    setCurrentView("detail");
  };

  let lastSelectedLeader: IUserResponse;

  const setRole = (role: string) => {
    selectedRrpp.user_role = role;
    if (role == "leader" && selectedRrpp?.team_leader) {
      lastSelectedLeader = selectedRrpp.team_leader;
    }

    setSelectedRrpp(selectedRrpp);
    if (role == "seller") {
      if (lastSelectedLeader) {
        selectedRrpp.team_leader = lastSelectedLeader;
      } else {
        selectedRrpp.team_leader = undefined;
      }
      setSelectedRrpp(selectedRrpp);
    }
  };

  useEffect(() => {
    if (seller_id) {
      setCurrentView("detail");
      getItem.mutateAsync().then((res) => {
        const recoveredLeader = recoverLeader(res);
        if (recoveredLeader) {
          res.team_leader = recoveredLeader;
          handleSetLeader(recoveredLeader);
        }
        setSelectedRrpp(res);
      });
    }
  }, []);

  const recoverLeader = (res: IRrppResponse) => {
    if (producer) {
      const recoverTeam = res.teams.find(
        (t) => t.producer_producer_id == producer.id
      );
      if (recoverTeam && recoverTeam.leader && recoverTeam.leader[0])
        return recoverTeam.leader[0];
    }
    if (producer_producer_id) {
      const recoverTeam = res.teams.find(
        (t) => t.producer_producer_id == producer_producer_id
      );
      if (recoverTeam && recoverTeam.leader && recoverTeam.leader[0])
        return recoverTeam.leader[0];
    }
    return null;
  };

  function renderSwitch() {
    switch (currentView) {
      case "search":
        return <NewSellerView handleSelectRrpp={handleSelectRrpp} />;
      case "detail":
        return (
          <AddSellerView
            selected={selectedRrpp}
            handleSetSection={handleSetSection}
            handleSetRole={setRole}
          />
        );
      case "select_leader":
        return (
          <SelectLeaderView
            selected={selectedRrpp}
            handleSetSection={handleSetSection}
            handleSetLeader={handleSetLeader}
          />
        );
      default:
        return <NewSellerView handleSelectRrpp={handleSelectRrpp} />;
    }
  }

  return <div className="relative w-full">{renderSwitch()}</div>;
};

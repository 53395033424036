import { FC, HTMLAttributes } from "react";
import { Link } from "react-router-dom";

type TableCellLinkProps = {
  href: string;
};

const classCellLink = "inline-grid w-full h-full items-center";

const TableCellLink: FC<
  TableCellLinkProps & HTMLAttributes<HTMLTableCellElement>
> = (props) => {
  return (
    <td className={props.className}>
      <Link to={props.href} className={classCellLink}>
        {props.children}
      </Link>
    </td>
  );
};

export default TableCellLink;

import { Row } from "./row";
import { IGroupedOffersByTicket } from "../../../../types/IOffer";

interface TicketsTableProps {
  groupedOffersByTicket: IGroupedOffersByTicket[];
}

export const TicketsTable = ({ groupedOffersByTicket }: TicketsTableProps) => {
  return (
    <>
      <div className="border-gray-500 font-700 mb-4 mt-20 flex gap-[1rem] border-b px-6 pb-4 font-semibold">
        <p className="grow">Tipo de ticket</p>
        <p className="basis-[100px] text-center">Cantidad</p>
        <p className="basis-[100px] text-center">Vendidas</p>
        <p className="hidden basis-[100px] md:block"></p>
      </div>
      <ul>
        {groupedOffersByTicket.map((group) => (
          <Row key={group.ticket_id} group={group} />
        ))}
      </ul>
    </>
  );
};

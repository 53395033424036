import { useNavigate } from "react-router-dom";
import { QueryObserverResult, useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";
import jsonToFormData from "@ajoelp/json-to-formdata";

import EventService from "../../../services_new/eventService";

interface useActionsProps {
  refetch: () => Promise<QueryObserverResult>;
}

const useActions = ({ refetch }: useActionsProps) => {
  const navigate = useNavigate();

  const updateEventVisibility = useMutation(
    ({ event_id, hidden }: { event_id: string; hidden: boolean }) =>
      EventService.updateVisibility(event_id, hidden),
    {
      onSuccess: (res) => {
        toast.success("Evento editado correctamente.");
        refetch();
      },
      onError: (err) => {
        toast.error("No pudimos editar el evento.");
      },
    }
  );

  const updateStatus = useMutation(
    ({ event_id, status }: { event_id: string; status: string }) =>
      EventService.updateStatus(event_id, status),
    {
      onSuccess: (res) => {
        toast.success("Evento editado correctamente.");
        refetch();
      },
      onError: (err) => {
        toast.error("No pudimos editar el evento.");
      },
    }
  );

  const authorizeEvent = useMutation(
    ({ event_id, data }: { event_id: string; data: FormData }) =>
      EventService.update(event_id, data),
    {
      onSuccess: (res) => {
        toast.success("Evento autorizado correctamente.");
        refetch();
      },
      onError: (err) => {
        toast.error("No pudimos autorizar el evento.");
      },
    }
  );

  const deleteEvent = useMutation(
    (id: string) => EventService.sendRecycler(id),
    {
      onSuccess: (res) => {
        toast.success("Evento movido a la papelera.");
        refetch();
      },
      onError: (err) => {
        toast.error("No pudimos eliminar el evento.");
      },
    }
  );

  const handleUpdateEventVisibility = (event_id: string, hidden: boolean) => {
    updateEventVisibility.mutate({ event_id, hidden });
  };

  const handleUpdateStatus = (event_id: string, status: string) => {
    updateStatus.mutate({ event_id, status });
  };

  const handleAuthorizeEvent = (event_id: string) => {
    const dataUpdate = { status: "active", admin_action: "approved" };

    let data = jsonToFormData(dataUpdate, {
      arrayIndexes: true,
      excludeNull: true,
    });

    authorizeEvent.mutate({ event_id, data });
  };

  const handleDeleteEvent = (event_id: string, hasOrders: boolean = false) => {
    if (
      !window.confirm(
        "¿Confirma que quiere enviar este registro a la papelera de reciclaje?"
      )
    )
      return;

    if (
      hasOrders &&
      !window.confirm(
        "¡Cuidado! Este evento tiene órdenes asignadas. ¿Estás seguro?"
      )
    )
      return;

    deleteEvent.mutate(event_id);
  };

  const handleReviewEvent = (event_id: string) => {
    navigate(`/admin/events/${event_id}/edit`);
  };

  const handleComments = (event_id: string) => {
    navigate(`/admin/events/${event_id}/comments`);
  };

  const isLoading =
    updateEventVisibility.isLoading ||
    deleteEvent.isLoading ||
    updateStatus.isLoading ||
    authorizeEvent.isLoading;

  return {
    handleUpdateEventVisibility,
    handleUpdateStatus,
    handleAuthorizeEvent,
    handleDeleteEvent,
    handleReviewEvent,
    handleComments,
    isLoading,
  };
};

export default useActions;

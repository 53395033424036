import { NavLink, useParams } from "react-router-dom";
import { whiteList } from "../../utils/permissions.constant";
import { AuthContext } from "../../context/AuthContext";
import { useContext } from "react";
import variables from "../../config/variables";

type FilterSectionProps = {
  filterSectionProps: Array<optionProps>;
  formValue: string;
  setFormValue: React.Dispatch<React.SetStateAction<string>>;
};

type optionProps = {
  title: string;
  id: string;
};

export const FilterSectionWithNavLink = ({
  filterSectionProps,
  formValue,
  setFormValue,
}: FilterSectionProps) => {
  const { event_id } = useParams();
  const { user } = useContext(AuthContext);

  return (
    <div className="flex font-medium text-gray-100 xl:gap-[2.6rem]">
      <div className="relative flex font-medium text-gray-100">
        {filterSectionProps?.map((option) =>
          option.id === "reports" && whiteList.includes(user.email) ? (
            <a
              className="h-full cursor-pointer select-none"
              key={option.id}
              href={`${variables?.reports_url}event/${event_id}/reports`}
            >
              <div
                onClick={() => setFormValue(option.id)}
                className={`h-12 px-6 text-center ${
                  option.id === formValue
                    ? "border-b-[4px] border-black-200 text-black-100"
                    : ""
                }`}
              >
                {option.title}
              </div>
            </a>
          ) : (
            <NavLink
              to={`?section=${option.id}`}
              className="h-full cursor-pointer select-none"
              key={option.id}
            >
              <div
                onClick={() => setFormValue(option.id)}
                className={`h-12 px-6 text-center ${
                  option.id === formValue
                    ? "border-b-[4px] border-black-200 text-black-100"
                    : ""
                }`}
              >
                {option.title}
              </div>
            </NavLink>
          )
        )}
      </div>
    </div>
  );
};

export const FilterOption = ({ title, id }: optionProps) => {
  return (
    <NavLink
      to={`?section=${id}`}
      className="h-full cursor-pointer select-none"
    >
      <div className="h-12 px-6 text-center  peer-checked:border-b-[4px] peer-checked:border-black-200 peer-checked:text-black-100">
        {title}
      </div>
    </NavLink>
  );
};

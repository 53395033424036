import { useState, useEffect, useContext } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { useMutation, useQuery } from "@tanstack/react-query";

import { Title } from "../Title";
import { CardTicket } from "./tickets/CardTicket";
import { FormTicket } from "./tickets/FormTicket/FormTicket";
import RadioModule from "./tickets/Components/RadioButtons/buttons";
import TicketService from "../../../services/ticketService";
import { ITicketsResponse } from "../../../types/ITicket";
import { IEventResponse } from "../../../types/IEvent";
import EventService from "../../../services/eventService";
import { StateContext } from "../../../context/StateProvider";

interface OrderTicketsBody {
  ticket_order: string;
}

type selectedProps = {
  id: string;
  data: IEventResponse;
};

export const TicketsEvent = ({ id, data }: selectedProps) => {
  const [result, setResult] = useState<ITicketsResponse>({
    rows: [],
    total: 0,
    pages: 0,
  });
  const { dispatch } = useContext(StateContext);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const ticket_id = searchParams.get("ticket_id");
  const action = searchParams.get("action") || "";
  const [idTicket, setIdTicket] = useState<string>(ticket_id ? ticket_id : "");
  const [selectedOption, setSelectedOption] = useState("");

  const options = [
    { label: "Fecha de creación", value: "creation-date" },
    { label: "Precio", value: "price" },
    { label: "Alfabetico", value: "alphabetically" },
  ];

  useEffect(() => {
    setSelectedOption(
      data.order_tickets ? data.order_tickets : "creation-date"
    );
  }, [data]);

  const handleOptionChange = (value: any) => {
    setSelectedOption(value);
  };

  const getItems = useMutation(() => TicketService.getAll({ event_id: id }));
  useEffect(() => {
    if (!idTicket) loadItems();
  }, [idTicket]);

  const updateOrder = useMutation((body: OrderTicketsBody) =>
    EventService.updateOrderTickets(id, body)
  );

  const handleFormDisplay = async (id = "") => {
    await setIdTicket(id);

    if (!id) navigate("?section=tickets");
    if (id === "new") navigate("?section=tickets&ticket_id=new");
  };

  const handleSave = async () => {
    const newBody: OrderTicketsBody = {
      ticket_order: selectedOption,
    };
    await updateOrder
      .mutateAsync(newBody)
      .then((res) => {
        console.log("res");
        console.log(res);
        dispatch({ type: "showLoaderScreen", payload: false });
        dispatch({
          type: "toastSuccess",
          payload: "Orden guardado correctamente",
        });
      })
      .catch((reason) => {
        dispatch({ type: "showLoaderScreen", payload: false });
        dispatch({ type: "toastError", payload: "Orden no se guardo" });
      });
  };

  const loadItems = () => {
    getItems.mutateAsync().then((res) => setResult(res));
  };

  const propsTicket = {
    handleFormDisplay,
    loadItems,
  };

  return (
    <div>
      {ticket_id ? (
        <FormTicket
          {...propsTicket}
          idEvent={id}
          idTicket={idTicket}
          action={action}
        />
      ) : (
        <>
          <div className="flex justify-between">
            <Title>Tickets</Title>
            <button
              onClick={() => handleFormDisplay("new")}
              className="rounded-full border-[2px] border-black-100 px-10 py-2 font-bold  text-black-100 hover:bg-black-100 hover:text-white"
            >
              Crear nuevo ticket
            </button>
            {/*}*/}
          </div>

          <div className="mt-6 grid grid-cols-2 gap-5 lg:grid-cols-3 2xl:grid-cols-4">
            {result.rows.length ? (
              result.rows.map((_, i) => (
                <CardTicket
                  {...propsTicket}
                  client_id={data?.producer?.client_id || ""}
                  event_id={data?.id || ""}
                  event_name={data?.name || ""}
                  data={_}
                  key={i}
                />
              ))
            ) : (
              <p className="text-center">No existen tickets disponibles</p>
            )}
          </div>
          <div className="mt-5 flex flex-col justify-start">
            <RadioModule
              label={"Mostrar tickets en la app ordenados por: "}
              toRender={options}
              selected={
                data.order_tickets === selectedOption
                  ? data.order_tickets
                  : selectedOption
              }
              onChange={handleOptionChange}
            />
            <div className="mt-5 flex w-px  justify-start">
              <button
                onClick={() => handleSave()}
                className="width:150px mt-4 rounded-full border-[2px] border-black-100 px-10 py-2 font-bold text-black-100 hover:bg-black-100 hover:text-white"
              >
                Guardar
              </button>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

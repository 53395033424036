import api from "./api";
import authHeader from "./authHeader";
import {
  IReportResponse,
  IReportRequest,
  IReportPerEventResponse,
  IReportSaleDetailResponse,
  IReportInvitationsDetailResponse,
  IReportPublicSaleResponse,
  IReportTicketsFreeResponse,
  IReportTicketsFreeSellerResponse,
} from "../types/IReport";
import { IPaginationResponse } from "../types/IPagination";
import { IFilterRequest } from "../types/IFilter";

const getAll = async (params: IReportRequest) => {
  const response = await api.get<IReportResponse>(`reports/list`, {
    headers: authHeader(),
    params: params,
  });
  return response.data;
};

const salesForEvent = async () => {
  const response = await api.get<Array<IReportPerEventResponse>>(
    `reports/sales_for_event`,
    {
      headers: authHeader(),
    }
  );
  return response.data;
};

const saleDetail = async (params: IReportRequest) => {
  const response = await api.get<Array<IReportSaleDetailResponse>>(
    `reports/sale_detail`,
    {
      headers: authHeader(),
      params: params,
    }
  );
  return response.data;
};

const invitationsDetail = async (params: IReportRequest) => {
  const response = await api.get<Array<IReportInvitationsDetailResponse>>(
    `reports/invitations_detail`,
    {
      headers: authHeader(),
      params: params,
    }
  );
  return response.data;
};
const courtesyDelivery = async (params: IReportRequest) => {
  const response = await api.get<Array<IReportInvitationsDetailResponse>>(
    `reports/courtesy_delivery`,
    {
      headers: authHeader(),
      params: params,
    }
  );
  return response.data;
};
const publicSale = async (params: IReportRequest) => {
  const response = await api.get<Array<IReportPublicSaleResponse>>(
    `reports/public_sale`,
    {
      headers: authHeader(),
      params: params,
    }
  );
  return response.data;
};
const getCsvSaleDetail = async (params: object = {}) => {
  const response = await api.get<any>(`reports/sale_detail/csv`, {
    headers: authHeader(),
    params: params,
  });
  console.log(response);
  return response.data;
};
const getCsvInvitationsDetail = async (params: object = {}) => {
  const response = await api.get<any>(`reports/invitations_detail/csv`, {
    headers: authHeader(),
    params: params,
  });

  return response.data;
};

const getCsvCourtesyDelivery = async (params: object = {}) => {
  const response = await api.get<any>(`reports/courtesy_delivery/csv`, {
    headers: authHeader(),
    params: params,
  });

  return response.data;
};
const getCsvPublicSale = async (params: object = {}) => {
  const response = await api.get<any>(`reports/public_sale/csv`, {
    headers: authHeader(),
    params: params,
  });
  return response.data;
};

const ticketsFree = async (params: IFilterRequest) => {
  const response = await api.get<IReportTicketsFreeResponse>(
    `reports/tickets_free`,
    {
      headers: authHeader(),
      params: params,
    }
  );
  return response.data;
};

const getTicketsFreeCsv = async (params: IFilterRequest) => {
  const response = await api.get<any>(`reports/tickets_free/csv`, {
    headers: authHeader(),
    params: params,
  });
  return response.data;
};

const ReportService = {
  getAll,
  salesForEvent,
  saleDetail,
  invitationsDetail,
  courtesyDelivery,
  publicSale,
  getCsvSaleDetail,
  getCsvInvitationsDetail,
  getCsvPublicSale,
  ticketsFree,
  getTicketsFreeCsv,
  getCsvCourtesyDelivery,
};

export default ReportService;

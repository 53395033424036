import { IOption } from "../../../types_new/IOption";

export const menuAdminTicketera: IOption[] = [
  {
    text: "Eventos",
    value: "admin/events",
  },
  {
    text: "Vendedores",
    value: "admin/rrpp",
  },
  {
    text: "Reportes",
    value: "admin/reports",
  },
  {
    text: "Secciones",
    value: "admin/sections",
  },
  {
    text: "Reviews",
    value: "admin/reviews",
  },
  {
    text: "Clientes",
    value: "admin/producers",
  },
  {
    text: "Usuarios",
    value: "admin/users",
  },
  {
    text: "Órdenes",
    value: "admin/orders",
  },
  {
    text: "Swap",
    value: "admin/offers",
  },
  {
    text: "Reportados",
    value: "admin/reported",
  },
  {
    text: "Papelera",
    value: "admin/recycler",
  },
  {
    text: "Feedback",
    value: "admin/feedback",
  },
  {
    text: "Ayuda Usuarios",
    value: "admin/user_help",
  },
];

export const menuAdminSocialMedia: IOption[] = [
  {
    text: "Novedades",
    value: "admin/news",
  },
  {
    text: "Artistas",
    value: "admin/artists",
  },
  {
    text: "Agenda",
    value: "admin/schedules",
  },
  {
    text: "Labels",
    value: "admin/labels",
  },
  {
    text: "Secciones",
    value: "admin/sections",
  },
];

export const menuSuperAdmin: IOption[] = [
  {
    text: "Control de acceso",
    value: "admin/access-control",
  },
  {
    text: "Notificaciones",
    value: "admin/notifications",
  },
  {
    text: "Configuraciones",
    value: "admin/configs",
  },
  {
    text: "Puntos bombo",
    value: "admin/points",
  },
  {
    text: "Administradores",
    value: "admin/admins",
  },
  {
    text: "Historial de cambios",
    value: "admin/changelog",
  },
  {
    text: "Terminos",
    value: "admin/terms",
  },
  {
    text: "Privacidad",
    value: "admin/privacy",
  },
];

export const menuCoproducer: IOption[] = [
  {
    text: "Productoras",
    value: "client/producers",
  },
];

export const menuProducer: IOption[] = [
  ...menuCoproducer,
  {
    text: "Permisos de acceso",
    value: "client/users",
  },
];

import { useMutation } from "@tanstack/react-query";
import { useState, FC, useEffect } from "react";
import ClientService from "../../../services/clientService";
import { IClientResponse } from "../../../types/IClient";
import {
  IOrderTicketResponse,
  IOrderTicketsResponse,
} from "../../../types/IOrderTicket";
import { Back } from "../../Back";
import { FilterOption } from "../../table/FilterSection";
import { BannerClient } from "../BannerClient";
import { NftClient } from "./nft/NftClient";
import { RewardsClient } from "./rewards/RewardsClient";
import { TicketsClient } from "./tickets/TicketsClient";

type typeProps = {
  handleSelectItem: (data: IClientResponse) => void;
  data: IClientResponse;
};

const defaultResult = {
  total: 0,
  pages: 0,
  rows: [],
};

export const SelectedClient: FC<typeProps> = ({ handleSelectItem, data }) => {
  const filterSectionProps = [
    { title: "Tickets", id: "tickets" },
    { title: "NFT", id: "nft" },
    { title: "Rewards", id: "rewards" },
  ];

  const [formValue, setFormValue] = useState("ordenes");
  const [orderTickets, setTickets] =
    useState<IOrderTicketsResponse>(defaultResult);

  const getOrderTickets = useMutation(() =>
    ClientService.getOrderTickets(data.id)
  );

  const handleFormValue = (event: React.ChangeEvent<HTMLFormElement>) => {
    setFormValue(event.target.id);
  };

  const showFormValue = () => {
    switch (formValue) {
      case "tickets":
        return <TicketsClient data={orderTickets} />;
      case "nft":
        return <NftClient user_id={data.id} />;
      case "rewards":
        return <RewardsClient user_id={data.id} />;
      default:
        return <TicketsClient data={orderTickets} />;
    }
  };

  useEffect(() => {
    getOrderTickets.mutateAsync().then((res) => setTickets(res));
  }, []);

  return (
    <div>
      <Back onClick={handleSelectItem} />
      <div className="pt-6">{data && <BannerClient data={data} />}</div>
      <div className="w-full overflow-auto border-b pt-10">
        <form
          className="flex gap-10 font-medium text-gray-100"
          onChange={handleFormValue}
        >
          {filterSectionProps.map((option) => (
            <FilterOption key={option.id} title={option.title} id={option.id} />
          ))}
        </form>
      </div>
      {/* <div className="pt-10">{showFormValue()}</div> */}
    </div>
  );
};

import { useNavigate } from "react-router-dom";

import { Back } from "../../Back";
import circleCheck from "../../../assets/circle-check.png";

export const CongratulationsLabel = ({}) => {
  const navigate = useNavigate();

  const handleBack = () => {
    navigate(`/admin/labels/add`);
  };

  const handleBackToList = () => {
    navigate(`/admin/labels`);
  };

  return (
    <div>
      <Back onClick={handleBack} />
      <div className="flex flex-col gap-10 xl:px-[23rem]">
        <div>
          <img
            src={circleCheck}
            alt="check"
            className="m-auto mb-4 text-center"
          />
          <h1 className="mb-4 text-center text-[20px] font-bold">
            Felicitaciones
          </h1>
          <p className="text-center">Label creado exitosamente</p>
        </div>

        <div className="mb-16 flex justify-center gap-10 pt-10">
          <button
            onClick={handleBack}
            className="rounded-full border-[2px] border-black-100 px-12 py-2 font-medium text-black-100 "
          >
            Crear Otro
          </button>
          <button
            onClick={handleBackToList}
            className="rounded-full border-[2px] border-black-100 bg-black-100 px-[5.4rem] py-2 font-medium  text-white"
          >
            Volver al listado
          </button>
        </div>
      </div>
    </div>
  );
};

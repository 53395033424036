import React, { useState, useEffect, FC, useContext } from "react";
import { motion } from "framer-motion";
import { FaEdit, FaTrashAlt } from "react-icons/fa";
import { TitleSection } from "../TitleSection";
import { FilterPage } from "../table/FilterPage";
import { FilterQtyPage } from "../table/FilterQtyPage";
import { FilterSection } from "../table/FilterSection";
import { GoGraph, GoSearch } from "react-icons/go";
import { FilterDate } from "../table/FilterDate";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  IFilterClientRequest,
  IFilterEventRequest,
  IFilterRequest,
} from "../../types/IFilter";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DropdownDots from "../FormElement/DropdownDots";
import { IClientResponse, IClientsResponse } from "../../types/IClient";
import ClientService from "../../services/clientService";
import { AuthContext } from "../../context/AuthContext";
import { PageContainer } from "../PageContainer";
import { useNavigate } from "react-router-dom";
import { FilterOrder } from "../table/FilterOrder";
import { StateContext } from "../../context/StateProvider";
import { formatDate, formatDateTime } from "../../utils/format-date";
import config from "../../config/variables";

const defaultResult = {
  total: 0,
  pages: 0,
  rows: [],
};
const defaultFilters = {
  limit: config.filter_default_limit,
  page: 1,
  status: "all",
  user_role: "client",
};

type propsTable = {
  handleCreateorEdit: (id: string) => void;
  handleSelectItem: (data: IClientResponse) => void;
};

export const TableClients: FC<propsTable> = ({
  handleCreateorEdit,
  handleSelectItem,
}) => {
  const auth = useContext(AuthContext);
  const authClientProducerID =
    auth.role == "producer" &&
    auth.producer &&
    auth.producer.id &&
    auth.producer.id.length
      ? auth.producer.id
      : undefined;
  const producerID = authClientProducerID || undefined;
  const [search, setSearch] = useState("");
  const { dispatch } = useContext(StateContext);

  const getItems = useMutation(() => ClientService.getAll(filters));

  const deleteItems = useMutation((id: string) => ClientService.remove(id), {
    onSuccess(data: any) {
      toast.success(data.message);
    },
  });

  const getCsv = useMutation(() => ClientService.getCsv(filters));

  useEffect(() => {
    handleFilter(filters);
  }, []);

  useEffect(() => {
    const delayDebounceFn = setTimeout(async () => {
      dispatch({ type: "showLoaderScreen", payload: true });
      getItems.mutateAsync().then((res) => {
        dispatch({ type: "showLoaderScreen", payload: false });
        setResult(res);
      });
    }, 1500);

    return () => clearTimeout(delayDebounceFn);
  }, [search]);

  const searcher = async (e: any) => {
    setSearch(e.target.value);
    await setFilters({ ...filters, page: 1, query: e.target.value });
  };

  const [result, setResult] = useState<IClientsResponse>(defaultResult);
  const [filters, setFilters] = useState<IFilterClientRequest>({
    ...defaultFilters,
    producer: producerID,
  });

  const columnData = [
    "ID",
    "Nombre",
    "nickname",
    "Email",
    "Tickets",
    "Cliente Desde",
    "Creado el",
  ];

  const filterSectionProps = [
    { title: "Todos los usuarios", id: "all" },
    { title: "Pendientes de aprobación", id: "pending" },
    { title: "Activos", id: "active" },
    { title: "Pausados", id: "paused" },
  ];

  const handleFilter = async (filter: IFilterRequest) => {
    await setFilters({ ...filters, ...filter });
    getItems.mutateAsync().then((res) => setResult(res));
  };
  const handleFilterSection = async (filter: string) => {
    await setFilters({ ...filters, page: 1, status: filter });
    getItems.mutateAsync().then((res) => setResult(res));
  };

  const handleDelete = (id: string) => {
    deleteItems.mutate(id);
    handleFilter(filters);
  };

  const handleCsv = async () => {
    await getCsv.mutateAsync().then((response) => {
      const url = window.URL.createObjectURL(new Blob([response]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "clients.csv");
      document.body.appendChild(link);
      link.click();
    });
  };

  const navigate = useNavigate();
  const handleEdit = (id: string) => {
    navigate(`/client/${id}/edit`);
  };
  return (
    <PageContainer>
      <div className="relative">
        <TitleSection>Clientes</TitleSection>
        <div className="absolute -top-0 right-0 ">
          <button
            className="flex items-center justify-center text-[#3E7EFF]"
            type="button"
            onClick={handleCsv}
          >
            <GoGraph className="mr-1" />
            <strong>Exportar a CSV</strong>
          </button>
        </div>
      </div>
      <div className="mb-7 flex justify-between border-b-[1px] border-colorBorder pr-5 pt-10  ">
        <FilterSection
          filterSectionProps={filterSectionProps}
          handleFilter={handleFilterSection}
        />
        <div className="flex items-center gap-5 ">
          <div className="flex items-center gap-5 ">
            {/* <GoSearch className="cursor-pointer text-gray-100" /> */}
            <input
              type="text"
              placeholder="Search..."
              className="hover:text-black rounded-full border-[2px] border-black-100 px-4 py-1 font-bold  text-black-100"
              value={search}
              onChange={searcher}
            />
            {/* <FilterOrder setSort={handleSortSection} options={sortOptions} /> */}
          </div>
          <FilterDate />
        </div>
      </div>
      <table className="my-table-spacing h-full w-full border-separate border-spacing-y-2 gap-5 overflow-x-auto pt-5 text-left">
        <thead className=" divide-solid border ">
          <tr className=" pb-4 text-sm font-bold text-gray-100">
            {columnData.map((column, index) => (
              <th
                className="border-b-[1px] border-colorBorder pb-4"
                key={column + index}
              >
                {column}
              </th>
            ))}
            <th className="border-b-[1px] border-colorBorder pb-4">
              <GoSearch
                className="ml-auto cursor-pointer text-gray-100"
                size={16}
              />
            </th>
          </tr>
        </thead>
        <tbody>
          {result.rows.length ? (
            result.rows.map((_, i) => (
              <motion.tr
                key={_.id}
                layout
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                initial={{ opacity: 0.2 }}
                transition={{ type: "spring", stiffness: 100, bounce: 0 }}
                className={`h-16  rounded-xl text-start font-medium outline outline-[1px] outline-colorBorder`}
              >
                <td
                  onClick={() => handleSelectItem(_)}
                  className="cursor-pointer pl-4"
                >{`#${_.id}`}</td>
                <td
                  onClick={() => handleSelectItem(_)}
                  className="cursor-pointer pl-4"
                >{`${_.first_name} ${_.last_name}`}</td>
                <td
                  onClick={() => handleSelectItem(_)}
                  className="cursor-pointer pl-4"
                >{`${_.nickname ? _.nickname : ""}`}</td>
                <td
                  onClick={() => handleSelectItem(_)}
                  className="flex h-full cursor-pointer items-center gap-2 pl-4"
                >
                  {_.email}
                </td>
                <td
                  onClick={() => handleSelectItem(_)}
                  className="cursor-pointer pl-4"
                >
                  <div className="flex cursor-pointer items-center gap-2">
                    2
                  </div>
                </td>
                <td
                  onClick={() => handleSelectItem(_)}
                  className="cursor-pointer pl-4"
                >
                  {formatDate(_.created_at)}
                </td>
                <td
                  onClick={() => handleSelectItem(_)}
                  className="cursor-pointer pl-4"
                >
                  {formatDateTime(_.updatedAt, "-03:00")}
                </td>
                <td className="pl-4">
                  <DropdownDots
                    options={[
                      {
                        title: "Editar",
                        action: () => handleEdit(_.id),
                        icon: <FaEdit size={23} />,
                        restricted: "only_admin",
                      },
                      {
                        title: "Eliminar",
                        action: () => handleDelete(_.id),
                        icon: <FaTrashAlt size={23} />,
                      },
                    ]}
                  />
                </td>
              </motion.tr>
            ))
          ) : (
            <></>
          )}
        </tbody>
      </table>
      <div className="flex justify-between pt-10">
        <FilterPage
          handleFilter={handleFilter}
          total={result.total}
          limit={filters.limit}
          status={filters.status}
          currentPage={filters.page}
        />
        <FilterQtyPage handleFilter={handleFilter} status={filters.status} />
      </div>
    </PageContainer>
  );
};

export const availableOrderStatus = [
  { label: "Aprobado", value: "approved" },
  { label: "Pendiente", value: "pending" },
  { label: "Reembolsado", value: "refunded" },
  { label: "Rechazado", value: "rejected" },
];

export const availableColumns = [
  { label: "Buyer First Name", value: "buyer_first_name" },
  { label: "Buyer Last Name", value: "buyer_last_name" },
  { label: "Buyer Email", value: "buyer_email" },
  { label: "Buyer Phone Number", value: "buyer_phone_number" },
  { label: "Buyer Dni", value: "buyer_dni" },
  { label: "Event Name", value: "event_name" },
  { label: "Order Id", value: "order_id" },
  { label: "Order Status", value: "order_status" },
  { label: "Order Total", value: "order_total" },
  { label: "Order Service Charge", value: "order_service_charge" },
  { label: "Order Subtotal", value: "order_subtotal" },
  { label: "Order External Reference", value: "order_external_reference" },
  { label: "Ticket Name", value: "ticket_name" },
  { label: "Ticket Type", value: "ticket_type" },
  { label: "Ticket Price", value: "ticket_price" },
  { label: "Ticket Id", value: "ticket_id" },
  { label: "Payment Method", value: "payment_method" },
  { label: "Payment Date", value: "payment_date" },
  { label: "Seller First Name", value: "seller_first_name" },
  { label: "Seller Last Name", value: "seller_last_name" },
  { label: "Seller ID", value: "seller_id" },
  { label: "Team Lead First Name", value: "team_lead_first_name" },
  { label: "Team Lead Last Name", value: "team_lead_last_name" },
];

export const templates = [
  {
    label: "Liquidación",
    fields: [
      "buyer_email",
      "order_id",
      "order_total",
      "order_service_charge",
      "order_subtotal",
      "order_external_reference",
      "payment_method",
      "payment_date",
    ],
  },
  {
    label: "Comprador",
    fields: [
      "order_id",
      "buyer_first_name",
      "buyer_last_name",
      "buyer_email",
      "buyer_phone_number",
      "buyer_dni",
    ],
  },
];

import { Status } from "../../Status";
import { getTeamRoleFromValue } from "../../../utils/roles";
import { IRrppResponse } from "../../../types/IRrpp";
import { formatDate } from "../../../utils/format-date";

type selectedProps = {
  selected: IRrppResponse;
};

const styles = {
  titleLi: "font-bold",
  pLi: "font-medium",
  wrapperLi: "flex flex-col justify-between ",
};

export const BannerRrpp = ({ selected }: selectedProps) => {
  console.log(selected);
  return (
    <>
      <div className="flex h-52 w-full justify-between rounded-xl px-10 py-10 outline outline-2 outline-colorBorder">
        <div className="flex gap-5">
          <div className="flex h-24 w-24 overflow-hidden rounded-full bg-gray-200">
            <img
              src={selected.profile_picture}
              width="100%"
              height="100%"
              className="object-cover"
            />
          </div>
          <div className="flex flex-col">
            <h1 className="text-2xl font-bold">{`${
              selected.first_name == undefined ? "" : selected.first_name
            } ${
              selected.last_name == undefined ? "" : selected.last_name
            }`}</h1>
            <p className="font-medium text-gray-100">
              Id de user: {selected?.id}
            </p>
          </div>
        </div>

        <ul className="flex gap-10 2xl:gap-32">
          <div className={`${styles.wrapperLi}`}>
            <li>
              <h3 className={`${styles.titleLi}`}>Fecha de alta</h3>
              <p className={`${styles.pLi}`}>
                {formatDate(selected?.updatedAt)}
              </p>
            </li>
          </div>
          <div className={`${styles.wrapperLi}`}>
            <li>
              <h3 className={`${styles.titleLi}`}>Email</h3>
              <p className={`${styles.pLi}`}>{selected.email}</p>
            </li>
          </div>
          <div className={`${styles.wrapperLi}`}>
            <li>
              <h3 className={`${styles.titleLi}`}>Tipo</h3>
              <p className={`${styles.pLi}`}>
                {getTeamRoleFromValue(selected.user_role)}
              </p>
            </li>
          </div>
          {!!selected.team_leader && (
            <div className={`${styles.wrapperLi}`}>
              <li>
                <h3 className={`${styles.titleLi}`}>Team Leader</h3>
                <p className={`${styles.pLi}`}>
                  {selected.team_leader.first_name}{" "}
                  {selected.team_leader.last_name}
                </p>
              </li>
            </div>
          )}
        </ul>
        <div>
          <Status
            status={
              selected.status && selected.status == "active"
                ? "activo"
                : "pendiente"
            }
          />
        </div>
      </div>
    </>
  );
};

import { INewResponse, INewsResponse } from "../types/INews";
import { IFilterRequest } from "../types/IFilter";
import api from "./api";
import authHeader from "./authHeader";
import { IEventResponse } from "../types/IEvent";
import {
  ICommentReportsResponse,
  ICommentResponse,
  ICommentsResponse,
} from "../types/IComment";

const getAll = async (params: IFilterRequest) => {
  const response = await api.get<INewsResponse>(`news/list`, {
    headers: authHeader(),
    params: params,
  });
  return response.data;
};

const get = async (id: string) => {
  const response = await api.get<INewResponse>(`news/detail/${id}`, {
    headers: authHeader(),
  });
  return response.data;
};

const create = async (item: FormData) => {
  const response = await api.post<INewResponse>(`news/create`, item, {
    headers: {
      "Content-Type": "multipart/form-data",
      ...authHeader(),
    },
  });
  return response.data;
};

const update = async (id: string | undefined, item: FormData) => {
  const response = await api.put<INewResponse>(`news/update/${id}`, item, {
    headers: {
      "Content-Type": "multipart/form-data",
      ...authHeader(),
    },
  });
  return response.data;
};

const remove = async (id: string) => {
  const response = await api.delete<INewResponse>(`news/delete/${id}`, {
    headers: authHeader(),
  });
  return response.data;
};

const sendRecycler = async (id: string) => {
  const response = await api.post<INewResponse>(
    `news/send_recycler/${id}`,
    {},
    {
      headers: authHeader(),
    }
  );
  return response.data;
};

const uploadFile = async (item: FormData) => {
  const response = await api.post<any>(`news/picture`, item, {
    headers: {
      "Content-Type": "multipart/form-data",
      ...authHeader(),
    },
  });
  return response.data;
};

const removePicture = async (id: string) => {
  const response = await api.delete<any>(`news/picture/${id}`, {
    headers: authHeader(),
  });
  return response.data;
};

const getComments = async (params: IFilterRequest) => {
  const response = await api.get<ICommentsResponse>(
    `news/comments/${params.id}`,
    {
      headers: authHeader(),
      params: params,
    }
  );
  return response.data;
};

const removeComment = async (id: string) => {
  const response = await api.delete<ICommentResponse>(`news/comments/${id}`, {
    headers: authHeader(),
  });
  return response.data;
};

const getCommentReports = async (params: IFilterRequest) => {
  const response = await api.get<ICommentReportsResponse>(
    `news/commentreports/${params.id}`,
    {
      headers: authHeader(),
      params: params,
    }
  );
  return response.data;
};

const removeCommentReport = async (id: string) => {
  const response = await api.delete<ICommentResponse>(
    `news/delete_comment_report/${id}`,
    {
      headers: authHeader(),
    }
  );
  return response.data;
};

const emptyTrash = async (params: IFilterRequest) => {
  const response = await api.delete<INewResponse>(`news/empty_trash`, {
    headers: authHeader(),
    params: params,
  });
  return response.data;
};

const updateOrder = async (news_id: string | undefined, order: number) => {
  const response = await api.put<INewsResponse>(
    `news/update_order/${news_id}`,
    { order },
    {
      headers: authHeader(),
    }
  );
  return response.data;
};

const NewsService = {
  getAll,
  get,
  create,
  update,
  remove,
  sendRecycler,
  getComments,
  removeComment,
  getCommentReports,
  removeCommentReport,
  uploadFile,
  removePicture,
  emptyTrash,
  updateOrder,
};

export default NewsService;

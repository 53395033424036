import React, { useEffect, useState } from "react";
import { useContext } from "react";
import { GrClose } from "react-icons/gr";
import { StateContext } from "../../../context/StateProvider";
import { Footer } from "../../footer/Footer";
import { Events } from "./Events";
import { InputSearch } from "../../rrpp/components/InputSearch/InputSearch";
import { IEventResponse } from "../../../types/IEvent";
import { useMutation } from "@tanstack/react-query";
import eventService from "../../../services/eventService";

export const PopUpEvent = ({}) => {
  const { state, dispatch } = useContext(StateContext);
  const [events, setEvents] = useState<Array<IEventResponse>>([]);
  const [selectedEvents, setSelectedEvents] = useState<any>([]);
  const [query, setQuery] = useState("");

  const getEvents = useMutation(() =>
    eventService.getAll({ upcoming_events: true })
  );

  const loadEvents = () => {
    getEvents.mutateAsync().then((result) => {
      console.log(result);
      setEvents(result.rows);
    });
  };

  useEffect(() => {
    loadEvents();
  }, []);

  const addEventSection = () => {
    dispatch({ type: "addEventSection", payload: null });
  };

  const showEvents = () => {
    dispatch({ type: "showEvents", payload: null });
  };

  const submitSelectedEvents = () => {
    dispatch({ type: "selectedEvents", payload: selectedEvents });
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(e.target.value);
    if (e.target.value === "") {
      loadEvents();
    } else {
      if (events) {
        const results = events.filter((event) => {
          if (e.target.value === "") return state;
          if (event.name) {
            return event.name
              .toLowerCase()
              .includes(e.target.value.toLowerCase());
          }
        });
        setEvents(results);
      }
    }
  };

  const handleOnChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (selectedEvents.map((ev: any) => ev.id).includes(value)) {
      setSelectedEvents(
        selectedEvents.filter((event: any) => event.id !== value)
      );
    } else {
      const event = events.find((el) => el.id === value);
      setSelectedEvents([...selectedEvents, event]);
    }
  };

  const isSelected = (value: string) => {
    return selectedEvents.map((ev: any) => ev.id).includes(value);
  };

  useEffect(() => {
    // setEventIds(state.events.map(item => {return item.id}));
    setSelectedEvents(state.selectedEvents);
  }, []);

  return (
    <div className="fixed  z-50 flex h-screen w-screen items-center justify-center bg-black-100/30 backdrop-blur-md">
      <div className="absolute top-20 m-auto flex flex-col justify-center rounded-2xl bg-white ">
        <div className="flex w-full justify-between border-b border-colorBorder px-8  pb-4 pt-5 text-center">
          <div />
          <h3 className=" text-lg font-bold">
            Asignación de evento para seccion
          </h3>
          <button className="" onClick={addEventSection}>
            <GrClose />
          </button>
        </div>
        <div className=" h-[40rem] w-[47rem] flex-col flex-wrap  overflow-auto  px-10 pt-4">
          <InputSearch
            value={query}
            onChange={() => {}}
            handleChange={handleChange}
            content="Buscar un evento"
          />

          <p className="pb-4 pt-2 font-bold">Eventos</p>
          <form
            className="flex flex-col items-center justify-center gap-5"
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <div className="flex flex-wrap justify-between gap-3 ">
              {events.map((item, index) => (
                <Events
                  key={index}
                  name={item.name}
                  value={item.id}
                  handleOnChange={handleOnChange}
                  isSelected={isSelected(item.id)}
                />
              ))}
            </div>
            {events.length == 0 && (
              <p className="text-center">No existen eventos disponibles</p>
            )}
            <div className=" pt-5">
              <button
                onClick={() => {
                  if (!state.showEvent) showEvents();
                  addEventSection();
                  submitSelectedEvents();
                }}
                type="submit"
                className="m-auto w-72 rounded-full border-[2px] border-black-100 bg-black-100 px-12 py-2 font-medium text-white"
              >
                Guardar
              </button>
            </div>
          </form>
        </div>
      </div>
      <div className=" flex h-full w-full items-end ">
        <Footer />
      </div>
    </div>
  );
};

import { useState, useEffect, FC, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useMutation, useQuery } from "@tanstack/react-query";
import { FaEdit, FaTrashAlt, FaArrowRight } from "react-icons/fa";
import { motion } from "framer-motion";
import "react-toastify/dist/ReactToastify.css";

import { TitleSection } from "../TitleSection";
import { PageContainer } from "../PageContainer";
import { FilterPage } from "../table/FilterPage";
import { FilterQtyPage } from "../table/FilterQtyPage";
import { FilterSection } from "../table/FilterSection";
import { FilterOrder } from "../table/FilterOrder";
import DropdownDots from "../FormElement/DropdownDots";
import TableCellLink from "../FormElement/TableCellLink";
import { IFilterRequest } from "../../types/IFilter";
import ISortOption from "../../types/ISortOption";
import { IUserResponse, IUsersResponse } from "../../types/IUser";
import { formatDateTime } from "../../utils/format-date";
import { getUserStatusFromValue } from "../../utils/status";
import { StateContext } from "../../context/StateProvider";
import UserService from "../../services/userService";
import config from "../../config/variables";

const defaultFilters = {
  limit: config.filter_default_limit,
  page: 1,
  status: "all",
  sortBy: "createdAt",
  sortOrder: "desc",
  query: "",
};

type propsTable = {
  handleCreateorEdit: (id: string) => void;
  handleSelectItem: (data: IUserResponse) => void;
};

export const TableUsers: FC<propsTable> = ({
  handleCreateorEdit,
  handleSelectItem,
}) => {
  const { state, dispatch } = useContext(StateContext);
  const [result, setResult] = useState<IUsersResponse | null>(null);
  const [filters, setFilters] = useState<IFilterRequest>(defaultFilters);
  const [search, setSearch] = useState("");
  const [usersCounter, setUsersCounter] = useState(0);
  const [ticketsCounter, setTicketsCounter] = useState(0);
  const [firstCallCounters, setFirstCallCounters] = useState(true);

  const getItems = useMutation(() => UserService.getAll(filters));
  const getCounter = useMutation(() => UserService.getCounter({}));

  let paramsFilters: any = { status: "all", sortBy: "createdAt" };

  const navigate = useNavigate();

  let resultado = [];
  resultado = result?.rows ? result?.rows : [];

  const columnData = [
    "Fecha de alta",
    "Nombre",
    "Nickname",
    "Email",
    "Tickets comprados",
    "Status",
    "",
  ];

  const filterSectionProps = [
    { title: "Todos los usuarios", id: "all" },
    { title: "Activos", id: "active" },
    { title: "Pausados", id: "paused" },
    { title: "Pendientes", id: "pending" },
    { title: "Eliminados", id: "deleted" },
  ];

  useEffect(() => {
    if (search != "") {
      const delayDebounceFn = setTimeout(() => {
        loadData();
      }, 600);
      return () => clearTimeout(delayDebounceFn);
    } else {
      loadData();
    }
  }, [search]);

  useEffect(() => {
    if (firstCallCounters) {
      setFirstCallCounters(false);
      getCounter.mutateAsync().then((res) => {
        setUsersCounter(res.users?.toLocaleString("es"));
        setTicketsCounter(res.tickets?.toLocaleString("es"));
      });
    }
    const interval = setInterval(() => {
      getCounter.mutateAsync().then((res) => {
        setUsersCounter(res.users?.toLocaleString("es"));
        setTicketsCounter(res.tickets?.toLocaleString("es"));
      });
    }, config.interval_ms_users_counter || 30000);

    return () => clearInterval(interval);
  }, []);

  const loadData = () => {
    dispatch({ type: "showLoaderScreen", payload: true });
    getItems.mutateAsync().then((res) => {
      dispatch({ type: "showLoaderScreen", payload: false });
      setResult(res);
    });
  };
  const searcher = async (e: any) => {
    setSearch(e.target.value);
    paramsFilters = { ...filters, query: e.target.value };
    await setFilters({ ...filters, page: 1, query: e.target.value });
  };

  const handleFilter = async (filter: IFilterRequest) => {
    await setFilters({ ...filters, ...filter });
    await getItems.mutateAsync().then((res) => setResult(res));
  };

  const handleFilterSection = async (filter: string) => {
    paramsFilters = { ...filters, status: filter };
    filter === "deleted"
      ? (paramsFilters.recycler = true)
      : delete paramsFilters.recycler;
    await setFilters({
      ...filters,
      page: 1,
      status: filter !== "deleted" ? filter : undefined,
      recycler: filter === "deleted" ? true : undefined,
    });
    await getItems.mutateAsync().then((res) => setResult(res));
  };

  const handleDeleteAccount = (id: string) => {
    if (
      window.confirm(
        "Confirma que quiere enviar este registro a la papelera de reciclaje?"
      )
    ) {
      deleteItems.mutateAsync(id).then((res) => {
        getItems.mutateAsync().then((res) => setResult(res));
      });
    }
  };

  const deleteItems = useMutation(
    (id: string) => UserService.deleteAccount(id),
    {
      onSuccess(data: any) {
        dispatch({
          type: "toastSuccess",
          payload: "El Registro fue enviado a la papelera de reciclaje.",
        });
      },
    }
  );

  const getUserDetailLink = (userDetailID: string) => {
    return `/admin/users/${userDetailID}`;
  };

  const handleSortSection = async (sortBy: string) => {
    const itemSort = sortOptions.find((s) => s.value == sortBy);
    paramsFilters = { ...filters, page: 1, sortBy: sortBy };
    if (itemSort) paramsFilters["sortOrder"] = itemSort.sortOrder;
    await setFilters(paramsFilters);
    await getItems.mutateAsync().then((res) => setResult(res));
  };

  const sortOptions: Array<ISortOption> = [
    { value: "createdAt", text: "Fecha", sortOrder: "desc" },
    { value: "first_name", text: "Nombre", sortOrder: "asc" },
  ];

  const [stateOrder, setState] = useState("asc");
  const handleChangeState = async (state: string) => {
    setState(state);
    await setFilters({ ...filters, sortOrder: state });
    loadData();
  };

  const dropdownOption = (id: string, status: string) => {
    const options = [
      {
        title: "Editar",
        action: () => handleCreateorEdit(id),
        icon: <FaEdit size={23} />,
      },
    ];

    if (filters["status"] && filters["status"] !== "deleted")
      options.push({
        title: "Enviar a la papelera",
        action: () => handleDeleteAccount(id),
        icon: <FaTrashAlt size={23} />,
      });

    if (filters.recycler) {
      const infinit = 100000000;
      const getTicketsParams = {
        limit: infinit,
        userOwner: "true",
      };
      options.push({
        title: "Reasignar tickets",
        action: async () => {
          const userOrderTickets = await UserService.getUserTickets(
            id,
            getTicketsParams
          );
          if (userOrderTickets.rows.length === 0)
            dispatch({
              type: "toastError",
              payload: "El usuario no tiene tickets disponibles",
            });
          else {
            dispatch({
              type: "setOrdersTickets",
              payload: userOrderTickets.rows,
            });
            dispatch({ type: "setOrderTicket", payload: null });
            dispatch({ type: "addUsersDetailTicket", payload: null });
          }
        },
        icon: <FaArrowRight size={23} />,
      });
    }

    return options;
  };

  return (
    <PageContainer>
      <div className="relative">
        <TitleSection>Usuarios</TitleSection>
        {/*<div className="absolute -top-0 right-0 ">
            <button
              onClick={() => handleCreateorEdit("")}
              className="rounded-full border-[2px] border-black-100 px-12 py-2 font-bold text-black-100 hover:bg-black-100 hover:text-white"
            >
              Crear nuevo Usuario
            </button>
          </div>*/}
      </div>
      <div className="mb-7 flex justify-between border-b-[1px] border-colorBorder pr-5 pt-10  ">
        <FilterSection
          filterSectionProps={filterSectionProps}
          handleFilter={handleFilterSection}
        />
        <div className="flex items-center gap-5 ">
          {/* <GoSearch className="cursor-pointer text-gray-100" /> */}
          <input
            type="text"
            placeholder="Search..."
            className="hover:text-black rounded-full border-[2px] border-black-100 px-4 py-1 font-bold  text-black-100"
            value={search}
            onChange={searcher}
          />
          <FilterOrder
            setSort={handleSortSection}
            options={sortOptions}
            handleChangeState={handleChangeState}
            state={stateOrder}
          />
        </div>
      </div>
      <div className="flex">
        <div className="pl-10">
          <div className="items-end- flex gap-3">
            <h1 className="text-5xl font-bold text-black-200">
              {usersCounter}
            </h1>
          </div>
          <p className="font-medium">Total de usuarios</p>
        </div>
        <div className="pl-10">
          <div className="flex items-end gap-3">
            <h1 className="text-5xl font-bold text-black-200">
              {ticketsCounter}
            </h1>
          </div>
          <p className="font-medium">Tickets vendidos</p>
        </div>
      </div>
      <table className="my-table-spacing h-full w-full border-separate border-spacing-y-2 gap-5 overflow-x-auto pt-5 text-left">
        <thead className=" divide-solid border ">
          <tr className=" pb-4 text-sm font-bold text-gray-100">
            {columnData.map((column, index) => (
              <th
                className="border-b-[1px] border-colorBorder pb-4"
                key={column + index}
              >
                {column}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {resultado?.length ? (
            resultado.map((_, i) => (
              <motion.tr
                key={_.id}
                layout
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                initial={{ opacity: 0.2 }}
                transition={{ type: "spring", stiffness: 100, bounce: 0 }}
                className={`h-16  rounded-xl text-start font-medium outline outline-[1px] outline-colorBorder`}
              >
                <TableCellLink href={getUserDetailLink(_.id)} className="pl-4">
                  {formatDateTime(_.createdAt)}
                </TableCellLink>
                <TableCellLink
                  href={getUserDetailLink(_.id)}
                  className="pl-4"
                >{`${_.first_name == undefined ? "" : _.first_name} ${
                  _.last_name == undefined ? "" : _.last_name
                }`}</TableCellLink>
                <TableCellLink href={getUserDetailLink(_.id)} className="pl-4">
                  {_.nickname ? _.nickname : ""}
                </TableCellLink>
                <TableCellLink href={getUserDetailLink(_.id)} className="pl-4">
                  {_.email}
                </TableCellLink>
                <TableCellLink href={getUserDetailLink(_.id)} className="pl-4">
                  <div className="flex items-center gap-2">
                    {_.order_tickets?.length}
                  </div>
                </TableCellLink>
                <td className="pl-4">
                  <div className="flex w-32 items-center justify-center gap-3 rounded-full border-[1px] border-colorBorder  py-2 text-center">
                    <div
                      className={`rounded-full  ${
                        _.status === "active" ? "bg-green" : "bg-red"
                      } p-[.35rem] `}
                    />
                    <p className="capitalize">
                      {getUserStatusFromValue(_.status)}
                    </p>
                  </div>
                </td>
                <td className="pl-4">
                  <DropdownDots options={dropdownOption(_.id, _.status)} />
                </td>
              </motion.tr>
            ))
          ) : (
            <></>
          )}
        </tbody>
      </table>
      <div className="flex justify-between pt-10">
        <FilterPage
          handleFilter={handleFilter}
          total={result?.total}
          limit={filters.limit}
          status={filters.status}
          currentPage={filters.page}
        />
        <FilterQtyPage handleFilter={handleFilter} status={filters.status} />
      </div>
    </PageContainer>
  );
};

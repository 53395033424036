import { useState } from "react";

import { TableProducers } from "./TableProducers";
import { FormClient } from "../clients/FormClient";
import { IClientResponse } from "../../types/IClient";

export const ProducersView = () => {
  // Manager of event selected
  const [showSelected, setShowSelected] = useState(false);
  const [createClient, setCreateClient] = useState(false);
  const [client, setClientID] = useState<IClientResponse>();

  const handleSelect = (client?: IClientResponse) => {
    setShowSelected(!showSelected);
    if (client) setClientID(client);
  };

  const handleCreateClient = () => {
    setCreateClient(!createClient);
  };

  const clientsTableProps = {
    handleCreateClient,
    handleSelect,
  };

  return (
    <div>
      {createClient ? (
        <FormClient />
      ) : (
        <TableProducers {...clientsTableProps} />
      )}
    </div>
  );
};

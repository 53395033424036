import { useState } from "react";

import { IUserResponse } from "../../types/IUser";
import { SelectedUser } from "./selected/SelectedUser";
import { TableUsers } from "./TableUsers";
import { FormUser } from "./Form";

export const UsersView = () => {
  // Btn create new event
  const [createItem, setCreateItem] = useState(false);

  const [id, setId] = useState<string>("");

  // Manager of event selected
  const [showSelected, setShowSelected] = useState(false);

  const [selectedItem, setSelectedItem] = useState<IUserResponse>();

  const handleCreateorEdit = async (id: string) => {
    await setId(id);
    await setCreateItem(!createItem);
  };

  const handleSelectItem = (data: IUserResponse) => {
    setShowSelected(!showSelected);
    if (data) {
      setSelectedItem(data);
    }
  };

  const itemsTableProps = {
    handleCreateorEdit,
    handleSelectItem,
  };

  const formUserProps = {
    handleCreateorEdit,
  };

  const selectedItemsProps = {
    handleSelectItem,
  };

  return (
    <div>
      {showSelected ? (
        <SelectedUser {...selectedItemsProps} />
      ) : !createItem ? (
        <TableUsers {...itemsTableProps} />
      ) : (
        <FormUser {...formUserProps} id={id} />
      )}
    </div>
  );
};

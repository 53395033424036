import { read } from "@popperjs/core";
import { FC, useEffect } from "react";
import { Controller, useFormState } from "react-hook-form";
import { FormInputProps } from "../../../types/FormInputsProps";

const FormTextArea: FC<FormInputProps> = ({
  name,
  type,
  label,
  placeholder,
  disabled = false,
  min = "",
  max = "",
  readonly = "",
}) => {
  const formState = useFormState();
  const myText = document.getElementById("my-text");
  useEffect(() => {
    if (myText != null) {
      myText.style.cssText = `height: ${myText.scrollHeight}px; overflow-y: hidden`;
      myText.addEventListener("input", function () {
        this.style.height = "auto";
        this.style.height = `${this.scrollHeight}px`;
      });
    }
  }, [myText?.scrollHeight]);

  return (
    <Controller
      name={name}
      render={({ field }) => (
        <div className="relative mb-6 h-auto w-full">
          <p className="absolute left-8 top-4 font-semibold text-gray-100">
            {label}
          </p>
          <textarea
            id="my-text"
            placeholder={placeholder}
            disabled={disabled}
            className="w-full resize-none overflow-hidden rounded-lg border border-colorBorder pb-5 pl-8 pt-11 font-bold text-black-100 focus:border-none focus:outline-none  focus:ring-colorBorder"
            {...field}
          />

          {formState.errors[name] && (
            <p className="text-red-600 dark:text-red-500 mt-2 text-sm text-red">
              {String(
                formState.errors[name] ? formState.errors[name]?.message : ""
              )}
            </p>
          )}
        </div>
      )}
    />
  );
};

export default FormTextArea;

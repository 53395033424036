import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";

import { BannerProducer } from "./BannerProducer";
import { Producers } from "./producers/Producers";
import { ClientsProducer } from "./clients/ClientsProducer";
import { Back } from "../../Back";
import { TeamProducer } from "./team/TeamProducer";
import { NewClientProducers } from "../newClient/NewClientProducers";
import { IClientResponse } from "../../../types/IClient";
import { IFilterProducerRequest, IFilterRequest } from "../../../types/IFilter";
import { IProducersResponse } from "../../../types/IProducer";
import { FilterPage } from "../../table/FilterPage";
import { FilterSection } from "../../table/FilterSection";
import { FilterQtyPage } from "../../table/FilterQtyPage";
import { UsersProducer } from "./users/UsersProducer";
import { ReportsEvent } from "../../events/selectedEvent/ReportsEvent";
import ProducerService from "../../../services/producerService";
import ClientService from "../../../services/clientService";

type selectedProps = {
  handleSelect?: React.Dispatch<React.SetStateAction<null>>;
};

const defaultResult = {
  total: 0,
  pages: 0,
  rows: [],
};

const defaultFilters = {
  page: 1,
  status: "all",
};

export const SelectedProducer = ({ handleSelect }: selectedProps) => {
  const navigate = useNavigate();
  const { producer_id } = useParams();
  const [data, setData] = useState<IClientResponse>({} as IClientResponse);
  const [createOrEditProducer, setCreateOrEditProducer] = useState(false);
  const [producerId, setProducerId] = useState<string>();

  const handleCreateProducer = (id?: string) => {
    if (createOrEditProducer)
      getItems.mutateAsync().then((res) => setResult(res));
    setCreateOrEditProducer(!createOrEditProducer);
    setProducerId("");
  };

  const handleEditProducer = (id?: string) => {
    setCreateOrEditProducer(!createOrEditProducer);
    if (id) {
      setProducerId(id);
    }
  };

  const newProducerProps = {
    handleCreateProducer,
    clientID: producer_id || "",
    producerID: producerId,
  };

  const handleDelete = async (id: string) => {
    if (window.confirm("Está seguro de eliminar esta productora?")) {
      deleteItem.mutateAsync(id).then((res) => {
        getItems.mutateAsync().then((res) => setResult(res));
      });
    }
  };

  const editProducerProps = {
    handleEditProducer,
    handleDelete,
    clientID: producer_id || "",
  };

  const getItem = useMutation(() => ClientService.get(producer_id || ""));
  const getItems = useMutation(() =>
    ProducerService.getAll({
      ...filters,
      client_id: producer_id || "",
    } as IFilterProducerRequest)
  );
  const deleteItem = useMutation((id: string) => ProducerService.remove(id));

  const [result, setResult] = useState<IProducersResponse>(defaultResult);
  const [filters, setFilters] = useState<IFilterRequest>(defaultFilters);

  useEffect(() => {
    getItem.mutateAsync().then((res) => setData(res));
    handleFilter(filters);
  }, []);

  const handleFilter = async (filters: IFilterRequest) => {
    await setFilters(filters);
    getItems.mutateAsync().then((res) => setResult(res));
  };

  const filterSectionProps = [
    { title: "Productoras", id: "producers" },
    { title: "Equipo", id: "team" },
    { title: "Clientes", id: "clients" },
    { title: "Reportes", id: "reports" },
    { title: "Usuarios", id: "users" },
  ];

  const [formValue, setFormValue] = useState("ordenes");

  const handleFormValue = (e: any) => {
    setFormValue(e.target.id);
  };

  const showFormValue = () => {
    switch (formValue) {
      case "producers":
        return (
          <>
            <Producers data={result} {...editProducerProps} />
            <div className="flex justify-between pt-10">
              <FilterPage
                handleFilter={handleFilter}
                total={result.total}
                limit={filters.limit}
                status={filters.status}
                currentPage={filters.page}
              />
              <FilterQtyPage
                handleFilter={handleFilter}
                status={filters.status}
              />
            </div>
          </>
        );
      case "team":
        return <TeamProducer producerId={producer_id || ""} />;
      case "clients":
        return <ClientsProducer producerId={producer_id || ""} />;
      case "reports":
        return <ReportsEvent producer_id={producer_id} />;
      case "users":
        return <UsersProducer />;
      default:
        return <Producers data={result} {...editProducerProps} />;
    }
  };

  return (
    <div>
      {createOrEditProducer ? (
        <NewClientProducers {...newProducerProps} />
      ) : (
        <div>
          <Back
            onClick={() => {
              navigate("/admin/producers");
            }}
          />
          <div className="flex justify-between">
            <div></div>
            <button
              onClick={() => handleCreateProducer()}
              className="rounded-full border-[2px] border-black-100 px-12 py-2 font-bold text-black-100 hover:bg-black-100 hover:text-white"
            >
              Crear nueva productora
            </button>
          </div>
          <div className="pt-6">
            <BannerProducer selected={data} />
          </div>
          <div className="w-full overflow-auto border-b pt-10">
            <form
              className="flex gap-10 font-medium text-gray-100"
              onChange={(e) => handleFormValue(e)}
            >
              <FilterSection
                filterSectionProps={filterSectionProps}
                handleFilter={() => {}}
                defaultSelected={"producers"}
              />
              {/*{filterSectionProps.map((option) => (*/}
              {/*    <FilterOption title={option.title} id={option.id} key={option.id} />*/}
              {/*))}*/}
            </form>
          </div>
          <div className="pt-10">{showFormValue()}</div>
        </div>
      )}
    </div>
  );
};

import React, { useContext, useEffect, useState } from "react";
import { Footer } from "../footer/Footer";
import logo from "../../assets/logo.svg";
import { AiOutlineEye, AiFillEyeInvisible } from "react-icons/ai";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, FormProvider } from "react-hook-form";
import * as yup from "yup";
import FormTextField from "../FormElement/FormTextField";
import { IAuthRequest } from "../../types/IAuth";
import { AuthContext } from "../../context/AuthContext";
import { useNavigate } from "react-router";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { StateContext } from "../../context/StateProvider";
import { useLocation } from "react-router-dom";
import { GoogleLogin } from "@react-oauth/google";
import Particles from "../ui/particles";

const schema = yup.object().shape({
  email: yup
    .string()
    .email("El correo electrónico no es válido")
    .required("El correo electrónico es requerido"),
  password: yup
    .string()
    .min(2, "La contraseña debe tener al menos 2 caracteres")
    .required("La contraseña es requerido"),
});

const defaultValues = {
  email: "",
  password: "",
};

export const LoginView = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const auth = useContext(AuthContext);
  const form = useForm<IAuthRequest>({
    resolver: yupResolver(schema),
    defaultValues: defaultValues,
  });

  const [coordinates, setCoordinates] = useState<{
    lat: number;
    lng: number;
  } | null>(null);

  const [showPassword, setShowPassword] = useState(false);
  const { dispatch } = useContext(StateContext);

  const onSubmit = async (data: IAuthRequest) => {
    auth
      .login(data)
      .then((res) => {
        console.log("DATA", res);
        res.user_role === "system" ? navigate("/system/user_counter") : "";

        res.permitted_producers
          ? dispatch({
              type: "setPermittedProducers",
              payload: res.permitted_producers,
            })
          : dispatch({ type: "setPermittedProducers", payload: [] });

        res.user_role == "admin"
          ? navigate("/admin/events")
          : navigate("/client/producers");

        if (res.user_role === "producer" && res.first_time_login === true) {
          navigate("/client/change_password");
        } else if (
          res.user_role === "coproducer" &&
          res.first_time_login === true
        ) {
          navigate("/client/change_password");
        } else {
          navigate("/client/producers");
        }
      })
      .catch((e) => {
        let message = "Credenciales inválidas";
        switch (e.response.data.message) {
          case "USER_NOT_FOUND":
            message = "Usuario no registrado";
            break;
          case "USER_INACTIVE_FOUND":
            message = "Usuario pendiente de aprobación";
            break;
        }
        toast.error(message);
      });
  };

  useEffect(() => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const latitude = position.coords.latitude;
          const longitude = position.coords.longitude;
          setCoordinates({ lat: latitude, lng: longitude });
        },
        (error) => {
          console.error("Error getting location:", error);
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  }, []);

  const handleGoogleSuccess = (response: any) => {
    auth
      .googleLogin(response.credential, coordinates)
      .then((res) => {
        console.log("DATA", res);
        res.permitted_producers
          ? dispatch({
              type: "setPermittedProducers",
              payload: res.permitted_producers,
            })
          : dispatch({ type: "setPermittedProducers", payload: [] });

        res.user_role == "admin"
          ? navigate("/admin/events")
          : navigate("/client/producers");

        if (res.user_role === "producer" && res.first_time_login === true) {
          navigate("/client/change_password");
        } else if (
          res.user_role === "coproducer" &&
          res.first_time_login === true
        ) {
          navigate("/client/change_password");
        } else {
          navigate("/client/producers");
        }
      })
      .catch((e) => {
        console.error(e);
        let message = "Credenciales inválidas";
        switch (e.response.data.message) {
          case "USER_NOT_FOUND":
            message = "Usuario no registrado";
            break;
          case "USER_INACTIVE_FOUND":
            message = "Usuario pendiente de aprobación";
            break;
        }
        toast.error(message);
      });
  };

  useEffect(() => {
    if (!auth.role) return;

    if (auth.role === "system") {
      navigate("/system/user_counter");
      return;
    }

    if (auth.role === "admin") {
      auth.permissions.admin_access !== "social_media"
        ? navigate("/admin/events")
        : navigate("/admin/news");
      return;
    } else {
      navigate("/client/producers");
      return;
    }
  }, []);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    if (queryParams.has("logout")) {
      auth.logout();
    }
    if (window.location.host === "bombo-backoffice.pages.dev") {
      window.location.href = "https://backoffice.wearebombo.com";
    }
  }, []);

  const onError = (error: any) => {
    console.log("error ", error);
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <>
      <Particles
        className="absolute inset-0"
        quantity={300}
        ease={80}
        color="#000"
        refresh
      />
      <main className="flex h-screen w-screen flex-col items-center justify-center">
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <div className="flex h-full flex-col items-center justify-center gap-4">
          <picture className="w-48">
            <img className="" width="100%" src={logo} />
          </picture>
          <h1 className="pt-16 text-2xl font-medium">Iniciar Sesión</h1>
          <FormProvider {...form}>
            <form
              className="relative mb-10 flex flex-col pt-5"
              onSubmit={form.handleSubmit(onSubmit, onError)}
            >
              <FormTextField name="email" type="email" placeholder="Tu email" />
              <div className="relative">
                <FormTextField
                  name="password"
                  type={showPassword ? "text" : "password"}
                  placeholder="Contraseña"
                />
                <div
                  className="icon_button absolute right-4 top-4 cursor-pointer"
                  onClick={handleClickShowPassword}
                >
                  {showPassword ? (
                    <AiOutlineEye className="text-[#92929D] " size={20} />
                  ) : (
                    <AiFillEyeInvisible className="text-[#92929D] " size={20} />
                  )}
                </div>
              </div>
              <button
                type="submit"
                className="mt-8 select-none rounded-full bg-black-100 px-36 py-3 font-medium text-white"
              >
                Iniciar Sesión
              </button>
            </form>
          </FormProvider>
          <GoogleLogin
            onSuccess={handleGoogleSuccess}
            // onError={handleGoogleError}
            useOneTap={true}
          />
        </div>
        <Footer />
      </main>
    </>
  );
};

import api from "./api";
import authHeader from "./authHeader";
import { IOfferResponse, IOffersResponse, ISwapSummary } from "../types/IOffer";
import { IPaginationResponseTyped } from "../types/IPagination";

const getAll = async (params: object = {}) => {
  const response = await api.get<IPaginationResponseTyped<IOffersResponse>>(
    `offers`,
    {
      headers: authHeader(),
      params: params,
    }
  );
  return response.data;
};

const get = async (id: string) => {
  const response = await api.get<IOfferResponse>(`offers/${id}`, {
    headers: authHeader(),
  });
  return response.data;
};

const update = async (id: string, updateFields: Partial<IOfferResponse>) => {
  const response = await api.put<IOfferResponse>(`offers/${id}`, updateFields, {
    headers: authHeader(),
  });
  return response.data;
};

const remove = async (id: string) => {
  const response = await api.delete<IOfferResponse>(`offers/${id}`, {
    headers: authHeader(),
  });
  return response.data;
};

const getSummary = async () => {
  const response = await api.get<ISwapSummary>("offers/summary", {
    headers: authHeader(),
  });
  return response.data;
};

const getEventOffers = async (id: string, signal: AbortSignal | undefined) => {
  const response = await api.get<IOfferResponse[]>(`offers/event/${id}`, {
    headers: authHeader(),
    signal,
  });
  return response.data;
};

const OfferService = {
  getAll,
  get,
  update,
  remove,
  getSummary,
  getEventOffers,
};

export default OfferService;

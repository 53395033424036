import { useContext, useState, useEffect } from "react";
import {
  IFilterProducerRequest,
  IProducerRequest,
  IProducerResponse,
} from "../../../types/IProducer";
import { FaEdit, FaEgg, FaPen, FaTrashAlt } from "react-icons/fa";
import FormInput from "../../FormElement/FormInput";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import jsonToFormData from "@ajoelp/json-to-formdata";
import { StateContext } from "../../../context/StateProvider";
import { useMutation } from "@tanstack/react-query";
import ProducerService from "../../../services/producerService";
import * as yup from "yup";
import loadingImg from "../../../assets/Loading_icon.gif";
import notFound from "../../../assets/notFound.png";
import Restricted from "../../../context/Restricted";
import { filter } from "lodash";
import { whiteList } from "../../../utils/permissions.constant";
import { AuthContext } from "../../../context/AuthContext";
import variables from "../../../config/variables";

const defaultValues = {
  name: "",
  picture: "",
};

const schema = yup.object().shape({
  name: yup.string().required("El nombre es requerido"),
});

const defaultFilters = {
  status: "all",
  client_id: "",
};

type typeProps = {
  handleSelectItem: (data: IProducerResponse) => void;
  data: IProducerResponse;

  handleFilter: (filtersRequest: IFilterProducerRequest) => void;
};
export const CardProducer = ({
  handleSelectItem,
  data,
  handleFilter,
}: typeProps) => {
  const { state, dispatch } = useContext(StateContext);
  const [id, setId] = useState("");
  const [producerSelected, setProducerSelected] = useState(null);
  const auth = useContext(AuthContext);

  const [dataUpdated, setDataUpdated] = useState(data);
  const [urlImg, setUrlImg] = useState(loadingImg);

  const updateItems = useMutation((item: FormData) =>
    ProducerService.update(id, item)
  );
  const form = useForm<IProducerRequest>({
    resolver: yupResolver(schema),
    defaultValues: defaultValues,
  });

  const onSubmit = async (dataSubmit: IProducerRequest) => {
    dataSubmit.client_id = data.client_id || "";
    let formData = jsonToFormData(dataSubmit, {
      arrayIndexes: true,
      excludeNull: true,
    });
    dispatch({ type: "showLoaderScreen", payload: true });
    await updateItems.mutateAsync(formData).then((res) => {
      data.name = dataSubmit.name;
      setId("");
      dispatch({ type: "showLoaderScreen", payload: false });
      dispatch({
        type: "toastSuccess",
        payload: "Productora actualizada correctamente",
      });
    });
  };

  const onError = (error: any) => {
    console.log("error ", error);
  };

  const handleChangeName = (data: any) => {
    form.reset({
      name: data.name,
    });
    setId(data.id);
  };

  const style = {
    li: "flex justify-between",
  };

  const handleEdit = (data: any) => {
    setProducerSelected(data);

    dispatch({ type: "showLoaderScreen", payload: false });
    dispatch({ type: "EditCard", payload: null });

    dispatch({ type: "setProducerSelected", payload: dataUpdated });
  };

  const handleDelete = (idPro: string) => {
    if (window.confirm("Está seguro de eliminar esta productora?")) {
      deleteItem.mutateAsync(idPro).then((res) => {
        console.log(res);
        handleFilter(defaultFilters);
      });
    }
  };

  const deleteItem = useMutation((id: string) => ProducerService.remove(id));

  useEffect(() => {
    if (state.producerUpdated !== null) {
      if (state.producerUpdated.id === data.id) {
        setDataUpdated(state.producerUpdated);
      }
    }
  }, [state.producerUpdated]);

  useEffect(() => {
    const isCanceled = state.producerUpdated === null;

    if (
      !state.showEditCard &&
      dataUpdated.picture !== data.picture &&
      !isCanceled
    ) {
      setUrlImg(loadingImg);
    } else {
      setUrlImg(
        dataUpdated.picture === undefined ? loadingImg : dataUpdated.picture
      );
    }
  }, [dataUpdated, state.showEditCard]);

  const cargaTerminada = () => {
    setUrlImg(
      dataUpdated.picture === undefined ? loadingImg : dataUpdated.picture
    );
  };

  return (
    <div className="border-1 cursor-pointer- flex min-h-[22rem] w-[26rem] flex-col  rounded-xl border border-colorBorder px-5 py-7">
      <div className="flex">
        <div className="w-[50%]">
          {dataUpdated.picture ? (
            <img
              src={urlImg}
              className="h-20 w-20 rounded-full bg-gray-200 object-cover"
              onLoad={cargaTerminada}
            />
          ) : (
            <img
              src={notFound}
              className="h-20 w-20 rounded-full bg-gray-200 object-cover"
              onLoad={cargaTerminada}
            />
          )}
        </div>
        <div className="w-[50%]">
          <div className="flex justify-end ">
            {!data.tickets_reserved &&
            data.events_free_count == 0 &&
            data.active_events == 0 ? (
              <Restricted to="admin">
                <FaTrashAlt
                  className="cursor-pointer"
                  size={23}
                  onClick={() => handleDelete(data.id)}
                />
              </Restricted>
            ) : (
              <div></div>
            )}
          </div>
        </div>
      </div>

      {id != data.id ? (
        <div className="flex">
          <div className="w-[90%]">
            {whiteList.includes(auth?.user?.email) &&
            auth.user.user_role !== "producer" ? (
              <a
                className="select-none"
                href={`${variables?.reports_url}producer/${data.id}`}
              >
                <h1
                  className="min-h-[8rem] w-72 cursor-pointer pt-3 text-4xl font-black"
                  onClick={() => handleSelectItem(data)}
                >
                  {dataUpdated.name}
                </h1>
              </a>
            ) : (
              <h1
                className="min-h-[8rem] w-72 cursor-pointer pt-3 text-4xl font-black"
                onClick={() => handleSelectItem(data)}
              >
                {dataUpdated.name}
              </h1>
            )}
          </div>
          <div className="w-[10%]">
            <div className="flex justify-end">
              <Restricted to="admin">
                <FaEdit
                  size={32}
                  className="cursor-pointer pt-3"
                  onClick={() => handleEdit(data)}
                />
              </Restricted>
            </div>
          </div>
        </div>
      ) : (
        <div className="flex justify-between">
          <FormProvider {...form}>
            <form
              onSubmit={form.handleSubmit(onSubmit, onError)}
              className="pt-2"
            >
              <FormInput name="name" type="text" label="Nombre" />
            </form>
          </FormProvider>
        </div>
      )}
      <ul className="cursor-pointer" onClick={() => handleSelectItem(data)}>
        <li className={style.li}>
          <p className="text-gray-100">Eventos disponibles</p>
          <p className="font-bold">{data.events_count}</p>
        </li>
        <li className={style.li}>
          <p className="text-gray-100">Lideres de grupo</p>
          <p className="font-bold">{data.leaders_count}</p>
        </li>
        <li className={style.li}>
          <p className="text-gray-100">Vendedores</p>
          <p className="font-bold">{data.sellers_count}</p>
        </li>
      </ul>
    </div>
  );
};

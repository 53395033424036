import { useContext } from "react";

import PermissionContext from "../context/PermissionContext";

const usePermission = (permission: string) => {
  const { isAllowedTo } = useContext(PermissionContext);
  return isAllowedTo(permission);
};

export default usePermission;

import { FC } from "react";
import { Controller, useFormState } from "react-hook-form";
import { FormInputProps } from "../../../types/FormInputsProps";

const styleInput = {
  input:
    "rounded-full bg-[#FAFAFB] border-none focus:ring-colorBorder focus:border-colorBorder py-3 pl-5 outline outline-[#F1F1F5] outline-1 w-full ",
};

const FormTextField: FC<FormInputProps> = ({ name, type, placeholder }) => {
  const formState = useFormState();

  return (
    <Controller
      name={name}
      render={({ field }) => (
        <div className="mb-6">
          <input
            type={type}
            placeholder={placeholder}
            className={styleInput.input}
            {...field}
          />
          {formState.errors[name] && (
            <p className="text-red-600 dark:text-red-500 mt-2 text-sm text-red">
              {String(
                formState.errors[name] ? formState.errors[name]?.message : ""
              )}
            </p>
          )}
        </div>
      )}
    />
  );
};

export default FormTextField;

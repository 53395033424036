import React, { useContext, useEffect, useState } from "react";
import { motion } from "framer-motion";
import { Title } from "../../../events/Title";
import { GoSearch } from "react-icons/go";
import { useMutation } from "@tanstack/react-query";
import RrppService from "../../../../services/rrppService";
import {
  IFilterClientRequest,
  IFilterRequest,
} from "../../../../types/IFilter";
import { useParams } from "react-router-dom";
import { formatDateTime } from "../../../../utils/format-date";
import { formatMoney } from "../../../../utils/format-money";
import { FilterPage } from "../../../table/FilterPage";
import { FilterQtyPage } from "../../../table/FilterQtyPage";
import { IEventsResponse } from "../../../../types/IEvent";
import notFound from "../../../../assets/notFound.png";
import { AuthContext } from "../../../../context/AuthContext";
import LoaderCircle from "../../../FormElement/LoaderCircle";
import config from "../../../../config/variables";

const defaultResult = {
  total: 0,
  pages: 0,
  rows: [],
};

const defaultFilters: IFilterClientRequest = {
  limit: config.filter_default_limit,
  page: 1,
};

export const EventsSeller = () => {
  const { seller_id, producer_id, producer_producer_id } = useParams();
  const { user, producer } = useContext(AuthContext);
  const [result, setResult] = useState<IEventsResponse>(defaultResult);
  const [filters, setFilters] = useState<IFilterClientRequest>({
    ...defaultFilters,
    ...{ id: seller_id, producer_producer_id: producer?.id },
  });
  const [showLoader, setShowLoader] = useState(false);

  const getItems = useMutation(() => RrppService.getEvents(filters));

  useEffect(() => {
    if (user.id && user.user_role) {
      if (producer_id && user.user_role == "admin") {
        filters.producer_producer_id = producer_producer_id;
      }
      handleFilter(filters);
    }
  }, [user.id, seller_id]);

  const handleFilter = async (filter: IFilterRequest) => {
    setShowLoader(true);
    await setFilters({ ...filters, ...filter });
    await getItems.mutateAsync().then((res) => {
      setShowLoader(false);
      setResult(res);
    });
  };

  const columnData = [
    "Nombre",
    "Fecha y hora",
    "Locación",
    "Entradas vendidas",
    "Total de ingresos",
    "",
  ];

  return (
    <>
      <Title>Eventos en venta</Title>
      <table className="my-table-spacing h-full w-full border-separate border-spacing-y-2 gap-5 overflow-x-auto pt-5 text-left">
        <thead className=" divide-solid border ">
          <tr className=" pb-4 text-sm font-bold text-gray-100">
            {columnData.map((column, index) => (
              <th
                className="border-b-[1px] border-colorBorder pb-4"
                key={column + index}
              >
                {column}
              </th>
            ))}
            <th className="border-b-[1px] border-colorBorder pb-4">
              <GoSearch
                className="m-auto cursor-pointer text-gray-100"
                size={16}
              />
            </th>
          </tr>
        </thead>
        <tbody>
          {result.rows.map((row, i) => (
            <motion.tr
              key={i}
              layout
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              initial={{ opacity: 0.2 }}
              transition={{ type: "spring", stiffness: 100, bounce: 0 }}
              className={`h-16  rounded-xl text-start font-medium outline outline-[1px] outline-colorBorder`}
            >
              <td className="pl-4">{row.name}</td>
              <td className="pl-4">{formatDateTime(row.date_start)}</td>
              <td className="flex h-full items-center gap-2 pl-4">
                {row.place?.name}
              </td>

              <td className="pl-4">{row.tickets_sold}</td>
              <td className="pl-4">{formatMoney(row.total_amount || 0)}</td>
            </motion.tr>
          ))}
        </tbody>
      </table>
      {showLoader ? (
        <div className="flex items-center justify-center">
          <LoaderCircle />
        </div>
      ) : (
        result.rows.length == 0 && (
          <div className="my-12">
            <img
              src={notFound}
              alt="check"
              className="m-auto mb-4 text-center"
            />
            <p className="text-center">Aún no tiene eventos asignados</p>
          </div>
        )
      )}
      {result.rows.length > 0 && (
        <div className="flex justify-between pt-10">
          <FilterPage
            handleFilter={handleFilter}
            total={result.total}
            limit={filters.limit}
            status={filters.status}
            currentPage={filters.page}
          />
          <FilterQtyPage handleFilter={handleFilter} status={filters.status} />
        </div>
      )}
    </>
  );
};

import { Dispatch, SetStateAction } from "react";

import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "../../ui/select";
import { IFilterRequest } from "../../../types_new/IFilter";
import { updateUrl } from "../../../utils_new/updateUrl";
import config from "../../../config/variables";

interface FilterLimitProps {
  filters: IFilterRequest;
  setFilters: Dispatch<SetStateAction<IFilterRequest>>;
}

export const FilterLimit = ({ filters, setFilters }: FilterLimitProps) => {
  const { limit = config.filter_default_limit } = filters;

  const handleLimitChange = (newLimit: string) => {
    const filtersToUpdate = { page: 1, limit: Number(newLimit) };
    updateUrl(filtersToUpdate);
    setFilters({ ...filters, ...filtersToUpdate });
  };

  return (
    <Select defaultValue={limit.toString()} onValueChange={handleLimitChange}>
      <SelectTrigger className="w-[180px]">
        <SelectValue placeholder="Cant. por página" />
      </SelectTrigger>
      <SelectContent>
        <SelectGroup>
          <SelectLabel>Cant. por página</SelectLabel>
          <SelectItem value="5">5 por página</SelectItem>
          <SelectItem value="10">10 por página</SelectItem>
          <SelectItem value="20">20 por página</SelectItem>
          <SelectItem value="50">50 por página</SelectItem>
        </SelectGroup>
      </SelectContent>
    </Select>
  );
};

import { useState, useContext } from "react";
import { useNavigate } from "react-router";
import { IoIosNotifications } from "react-icons/io";
import { AvatarIcon, Pencil1Icon, ArrowDownIcon } from "@radix-ui/react-icons";

import logo from "../../assets/logo.svg";
import account from "../../assets/account.png";
import { AuthContext } from "../../context/AuthContext";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "../ui/dropdown-menu";
import { Button } from "../ui/button";

export const Header = () => {
  const [notification, setNotification] = useState(true);
  const { role, changeClient, logout, user } = useContext(AuthContext);
  const navigate = useNavigate();
  const auth = useContext(AuthContext);

  const handleNotification = () => {
    setNotification(!notification);
  };

  const handleChangeMenu = () => {
    let path = role == "admin" ? "/admin/events" : "/client/producers";
    if (role !== "admin") {
      auth.changeClient();
    }
    navigate(path);
  };

  return (
    <nav className="flex h-16 items-center justify-between border-b-[1px] border-colorBorder  px-10">
      <picture>
        <div onClick={handleChangeMenu} className="cursor-pointer">
          <img src={logo} />
        </div>
      </picture>
      <div className="flex select-none items-center gap-4 text-2xl">
        <div className="relative">
          <div
            className={`cursor-pointer text-gray-100 after:absolute after:-right-2 after:-top-1 after:h-2 after:w-2  after:rounded-full after:bg-red/60 ${
              notification ? "after:block" : "after:hidden"
            }`}
          >
            <IoIosNotifications onClick={handleNotification} />
          </div>
        </div>
        <p className="text-black text-xs">{user.email}</p>
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button
              variant="ghost"
              className="flex h-8 w-8 p-0 data-[state=open]:bg-muted"
            >
              <img
                src={user.profile_picture ? user.profile_picture : account}
                alt="profile-picture"
                className="rounded-full object-cover"
                width="36px"
                height="36px"
              />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent>
            <DropdownMenuItem
              className="flex cursor-pointer gap-1"
              onClick={logout}
            >
              <ArrowDownIcon />
              Logout
            </DropdownMenuItem>
            {role == "producer" && (
              <>
                <DropdownMenuSeparator />
                <DropdownMenuItem
                  className="flex cursor-pointer gap-1"
                  onClick={() => navigate("/client/change_password")}
                >
                  <Pencil1Icon />
                  Cambiar Contraseña
                </DropdownMenuItem>
                <DropdownMenuSeparator />
                <DropdownMenuItem
                  className="flex cursor-pointer gap-1"
                  onClick={() => navigate("/client/profile")}
                >
                  <AvatarIcon />
                  Perfil
                </DropdownMenuItem>
              </>
            )}
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
    </nav>
  );
};

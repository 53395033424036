import React, { useState, useEffect, FC, useContext } from "react";
import { Back } from "../Back";
import FormInput from "../FormElement/FormInput";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, FormProvider } from "react-hook-form";
import * as yup from "yup";
import jsonToFormData from "@ajoelp/json-to-formdata";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import ProducerService from "../../services/producerService";
import { IProducerRequest } from "../../types/IProducer";
import { AuthContext } from "../../context/AuthContext";
import FormCropperImage from "../FormElement/FormCropperImage";
import { blobToFile } from "../../utils/form";
import { StateContext } from "../../context/StateProvider";

const schema = yup.object().shape({
  name: yup.string().required("El nombre es requerido"),
  picture: yup.mixed(),
});

const defaultValues = {
  name: "",
  picture: "",
};
type typeProps = {
  handleCreateorEdit: (id: string) => void;
  id: string;
};
export const FormProducer: FC<typeProps> = ({ handleCreateorEdit, id }) => {
  const { user } = useContext(AuthContext);
  const { state, dispatch } = useContext(StateContext);
  const createItems = useMutation((item: FormData) =>
    ProducerService.create(item)
  );
  const updateItems = useMutation((item: FormData) =>
    ProducerService.update(id, item)
  );
  const getUserApi = useMutation((id: string) => ProducerService.get(id));

  const form = useForm<IProducerRequest>({
    resolver: yupResolver(schema),
    defaultValues: defaultValues,
  });

  useEffect(() => {
    if (id) {
      getUserApi.mutateAsync(id).then((res) => form.reset({ name: res.name }));
    }
  }, [form.reset]);

  const onSubmit = async (data: IProducerRequest) => {
    data = { ...data, client_id: user.id };
    if (data.picture && data.picture instanceof Blob) {
      data.picture = blobToFile(data.picture);
    }
    let formData = jsonToFormData(data, {
      arrayIndexes: true,
      excludeNull: true,
    });
    dispatch({ type: "showLoaderScreen", payload: true });
    if (id) {
      await updateItems.mutateAsync(formData);
      dispatch({ type: "showLoaderScreen", payload: false });
      dispatch({
        type: "toastSuccess",
        payload: "Productora actualizada correctamente",
      });
    } else {
      await createItems.mutateAsync(formData);
      dispatch({ type: "showLoaderScreen", payload: false });
      dispatch({
        type: "toastSuccess",
        payload: "Productora creada correctamente",
      });
    }
    handleCreateorEdit("");
  };

  const onError = (error: any) => {
    console.log("error ", error);
  };

  return (
    <div className="px-[15%] xl:px-0">
      <Back onClick={() => handleCreateorEdit("")} />
      <div className="flex flex-col gap-10 xl:px-[23rem]">
        <h1 className="text-center text-3xl font-bold">
          Crear nueva Productora
        </h1>
        <FormProvider {...form}>
          <form onSubmit={form.handleSubmit(onSubmit, onError)}>
            <FormInput name="name" type="text" label="Nombre" />
            <FormCropperImage
              name="picture"
              label="Foto de la productora"
              accept="image/png, image/gif, image/jpeg"
              initialAspectRatio={3 / 4}
              cropBoxResizable={false}
              dragMode="move"
            />
            <div className="mb-16 flex justify-center gap-10 pt-10">
              <button
                type="submit"
                className="rounded-full border-[2px] border-black-100 bg-black-100 px-[5.4rem] py-2 font-medium  text-white"
              >
                {id ? "Guardar Productora" : "Publicar Productora"}
              </button>
            </div>
          </form>
        </FormProvider>
      </div>
    </div>
  );
};

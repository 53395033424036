import { useState } from "react";

import { AvailableEvents } from "./AvailableEvents";
import { NewEvent } from "./NewEvent/NewEvent";
import { IEventResponse } from "../../types/IEvent";

export const EventsView = () => {
  // Btn create new event
  const [createEvent, setCreateEvent] = useState(false);

  // Manager of event selected
  const [showSelected, setShowSelected] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState<IEventResponse>(
    {} as IEventResponse
  );

  const handleCreateEvent = () => {
    setCreateEvent(!createEvent);
  };

  const handleSelectEvent = (data?: IEventResponse) => {
    setShowSelected(!showSelected);
    if (data) {
      setSelectedEvent(data);
    }
  };

  const availableProps = {
    handleCreateEvent,
    handleSelectEvent,
  };

  const newEventProps = {
    handleCreateEvent,
    setEditEvent: () => {},
  };

  return (
    <div className="relative w-full">
      {!createEvent ? (
        <AvailableEvents {...availableProps} />
      ) : (
        <NewEvent {...newEventProps} />
      )}
    </div>
  );
};

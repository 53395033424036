import { ComponentProps } from "react";
import { useController } from "react-hook-form";
import FormSelectMultiple from "../../../../FormElement/FormSelectMultiple";

const SelectMultiple = ({
  name,
  options,
  allowSelectAll,
}: Omit<ComponentProps<typeof FormSelectMultiple>, "options"> & {
  options?: { value: string | number; label: string; type?: string }[];
}) => {
  const { field } = useController({ name });

  const handleChange = (selectedValues: any) => {
    const freeTickets = options?.filter((ticket) => ticket.type === "Free");
    const paidTickets = options?.filter((ticket) => ticket.type === "Estandar");

    if (selectedValues.find(({ value }: any) => value === "all_free")) {
      field.onChange(freeTickets);
      return;
    }

    if (selectedValues.find(({ value }: any) => value === "all_paid")) {
      field.onChange(paidTickets);
      return;
    }

    if (selectedValues.findIndex(({ value }: any) => value === "all") !== -1)
      return field.onChange(options);

    return field.onChange(selectedValues);
  };

  return (
    <div className="min-w-[15rem] [&>:nth-child(1)]:mb-0 [&_select]:py-0">
      <FormSelectMultiple
        name={name}
        options={[
          ...(name === "tickets"
            ? [
                { value: "all_free", label: "Todos los tickets free" },
                { value: "all_paid", label: "Todos los tickets pagos" },
              ]
            : []),
          ...(options || []),
        ]}
        allowSelectAll={allowSelectAll}
        onChangeCustom={handleChange}
      />
    </div>
  );
};

export default SelectMultiple;

type sortProps = {
  setSort?: (filter: string) => void;
};

export const FilterDate = ({ setSort = () => {} }: sortProps) => {
  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    if (typeof setSort === "function") {
      setSort(event.target.value);
    }
  };

  return (
    <div className="">
      <form className=" text-sm font-bold">
        <label>Ordenar:</label>
        <select
          name="filtrar"
          id="filtrar"
          className="cursor-pointer border-none "
          onChange={(e) => handleChange(e)}
        >
          <option value="createdAt">Fecha</option>
          <option value="title">Nombre</option>
        </select>
      </form>
    </div>
  );
};

import { Dispatch, SetStateAction } from "react";
import { Link } from "react-router-dom";
import { QueryObserverResult } from "@tanstack/react-query";
import { ColumnDef } from "@tanstack/react-table";

import { DataTableRowActions } from "../../layout/table/dataTableRowActions";
import useActions from "../hooks/useActions";
import { Checkbox } from "../../ui/checkbox";
import { getDropdownOptions } from "./dropdownOptions";
import { IOfferResponse } from "../../../types_new/IOffer";
import { formatDate } from "../../../utils_new/formatDate";
import { formatMoney } from "../../../utils_new/formatMoney";
import {
  getBgColorFromValue,
  getOfferStatusFromValue,
} from "../../../utils_new/status";

interface OffersColumnsProps {
  refetch: () => Promise<QueryObserverResult>;
  setRowOnEdit: Dispatch<SetStateAction<IOfferResponse | undefined>>;
  offers: IOfferResponse[];
  setIsDialogOpen: Dispatch<SetStateAction<boolean>>;
  isFetchingOffers: boolean;
}

export const getOffersColumns = ({
  refetch,
  setRowOnEdit,
  offers,
  setIsDialogOpen,
  isFetchingOffers,
}: OffersColumnsProps): ColumnDef<IOfferResponse>[] => {
  const { handleEditPrice, handleDelete, handleSeeTraceability, isLoading } =
    useActions({ refetch, setRowOnEdit, offers, setIsDialogOpen });

  const getOfferUrl = (id: string) => `/admin/offers/${id}`;

  if (isLoading) {
    return [
      {
        id: "loading",
        cell: () => <div />,
      },
    ];
  }

  return [
    {
      id: "select",
      header: ({ table }) =>
        !isFetchingOffers ? (
          <Checkbox
            checked={
              table.getIsAllPageRowsSelected() ||
              (table.getIsSomePageRowsSelected() && "indeterminate")
            }
            onCheckedChange={(value) =>
              table.toggleAllPageRowsSelected(!!value)
            }
            aria-label="Select all"
          />
        ) : (
          <div />
        ),
      cell: ({ row }) =>
        !isFetchingOffers ? (
          <Checkbox
            checked={row.getIsSelected()}
            onCheckedChange={(value) => row.toggleSelected(!!value)}
            aria-label="Select row"
          />
        ) : (
          <div />
        ),
    },
    {
      accessorKey: "event_name",
      header: "Evento",
      cell: ({ row }) => {
        const { id } = row.original;
        return <Link to={getOfferUrl(id)}>{row.original.event_name}</Link>;
      },
    },
    {
      accessorKey: "producer_name",
      header: "Productora",
      cell: ({ row }) => {
        const { id } = row.original;
        return <Link to={getOfferUrl(id)}>{row.original.producer_name}</Link>;
      },
    },
    {
      accessorKey: "order_ticket_id",
      header: "Order Ticket",
      cell: ({ row }) => {
        const { id, order_ticket_id } = row.original;
        return <Link to={getOfferUrl(id)}>{`#${order_ticket_id}`}</Link>;
      },
    },
    {
      accessorKey: "createdAt",
      header: "Fecha de Publicación",
      cell: ({ row }) => {
        const { id } = row.original;
        return (
          <Link to={getOfferUrl(id)}>{formatDate(row.original.createdAt)}</Link>
        );
      },
    },
    {
      accessorKey: "subtotal",
      header: "Subtotal",
      cell: ({ row }) => {
        const { id } = row.original;
        return (
          <Link to={getOfferUrl(id)}>{formatMoney(row.original.subtotal)}</Link>
        );
      },
    },
    {
      accessorKey: "service",
      header: "Recargo",
      cell: ({ row }) => {
        const { id } = row.original;
        return (
          <Link to={getOfferUrl(id)}>{formatMoney(row.original.service)}</Link>
        );
      },
    },
    {
      accessorKey: "total",
      header: "Total",
      cell: ({ row }) => {
        const { id } = row.original;
        return (
          <Link to={getOfferUrl(id)}>{formatMoney(row.original.total)}</Link>
        );
      },
    },
    {
      accessorKey: "ticket_name",
      header: "Ticket",
      cell: ({ row }) => {
        const { id } = row.original;
        return <Link to={getOfferUrl(id)}>{row.original.ticket_name}</Link>;
      },
    },
    {
      accessorKey: "trader_name",
      header: "Trader",
      cell: ({ row }) => {
        const { id } = row.original;
        return (
          <Link to={getOfferUrl(id)}>
            <strong>{row.original.trader_name}</strong>
          </Link>
        );
      },
    },
    {
      accessorKey: "status",
      header: "Estado Publicación",
      cell: ({ row }) => {
        const { id } = row.original;
        return (
          <Link
            to={getOfferUrl(id)}
            className="flex items-center justify-center gap-3 rounded-full border-[1px] border-colorBorder px-5  py-2 text-center "
          >
            <div
              className={`rounded-full ${getBgColorFromValue(
                row.getValue("status")
              )} p-[.35rem]`}
            />
            <p className="capitalize">
              {getOfferStatusFromValue(row.getValue("status"))}
            </p>
          </Link>
        );
      },
    },
    {
      id: "actions",
      cell: ({ row }) => {
        const { id, order_ticket_id } = row.original;
        return (
          <DataTableRowActions
            row={row}
            dropdownOptions={getDropdownOptions({
              offer_id: id,
              order_ticket_id,
              handleEditPrice,
              handleDelete,
              handleSeeTraceability,
            })}
          />
        );
      },
    },
  ];
};

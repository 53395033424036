import { Dispatch, SetStateAction } from "react";

import { Input } from "../../ui/input";
import { IFilterRequest } from "../../../types_new/IFilter";
import { updateUrl } from "../../../utils_new/updateUrl";

interface FilterQueryProps {
  search: string;
  setSearch: Dispatch<SetStateAction<string>>;
  filters: IFilterRequest;
  setFilters: Dispatch<SetStateAction<IFilterRequest>>;
}

export const FilterQuery = ({
  search,
  setSearch,
  filters,
  setFilters,
}: FilterQueryProps) => {
  const handleChangeSearch = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const newSearch = evt.target.value;
    updateUrl({ query: newSearch, page: 1 });
    setFilters({ ...filters, page: 1 });
    setSearch(newSearch);
  };

  return (
    <Input
      type="text"
      placeholder="Buscar..."
      value={search}
      onChange={handleChangeSearch}
    />
  );
};

import React, { useEffect } from "react";
import { Back } from "../../Back";
import FormInput from "../../FormElement/FormInput";
import { useForm, FormProvider } from "react-hook-form";
import * as yup from "yup";
import { useNavigate } from "react-router";
import { IProducerRequest } from "../../../types/IProducer";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { useMutation } from "@tanstack/react-query";
import ProducerService from "../../../services/producerService";
import jsonToFormData from "@ajoelp/json-to-formdata";
import { setFormErrorsFromServer, blobToFile } from "../../../utils/form";
import FormCropperImage from "../../FormElement/FormCropperImage";

const schema = yup.object().shape({
  name: yup.string().required("Campo requerido"),
  picture: yup.mixed(),
});

const defaultValues = {
  name: "",
  picture: "",
};

type newClientProducerProps = {
  handleCreateProducer: () => void;
  clientID: string;
  producerID?: string | undefined;
};

export const NewClientProducers = ({
  handleCreateProducer,
  clientID,
  producerID,
}: newClientProducerProps) => {
  const navigate = useNavigate();
  const form = useForm<IProducerRequest>({
    resolver: yupResolver(schema),
    defaultValues: { ...defaultValues, client_id: clientID },
  });
  const createItems = useMutation((item: FormData) =>
    ProducerService.create(item)
  );
  const updateItems = useMutation((item: FormData) =>
    ProducerService.update(producerID || "", item)
  );
  const onSubmit = async (data: IProducerRequest) => {
    if (data.picture && data.picture instanceof Blob) {
      data.picture = blobToFile(data.picture);
    }
    let formData = jsonToFormData(data, {
      arrayIndexes: true,
      excludeNull: true,
    });
    if (producerID && producerID.length) {
      updateItems
        .mutateAsync(formData)
        .then((res) => handleCreateProducer())
        .catch((reason) => {
          setFormErrorsFromServer(reason, form.setError);
        });
    } else {
      createItems
        .mutateAsync(formData)
        .then((res) => handleCreateProducer())
        .catch((reason) => {
          setFormErrorsFromServer(reason, form.setError);
        });
    }
  };

  const getItem = useMutation(() => ProducerService.get(producerID || ""));
  useEffect(() => {
    if (producerID) {
      getItem.mutateAsync().then((res) => form.reset(res));
    }
  }, [form.reset]);

  const onError = (error: any) => {
    console.log("error ", error);
  };

  // useEffect(() => {
  //   console.info('local storage session', localStorage.getItem(config.session.tokenName))
  // },[]);
  return (
    <div>
      <Back onClick={handleCreateProducer} />
      <div className="flex flex-col gap-10 xl:px-[23rem]">
        <h1 className="text-center text-3xl font-bold">
          {producerID ? "Editar" : "Crear nueva"} Productora
        </h1>
        <FormProvider {...form}>
          <form
            onSubmit={form.handleSubmit(onSubmit, onError)}
            className="flex flex-col gap-10"
          >
            <FormInput name="name" type="text" label="Nombre" />
            <FormCropperImage
              name="picture"
              label="Foto de la productora"
              accept="image/png, image/gif, image/jpeg"
              initialAspectRatio={3 / 4}
              cropBoxResizable={false}
              dragMode="move"
            />
            <div className="mb-16 flex justify-center gap-10 pt-10">
              <button
                type="submit"
                className="rounded-full border-[2px] border-black-100 bg-black-100 px-[5.4rem] py-2 font-medium  text-white"
              >
                {producerID ? "Guardar" : "Crear"} Productora
              </button>
            </div>
          </form>
        </FormProvider>
      </div>
    </div>
  );
};

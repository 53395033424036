import { OptionProps } from "./index.types";

const Option = ({ title, description, children }: OptionProps) => {
  return (
    <div className="flex flex-wrap items-center gap-7 border-y border-black-100 py-1.5">
      <div className="max-w-[210px]">
        <b className="text-base">{title}</b>
        {description && <p className="text-sm">{description}</p>}
      </div>
      {children}
    </div>
  );
};

export default Option;

export const buildPieChartData = (data: Array<number>) => {
  return {
    labels: [],
    datasets: [
      {
        data: data,
        backgroundColor: [
          "rgba(95, 220, 179, 0.5)",
          "rgba(255, 255, 255, 0.5)",
        ],
        borderColor: ["rgba(95, 220, 179, 0.5)", "rgba(0, 0, 10, 0.1)"],
        borderWidth: 2,
      },
    ],
  };
};
import { useState, useEffect, useContext } from "react";
import { FaTrashAlt } from "react-icons/fa";
import { toast } from "react-toastify";
import { useMutation } from "@tanstack/react-query";

import { IFilterRequest } from "../../types/IFilter";
import ISortOption from "../../types/ISortOption";
import { PageContainer } from "../PageContainer";
import { TitleSection } from "../TitleSection";
import { FilterOrder } from "../table/FilterOrder";
import { FilterPage } from "../table/FilterPage";
import { FilterQtyPage } from "../table/FilterQtyPage";
import { FilterSection } from "../table/FilterSection";
import { UserRecycler } from "./partials/UserRecycler";
import { OrderRecycler } from "./partials/OrderRecycler";
import { TicketRecycler } from "./partials/TicketRecycler";
import { NewsRecycler } from "./partials/NewsRecycler";
import { EventRecycler } from "./partials/EventsRecycler";
import EventService from "../../services/eventService";
import OrderService from "../../services/orderService";
import TicketService from "../../services/ticketService";
import UserService from "../../services/userService";
import NewsService from "../../services/newsService";
import { StateContext } from "../../context/StateProvider";
import config from "../../config/variables";

const defaultResult = {
  total: 0,
  pages: 0,
  rows: [],
};

const defaultFilters = {
  limit: config.filter_default_limit,
  page: 1,
  status: "all",
  recycler: true,
  sortBy: "updatedAt",
  sortOrder: "desc",
};

const filterSectionProps = [
  { title: "Usuarios", id: "users" },
  { title: "Ordenes", id: "orders" },
  { title: "Entradas", id: "tickets" },
  { title: "Novedades", id: "news" },
  { title: "Rumores", id: "rumors" },
  { title: "Eventos", id: "events" },
];

const sortOptions: Array<ISortOption> = [
  { value: "updatedAt", text: "Fecha", sortOrder: "desc" },
  { value: "first_name", text: "Nombre", sortOrder: "asc" },
];

export const TableRecycler = () => {
  const getItems = useMutation((option: string) => {
    switch (option) {
      case "users":
        return UserService.getAll(filters);
      case "orders":
        return OrderService.getAll(filters);
      case "tickets":
        return TicketService.getAll(filters);
      case "news":
        return NewsService.getAll({ ...filters, type: "novedad" });
      case "rumors":
        return NewsService.getAll({ ...filters, type: "rumor" });
      case "events":
        return EventService.getAll(filters);
    }

    return Promise.resolve();
  });

  let paramsFilters: any = { status: "", sortBy: "" };

  useEffect(() => {
    handleFilter(filters);
  }, []);

  const [result, setResult] = useState<any>(defaultResult);
  const [filters, setFilters] = useState<IFilterRequest>(defaultFilters);
  const [filterSectionOption, setFilterSectionOption] = useState("users");
  const [search, setSearch] = useState("");

  const searcher = async (e: any) => {
    setSearch(e.target.value);

    paramsFilters = { ...filters, query: e.target.value };
    await setFilters({ ...filters, page: 1, query: e.target.value });
    await getItems.mutateAsync(filterSectionOption).then((res) => {
      setResult(res);
    });
  };

  const handleFilter = async (filter: IFilterRequest) => {
    await setFilters({ ...filters, ...filter });
    getItems.mutateAsync(filterSectionOption).then((res) => setResult(res));
  };

  let filterString: string;

  const handleFilterSection = async (filter: string) => {
    filterString = filter;
    await setFilters({ ...filters, page: 1, status: "all" });
    await getItems.mutateAsync(filter).then(async (res) => {
      await setResult(res);
    });
    setFilterSectionOption(filter);
  };

  const handleSortSection = async (sortBy: string) => {
    const itemSort = sortOptions.find((s) => s.value == sortBy);
    paramsFilters = { ...filters, page: 1, sortBy: sortBy };
    if (itemSort) paramsFilters["sortOrder"] = itemSort.sortOrder;
    await setFilters(paramsFilters);
    await getItems
      .mutateAsync(filterSectionOption)
      .then((res) => setResult(res));
  };

  const { state, dispatch } = useContext(StateContext);
  const [stateOrder, setState] = useState("desc");

  const handleChangeState = async (state: string) => {
    await setState(state);
    await setFilters({ ...filters, sortOrder: state });

    dispatch({ type: "showLoaderScreen", payload: true });
    getItems.mutateAsync(filterSectionOption).then((res) => {
      dispatch({ type: "showLoaderScreen", payload: false });
      setResult(res);
    });
  };

  const emptyTrash = () => {
    if (confirm("Está seguro de eliminar definitivamente estos ítems?")) {
      switch (filterSectionOption) {
        case "users":
          return UserService.emptyTrash()
            .then((res) => setResult(defaultResult))
            .catch((error) => {
              toast.error(error.response.data.message);
            });
        case "orders":
          return OrderService.emptyTrash()
            .then((res) => setResult(defaultResult))
            .catch((error) => {
              toast.error(error.response.data.message);
            });
        case "tickets":
          return TicketService.emptyTrash()
            .then((res) => setResult(defaultResult))
            .catch((error) => {
              toast.error(error.response.data.message);
            });
        case "news":
          return NewsService.emptyTrash({ type: "Novedad" })
            .then((res) => setResult(defaultResult))
            .catch((error) => {
              toast.error(error.response.data.message);
            });
        case "rumors":
          return NewsService.emptyTrash({ type: "Rumor" })
            .then((res) => setResult(defaultResult))
            .catch((error) => {
              toast.error(error.response.data.message);
            });
        case "events":
          return EventService.emptyTrash()
            .then((res) => setResult(defaultResult))
            .catch((error) => {
              toast.error(error.response.data.message);
            });
      }
    }
  };

  const showTables = () => {
    switch (filterSectionOption) {
      case "usuarios":
        return <UserRecycler data={result} updateData={handleFilterSection} />;
      case "orders":
        return <OrderRecycler data={result} updateData={handleFilterSection} />;
      case "tickets":
        return (
          <TicketRecycler data={result} updateData={handleFilterSection} />
        );
      case "news":
        return (
          <NewsRecycler
            data={result}
            updateData={handleFilterSection}
            filter={filterSectionOption}
          />
        );
      case "rumors":
        return (
          <NewsRecycler
            data={result}
            updateData={handleFilterSection}
            filter={filterSectionOption}
          />
        );
      case "events":
        return <EventRecycler data={result} updateData={handleFilterSection} />;
      default:
        return <UserRecycler data={result} updateData={handleFilterSection} />;
    }
  };

  return (
    <PageContainer>
      <div className="relative">
        <TitleSection>Papelera</TitleSection>
      </div>
      <div className="mb-7 flex justify-between border-b-[1px] border-colorBorder pr-5 pt-10">
        <FilterSection
          filterSectionProps={filterSectionProps}
          handleFilter={handleFilterSection}
          defaultSelected="users"
        />
        <button
          type="button"
          className="flex items-center justify-center text-[#3E7EFF]"
          onClick={emptyTrash}
        >
          <FaTrashAlt size={20} />
          Vaciar Papelera
        </button>
        <div className="flex items-center gap-5 ">
          <input
            type="text"
            placeholder="Search..."
            className="hover:text-black rounded-full border-[2px] border-black-100 px-4 py-1 font-bold  text-black-100"
            value={search}
            onChange={searcher}
          />
          <FilterOrder
            setSort={handleSortSection}
            options={sortOptions}
            handleChangeState={handleChangeState}
            state={stateOrder}
          />
        </div>
      </div>
      <div>{showTables()}</div>
      <div className="flex justify-between pt-10">
        <FilterPage
          handleFilter={handleFilter}
          total={result.total}
          limit={filters.limit}
          status={filters.status}
          currentPage={filters.page}
        />
        <FilterQtyPage handleFilter={handleFilter} status={filters.status} />
      </div>
    </PageContainer>
  );
};

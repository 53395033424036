import { useEffect, useContext } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { useMutation } from "@tanstack/react-query";
import jsonToFormData from "@ajoelp/json-to-formdata";
import * as yup from "yup";

import FormInput from "../FormElement/FormInput";
import { IConfigRequest } from "../../types/IConfig";
import ConfigService from "../../services/configService";
import { setFormErrorsFromServer } from "../../utils/form";
import { StateContext } from "../../context/StateProvider";

const schema = yup.object().shape({
  bombo_points: yup.string().required("Campo requerido"),
});

const defaultValues = {
  bombo_points: 0,
};

export const FormConfigPoint = ({}) => {
  const form = useForm<IConfigRequest>({
    resolver: yupResolver(schema),
    defaultValues: defaultValues,
  });

  const { state, dispatch } = useContext(StateContext);

  const getItem = useMutation(() => ConfigService.get("0"));
  const updateItems = useMutation((item: FormData) =>
    ConfigService.update("0", item)
  );

  const onSubmit = async (data: IConfigRequest) => {
    let formData = jsonToFormData(data, {
      arrayIndexes: true,
      excludeNull: true,
    });
    dispatch({ type: "showLoaderScreen", payload: true });
    updateItems
      .mutateAsync(formData)
      .then((res) => {
        dispatch({ type: "showLoaderScreen", payload: false });
        dispatch({
          type: "toastSuccess",
          payload: "Costo de servicio actualizado",
        });
      })
      .catch((reason) => {
        setFormErrorsFromServer(reason, form.setError);
      });
  };

  useEffect(() => {
    getItem
      .mutateAsync()
      .then((res) => form.setValue("bombo_points", res.bombo_points));
  }, [form.reset]);

  const onError = (error: any) => {
    console.log("error ", error);
  };

  return (
    <div className="flex flex-col gap-10 px-[20%] xl:px-[23rem]">
      <h1 className="text-center text-3xl font-bold">Puntos bombo</h1>
      <FormProvider {...form}>
        <form
          onSubmit={form.handleSubmit(onSubmit, onError)}
          className="flex flex-col gap-10"
        >
          <div className="flex justify-center gap-10 ">
            <FormInput
              name="bombo_points"
              type="number"
              label="Porcentaje de servicio"
              min="0"
              max="100"
            />
          </div>
          <div className="mb-16 flex justify-center gap-10 pt-10">
            <button
              type="submit"
              className="rounded-full border-[2px] border-black-100 bg-black-100 px-[5.4rem] py-2 font-medium  text-white"
            >
              Guardar
            </button>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

import moment from "moment/moment";
import "moment/locale/es";
moment.locale("es");

export const formatDate = (date?: string) => {
  if (!date) return undefined;

  var dato = moment(date).utcOffset(3, true).format("DD MMM YYYY");
  const convertDate = dato.replace(". ", " ");
  return convertDate;
};

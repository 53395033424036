import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { GoGraph } from "react-icons/go";
import { FaEdit, FaTrashAlt } from "react-icons/fa";
import { useMutation } from "@tanstack/react-query";
import { motion } from "framer-motion";
import jsonToFormData from "@ajoelp/json-to-formdata";

import { FilterSection } from "../table/FilterSection";
import { BannerEvent } from "./BannerEvent";
import eventService from "../../services/eventService";
import { IEventResponse, IEventsResponse } from "../../types/IEvent";
import { AuthContext } from "../../context/AuthContext";
import { FilterPage } from "../table/FilterPage";
import { FilterQtyPage } from "../table/FilterQtyPage";
import { IFilterEventRequest, IFilterRequest } from "../../types/IFilter";
import DropdownDots from "../FormElement/DropdownDots";
import { getEventStatus, getStatusFromValue } from "../../utils/status";
import { formatDate } from "../../utils/format-date";
import ISortOption from "../../types/ISortOption";
import { FilterOrder } from "../table/FilterOrder";
import { Back } from "../Back";
import { StateContext } from "../../context/StateProvider";
import { PageContainer } from "../PageContainer";
import { toast } from "react-toastify";
import { IProducerResponse } from "../../types/IProducer";
import ProducerService from "../../services/producerService";
import TableCellLink from "../FormElement/TableCellLink.tsx";
import config from "../../config/variables";
import { DatePickerWithRange } from "../ui/datepicker";
import { DateRange } from "react-day-picker";
import { format } from "date-fns";
import SkeletonTable from "../DataTable/SkeletonTable";

type AvailableEventsProps = {
  handleCreateEvent: () => void;
  handleSelectEvent: (item?: IEventResponse) => void;
};

const defaultFilters = {
  limit: config.filter_default_limit,
  page: 1,
  status: "all",
  sortBy: "createdAt",
  sortOrder: "-1",
  query: "",
};

const filterSectionProps = [
  { title: "Todos los eventos", id: "all" },
  { title: "Activos", id: "active" },
  { title: "Pausados", id: "paused" },
  { title: "Finalizados", id: "finished" },
];

const filterSectionPropsAdmin = [
  { title: "Todos los eventos", id: "all" },
  { title: "Activos", id: "active" },
  { title: "Pausados", id: "paused" },
  { title: "Esperando aprobación", id: "pending_approval" },
  { title: "Finalizados", id: "finished" },
];

const columnData = [
  "Fecha de alta",
  "Fecha del evento",
  "Nombre",
  "Productora",
  "Cliente",
  "NFT",
  "Status",
  "Orden",
  "Oculto",
  "",
];

export const AvailableEvents = ({
  handleCreateEvent,
  handleSelectEvent,
}: AvailableEventsProps) => {
  const navigate = useNavigate();
  const auth = useContext(AuthContext);
  const { producer_id } = useParams();

  const isAdmin = auth.role === "admin";
  const authClientProducerID =
    !isAdmin && auth?.producer?.id && auth.producer.id.length
      ? auth.producer.id
      : undefined;

  const producerID = producer_id || authClientProducerID || undefined;
  console.log("🚀 ~ producerID:", producerID);

  const [filters, setFilters] = useState<IFilterEventRequest>({
    ...defaultFilters,
    producer_id: producerID,
  });

  const [loading, setLoading] = useState<boolean>(false);

  const [itemId, setItemId] = useState<string>();
  const [currentEditOrder, setCurrentEditOrder] = useState<string>();
  const [currentValueOrder, setCurrentValueOrder] = useState<number>();
  const [currentProducer, setCurrentProducer] = useState<IProducerResponse>();
  let clickDelete = false;

  let paramsFilters: any = { producer_id: producerID, status: "", sortBy: "" };

  const getItems = useMutation(() => eventService.getAll(filters));
  const getCsv = useMutation(() =>
    eventService.getCsv({ producer_id: producerID })
  );
  const deleteItems = useMutation((id: string) =>
    eventService.sendRecycler(id)
  );
  const updateItems = useMutation((item: FormData) =>
    eventService.update(itemId, item)
  );
  const updateEventOrder = useMutation((order: number) =>
    eventService.updateOrder(itemId, order)
  );
  const updateEventStatus = useMutation((status: string) =>
    eventService.updateStatus(itemId, status)
  );
  const updateEventVisibility = useMutation((hidden: boolean) =>
    eventService.updateVisibility(itemId, hidden)
  );
  const getProducer = useMutation((id: string) => ProducerService.get(id));

  const [data, setData] = useState<IEventsResponse>({
    rows: [],
    total: 0,
    pages: 0,
  });

  const [search, setSearch] = useState("");

  useEffect(() => {
    if (producer_id) {
      getProducer
        .mutateAsync(producer_id)
        .then((res) => setCurrentProducer(res));
    }
  }, []);

  useEffect(() => {
    if (search != "") {
      const delayDebounceFn = setTimeout(() => {
        loadData();
      }, 600);
      return () => clearTimeout(delayDebounceFn);
    } else {
      loadData();
    }
  }, [filters]);

  const loadData = () => {
    setLoading(true);
    // dispatch({ type: "showLoaderScreen", payload: true });
    getItems.mutateAsync().then((res) => {
      // dispatch({ type: "showLoaderScreen", payload: false });
      if (!isAdmin && !producerID) {
        setLoading(false);
        return;
      }
      setData(res);
      setLoading(false);
    });
  };

  const searcher = async (e: any) => {
    setSearch(e.target.value);
    paramsFilters = { ...filters, query: e.target.value };
    await setFilters({ ...filters, page: 1, query: e.target.value });
  };

  let resultado = [];
  resultado = data.rows;

  const handleFilter = async (filter: IFilterEventRequest) => {
    await setFilters({ ...filters, ...filter });
    // await getItems.mutateAsync().then((res) => setData(res));
  };

  const handleFilterSection = async (filter: string) => {
    paramsFilters = { ...filters, status: filter };
    await setFilters({ ...filters, page: 1, status: filter });
    // await getItems.mutateAsync().then((res) => setData(res));
  };

  const handleSortSection = async (sortBy: string) => {
    const itemSort = sortOptions.find((s) => s.value == sortBy);
    paramsFilters = { ...filters, page: 1, sortBy: sortBy };
    if (itemSort) paramsFilters["sortOrder"] = itemSort.sortOrder;
    await setFilters(paramsFilters);
    // await getItems.mutateAsync().then((res) => setData(res));
  };

  const handleDelete = async (id: string, hasOrders: boolean = false) => {
    clickDelete = true;
    if (
      !window.confirm(
        "¿Confirma que quiere enviar este registro a la papelera de reciclaje?"
      )
    )
      return;
    if (
      hasOrders &&
      !window.confirm(
        "¡Cuidado! Este evento tiene órdenes asignadas. ¿Estás seguro?"
      )
    )
      return;

    await deleteItems
      .mutateAsync(id)
      .then((res) =>
        getItems
          .mutateAsync()
          .then((res) => {
            setData(res);
            clickDelete = false;
          })
          .catch(() => {
            clickDelete = false;
          })
      )
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  };

  const handleAuthorizeEvent = async (id: string) => {
    await setItemId(id);
    const dataUpdate: Object = { status: "active", admin_action: "approved" };

    let formData = jsonToFormData(dataUpdate, {
      arrayIndexes: true,
      excludeNull: true,
    });

    await updateItems.mutateAsync(formData).then((res) => {
      const message = "Evento Autorizado";
      dispatch({ type: "toastSuccess", payload: message });
      getItems
        .mutateAsync()
        .then((res) => {
          setData(res);
        })
        .catch(() => {});
    });
  };

  const handleComments = (id: string) => {
    if (window.location.pathname.includes("admin/")) {
      navigate(`/admin/events/${id}/comments`);
    }
  };

  const handleCsv = async () => {
    await getCsv.mutateAsync().then((response) => {
      const url = window.URL.createObjectURL(new Blob([response]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "events.csv");
      document.body.appendChild(link);
      link.click();
    });
  };

  const getFilterSection = () => {
    return auth.role === "admin" ? filterSectionPropsAdmin : filterSectionProps;
  };

  const getEventDetailLink = (id: string) => {
    let eventDetailLink: string = "";

    if (window.location.pathname.includes("admin/")) {
      const eventsPath = producer_id
        ? `producers/${producer_id}/events`
        : "events";
      eventDetailLink = `/admin/${eventsPath}/${id}`;
    }

    if (window.location.pathname.includes("client/"))
      eventDetailLink = `/client/events/${id}`;

    return eventDetailLink;
  };

  const handleEventDetail = (id: string) => {
    navigate(getEventDetailLink(id));
  };

  const sortOptions: Array<ISortOption> = [
    { value: "date_start", text: "Fecha", sortOrder: "asc" },
    { value: "name", text: "Nombre", sortOrder: "asc" },
  ];

  const { state, dispatch } = useContext(StateContext);

  const handleChangeMenu = () => {
    let path = "/client/producers";
    if (auth.role !== "admin") {
      auth.changeClient();
    }
    navigate(path);
  };

  const handleOnChange = (e: any) => {
    setCurrentValueOrder(Number(e.target.value));
  };

  const handleUpdateEventOrder = async (id: string, row: IEventResponse) => {
    await setItemId(id);
    dispatch({ type: "showLoaderScreen", payload: true });

    await updateEventOrder
      .mutateAsync(currentValueOrder !== undefined ? currentValueOrder : 0)
      .then((res) => {
        row.order = parseInt(currentValueOrder?.toString() || "0");
        dispatch({ type: "showLoaderScreen", payload: false });
        // getItems.mutateAsync().then(res => { setData(res) }).catch(() => { });
        setCurrentEditOrder("");
      })
      .catch((e) => dispatch({ type: "showLoaderScreen", payload: false }));
  };

  const handleUpdateEventVisibility = async (
    id: string,
    hidden: boolean,
    row: IEventResponse
  ) => {
    await setItemId(id);
    dispatch({ type: "showLoaderScreen", payload: true });
    await updateEventVisibility
      .mutateAsync(hidden)
      .then((res) => {
        row.hidden = hidden;
        dispatch({ type: "showLoaderScreen", payload: false });
      })
      .catch((e) => dispatch({ type: "showLoaderScreen", payload: false }));
  };

  const handleUpdateEventStatus = async (
    id: string,
    status: string,
    row: IEventResponse
  ) => {
    await setItemId(id);
    dispatch({ type: "showLoaderScreen", payload: true });
    await updateEventStatus
      .mutateAsync(status)
      .then((res) => {
        row.status = status;
        dispatch({ type: "showLoaderScreen", payload: false });
      })
      .catch((e) => dispatch({ type: "showLoaderScreen", payload: false }));
  };

  const editPendingApproval = (event: any): Array<any> => {
    const result = [];
    if (getEventStatus(event) == "pending_approval") {
      result.push({
        title: "Revisar",
        action: () => handleEventDetail(event.id),
        icon: <FaEdit size={23} />,
      });
    }
    return result;
  };

  const [stateOrder, setState] = useState("asc");

  const handleChangeState = async (state: string) => {
    setState(state);
    await setFilters({ ...filters, sortOrder: state });
    loadData();
  };

  const handleBack = () => {
    navigate(`/admin/producers/${currentProducer?.client_id}`);
  };

  const getDropdownDotsOptions = (event: IEventResponse) => {
    const options = [
      {
        title: "Enviar a la papelera",
        action: () => handleDelete(event.id, event.orders!.length > 0),
        icon: <FaTrashAlt size={23} />,
      },
      {
        title: "Ver Comentarios",
        action: () => {
          handleComments(event.id);
        },
        description: "Listado de comentarios",
      },
    ];

    const statusOption =
      getEventStatus(event) == "active"
        ? [
            {
              title: "Pausar Evento",
              action: () => {
                handleUpdateEventStatus(event.id, "paused", event);
              },
              description: "El evento quedará pausado",
            },
          ]
        : getEventStatus(event) == "paused"
        ? [
            {
              title: "Activar Evento",
              action: () => {
                handleUpdateEventStatus(event.id, "active", event);
              },
              description: "El evento quedará activo",
            },
          ]
        : getEventStatus(event) == "pending_approval"
        ? [
            {
              title: "Autorizar Evento",
              action: () => {
                handleAuthorizeEvent(event.id);
              },
              description:
                "El evento quedará publicado en el perfil de la productora",
            },
            ...editPendingApproval(event),
          ]
        : null;

    if (statusOption) options.push(...statusOption);

    return options;
  };

  const [date, setDate] = useState<DateRange | undefined>(undefined);
  const handleDateRangeChange = async (date: DateRange | undefined) => {
    console.log("🚀 ~ handleDateRangeChange ~ date:", date);
    setDate(date);
    const from = date?.from;
    const to = date?.to;
    if ((!from && to) || (from && !to)) return;
    setFilters({
      ...filters,
      date_from: from ? format(from, "yyyy-MM-dd") : undefined,
      date_to: to ? format(to, "yyyy-MM-dd") : undefined,
    });
    // await getItems.mutateAsync().then((res) => setData(res));

    // handleFilter({
    //   date_from: from ? format(from, "yyyy-MM-dd") : undefined,
    //   date_to: to ? format(to, "yyyy-MM-dd") : undefined,
    //   ...filters,
    // });
  };

  return (
    <PageContainer>
      <div className="pb-4">
        {auth.role !== "admin" && <Back onClick={handleChangeMenu} />}
        {auth.role == "admin" && !!producer_id && <Back onClick={handleBack} />}
      </div>
      <div className="flex justify-between">
        <h1 className="text-3xl font-bold">
          Eventos{" "}
          {producer_id &&
            currentProducer &&
            auth.role == "admin" &&
            `(${currentProducer.name})`}
        </h1>
        <div className="flex justify-between gap-2">
          {(["producer", "coproducer"].includes(auth.user.user_role) ||
            !!producer_id) &&
          ["active"].includes(auth.user.status) ? (
            <button
              onClick={handleCreateEvent}
              className="rounded-full border-[2px] border-black-100 px-12 py-2 font-bold text-black-100 hover:bg-black-100 hover:text-white"
            >
              Crear nuevo evento
            </button>
          ) : null}
          <DatePickerWithRange
            date={date}
            onDateChange={handleDateRangeChange}
          />
          <input
            type="text"
            placeholder="Search..."
            className="hover:text-black rounded-full border-[2px] border-black-100 px-4 py-1 font-bold  text-black-100"
            value={search}
            onChange={searcher}
          />
        </div>
      </div>

      <div className="mb-7 flex justify-between border-b-[1px] border-colorBorder pt-10  ">
        <FilterSection
          filterSectionProps={getFilterSection()}
          handleFilter={handleFilterSection}
        />
        <div className="flex items-center gap-5 ">
          <button
            className="flex items-center justify-center text-[#3E7EFF]"
            onClick={handleCsv}
          >
            <GoGraph className="mr-1" />
            Exportar a CSV
          </button>
          <FilterOrder
            setSort={handleSortSection}
            options={sortOptions}
            handleChangeState={handleChangeState}
            state={stateOrder}
          />
        </div>
      </div>
      {loading && (
        <>
          {" "}
          <SkeletonTable columns={[]} />
        </>
      )}
      {auth.role === "admin" && !loading && (
        <table className="my-table-spacing h-full w-full border-separate border-spacing-y-2 gap-5 pt-5 text-left">
          <thead className=" divide-solid border ">
            <tr className=" pb-4 text-sm font-bold text-gray-100">
              {columnData.map((column, index) => (
                <th
                  className="border-b-[1px] border-colorBorder pb-4"
                  key={column + index}
                >
                  {column}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {resultado?.map((row, i) => (
              <motion.tr
                key={i}
                layout
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                initial={{ opacity: 0.2 }}
                transition={{ type: "spring", stiffness: 100, bounce: 0 }}
                className={`h-16  rounded-xl text-start font-medium outline outline-[1px] outline-colorBorder`}
              >
                <TableCellLink
                  href={getEventDetailLink(row.id)}
                  className="pl-4"
                >
                  {formatDate(row.createdAt)}
                </TableCellLink>
                <TableCellLink
                  href={getEventDetailLink(row.id)}
                  className="pl-4"
                >
                  {formatDate(row.date_start)}
                </TableCellLink>
                <TableCellLink
                  href={getEventDetailLink(row.id)}
                  className="pl-4"
                >
                  {row.name}
                </TableCellLink>
                <TableCellLink
                  href={getEventDetailLink(row.id)}
                  className="pl-4"
                >
                  {row.producer ? row.producer.name : ""}
                </TableCellLink>
                <TableCellLink
                  href={getEventDetailLink(row.id)}
                  className="pl-4"
                >
                  {row.client && !Array.isArray(row.client)
                    ? row.client?.first_name + " " + row.client?.last_name
                    : ""}
                </TableCellLink>
                {row?.tickets[0]?.nft_ticket ? (
                  <td className="flex h-full cursor-pointer items-center gap-2 pl-4 font-normal underline">
                    <a href={row?.tickets[0]?.nft_ticket} target="_blank">
                      Ver NFT
                    </a>
                  </td>
                ) : (
                  <td className="flex h-full items-center gap-2 pl-4 font-normal ">
                    <a href={row?.tickets[0]?.nft_ticket}></a>
                  </td>
                )}
                <TableCellLink
                  href={getEventDetailLink(row.id)}
                  className="pl-4"
                >
                  <div
                    className={`flex ${
                      getEventStatus(row).length > 12 ? "w-34" : "w-32"
                    } items-center justify-center gap-3 rounded-full border-[1px] border-colorBorder  py-2 text-center`}
                  >
                    <div
                      className={`rounded-full  ${
                        ["active", "finished"].includes(getEventStatus(row))
                          ? "bg-green"
                          : "bg-red"
                      } p-[.35rem]`}
                    />
                    <p className="capitalize">
                      {getStatusFromValue(getEventStatus(row))}
                    </p>
                  </div>
                </TableCellLink>
                <td className="pl-4">
                  {currentEditOrder == row.id ? (
                    <div className="flex items-center">
                      <input
                        type="number"
                        name={"event_" + row.id}
                        value={currentValueOrder}
                        onChange={handleOnChange}
                        className="hover:text-black w-16 rounded-full border-[1px] border-black-100 px-2 py-1 font-bold text-black-100"
                      />
                      <button
                        onClick={() => handleUpdateEventOrder(row.id, row)}
                        className="ml-1 rounded-full border-[1px] border-black-100 px-3 py-2 font-bold text-black-100 hover:bg-black-100 hover:text-white"
                      >
                        <FaEdit size={12} />
                      </button>
                    </div>
                  ) : (
                    <div className="flex items-center">
                      {row.order}
                      <button
                        onClick={() => {
                          setCurrentEditOrder(row.id);
                          setCurrentValueOrder(row.order);
                        }}
                        className="ml-1 rounded-full border-[2px] border-black-100 px-2 py-2 font-bold text-black-100 hover:bg-black-100 hover:text-white"
                      >
                        <FaEdit size={12} />
                      </button>
                    </div>
                  )}
                </td>
                <td>
                  <div className="flex flex-row">
                    <span className="text-xs font-medium">No</span>
                    <label
                      htmlFor={row.id}
                      className="relative mx-1 mb-5 inline-flex cursor-pointer items-center"
                    >
                      <input
                        id={row.id}
                        type="checkbox"
                        className="peer sr-only"
                        onChange={() =>
                          handleUpdateEventVisibility(row.id, !row.hidden, row)
                        }
                        checked={!!row.hidden}
                      />
                      <div className="h-4 w-10 rounded-full bg-gray-200 after:absolute after:left-[1px]  after:top-[-2px]  after:h-5 after:w-5 after:rounded-full after:border after:border-gray-300 after:bg-[#171721] after:transition-all after:content-[''] peer-checked:after:translate-x-full peer-checked:after:border-white peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-green  "></div>
                    </label>
                    <span className="text-xs font-medium">Si</span>
                  </div>
                </td>
                <td>
                  <DropdownDots options={getDropdownDotsOptions(row)} />
                </td>
              </motion.tr>
            ))}
          </tbody>
        </table>
      )}
      {auth.role !== "admin" && !loading && (
        <div className="flex h-full w-full flex-col gap-5 ">
          {data.rows.length ? (
            data.rows.map((row, i) => (
              <div
                onClick={() =>
                  clickDelete
                    ? (clickDelete = false)
                    : handleEventDetail(row.id)
                }
                key={i}
              >
                <BannerEvent
                  data={row}
                  handleDelete={
                    row?.orders?.length === 0 ? handleDelete : undefined
                  }
                  showTeamMembers={false}
                />
              </div>
            ))
          ) : (
            <></>
          )}
        </div>
      )}
      <div className="flex justify-between py-10">
        <FilterPage
          handleFilter={handleFilter}
          total={data.total}
          limit={filters.limit}
          status={filters.status}
          currentPage={filters.page}
        />
        <FilterQtyPage handleFilter={handleFilter} status={filters.status} />
      </div>
    </PageContainer>
  );
};

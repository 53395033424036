import { ColumnDef } from "@tanstack/react-table";

import { Skeleton } from "../ui/skeleton";
import DataTableHeader from "./DataTableHeader";

interface SkeletonTableProps<TData, TValue> {
  columns: ColumnDef<TData, TValue>[];
}

function SkeletonTable<TData, TValue>({
  columns,
}: SkeletonTableProps<TData, TValue>) {
  return (
    <div className="mt-8">
      <DataTableHeader columns={columns} />
      <Skeleton className="mt-3 h-12 rounded-xl text-start font-medium outline outline-[1px] outline-colorBorder" />
      <Skeleton className="mt-3 h-12 rounded-xl text-start font-medium outline outline-[1px] outline-colorBorder" />
      <Skeleton className="mt-3 h-12 rounded-xl text-start font-medium outline outline-[1px] outline-colorBorder" />
      <Skeleton className="mt-3 h-12 rounded-xl text-start font-medium outline outline-[1px] outline-colorBorder" />
      <Skeleton className="mt-3 h-12 rounded-xl text-start font-medium outline outline-[1px] outline-colorBorder" />
      <Skeleton className="mt-3 h-12 rounded-xl text-start font-medium outline outline-[1px] outline-colorBorder" />
      <Skeleton className="mt-3 h-12 rounded-xl text-start font-medium outline outline-[1px] outline-colorBorder" />
    </div>
  );
}

export default SkeletonTable;

import React, { useState, useRef, FC } from "react";
import { BiDotsHorizontalRounded } from "react-icons/bi";
import useComponentVisible from "../../../hooks/use-component-visible.hook";
import { Props } from "@headlessui/react/dist/types";
import Restricted from "../../../context/Restricted";

type DropdownDotsItem = {
  title?: string;
  description?: string;
  href?: string;
  action?: (event: React.MouseEvent<HTMLElement>) => void;
  icon?: any;
  restricted?: string;
};
type DropdownDotsProps = {
  options: Array<DropdownDotsItem>;
  children?: React.ReactNode;
};

export default function DropdownDots({
  options = [],
  children,
}: DropdownDotsProps) {
  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible(false);

  return (
    <div className="inline-flex " ref={ref}>
      <div className="relative">
        <button
          className="flex text-gray-100"
          onClick={() => setIsComponentVisible(!isComponentVisible)}
        >
          {children ? children : <BiDotsHorizontalRounded size={23} />}
        </button>

        {isComponentVisible ? (
          <div
            className={`border-gray-50 absolute right-0 z-10 mt-1 w-[207px] origin-top-right rounded-md border bg-white shadow-lg`}
          >
            <div className="p-2">
              {options.map((item, index) =>
                item.title ? (
                  <Restricted to={item.restricted || "any"} key={index}>
                    <button
                      onClick={item.action}
                      className="hover:text-black-700 block w-full rounded-lg px-4 py-2 text-sm text-black-100 hover:bg-gray-100 hover:bg-opacity-20"
                    >
                      <div
                        className="flex items-center"
                        onClick={() => setIsComponentVisible(false)}
                      >
                        {item.icon}
                        <div>
                          <p className="w-max">
                            {item.title && <strong> {item.title}</strong>}
                          </p>
                          <p className="text-left text-[10px] font-normal">
                            {item.description}
                          </p>
                        </div>
                      </div>
                    </button>
                  </Restricted>
                ) : (
                  <div></div>
                )
              )}
            </div>
          </div>
        ) : (
          <div></div>
        )}
      </div>
    </div>
  );
}

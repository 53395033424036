import React, { useEffect, useState } from "react";
import { useContext } from "react";
import { GrClose } from "react-icons/gr";
import { StateContext } from "../../../../context/StateProvider";
import { IArtistResponse } from "../../../../types/IArtist";
import { Footer } from "../../../footer/Footer";
import { InputSearch } from "../../../rrpp/components/InputSearch/InputSearch";

import { Artists } from "./Artists";
import { ITeamResponse } from "../../../../types/ITeam";

export const PopUpValidators = ({}) => {
  const { state, dispatch } = useContext(StateContext);
  const [selectedValidatorIds, setSelectedValidatorIds] = useState<
    Array<string>
  >([]);

  const addValidatorEvent = () => {
    dispatch({ type: "addValidatorEvent", payload: null });
  };

  const showValidators = () => {
    dispatch({ type: "showValidators", payload: null });
  };

  const setSelectedValidators = () => {
    const selectedValidators: any[] = [];
    state.validators.forEach((validator) => {
      if (selectedValidatorIds.includes(validator.id)) {
        selectedValidators.push(validator);
      }
    });
    dispatch({ type: "selectedValidators", payload: selectedValidators });
    console.info("selectedValidators", selectedValidators);
  };

  const handleOnChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;

    if (selectedValidatorIds?.includes(value)) {
      const index = selectedValidatorIds?.indexOf(value);
      selectedValidatorIds?.splice(index, 1);
      setSelectedValidatorIds([...selectedValidatorIds]);
    } else {
      setSelectedValidatorIds([...selectedValidatorIds, ...[value]]);
    }
  };

  const isSelected = (value: string) => {
    return selectedValidatorIds?.includes(value);
  };

  useEffect(() => {
    setSelectedValidatorIds(
      state.selectedValidators.map((item) => {
        return item.id;
      })
    );
  }, []);

  const [query, setQuery] = useState("");
  const [validators, setValidators] = useState<Array<ITeamResponse>>(
    state.validators
  );

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(e.target.value);
    if (state.validators) {
      const results = state.validators.filter((validator) => {
        if (e.target.value === "") return state;
        if (validator && validator.user[0]) {
          const fullname =
            validator.user[0].first_name + " " + validator.user[0].last_name;
          return fullname.toLowerCase().includes(e.target.value.toLowerCase());
        }
      });
      setValidators(results);
    }
  };

  const handleChangeSelectAll = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked)
      setSelectedValidatorIds(validators.map((validator) => validator.id));
    else setSelectedValidatorIds([]);
  };

  return (
    <div
      className="fixed  z-50 flex h-screen w-screen items-center justify-center bg-black-100/30 backdrop-blur-md"
      style={{ minHeight: "calc(100vh)" }}
    >
      <div
        className="relative mx-[10%] w-full rounded-2xl bg-white "
        style={{ height: "calc(100vh - 15%)" }}
      >
        <div className="h-full">
          <div className="flex h-[72px] items-center justify-between px-8  pb-4 pt-5 text-center">
            <div />
            <h3 className=" text-lg font-bold">
              Asignación de validadores para el evento
            </h3>
            <button className="" onClick={addValidatorEvent}>
              <GrClose />
            </button>
          </div>
          <div className="mx-[10%]" style={{ height: "calc(100% - 174px)" }}>
            <div className=" border-b border-colorBorder"> </div>
            <div className="h-[182px] pt-12">
              <InputSearch
                value={query}
                onChange={() => {}}
                handleChange={handleChange}
                content="Buscar un validador"
              />
              <label htmlFor={"all"} className="w-[20rem]">
                <div className="flex w-full items-center gap-5 rounded-2xl py-5 pl-4 font-medium ">
                  <input
                    type="checkbox"
                    name="team_ids[]"
                    id={"all"}
                    onChange={handleChangeSelectAll}
                    className="ml-auto rounded-full p-2 checked:bg-black-100 focus:ring-transparent active:bg-black-100"
                  />
                  Seleccionar todos
                </div>
              </label>
              <p className="pb-4 font-bold">Validadores</p>
            </div>
            <div
              className=" flex-col flex-wrap overflow-auto px-1 pt-4"
              style={{ height: "calc(100% - 182px)" }}
            >
              <form
                className="flex flex-col items-center justify-center gap-5"
                onSubmit={(e) => {
                  e.preventDefault();
                }}
              >
                <div className="flex flex-wrap justify-between gap-3 ">
                  {validators.map((item, index) => (
                    <Artists
                      key={index}
                      name={`${item.user[0]?.first_name} ${item.user[0]?.last_name}`}
                      value={item.id}
                      handleOnChange={handleOnChange}
                      isSelected={isSelected(item.id)}
                    />
                  ))}
                </div>
                {state.validators.length == 0 && (
                  <p className="text-center">
                    No existen validadores disponibles
                  </p>
                )}
              </form>
            </div>
          </div>
          <div className="border-b- flex w-full justify-between border-colorBorder px-8  pb-4 pt-5 text-center ">
            <button
              onClick={() => {
                if (!state.showValidator) showValidators();
                addValidatorEvent();
                setSelectedValidators();
              }}
              type="submit"
              className="m-auto w-72 rounded-full border-[2px] border-black-100 bg-black-100 px-12 py-2 font-medium text-white"
            >
              Guardar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

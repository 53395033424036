import React, { useEffect, useState } from "react";
import { useContext } from "react";
import { GrClose } from "react-icons/gr";
import { StateContext } from "../../../context/StateProvider";
import { Footer } from "../../footer/Footer";
import { InputSearch } from "../../rrpp/components/InputSearch/InputSearch";
import { useQuery } from "@tanstack/react-query";
import NewsService from "../../../services/newsService";
import { Events } from "../event/Events";
import { Oval } from "react-loader-spinner";
import { INewResponse } from "../../../types/INews";

export const PopUpNews = ({}) => {
  const { state, dispatch } = useContext(StateContext);
  const [selectedNewsIds, setSelectedNewsIds] = useState<Array<string>>([]);
  const [query, setQuery] = useState("");
  const [newsArray, setNews] = useState<Array<INewResponse>>([]);

  const news = useQuery({
    queryKey: ["news"],
    queryFn: () =>
      NewsService.getAll({
        status: "all",
        sortBy: "createdAt",
        sortOrder: "desc",
        query,
      }),
  });

  useEffect(() => {
    if (news.isSuccess && query === "") {
      const n = news.data?.rows;
      console.log(n);
      setNews(n);
    }
  }, [news]);

  const addEventSection = () => {
    dispatch({ type: "addNewsSection", payload: null });
  };

  const showEvents = () => {
    dispatch({ type: "showEvents", payload: null });
  };

  const setSelectedNews = () => {
    const selectedNews: any[] = [];
    newsArray.forEach((n) => {
      if (selectedNewsIds.includes(n.id)) {
        selectedNews.push(n);
      }
    });
    dispatch({ type: "selectedNews", payload: selectedNews });
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchQuery = e.target.value.toLowerCase();
    setQuery(searchQuery);

    const results = newsArray.filter((n) => {
      if (searchQuery === "") {
        return true; // Include all items when the search input is empty.
      }
      if (n.title) {
        return n.title.toLowerCase().includes(searchQuery);
      }
      return false; // Handle the case where `n.title` doesn't exist.
    });
    setNews(results);
  };

  const handleOnChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    console.log(value);
    // const index = eventIds.indexOf(value);
    // if (index > -1) {
    //   setEventIds([...eventIds.slice(0, index), ...eventIds.slice(index + 1)]);
    // } else {
    //   setEventIds([...eventIds, ...[value]]);
    // }

    if (selectedNewsIds.includes(value)) {
      const index = selectedNewsIds.indexOf(value);
      selectedNewsIds.splice(index, 1);
      setSelectedNewsIds([...selectedNewsIds]);
    } else {
      setSelectedNewsIds([...selectedNewsIds, ...[value]]);
    }
  };

  const isSelected = (value: string) => {
    return selectedNewsIds.includes(value);
  };

  useEffect(() => {
    setSelectedNewsIds(
      state.selectedNews.map((item) => {
        return item.id;
      })
    );
  }, []);

  return (
    <div className="fixed  z-50 flex h-screen w-screen items-center justify-center bg-black-100/30 backdrop-blur-md">
      <div className="absolute top-20 m-auto flex flex-col justify-center rounded-2xl bg-white ">
        <div className="flex w-full justify-between border-b border-colorBorder px-8  pb-4 pt-5 text-center">
          <div />
          <h3 className=" text-lg font-bold">
            Asignación de novedad para seccion
          </h3>
          <button className="" onClick={addEventSection}>
            <GrClose />
          </button>
        </div>
        <div className=" h-[40rem] w-[47rem] flex-col flex-wrap  overflow-auto  px-10 pt-4">
          <InputSearch
            value={query}
            onChange={() => {}}
            handleChange={handleChange}
            content="Buscar una novedad"
          />

          <p className="pb-4 pt-2 font-bold">Novedades</p>
          <form
            className="flex flex-col items-center justify-center gap-5"
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <div className="flex flex-wrap justify-between">
              {news.isLoading ? (
                <Oval
                  height={64}
                  width={64}
                  color="#000000"
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible={true}
                  ariaLabel="oval-loading"
                  secondaryColor="#E4E6E8"
                  strokeWidth={4}
                  strokeWidthSecondary={4}
                />
              ) : (
                newsArray.map((item, index) => (
                  <Events
                    key={index}
                    name={item.title}
                    value={item.id}
                    handleOnChange={handleOnChange}
                    isSelected={isSelected(item.id)}
                  />
                ))
              )}
            </div>
            {news.isSuccess && newsArray.length == 0 && (
              <p className="text-center">No existen novedades disponibles</p>
            )}
            <div className=" pt-5">
              <button
                onClick={() => {
                  /*  if (!state.showEvent) showEvents();*/
                  addEventSection();
                  setSelectedNews();
                }}
                type="submit"
                className="m-auto mb-5 w-72 rounded-full border-[2px] border-black-100 bg-black-100 px-12 py-2 font-medium text-white"
              >
                Guardar
              </button>
            </div>
          </form>
        </div>
      </div>
      <div className=" flex h-full w-full items-end ">
        <Footer />
      </div>
    </div>
  );
};

import { Skeleton } from "../ui/skeleton";

const OffersDetailsSkeleton = () => {
  return (
    <div className="mt-8">
      <Skeleton className="mt-3 h-24 rounded-xl text-start font-medium outline outline-[1px] outline-colorBorder" />
      <Skeleton className="mt-3 h-24 rounded-xl text-start font-medium outline outline-[1px] outline-colorBorder" />
      <Skeleton className="mt-3 h-24 rounded-xl text-start font-medium outline outline-[1px] outline-colorBorder" />
    </div>
  );
};

export default OffersDetailsSkeleton;

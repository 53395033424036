import { useContext, useEffect, useState } from "react";
import { StateContext } from "../../../../context/StateProvider";
import { GrClose } from "react-icons/gr";
import { PreloadedFile } from "./PreloadedFile";
import InvitationsService, {
  inviteViaCSV,
} from "../../../../services/tickets/invitations.service";
import { ItemErrorCSV } from "./ItemErrorCSV";
import { Title } from "../../Title";
import { CSVResponse } from "./CSVResponse";
import LoaderCircle from "../../../FormElement/LoaderCircle";
import { error } from "console";

export const PopUpInviteCSV = () => {
  const { state, dispatch } = useContext(StateContext);
  const [file, setFile] = useState<Blob>();
  const [fileName, setFileName] = useState("");
  const [errors, setErrors] = useState<CSVResponse>();
  const [isLoading, setLoading] = useState(false);
  const [isInputEnabled, setInputEnabled] = useState(true);

  const hideImportCsv = () => {
    if (errors) {
      if (
        window.confirm(
          `¿Está seguro de cerrar? Se perderá el tracking de los errores`
        )
      ) {
        dispatch({ type: "inviteViaCSV", payload: null });
      }
    } else {
      dispatch({ type: "inviteViaCSV", payload: null });
    }
  };

  useEffect(() => {
    document.getElementById("upload_files")?.click();
  }, []);

  const onChangeFiles = (e: any) => {
    e.preventDefault();
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }
    setFile(files[0]);
    setFileName(files[0].name);
  };

  const onSubmit = async (e: any) => {
    let ticketName = state.params.ticket_name;
    setInputEnabled(false);
    if (file) {
      if (
        window.confirm(`¿Está seguro de enviar invitaciones de ${ticketName}?`)
      ) {
        setLoading(true);
        await InvitationsService.inviteViaCSV(
          file as Blob,
          state.params.ticket_id
        )
          .then((response) => {
            setErrors(response.data);
            setLoading(false);
            dispatch({
              type: "toastSuccess",
              payload: "Invitaciones enviadas",
            });
          })
          .catch((error) => {
            setLoading(false);
            dispatch({ type: "toastError", payload: "Error en el envío" });
            hideImportCsv();
          });
      }
    }
  };

  return (
    <div
      className="fixed  z-50 flex h-screen w-screen items-center justify-center bg-black-100/30 backdrop-blur-md"
      style={{ minHeight: "calc(100vh)" }}
    >
      <div
        className="absolute top-20 m-auto flex flex-col justify-center rounded-2xl bg-white "
        style={{ height: "calc(100vh - 160px)" }}
      >
        <div className="flex w-full justify-between border-b border-colorBorder px-8  pb-4 pt-5 text-center">
          <h3 className=" text-lg font-bold">Invitaciones por CSV</h3>
          <button className="" onClick={hideImportCsv}>
            <GrClose />
          </button>
        </div>

        <div className="flex-grow">
          {file ? (
            <PreloadedFile file_name={fileName} size_file={file.size / 1000} />
          ) : null}
          {isLoading ? (
            <div className="flex items-center justify-center pt-8">
              <LoaderCircle />
            </div>
          ) : (
            <div>
              {errors ? (
                <h3 className="pb-4 pt-4 text-center text-lg font-bold">
                  Errores
                </h3>
              ) : null}
              {errors?.invalidEmails && errors!.invalidEmails.length > 0 ? (
                <ItemErrorCSV
                  item={JSON.stringify(
                    errors.invalidEmails.map((e) => e.email)
                  )}
                  error={"Email(s) incorrecto(s)"}
                />
              ) : null}
              {errors?.invalidNames && errors!.invalidNames.length > 0 ? (
                <ItemErrorCSV
                  item={JSON.stringify(
                    errors?.invalidNames.map((e) => e.email)
                  )}
                  error={"Nombre(s) incorrecto(s)"}
                />
              ) : null}
              {errors?.invalidQtys && errors!.invalidQtys.length > 0 ? (
                <ItemErrorCSV
                  item={JSON.stringify(errors.invalidQtys.map((e) => e.email))}
                  error={"Cantidad(es) incorrecta(s)"}
                />
              ) : null}
            </div>
          )}
        </div>

        <div className="flex w-full justify-between border-colorBorder px-8  pb-4 pt-5 text-center ">
          <button
            className="m-auto flex items-center gap-2 rounded-full border-[2px] border-black-100 px-12 py-2 font-bold text-black-100 hover:bg-black-100 hover:fill-white hover:text-white"
            onClick={onSubmit}
            disabled={!isInputEnabled}
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M2.25 17C2.25 19.6234 4.37665 21.75 7 21.75L17 21.75C19.6234 21.75 21.75 19.6234 21.75 17L21.75 13C21.75 10.3766 19.6234 8.25 17 8.25L16.5 8.25C16.0858 8.25 15.75 8.58579 15.75 9C15.75 9.41421 16.0858 9.75 16.5 9.75L17 9.75C18.7949 9.75 20.25 11.2051 20.25 13L20.25 17C20.25 18.7949 18.7949 20.25 17 20.25L7 20.25C5.20507 20.25 3.75 18.7949 3.75 17L3.75 13C3.75 11.2051 5.20507 9.75 7 9.75L7.5 9.75C7.91421 9.75 8.25 9.41421 8.25 9C8.25 8.58579 7.91421 8.25 7.5 8.25L7 8.25C4.37665 8.25 2.25 10.3766 2.25 13L2.25 17ZM8.46967 5.46967C8.17678 5.76256 8.17678 6.23744 8.46967 6.53033C8.76256 6.82322 9.23744 6.82322 9.53033 6.53033L11.25 4.81066L11.25 15C11.25 15.4142 11.5858 15.75 12 15.75C12.4142 15.75 12.75 15.4142 12.75 15L12.75 4.81066L14.4697 6.53033C14.7626 6.82322 15.2374 6.82322 15.5303 6.53033C15.8232 6.23744 15.8232 5.76256 15.5303 5.46967L12.5303 2.46967C12.2374 2.17678 11.7626 2.17678 11.4697 2.46967L8.46967 5.46967Z"
                fill="black"
              />
            </svg>
            Importar CSV
          </button>
        </div>

        <input
          type="file"
          accept=".csv"
          className="hidden"
          id="upload_files"
          name="files"
          onChange={(e) => onChangeFiles(e)}
          multiple
        />
      </div>
    </div>
  );
};

import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { RowSelectionState } from "@tanstack/react-table";
import { useDebounce } from "use-debounce";

import { PageContainer } from "../layout/pageContainer";
import { TitleSection } from "../layout/titleSection";
import { DataTable } from "../layout/table/dataTable";
import { SkeletonTable } from "../layout/table/skeletonTable";
import { FilterSort } from "../layout/table/filterSort";
import { FilterPage } from "../layout/table/filterPage";
import { FilterLimit } from "../layout/table/filterLimit";
import { FilterDate } from "../layout/table/filterDate";
import { FilterStatus } from "../layout/table/filterStatus";
import { FilterQuery } from "../layout/table/filterQuery";
import { getEventsColumns } from "./table/columns";
import { IFilterRequest } from "../../types_new/IFilter";
import { getUrlParams } from "../../utils_new/getUrlParams";
import EventsService from "../../services_new/eventService";
import { sortOptions, statusOptions } from "./constants/options";
import config from "../../config/variables";

export const EventsPage = () => {
  const {
    limit = config.filter_default_limit,
    page = 1,
    sortBy = "createdAt",
    sortOrder = "desc",
    query,
    date_from,
    date_to,
    status = statusOptions[0].value,
  } = getUrlParams();

  const defaultFilters: IFilterRequest = {
    limit: Number(limit),
    page: Number(page),
    sortBy,
    sortOrder,
    date_from,
    date_to,
    status,
  };
  const [filters, setFilters] = useState<IFilterRequest>(defaultFilters);

  const [search, setSearch] = useState<string>(query);
  const [debouncedSearch] = useDebounce(search, 500);

  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});

  const {
    data: eventsData,
    isFetching,
    refetch,
  } = useQuery({
    queryKey: ["events", filters, debouncedSearch],
    queryFn: ({ signal }) =>
      EventsService.getAll({ ...filters, query: debouncedSearch, signal }),
  });

  return (
    <PageContainer>
      <div className="flex items-center justify-between">
        <TitleSection>Eventos</TitleSection>
        <div className="flex flex-wrap justify-end gap-2">
          <FilterDate filters={filters} setFilters={setFilters} />
          <FilterQuery
            search={search}
            setSearch={setSearch}
            filters={filters}
            setFilters={setFilters}
          />
        </div>
      </div>

      <div className="my-5 flex flex flex-col border-b border-gray-200 xl:flex-row-reverse xl:justify-between">
        <FilterSort
          sortOptions={sortOptions}
          filters={filters}
          setFilters={setFilters}
        />
        <FilterStatus
          statusOptions={statusOptions}
          filters={filters}
          setFilters={setFilters}
        />
      </div>

      {isFetching ? (
        <SkeletonTable columns={getEventsColumns({ refetch })} />
      ) : (
        <DataTable
          columns={getEventsColumns({ refetch })}
          data={eventsData?.rows || []}
          rowSelection={rowSelection}
          setRowSelection={setRowSelection}
        />
      )}

      <div className="flex justify-between pt-10">
        <FilterPage
          filters={filters}
          setFilters={setFilters}
          totalPages={eventsData?.pages || 1}
        />
        <FilterLimit filters={filters} setFilters={setFilters} />
      </div>
    </PageContainer>
  );
};

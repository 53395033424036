import api from "./api";
import authHeader from "./authHeader";
import { IEventResponse, IEventsResponse } from "../types_new/IEvent";
import { IFilterRequest } from "../types_new/IFilter";

const getAll = async (params: IFilterRequest) => {
  const response = await api.get<IEventsResponse>(`events/list`, {
    headers: authHeader(),
    params: params,
    signal: params.signal,
  });
  return response.data;
};

const updateStatus = async (event_id: string, status: string) => {
  const response = await api.put<IEventResponse>(
    `events/update_status/${event_id}`,
    { status },
    {
      headers: authHeader(),
    }
  );
  return response.data;
};

const sendRecycler = async (id: string) => {
  const response = await api.post<IEventResponse>(
    `events/send_recycler/${id}`,
    {},
    {
      headers: authHeader(),
    }
  );
  return response.data;
};

const update = async (id: string, item: FormData) => {
  const response = await api.put<IEventResponse>(`events/update/${id}`, item, {
    headers: {
      "Content-Type": "multipart/form-data",
      ...authHeader(),
    },
  });
  return response.data;
};

const updateVisibility = async (event_id: string, hidden: boolean) => {
  const response = await api.put<IEventResponse>(
    `events/update_visibility/${event_id}`,
    { hidden },
    {
      headers: authHeader(),
    }
  );
  return response.data;
};

const EventService = {
  getAll,
  updateStatus,
  sendRecycler,
  update,
  updateVisibility,
};

export default EventService;

import { IOption, IActionOption } from "../../../types_new/IOption";
import { IOfferResponse } from "../../../types_new/IOffer";
import { OfferStatus } from "../../../types_new/IOffer";
import OfferService from "../../../services_new/offerService";

export const statusOptions: IOption[] = [
  {
    value: "all",
    text: "Todas las ofertas",
  },
  {
    value: OfferStatus.ACTIVE,
    text: "Activas",
  },
  {
    value: OfferStatus.PAUSED,
    text: "Pausadas",
  },
  {
    value: OfferStatus.SOLD,
    text: "Vendidas",
  },
  {
    value: OfferStatus.DELETED,
    text: "Eliminadas",
  },
];

export const actionOptions: IActionOption<IOfferResponse>[] = [
  {
    value: "active",
    text: "Cambiar a Activo",
    callback: (id) => OfferService.update(id, { status: OfferStatus.ACTIVE }),
    payload: OfferStatus.ACTIVE,
  },
  {
    value: "paused",
    text: "Cambiar a Pausado",
    callback: (id) => OfferService.update(id, { status: OfferStatus.PAUSED }),
    payload: OfferStatus.PAUSED,
  },
  {
    value: "sold",
    text: "Cambiar a Vendido",
    callback: (id) => OfferService.update(id, { status: OfferStatus.SOLD }),
    payload: OfferStatus.SOLD,
  },
];

export const sortOptions: IOption[] = [
  {
    value: "createdAt",
    text: "Fecha de publicación",
  },
  {
    value: "event_name",
    text: "Evento",
  },
  {
    value: "status",
    text: "Estado publicación",
  },
  {
    value: "trader_name",
    text: "Trader",
  },
  {
    value: "producer_name",
    text: "Productora",
  },
  {
    value: "total",
    text: "Precio",
  },
];

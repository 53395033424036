import React, { useEffect, useState } from "react";

import { AuthContext } from "./AuthContext";
import AuthService from "../services/authService";
import { IAuthRequest, IAuthResponse } from "../types/IAuth";
import { IProducerResponse } from "../types/IProducer";
import { IPermissions } from "../types/IPermissions";
import config from "../config/variables";
import Cookies from "js-cookie";

interface Props {
  children: React.ReactNode;
}

const permissionsValues = {
  admin: false,
  events: false,
  reports: false,
  admin_access: "",
};

const userDefaultValues = {
  id: "",
  first_name: "",
  last_name: "",
  email: "",
  user_role: "",
  access_token: "",
  profile_picture: "",
  producer_id: "",
  permissions: permissionsValues,
};

const producerValues = {
  id: "",
  name: "",
  client: [],
  events_valids_count: 0,
  events_free_count: 0,
  tickets_sold: 0,
  tickets_reserved: 0,
  active_events: 0,
  events: [],
  createdAt: "",
  updatedAt: "",
};

const AuthContextProvider: React.FC<Props> = ({ children }) => {
  const [token, setToken] = useState(
    localStorage.getItem(config.session.tokenName)
      ? localStorage.getItem(config.session.tokenName)
      : ""
  );

  const [clientProducerId, setClientProducerId] = useState(
    localStorage.getItem(config.session.clientProducerID)
      ? localStorage.getItem(config.session.clientProducerID)
      : ""
  );

  const [user, setUser] = useState<IAuthResponse>(userDefaultValues);
  const [role, setRole] = useState<string>(
    localStorage.getItem("role") ? String(localStorage.getItem("role")) : ""
  );

  const [permissions, setPermissions] = useState<IPermissions>(
    localStorage.getItem("permissions")
      ? JSON.parse(localStorage.getItem("permissions") || "{}")
      : permissionsValues
  );

  const [menu, setMenu] = useState<string>(
    localStorage.getItem("menu") ? String(localStorage.getItem("menu")) : ""
  );

  const [producer, setProducer] = useState<IProducerResponse>(
    localStorage.getItem("producer")
      ? JSON.parse(localStorage.getItem("producer") || "{}")
      : producerValues
  );

  useEffect(() => {
    if (token) {
      localStorage.setItem(config.session.tokenName, token);
      getUser();
    } else {
      localStorage.removeItem(config.session.tokenName);
    }
  }, [token]);

  const getUser = async () => {
    await AuthService.getUser()
      .then((response) => {
        if (
          response.data.user_role == "coproducer" &&
          response.data.first_time_login == false
        ) {
          setUser({ ...response.data, id: response.data.producer_id });
        } else setUser(response.data);
      })
      .catch((e) => {
        if (
          e.response.status == 401 &&
          e.response.statusText == "Unauthorized"
        ) {
          console.info("JWT inválido. Desautenticando...");
          logout();
        }
      });
  };

  const login = async (data: IAuthRequest) => {
    const response = await AuthService.login(data);
    if (response.data) {
      if (response.data.user_role == "coproducer") {
        setUser({ ...response.data, id: response.data.producer_id });
      } else {
        setUser(response.data);
      }

      const token = response?.data?.access_token;
      Cookies.set("access_token", token ?? "", {
        domain: ".wearebombo.com",
        secure: true,
        httpOnly: false,
        expires: 7,
      });

      setToken(response.data.access_token);
      setRole(response.data.user_role);
      localStorage.setItem("role", response.data.user_role);

      if (response.data.user_role == "admin") {
        changeAdmin();
        changePermissions(response.data);
      } else {
        changeClient();
        changeClientProducerId(response.data);
        changePermissions(response.data);
      }
    }
    return response.data;
  };

  const googleLogin = async (google_token: string, coordinates: any) => {
    const response = await AuthService.googleLogin(google_token, coordinates);
    if (response.data) {
      setUser(response.data);

      const token = response?.data?.access_token;
      Cookies.set("access_token", token ?? "", {
        domain: ".wearebombo.com",
        secure: true,
        httpOnly: false,
        expires: 7,
      });

      setToken(response.data.access_token);
      setRole(response.data.user_role);
      localStorage.setItem("role", response.data.user_role);

      if (response.data.user_role == "admin") {
        changeAdmin();
        changePermissions(response.data);
      }
    }
    return response.data;
  };

  const logout = () => {
    localStorage.clear();
    setUser(userDefaultValues);
    setToken("");
    setRole("");
    setMenu("");
    setClientProducerId("");
    setPermissions(permissionsValues);
    setProducer(producerValues);
  };

  // Test cerrar sesiones en todas las pestañas
  window.addEventListener("storage", (e) => {
    if (e.key === config.session.tokenName) {
      if (e.oldValue && !e.newValue) {
        localStorage.setItem("role", "");
        setRole("");
        localStorage.clear();
      }
      // if (e.oldValue && e.newValue && e.oldValue != e.newValue) {
      //   window.location.reload()
      // }
    }
  });
  //////////////////////////////////////////////

  if (token) {
    localStorage.setItem(config.session.tokenName, token);
  }

  const changeAdmin = () => {
    setMenu("admin");
    localStorage.setItem("menu", "admin");
  };

  const changeClient = () => {
    setMenu("client");
    localStorage.setItem("menu", "client");
    localStorage.setItem("producer", JSON.stringify(producerValues));
  };

  const changeProducer = (producer: IProducerResponse) => {
    setProducer(producer);
    setMenu("submenu");
    localStorage.setItem("menu", "submenu");
    localStorage.setItem("producer", JSON.stringify(producer));
  };

  const propsMethods = {
    login,
    googleLogin,
    logout,
    changeClient,
    changeProducer,
    getUser,
  };

  const changeClientProducerId = (user: IAuthResponse) => {
    if (user.user_role == "coproducer") {
      setClientProducerId(user.producer_id);
      if (user.producer_id)
        localStorage.setItem(config.session.clientProducerID, user.producer_id);
    } else {
      setClientProducerId(user.id);
      localStorage.setItem(config.session.clientProducerID, user.id);
    }
  };

  const changePermissions = (user: IAuthResponse) => {
    if (user.permissions) {
      setPermissions(user.permissions);
      localStorage.setItem("permissions", JSON.stringify(user.permissions));
    }
  };

  return (
    <AuthContext.Provider
      value={{
        ...propsMethods,
        user,
        role,
        token,
        menu,
        producer,
        clientProducerId,
        permissions,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;

import React, { useState } from "react";
import { IRow, status } from "../../../../../interfaces/Table";
import { Status } from "../../../../Status";
import { getTeamRoleFromValue } from "../../../../../utils/roles";
import { IRrppResponse } from "../../../../../types/IRrpp";
import { PageContainer } from "../../../../PageContainer";
import { formatDate } from "../../../../../utils/format-date";
import { useParams } from "react-router-dom";

type selectedProps = {
  selected: IRrppResponse;
  handleSetSection: (section: string) => void;
  handleSetRole: (role: string) => void;
};

const styles = {
  titleLi: "font-bold",
  pLi: "font-medium",
  wrapperLi: "flex flex-col justify-between ",
};

export const BannerSeller = ({
  selected,
  handleSetSection,
  handleSetRole,
}: selectedProps) => {
  const { seller_id } = useParams();
  const [role, setRole] = useState<string>(selected.user_role);
  const handleSaveRole = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (role == "leader") {
      handleSetRole("seller");
      setRole("seller");
    } else {
      handleSetRole("leader");
      setRole("leader");
    }
  };
  return (
    <>
      <div className="m-2 flex h-52 w-full justify-between rounded-xl px-10 py-10 outline outline-2 outline-colorBorder">
        <div className="flex gap-5">
          <div className="flex h-24 w-24 overflow-hidden rounded-full bg-gray-200">
            {/* <img src={selected.imgProducer} width="100%" height="100%" /> */}
          </div>
          <div className="flex flex-col">
            <h1 className="text-2xl font-bold">{`${selected.first_name || ""} ${
              selected.last_name || ""
            }`}</h1>
            <p className="font-medium text-gray-100">
              Id de user: {selected?.id}
            </p>
          </div>
        </div>

        <ul className="flex gap-32">
          <div className={`${styles.wrapperLi}`}>
            <li>
              <h3 className={`${styles.titleLi}`}>Fecha de alta</h3>
              <p className={`${styles.pLi}`}>
                {formatDate(selected.createdAt)}
              </p>
            </li>
            <li>
              <h3 className={`${styles.titleLi}`}>Team Leader</h3>
              <div className="flex flex-row">
                <span className="text-xs font-medium">No</span>
                <label className="relative mx-1 mb-5 inline-flex cursor-pointer items-center">
                  <input
                    type="checkbox"
                    value=""
                    className="peer sr-only"
                    onChange={handleSaveRole}
                    checked={role == "leader"}
                  />
                  <div className="h-4 w-10 rounded-full bg-gray-200 after:absolute after:left-[1px]  after:top-[-2px]  after:h-5 after:w-5 after:rounded-full after:border after:border-gray-300 after:bg-[#171721] after:transition-all after:content-[''] peer-checked:after:translate-x-full peer-checked:after:border-white peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-green  "></div>
                </label>
                <span className="text-xs font-medium">Si</span>
              </div>
            </li>
          </div>
          <div className={`${styles.wrapperLi}`}>
            <li>
              <h3 className={`${styles.titleLi}`}>Email</h3>
              <p className={`${styles.pLi}`}>{selected.email || ""}</p>
            </li>
            {selected.user_role != "leader" && !seller_id && (
              <li>
                <h3 className={`${styles.titleLi}`}>Asignar team leader</h3>
                {!selected.team_leader && (
                  <button
                    onClick={() => handleSetSection("select_leader")}
                    className="rounded-full border-[2px] border-black-100 px-12 py-2 font-medium text-black-100"
                  >
                    + Agregar
                  </button>
                )}
                {selected.team_leader && (
                  <div className="flex items-center justify-between">
                    <p className="mr-2">
                      {Array.isArray(selected.team_leader)
                        ? `${selected.team_leader[0].first_name} ${
                            selected.team_leader[0].last_name
                          }${selected.team_leader.length > 1 ? ", ..." : ""}`
                        : `${selected.team_leader.first_name} ${selected.team_leader.last_name}`}
                    </p>
                    <button
                      onClick={() => handleSetSection("select_leader")}
                      className="rounded-full border-[2px] border-black-100 px-12 py-2 font-medium text-black-100"
                    >
                      Editar
                    </button>
                  </div>
                )}
              </li>
            )}
            {!!seller_id &&
              selected.user_role != "leader" &&
              !!selected.team_leader && (
                <li>
                  <h3 className={`${styles.titleLi}`}>Team Leader Asignado</h3>
                  {selected.team_leader && (
                    <div className="flex items-center justify-between">
                      <p className="mr-2">
                        {Array.isArray(selected.team_leader)
                          ? `${selected.team_leader[0].first_name} ${
                              selected.team_leader[0].last_name
                            }${selected.team_leader.length > 1 ? ", ..." : ""}`
                          : `${selected.team_leader.first_name} ${selected.team_leader.last_name}`}
                      </p>
                    </div>
                  )}
                </li>
              )}
          </div>
          <div className={`${styles.wrapperLi}`}>
            <li>
              <h3 className={`${styles.titleLi}`}>Tipo</h3>
              <p className={`${styles.pLi}`}>
                {getTeamRoleFromValue(selected.user_role || "")}
              </p>
            </li>
          </div>
        </ul>
        <div>
          <Status
            status={
              selected && selected.status && selected.status == "active"
                ? "activo"
                : "pendiente"
            }
          />
        </div>
      </div>
    </>
  );
};

// import { Status } from "../../../../Status";
//
// export const BannerSeller = () => {
//
//     const styles = {
//         titleLi: "font-bold pb-1 text-xs leading-[21px] ",
//         pLi: "font-medium text-xs leading-[18px] ",
//         wrapperLi: "flex justify-between gap-28 ",
//     };
//
//     return (
//         <div className="my-6">
//             <div className="flex h-[12rem] w-full gap-64 overflow-auto rounded-xl px-5 py-5 outline outline-2 outline-colorBorder">
//                 <div className="flex w-80 xl:gap-5 ">
//                     <div className=" flex self-start overflow-hidden rounded-full bg-[#D6D6D6] p-10"></div>
//                     <div className="flex flex-col ">
//                     <h1 className="text-2xl font-bold">Marcos Foglia</h1>
//                     <p className="font-500 text-[#171721] text-[12px] leading-[18px] ">marcos.foglia@gmail.com</p>
//                     </div>
//                 </div>
//
//                 <ul className="flex    ">
//                     <div className={`${styles.wrapperLi} pb-3`}>
//                         <li>
//                             <h3 className={`${styles.titleLi}`}>Team leader</h3>
//                             <p className={`${styles.pLi}`}>19 Jun 2021 - 12:43:22</p>
//
//                             <div className="pt-5">
//                                 <h3 className={`${styles.titleLi}`}>Team leader</h3>
//                                 <div className="flex flex-row">
//                                     <span className="text-xs font-medium">No</span>
//                                     <label className="inline-flex relative items-center mb-5 cursor-pointer mx-1">
//                                         <input type="checkbox" value="" className="sr-only peer"/>
//                                         <div className="w-10 h-4 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-green  rounded-full  peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[-2px] after:left-[1px] after:bg-[#171721] after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all  "></div>
//
//                                     </label>
//                                     <span className="text-xs font-medium">Si</span>
//                                 </div>
//                             </div>
//                         </li>
//                         <li>
//                             <h3 className={`${styles.titleLi}`}>Email</h3>
//                             <p className={`${styles.pLi}`}>laura.machado@gmail.com</p>
//                         </li>
//                         <li>
//                             <h3 className={`${styles.titleLi}`}>Tipo</h3>
//                             <p className={`${styles.pLi}`}>Vendedor</p>
//                         </li>
//
//                     </div>
//                 </ul>
//                 <div className=" items-center">
//                         <Status status={"activo"} />
//                 </div>
//             </div>
//         </div>
//     );
// }

import { useEffect, useState, useContext } from "react";
import { useLocation, Outlet } from "react-router-dom";

import { Aside } from "../components/aside/Aside";
import { Footer } from "../components/footer/Footer";
import { Navbar } from "../components/navbar/Navbar";
import { StateContext } from "../context/StateProvider";
import { PopUpTeam } from "../components/events/NewEvent/team/PopUpTeam";
import { PopUpReview } from "../components/reviews/popUpReview/PopUpReview";
import { PopUpEvent } from "../components/sections/event/PopUpEvent";
import { PopUpArtist } from "../components/events/NewEvent/artist/PopUpArtist";
import { PopUpTeamLeader } from "../components/events/NewEvent/team/PopUpTeamLeader";
import LoaderScreen from "../components/FormElement/LoaderScreen";
import { ModalCropper } from "../components/FormElement/FormCropperImage/ModalCropper";
import { PopUpMessage } from "../components/events/NewEvent/message/PopUpMessage";
import { AlertToastify } from "../components/AlertToastify";
import { PopUpEditCard } from "../components/producersCard/card/PopUpEditCard";
import { PopUpValidators } from "../components/events/NewEvent/artist/PopUpValidators";
import { PopUpProducers } from "../components/usersCard/producers/PopUpProducers";
import { PopUpRefund } from "../components/events/NewEvent/refund/PopUpRefund";
import { PopUpImportCsv } from "../components/rrpp/importCsv/PopUpImportCsv";
import { PopUpSendTicket } from "../components/users/selected/tickets/PopUpSendTicket";
import { PopUpHistory } from "../components/events/selectedEvent/reports/PopUpHistory";
import { PopUpInviteCSV } from "../components/events/selectedEvent/csv/PopupInviteCSV";
import { PopUpNews } from "../components/sections/news/PopUpNews";

export const Layout = ({ children }: any) => {
  const location = useLocation();
  const path = location.pathname;

  const [title, setTitle] = useState("");

  useEffect(() => {
    switch (path) {
      case "/producers":
        return setTitle("Productoras");
      case "/users":
        return setTitle("Usuarios");
      case "/artists":
        return setTitle("Artistas");
      case "/rrpp":
        return setTitle("RRPP");
      case "/reports":
        return setTitle("Reportes");
      case "/news":
        return setTitle("Novedades");
      case "/access-control":
        return setTitle("Control de acceso");
      case "/help":
        return setTitle("Ayuda");
      default:
        return setTitle("");
    }
  }, [location]);

  const { state } = useContext(StateContext);

  return (
    <main>
      <div
        className="overflow-hidden"
        style={
          state.showAddTeam ||
          state.showReview ||
          state.showAddEvent ||
          state.showAddArtist ||
          state.showAddValidator ||
          state.showAssignLeader ||
          state.showMessage ||
          state.showRequestRefund ||
          state.showEditCard ||
          state.showAddProducer ||
          state.showCropper ||
          state.showLoaderScreen ||
          state.showImportCsv ||
          state.showInviteViaCSV ||
          state.showAddUser
            ? { height: "calc(100vh)" }
            : { minHeight: "calc(100vh - 129.39px)" }
        }
      >
        <AlertToastify />
        {state.showAddTeam && <PopUpTeam />}
        {state.showReview && <PopUpReview />}
        {state.showAddEvent && <PopUpEvent />}
        {state.showAddNews && <PopUpNews />}
        {state.showAddArtist && <PopUpArtist />}
        {state.showAddValidator && <PopUpValidators />}
        {state.showAssignLeader && <PopUpTeamLeader />}
        {state.showMessage && <PopUpMessage />}
        {state.showRequestRefund && <PopUpRefund />}
        {state.showEditCard && <PopUpEditCard />}
        {state.showAddProducer && <PopUpProducers />}
        {state.showCropper && <ModalCropper />}
        {state.showLoaderScreen && <LoaderScreen />}
        {state.showImportCsv && <PopUpImportCsv />}
        {state.showAddUser && <PopUpSendTicket />}
        {state.showHistory && <PopUpHistory />}
        {state.showInviteViaCSV && <PopUpInviteCSV />}
        <Navbar />
        <div className="flex" style={{ minHeight: "calc(100vh - 129.39px)" }}>
          <Aside />
          <div
            className="w-full overflow-hidden border-l-[1px] border-colorBorder px-1 pt-10 xl:px-12"
            style={{ minWidth: "calc(100% - 288px)" }}
          >
            <Outlet />
          </div>
        </div>
        <Footer />
      </div>
    </main>
  );
};

import { Link } from "react-router-dom";
import { ColumnDef } from "@tanstack/react-table";

import { formatDate } from "../../../utils_new/formatDate";
import { formatMoney } from "../../../utils_new/formatMoney";
import {
  getBgColorFromValue,
  getPaymentStatusFromValue,
} from "../../../utils_new/status";
import { IOrderResponse } from "../../../types_new/IOrder";

export const getOrdersColumns = (): ColumnDef<IOrderResponse>[] => {
  const getOrderUrl = (id: string) => `/admin/order/${id}`;

  return [
    {
      accessorKey: "id",
      header: "Orden",
      cell: ({ row }) => {
        const order_id = row.original.id;
        return <Link to={getOrderUrl(order_id)}>{`#${order_id}`}</Link>;
      },
    },
    {
      accessorKey: "updatedAt",
      header: "Fecha",
      cell: ({ row }) => {
        const order_id = row.original.id;
        return (
          <Link to={getOrderUrl(order_id)}>
            {formatDate(row.original.updatedAt)}
          </Link>
        );
      },
    },
    {
      accessorKey: "event_name",
      header: "Evento",
      cell: ({ row }) => {
        const order_id = row.original.id;
        return (
          <Link to={getOrderUrl(order_id)}>
            {row.original?.event?.name || "-"}
          </Link>
        );
      },
    },
    {
      accessorKey: "seller",
      header: "Vendedor",
      cell: ({ row }) => {
        const seller: any = row.getValue("seller");
        const order_id = row.original.id;
        return (
          <Link to={getOrderUrl(order_id)}>
            {seller?.first_name
              ? `${seller?.first_name} ${seller?.last_name}`
              : "-"}
          </Link>
        );
      },
    },
    {
      accessorKey: "email",
      header: "Email",
      cell: ({ row }) => {
        const order_id = row.original.id;
        return (
          <Link to={getOrderUrl(order_id)}>{row.original.user.email}</Link>
        );
      },
    },
    {
      accessorKey: "order_tickets",
      header: "Cant",
      cell: ({ row }) => {
        const orderTickets: Array<any> = row.getValue("order_tickets");
        const order_id = row.original.id;
        return (
          <Link to={getOrderUrl(order_id)}>
            {orderTickets.reduce(
              (total, currentValue) => total + currentValue.qty,
              0
            )}
          </Link>
        );
      },
    },
    {
      accessorKey: "total",
      header: "Precio",
      cell: ({ row }) => {
        const order_id = row.original.id;
        return (
          <Link to={getOrderUrl(order_id)}>
            {formatMoney(row.original.total)}
          </Link>
        );
      },
    },
    {
      accessorKey: "order_tickets",
      header: "Tipo",
      cell: ({ row }) => {
        const orderTickets: Array<any> = row.getValue("order_tickets");
        const order_id = row.original.id;

        return (
          <Link to={getOrderUrl(order_id)}>
            {Array.from(
              new Set(
                orderTickets.flatMap((e) => e.ticket.map((e: any) => e.type))
              )
            ).join("/")}
          </Link>
        );
      },
    },
    {
      accessorKey: "status",
      header: "Status Pago",
      cell: ({ row }) => {
        const order_id = row.original.id;

        return (
          <Link
            to={getOrderUrl(order_id)}
            className="flex items-center justify-center gap-3 rounded-full border-[1px] border-colorBorder px-5  py-2 text-center "
          >
            <div
              className={`rounded-full ${getBgColorFromValue(
                row.getValue("status")
              )} p-[.35rem]`}
            />
            <p className="capitalize">
              {getPaymentStatusFromValue(row.getValue("status"))}
            </p>
          </Link>
        );
      },
    },
  ];
};

import React, { useContext, useEffect, useState } from "react";
import { GoGraph } from "react-icons/go";
import { motion } from "framer-motion";

import { TitleSection } from "../TitleSection";
import { FilterPage } from "../table/FilterPage";
import { FilterQtyPage } from "../table/FilterQtyPage";
import { FilterSection } from "../table/FilterSection";
import { formatDate } from "../../utils/format-date";
import { useMutation } from "@tanstack/react-query";
import ClientService from "../../services/clientService";
import { IClientResponse, IClientsResponse } from "../../types/IClient";
import { IFilterRequest } from "../../types/IFilter";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import { FaEdit, FaTrashAlt } from "react-icons/fa";
import DropdownDots from "../FormElement/DropdownDots";
import { getStatusFromValue } from "../../utils/status";
import { FilterOrder } from "../table/FilterOrder";
import ISortOption from "../../types/ISortOption";
import { PageContainer } from "../PageContainer";
import { StateContext } from "../../context/StateProvider";
import config from "../../config/variables";
import TableCellLink from "../FormElement/TableCellLink";

type TableProducersProps = {
  handleCreateClient: () => void;
  handleSelect?: (data?: IClientResponse) => void;
};

type rowProps = {
  rowData: {
    date: string;
    name: string;
    email: string;
    status: string;
  };
  qty?: number;
};

const defaultResult = {
  total: 0,
  pages: 0,
  rows: [],
};
const defaultFilters = {
  limit: config.filter_default_limit,
  page: 1,
  status: "all",
  user_role: "producer",
  sortBy: "createdAt",
  sortOrder: "desc",
  query: "",
};

export const TableProducers = ({
  handleCreateClient,
  handleSelect = () => {},
}: TableProducersProps) => {
  const { role } = useContext(AuthContext);
  const navigate = useNavigate();
  const getItems = useMutation(() => ClientService.getAll(filters));
  const { dispatch } = useContext(StateContext);

  const getCsv = useMutation(() => ClientService.getCsv({}));
  const deleteItems = useMutation((id: string) =>
    ClientService.deleteAccount(id)
  );
  let paramsFilters: any = { status: "", sortBy: "" };
  const [result, setResult] = useState<IClientsResponse>(defaultResult);
  const [filters, setFilters] = useState<IFilterRequest>(defaultFilters);
  const [search, setSearch] = useState("");

  let resultado = [];
  resultado = result.rows;

  useEffect(() => {
    if (search != "") {
      const delayDebounceFn = setTimeout(() => {
        loadData();
      }, 600);
      return () => clearTimeout(delayDebounceFn);
    } else {
      loadData();
    }
  }, [search]);

  const loadData = () => {
    dispatch({ type: "showLoaderScreen", payload: true });
    getItems.mutateAsync().then((res) => {
      dispatch({ type: "showLoaderScreen", payload: false });
      setResult(res);
    });
  };

  const searcher = async (e: any) => {
    setSearch(e.target.value);
    paramsFilters = { ...filters, query: e.target.value };
    await setFilters({ ...filters, page: 1, query: e.target.value });
  };

  const handleFilter = async (filter: IFilterRequest) => {
    await setFilters({ ...filters, ...filter });
    await getItems.mutateAsync().then((res) => setResult(res));
  };

  const handleFilterSection = async (filter?: string) => {
    if (!filter) return;
    paramsFilters = { ...filters, status: filter };
    await setFilters({ ...filters, page: 1, status: filter });
    await getItems.mutateAsync().then((res) => setResult(res));
  };

  const handleSortSection = async (sortBy: string) => {
    const itemSort = sortOptions.find((s) => s.value == sortBy);
    paramsFilters = { ...filters, page: 1, sortBy: sortBy };
    if (itemSort) paramsFilters["sortOrder"] = itemSort.sortOrder;
    await setFilters(paramsFilters);
    await getItems.mutateAsync().then((res) => setResult(res));
  };

  const handleEdit = (id: string) => {
    navigate(`/admin/client/${id}/edit`);
  };

  const handleDeleteAccount = async (id: string) => {
    if (window.confirm("Está seguro de eliminar este cliente?")) {
      await deleteItems.mutateAsync(id).then((res) => {
        getItems.mutateAsync().then((res) => setResult(res));
      });
    }
  };

  const columnData = [
    "Fecha de alta",
    "Nombre",
    "Nickname",
    "Productoras",
    "Email",
    "Status",
    "Acciones",
  ];

  const rowData = {
    date: "19 Jun 2021 - 12:43:22",
    name: "Martins SRL",
    clients: 6,
    email: "administracion@gmail.com",
    status: "Activo",
  };

  const filterSectionProps = [
    { title: "Todos los clientes", id: "all" },
    { title: "Activos", id: "active" },
    { title: "Pausados", id: "paused" },
  ];

  const sortOptions: Array<ISortOption> = [
    { value: "createdAt", text: "Fecha", sortOrder: "desc" },
    { value: "first_name", text: "Nombre", sortOrder: "asc" },
  ];

  const handleCsv = async () => {
    await getCsv.mutateAsync().then((response) => {
      const url = window.URL.createObjectURL(new Blob([response]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "producers.csv");
      document.body.appendChild(link);
      link.click();
    });
  };

  const getFilterSection = () => {
    return filterSectionProps;
  };

  const getProducerDetailLink = (producerDetailID: string) => {
    return "/admin/producers/" + producerDetailID;
  };

  const handleProducerDetail = (id: string) => {
    navigate("/admin/producers/" + id);
  };

  const [stateOrder, setState] = useState("asc");
  const handleChangeState = async (state: string) => {
    setState(state);
    await setFilters({ ...filters, sortOrder: state });
    loadData();
  };

  return (
    <PageContainer>
      <div className="flex justify-between">
        <TitleSection>Clientes</TitleSection>
        <button
          onClick={() => navigate("/admin/client/add")}
          className="rounded-full border-[2px] border-black-100 px-12 py-2 font-bold text-black-100 hover:bg-black-100 hover:text-white"
        >
          Crear nuevo cliente
        </button>
      </div>

      <div className="mb-7 flex justify-between border-b-[1px] border-colorBorder pr-5 pt-10">
        <FilterSection
          filterSectionProps={getFilterSection()}
          handleFilter={handleFilterSection}
        />
        <div className="flex items-center gap-5 ">
          <button
            className="flex items-center justify-center text-[#3E7EFF]"
            onClick={handleCsv}
          >
            <GoGraph className="mr-1" />
            Exportar a CSV
          </button>
          {/* <GoSearch className="cursor-pointer text-gray-100" /> */}
          <input
            type="text"
            placeholder="Search..."
            className="hover:text-black rounded-full border-[2px] border-black-100 px-4 py-1 font-bold  text-black-100"
            value={search}
            onChange={searcher}
          />
          <FilterOrder
            setSort={handleSortSection}
            options={sortOptions}
            handleChangeState={handleChangeState}
            state={stateOrder}
          />
        </div>
      </div>

      <table className="my-table-spacing h-full w-full border-separate border-spacing-y-2 gap-5 overflow-x-auto pt-5 text-left">
        <thead className=" divide-solid border ">
          <tr className=" pb-4 text-sm font-bold text-gray-100">
            {columnData.map((column, index) => (
              <th
                className="border-b-[1px] border-colorBorder pb-4"
                key={column + index}
              >
                {column}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {resultado.map((row, i) => (
            <motion.tr
              key={i}
              layout
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              initial={{ opacity: 0.2 }}
              transition={{ type: "spring", stiffness: 100, bounce: 0 }}
              className={`h-16  rounded-xl text-start font-medium outline outline-[1px] outline-colorBorder`}
            >
              <TableCellLink
                href={getProducerDetailLink(row.id)}
                className="pl-4"
              >
                {formatDate(row.created_at)}
              </TableCellLink>
              <TableCellLink
                href={getProducerDetailLink(row.id)}
                className="pl-4"
              >{`${row.first_name} ${row.last_name}`}</TableCellLink>
              <TableCellLink
                href={getProducerDetailLink(row.id)}
                className="pl-4"
              >{`${row.nickname ? row.nickname : ""}`}</TableCellLink>
              <td className="flex h-full cursor-pointer items-center gap-2 pl-4">
                <div className="flex items-center">
                  <p className="mr-[10px]">{row.producers.length}</p>
                  {/* <Dropdown options={row.producers} /> */}
                </div>
              </td>
              <TableCellLink
                href={getProducerDetailLink(row.id)}
                className="pl-4"
              >
                {row.email}
              </TableCellLink>
              <td className="pl-4">
                <div className="flex w-32 items-center justify-center gap-3 rounded-full border-[1px] border-colorBorder  py-2 text-center ">
                  <div
                    className={`rounded-full  ${
                      row.status === "active" ? "bg-green" : "bg-red"
                    } p-[.35rem]`}
                  />
                  <p className="capitalize">
                    {getStatusFromValue(row.status || "pending")}
                  </p>
                </div>
              </td>
              <td className="pl-4">
                {role == "admin" && (
                  <DropdownDots
                    options={[
                      {
                        title: "Editar",
                        action: () => handleEdit(row.id),
                        icon: <FaEdit size={23} />,
                      },
                      {
                        title: "Eliminar",
                        action: () => handleDeleteAccount(row.id),
                        icon: <FaTrashAlt size={23} />,
                      },
                    ]}
                  />
                )}
              </td>
            </motion.tr>
          ))}
        </tbody>
      </table>
      <div className="flex justify-between pt-10">
        <FilterPage
          handleFilter={handleFilter}
          total={result.total}
          limit={filters.limit}
          status={filters.status}
          currentPage={filters.page}
        />
        <FilterQtyPage handleFilter={handleFilter} status={filters.status} />
      </div>
    </PageContainer>
  );
};

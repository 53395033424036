import { Dispatch, SetStateAction, useState } from "react";
import { MinusIcon, PlusIcon } from "@radix-ui/react-icons";

interface GroupFieldsProps {
  groupFields: number;
  setGroupFields: Dispatch<SetStateAction<number>>;
  maxGroupFields: number;
  buttonText: string;
}

export const GroupFieldsButtons = ({
  groupFields,
  setGroupFields,
  maxGroupFields,
  buttonText,
}: GroupFieldsProps) => {
  const [groupFieldsToAdd, setGroupFieldsToAdd] = useState<number>(1);

  const handleAddGroupFields = () => {
    if (groupFields == maxGroupFields) return;
    setGroupFields((prevGroupFields) => prevGroupFields + groupFieldsToAdd);
    setGroupFieldsToAdd(1);
  };

  const handleRemoveGroupField = () =>
    setGroupFields((prevGroupFields) => prevGroupFields - 1);

  const handleDecreaseGroupFields = () =>
    setGroupFieldsToAdd((groupFieldsToAdd) => groupFieldsToAdd - 1);

  const handleIncreaseGroupFields = () =>
    setGroupFieldsToAdd((groupFieldsToAdd) => groupFieldsToAdd + 1);

  const removeButton = (
    <button
      onClick={handleRemoveGroupField}
      type="button"
      className="ml-[auto] w-[250px] rounded-full border-[2px] border-black-100 px-12 py-2 font-medium text-black-100"
    >
      - Quitar {buttonText}
    </button>
  );

  if (groupFields === maxGroupFields) {
    return removeButton;
  }

  return (
    <div className="grid grid-cols-[25px_250px_25px_1fr] items-center gap-2">
      {groupFieldsToAdd > 1 ? (
        <button
          type="button"
          onClick={handleDecreaseGroupFields}
          className="rounded-full border-[1px] border-black-300 p-1"
        >
          <MinusIcon />
        </button>
      ) : (
        <div />
      )}
      <button
        onClick={handleAddGroupFields}
        type="button"
        className="rounded-full border-[2px] border-black-100 px-12 py-2 font-medium text-black-100 "
      >
        + Agregar {groupFieldsToAdd} {buttonText}
      </button>
      {groupFieldsToAdd < maxGroupFields - groupFields ? (
        <button
          type="button"
          onClick={handleIncreaseGroupFields}
          className="rounded-full border-[1px] border-black-300 p-1"
        >
          <PlusIcon />
        </button>
      ) : (
        <div />
      )}
      {groupFields > 1 && removeButton}
    </div>
  );
};

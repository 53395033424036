import React, { FC, Fragment, useState } from "react";
import { Controller, useFormState, useFormContext } from "react-hook-form";
import icon from "../../../assets/imgIcon.svg";
import { FormInputCropperProps } from "../../../types/FormInputCropperProps";
import CropUploadAvatar from "../../CropUploadAvatar";
import CropUpload from "../../CropUpload";
import { FaTrashAlt } from "react-icons/fa";
import { validateDimensionsFile } from "../../../utils/form";

const FormCropperImage: FC<FormInputCropperProps> = ({
  name,
  label,
  accept,
  initialAspectRatio,
  cropBoxResizable = true,
  dragMode,
  rounded = false,
  maxWidth,
  maxHeight,
}) => {
  const formState = useFormState();
  const formContext = useFormContext();
  const [showCroppie, setShowCroppie] = useState(false);
  const [selectedPicture, setSelectedPicture] = useState<any>(null);
  const onChangeCropper = async (e: any) => {
    e.preventDefault();
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }

    if (files) {
      let file;
      if (maxWidth && maxHeight) {
        const validate = await validateDimensionsFile(
          files[0],
          maxWidth,
          maxHeight
        );
        if (validate) {
          if (["image/gif"].includes(files[0].type)) {
            file = files[0];
            formContext.setValue(name, file);
          } else {
            file = URL.createObjectURL(files[0]);
            setShowCroppie(true);
          }
          setSelectedPicture(file);
        } else {
          formContext.setError(name, {
            type: "custom",
            message: `Tamaño permitido ${maxWidth} x ${maxHeight}`,
          });
        }
      } else {
        file = URL.createObjectURL(files[0]);
        setSelectedPicture(file);
        setShowCroppie(true);
      }
    }
  };

  const handleCloseCrop = () => {
    setSelectedPicture(null);
    setShowCroppie(false);
  };

  const handleSuccess = async (file: File) => {
    setSelectedPicture(file);
    formContext.setValue(name, file);
    setShowCroppie(false);
  };

  const getPreviewValueOrUrl = (value: any): string => {
    if (value instanceof Blob) {
      return URL.createObjectURL(value);
    }
    if (value) {
      return value;
    }
    return value;
  };

  const onLoad = (onChange: any) => {
    if (selectedPicture) {
      onChange(selectedPicture);
    }
  };

  const handleDeleteImage = (onChange: any) => {
    onChange("");
    setSelectedPicture(null);
  };

  return (
    <Controller
      name={name}
      render={({ field: { value, name, onBlur, ref, onChange } }) => (
        <Fragment>
          {showCroppie ? (
            rounded ? (
              <CropUploadAvatar
                image={selectedPicture}
                initialAspectRatio={initialAspectRatio}
                cropBoxResizable={cropBoxResizable}
                dragMode={dragMode}
                closeModal={handleCloseCrop}
                onSuccess={handleSuccess}
                maxWidth={maxWidth}
                maxHeight={maxHeight}
              />
            ) : (
              <CropUpload
                image={selectedPicture}
                initialAspectRatio={initialAspectRatio}
                cropBoxResizable={cropBoxResizable}
                dragMode={dragMode}
                closeModal={handleCloseCrop}
                onSuccess={handleSuccess}
                maxWidth={maxWidth}
                maxHeight={maxHeight}
              />
            )
          ) : (
            <div className="relative flex w-full flex-col justify-center">
              <h3 className="pb-3 font-medium text-gray-100">{label}</h3>
              <label htmlFor={`upload_${name}`} className="relative">
                <div className="flex h-56 cursor-pointer items-center justify-center border border-dashed border-[#E4E6E8] bg-[#F5F5FA]">
                  <img
                    src={getPreviewValueOrUrl(value) || icon}
                    width={value ? "auto" : "10%"}
                    onLoad={() => onLoad(onChange)}
                    style={{ maxWidth: "100%", maxHeight: "100%" }}
                  />
                </div>
                <input
                  type="file"
                  className={"hidden"}
                  id={`upload_${name}`}
                  name={name}
                  onBlur={onBlur}
                  ref={ref}
                  onChange={(e) => onChangeCropper(e)}
                  accept={accept ? accept : ""}
                />
              </label>
              {formState.errors[name] && formState.errors[name]?.message && (
                <p className="text-red-600 dark:text-red-500 mt-2 text-sm text-red">
                  {String(
                    formState.errors[name]
                      ? formState.errors[name]?.message
                      : ""
                  )}
                </p>
              )}
              {!!value && (
                <div
                  className="absolute right-4 top-12 cursor-pointer"
                  onClick={(e) => handleDeleteImage(onChange)}
                >
                  <FaTrashAlt size={23} />
                </div>
              )}
            </div>
          )}
        </Fragment>
      )}
    />
  );
};

export default FormCropperImage;

import { ComponentProps } from "react";
import FormCheck from "../../../../FormElement/FormCheck";

const Check = ({ name, options }: ComponentProps<typeof FormCheck>) => {
  return (
    <span className="[&_h2]:pb-0 [&_label]:w-full">
      <FormCheck name={name} options={options} />
    </span>
  );
};

export default Check;

import { useEffect, FC, useContext } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { FormProvider, useForm } from "react-hook-form";
import { useMutation } from "@tanstack/react-query";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import jsonToFormData from "@ajoelp/json-to-formdata";

import { AuthContext } from "../../context/AuthContext";
import { StateContext } from "../../context/StateProvider";
import ClientService from "../../services/clientService";
import { IClientRequest } from "../../types/IClient";
import FormInput from "../FormElement/FormInput";
import FormUploadImage from "../FormElement/FormUploadImage";
import FormSelect from "../FormElement/FormSelect";
import { Back } from "../Back";

const schema = yup.object().shape({
  first_name: yup.string().required("El nombre es requerido"),
  last_name: yup.string(),
  email: yup
    .string()
    .required("El email es requerido")
    .email("El correo electrónico no es válido"),
  password: yup.string().required("El password es requerido"),
  profile_picture: yup
    .mixed()
    .test("size", "Tamaño permitido menor a 20MB", (value) => {
      if (value instanceof File) {
        return value.size <= 20000000;
      }
      return true;
    }),
});

const schemaEdit = yup.object().shape({
  first_name: yup.string().required("El nombre es requerido"),
  last_name: yup.string(),
  email: yup
    .string()
    .required("El email es requerido")
    .email("El correo electrónico no es válido"),
  password: yup.string(),
  profile_picture: yup
    .mixed()
    .test("size", "Tamaño permitido menor a 20MB", (value) => {
      if (value instanceof File) {
        return value.size <= 20000000;
      }
      return true;
    }),
});

const defaultValues = {
  first_name: "",
  last_name: "",
  nickname: "",
  email: "",
  password: "",
  picture: "",
  user_role: "producer",
  profile_picture: "",
  status: "active",
  mp_token: "",
};

const statusOptions: Array<object> = [
  { value: "pending", text: "Pendiente" },
  { value: "active", text: "Activo" },
  { value: "paused", text: "Pausado" },
];

type typeProps = {
  handleCreateorEdit?: (id: string) => void;
  id?: string;
};

export const FormClient: FC<typeProps> = ({ handleCreateorEdit, id }) => {
  const { role } = useContext(AuthContext);
  const navigate = useNavigate();
  const { client_id } = useParams();
  const location = useLocation();

  const createItems = useMutation((item: FormData) =>
    ClientService.create(item)
  );
  const updateItems = useMutation((item: FormData) =>
    ClientService.update(client_id, item)
  );
  const getUserApi = useMutation((id: string) => ClientService.get(id));

  const form = useForm<IClientRequest>({
    resolver: yupResolver(client_id ? schemaEdit : schema),
    defaultValues: defaultValues,
  });

  useEffect(() => {
    if (client_id) {
      getUserApi.mutateAsync(client_id).then((res) => form.reset(res));
    }
  }, [form.reset]);

  const { state, dispatch } = useContext(StateContext);

  const onSubmit = async (data: IClientRequest) => {
    let formData = jsonToFormData(data, {
      arrayIndexes: true,
      excludeNull: true,
    });

    dispatch({ type: "showLoaderScreen", payload: true });

    if (client_id) {
      await updateItems
        .mutateAsync(formData)
        .then((res) => {
          dispatch({ type: "showLoaderScreen", payload: false });
          dispatch({
            type: "toastSuccess",
            payload: "Registro del Cliente actualizado",
          });
        })
        .catch((reason) => {
          dispatch({ type: "showLoaderScreen", payload: false });
          dispatch({
            type: "toastError",
            payload: "Cuenta de Correo duplicada",
          });
        });
    } else {
      await createItems
        .mutateAsync(formData)
        .then((res) => {
          dispatch({ type: "showLoaderScreen", payload: false });
          dispatch({
            type: "toastSuccess",
            payload: "El registro del Cliente se creo correctamente",
          });
        })
        .catch((reason) => {
          dispatch({ type: "showLoaderScreen", payload: false });
          dispatch({
            type: "toastError",
            payload: "Cuenta de Correo duplicada",
          });
        });
    }

    navigate(role == "admin" ? "/admin/producers" : "/client/list");
  };

  const onError = (error: any) => {
    console.log("error ", error);
  };

  return (
    <div className="">
      <Back
        onClick={() =>
          navigate(role == "admin" ? "/admin/producers" : "/client/list")
        }
      />

      <div className="flex flex-col gap-10 xl:px-[23rem]">
        <h1 className="text-center text-3xl font-bold">
          {client_id ? "Editar" : "Crear nuevo"} Cliente
        </h1>
        <FormProvider {...form}>
          <form onSubmit={form.handleSubmit(onSubmit, onError)}>
            <FormInput name="first_name" type="text" label="Nombre" />
            <FormInput name="email" type="text" label="Correo" />
            <FormInput name="password" type="password" label="Contraseña" />
            <FormInput
              name="mp_token"
              type="password"
              label="Mercado Pago Access Token"
            />
            {location.pathname.includes("edit") ? (
              <FormSelect
                name="status"
                options={statusOptions}
                label="Estado del cliente"
              />
            ) : null}
            <div className="flex justify-center gap-10 ">
              <FormUploadImage name="profile_picture" label="Picture" />
            </div>
            <div className="mb-16 flex justify-center gap-10 pt-10">
              <button
                type="submit"
                className="rounded-full border-[2px] border-black-100 bg-black-100 px-[5.4rem] py-2 font-medium  text-white"
              >
                {client_id ? "Guardar Cliente" : "Publicar Cliente"}
              </button>
            </div>
          </form>
        </FormProvider>
      </div>
    </div>
  );
};

import { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FaEdit, FaTrashAlt } from "react-icons/fa";
import { GoGraph, GoSearch } from "react-icons/go";
import { useMutation } from "@tanstack/react-query";
import { motion } from "framer-motion";

import { TitleSection } from "../TitleSection";
import DropdownDots from "../FormElement/DropdownDots";
import { FilterPage } from "../table/FilterPage";
import { FilterQtyPage } from "../table/FilterQtyPage";
import { ILabelResponse, ILabelsResponse } from "../../types/ILabel";
import { IFilterRequest } from "../../types/IFilter";
import LabelService from "../../services/labelService";
import { formatDate } from "../../utils/format-date";
import { AuthContext } from "../../context/AuthContext";
import { PageContainer } from "../PageContainer";
import config from "../../config/variables";

type TableProducersProps = {
  handleCreateClient: () => void;
  handleSelect: (data?: ILabelResponse) => void;
};

type rowProps = {
  rowData: {
    date: string;
    name: string;
    email: string;
    status: string;
  };
  qty?: number;
};

const defaultResult = {
  total: 0,
  pages: 0,
  rows: [],
};

const defaultFilters = {
  limit: config.filter_default_limit,
  page: 1,
  status: "all",
  query: "",
};

const columnData = ["Orden", "Fecha", "Nombre del label", "Status", "Acciones"];

export const LabelsView = () => {
  const { role } = useContext(AuthContext);
  const navigate = useNavigate();

  const getItems = useMutation(() => LabelService.getAll(filters));
  const deleteItems = useMutation((id: string) => LabelService.remove(id));
  const getCsv = useMutation(() => LabelService.getCsv({}));

  const [result, setResult] = useState<ILabelsResponse>(defaultResult);
  const [filters, setFilters] = useState<IFilterRequest>(defaultFilters);
  const [artistColumns, setArtistColumns] = useState<Array<string>>([]);
  const [search, setSearch] = useState("");

  useEffect(() => {
    handleFilter(filters);
  }, []);

  const searcher = async (e: any) => {
    setSearch(e.target.value);
    await setFilters({ ...filters, page: 1, query: e.target.value });
    await getItems.mutateAsync().then((res) => {
      setResult(res);
    });
  };

  let resultado = [];
  resultado = result.rows;

  const handleFilter = async (filter: IFilterRequest) => {
    await setFilters({ ...filters, ...filter });
    await getItems.mutateAsync().then((res) => setResult(res));
  };

  const handleDelete = async (id: string) => {
    if (window.confirm("Está seguro de eliminar esta notificación?")) {
      await deleteItems.mutateAsync(id).then((res) => {
        getItems.mutateAsync().then((res) => setResult(res));
      });
    }
  };

  const handleCsv = async () => {
    await getCsv.mutateAsync().then((response) => {
      const url = window.URL.createObjectURL(new Blob([response]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "labels.csv");
      document.body.appendChild(link);
      link.click();
    });
  };

  return (
    <PageContainer>
      <div className="flex justify-between">
        <TitleSection>Labels</TitleSection>
        <button
          onClick={() => navigate("/admin/labels/add")}
          className="rounded-full border-[2px] border-black-100 px-12 py-2 font-bold text-black-100 hover:bg-black-100 hover:text-white"
        >
          Crear nuevo label
        </button>
      </div>
      <div className="flex justify-center gap-2 py-2">
        <button
          className="my-2 ml-auto flex items-center justify-center text-[#3E7EFF]"
          onClick={handleCsv}
        >
          <GoGraph className="mr-1" />
          Exportar a CSV
        </button>
        <input
          type="text"
          placeholder="Search..."
          className="hover:text-black rounded-full border-[2px] border-black-100 px-4 py-1 font-bold  text-black-100"
          value={search}
          onChange={searcher}
        />
      </div>
      <table className="my-table-spacing h-full w-full border-separate border-spacing-y-2 gap-5 overflow-x-auto pt-5 text-left">
        <thead className=" divide-solid border ">
          <tr className=" pb-4 text-sm font-bold text-gray-100">
            {columnData.map((column, index) => (
              <th
                className="border-b-[1px] border-colorBorder pb-4"
                key={column + index}
              >
                {column}
              </th>
            ))}
            {artistColumns.map((column, index) => (
              <th
                className="border-b-[1px] border-colorBorder pb-4"
                key={column + index}
              >
                {column}
              </th>
            ))}
            <th className="border-b-[1px] border-colorBorder pb-4"></th>
          </tr>
        </thead>
        <tbody>
          {resultado.map((row, i) => (
            <motion.tr
              key={i}
              layout
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              initial={{ opacity: 0.2 }}
              transition={{ type: "spring", stiffness: 100, bounce: 0 }}
              className={`h-16  rounded-xl text-start font-medium outline outline-[1px] outline-colorBorder`}
            >
              <td className="pl-4">#{row.id}</td>
              <td className="pl-4">{formatDate(row.createdAt)}</td>
              <td className="pl-4">{row.name}</td>
              <td className="pl-4">
                <div className="flex w-32 items-center justify-center gap-3 rounded-full border-[1px] border-colorBorder  py-2 text-center ">
                  <div className={`rounded-full  bg-green p-[.35rem]`} />
                  <p className="capitalize">Activo</p>
                </div>
              </td>
              <td className="pl-4">
                {role == "admin" && (
                  <DropdownDots
                    options={[
                      {
                        title: "Editar",
                        action: () =>
                          navigate("/admin/labels/" + row.id + "/edit"),
                        icon: <FaEdit size={23} />,
                      },
                      {
                        title: "Eliminar",
                        action: () => handleDelete(row.id),
                        icon: <FaTrashAlt size={23} />,
                      },
                    ]}
                  />
                )}
              </td>
            </motion.tr>
          ))}
        </tbody>
      </table>
      <div className="flex justify-between pt-10">
        <FilterPage
          handleFilter={handleFilter}
          total={result.total}
          limit={filters.limit}
          status={filters.status}
          currentPage={filters.page}
        />
        <FilterQtyPage handleFilter={handleFilter} />
      </div>
    </PageContainer>
  );
};

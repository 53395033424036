import { Fragment, useEffect, useRef, useState, KeyboardEvent } from "react";
import { AiOutlineSearch } from "react-icons/ai";

const getOptionClass = (index: number, length: number, cursor: number) => {
  let className = "hover:bg-gray-200 px-4 ";
  if (index === 0) className += "py-2 rounded-t-lg";
  else if (index === -1) className += "py-2 rounded-b-lg";
  else if (index === 0 && length === 1) className += "py-2 rounded-b-lg";
  else className += "py-2 rounded-b-lg";
  if (index === cursor) className += " bg-gray-200";
  return className;
};

type typeProps = {
  value: string;
  onChange: (option: string) => void;
  handleChange: (data: any) => void;
  content: string;
};

export const InputSearch = ({ handleChange, content }: typeProps) => {
  return (
    <Fragment>
      <div className="relative mx-auto w-full">
        <div
          className="flex h-[62px] w-full overflow-hidden border-[1px] border-colorBorder"
          style={{ borderRadius: "8px" }}
        >
          <input
            type="text"
            placeholder={content}
            className="w-[80%] border-none pl-[23px] placeholder-gray-100"
            onChange={handleChange}
          />
          <button
            type="submit"
            className="flex w-[20%] items-center justify-around bg-black-100 font-sans leading-[18px] text-white"
          >
            <p>Buscar</p> <AiOutlineSearch />
          </button>
        </div>
      </div>
    </Fragment>
  );
};

/* eslint-disable react-hooks/exhaustive-deps */
import { Fragment, useEffect, useRef, useState, KeyboardEvent } from "react";
import { AiOutlineSearch } from "react-icons/ai";
import { IRrppResponse } from "../../../../../types/IRrpp";
import notFound from "../../../../../assets/notFound.png";
import { PictureAndName } from "../../../components/PictureAndName/PictureAndName";

const getOptionClass = (index: number, length: number, cursor: number) => {
  let className = "hover:bg-gray-200 px-4 ";
  if (index === 0) className += "py-2 rounded-t-lg";
  else if (index === -1) className += "py-2 rounded-b-lg";
  else if (index === 0 && length === 1) className += "py-2 rounded-b-lg";
  else className += "py-2 rounded-b-lg";
  if (index === cursor) className += " bg-gray-200";
  return className;
};

type typeProps = {
  value: string;
  options: any[];
  onChange: (option: string) => void;
  handleSelect: (data: any) => void;
  showEmail?: boolean;
};

export const SearchAutocomplete = ({
  value,
  options,
  onChange,
  handleSelect,
  showEmail = false,
}: typeProps) => {
  const [showOptions, setShowOptions] = useState(true);
  const [cursor, setCursor] = useState(-1);
  const ref = useRef<HTMLDivElement>(null);
  const [search, setSearch] = useState("");
  const [firstTime, setFirstTime] = useState(true);

  const selectOption = (option: IRrppResponse) => {
    onChange(option.first_name);
    handleSelect(option);
    setShowOptions(false);
  };

  const handleChange = (text: string) => {
    setFirstTime(false);
    setSearch(text);
    onChange(text);
  };

  useEffect(() => {}, [options.length != 0]);

  return (
    <Fragment>
      <div className="relative mx-auto w-full" ref={ref}>
        <div
          className="flex h-[62px] w-full overflow-hidden border-[1px] border-colorBorder"
          style={{ borderRadius: "8px" }}
        >
          <input
            type="text"
            placeholder="Buscar un usuario"
            value={value}
            className="w-[80%] border-none pl-[23px] placeholder-gray-100"
            onChange={(e) => handleChange(e.target.value)}
            onFocus={(e) => setShowOptions(true)}
            onBlur={(e) => !value && setShowOptions(false)}
          />
          <button
            type="button"
            className="flex w-[20%] items-center justify-around bg-black-100 font-sans leading-[18px] text-white"
          >
            <p>Buscar</p> <AiOutlineSearch />
          </button>
        </div>
        {!value && (
          <p className="py-2 text-[12px]">
            Nota:{" "}
            <span className="font-bold">
              Utilice el buscador para encontrar un usuario en la base de Bombo.
            </span>
          </p>
        )}
        {showOptions == true && !firstTime ? (
          <div
            className={`z-10 max-h-[700px] w-[80%] overflow-auto rounded-lg bg-white p-[10px]  drop-shadow-md`}
          >
            <ul className="select-none">
              {options.length > 0 ? (
                options.slice(0, 50).map((option, index) => (
                  <li
                    key={index}
                    className={getOptionClass(index, options.length, cursor)}
                    onClick={(e) => selectOption(option)}
                  >
                    {option.user_role == "artist" && !!option.nickname ? (
                      <PictureAndName
                        profilePicture={option.profile_picture}
                        firstName={option.nickname}
                        lastName={""}
                        email={showEmail ? option.email : null}
                      />
                    ) : (
                      <PictureAndName
                        profilePicture={option.profile_picture}
                        firstName={option.first_name}
                        lastName={option.last_name}
                        email={showEmail ? option.email : null}
                      />
                    )}
                  </li>
                ))
              ) : (
                <li className="px-4 py-2">No hay resultados</li>
              )}
            </ul>
          </div>
        ) : (
          <div></div>
        )}
      </div>
    </Fragment>
  );
};

import { Outlet } from "react-router-dom";

import { Header } from "./header";
import { Footer } from "./footer";
import { AlertToastify } from "./alertToastify";
import { Aside } from "./aside";

export const MainLayout = () => {
  return (
    <main>
      <AlertToastify />
      <div className="overflow-hidden">
        <Header />
        <div className="flex" style={{ minHeight: "calc(100vh - 129.39px)" }}>
          <Aside />
          <div
            className="w-full overflow-hidden border-l-[1px] border-colorBorder px-1 pt-10 xl:px-12"
            style={{ minWidth: "calc(100% - 288px)" }}
          >
            <Outlet />
          </div>
        </div>
        <Footer />
      </div>
    </main>
  );
};

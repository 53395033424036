import React, { Fragment } from "react";

type propsType = {
  profilePicture?: string;
  firstName: string;
  lastName?: string;
  status?: string;
  email?: string;
};
let ImageDefault = "https://files.wearebombo.com/clients/1669041332482.jpeg";

export const PictureAndName = ({
  profilePicture,
  firstName,
  lastName,
  status,
  email,
}: propsType) => {
  return (
    <Fragment>
      <div className="flex items-center gap-[5px]">
        <div
          className="rounded-full"
          style={{
            backgroundImage: `url(${
              profilePicture ? profilePicture : ImageDefault
            })`,
            backgroundSize: "100%",
            backgroundPosition: "center",
            backgroundColor: "darkgray",
            width: 36,
            height: 36,
            marginRight: 7,
          }}
        ></div>
        <strong>
          {firstName} {lastName}
          {status == "deleted" && (
            <small className="font-light"> (eliminado)</small>
          )}
        </strong>
        {email && <p>- {email}</p>}
      </div>
    </Fragment>
  );
};

import { NavLink } from "react-router-dom";

import { IOption } from "../../../types_new/IOption";
import { isActiveLink } from "../../../utils_new/isActiveLink";

type AsideLinkProps = {
  option: IOption;
};

export const AsideLink = ({ option }: AsideLinkProps) => {
  return (
    <NavLink
      to={`/${option.value}`}
      className={({ isActive }) =>
        isActive || isActiveLink(option.value)
          ? "font-black text-black-200"
          : "font-medium text-black-200"
      }
      key={`${option.text}`}
    >
      <li className="mb-2 flex items-center py-[14px] before:absolute before:left-0 before:hidden  before:h-12 before:w-[0.4rem] before:bg-black-100 hover:before:block">
        {option.text}
      </li>
    </NavLink>
  );
};

import {
  useReactTable,
  flexRender,
  ColumnDef,
  getCoreRowModel,
} from "@tanstack/react-table";

import { TableHead, TableHeader, TableRow } from "../../ui/table";

interface DataTableHeaderProps<TData, TValue> {
  columns: ColumnDef<TData, TValue>[];
}

export const DataTableHeader = <TData, TValue>({
  columns,
}: DataTableHeaderProps<TData, TValue>) => {
  const table = useReactTable({
    columns: columns,
    data: [],
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <TableHeader className="divide-solid border">
      {table.getHeaderGroups().map((headerGroup) => (
        <TableRow
          key={headerGroup.id}
          className="pb-4 text-sm font-bold text-gray-100"
        >
          {headerGroup.headers.map((header, i) => {
            return (
              <TableHead
                key={`${header.id}-${i}`}
                className="border-b-[1px] border-colorBorder pb-4"
              >
                {header.isPlaceholder
                  ? null
                  : flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
              </TableHead>
            );
          })}
        </TableRow>
      ))}
    </TableHeader>
  );
};

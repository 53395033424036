import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { useDebounce } from "use-debounce";

import { PageContainer } from "../layout/pageContainer";
import { TitleSection } from "../layout/titleSection";
import { DataTable } from "../layout/table/dataTable";
import { SkeletonTable } from "../layout/table/skeletonTable";
import { FilterPage } from "../layout/table/filterPage";
import { FilterLimit } from "../layout/table/filterLimit";
import { FilterQuery } from "../layout/table/filterQuery";
import { getOrdersColumns } from "./table/columns";
import { IFilterRequest } from "../../types_new/IFilter";
import { getUrlParams } from "../../utils_new/getUrlParams";
import OrderService from "../../services_new/orderService";
import config from "../../config/variables";

export const OrdersPage = () => {
  const {
    limit = config.filter_default_limit,
    page = 1,
    sortBy = "createdAt",
    sortOrder = "desc",
    query,
  } = getUrlParams();

  const defaultFilters: IFilterRequest = {
    limit: Number(limit),
    page: Number(page),
    sortBy,
    sortOrder,
  };
  const [filters, setFilters] = useState<IFilterRequest>(defaultFilters);

  const [search, setSearch] = useState<string>(query);
  const [debouncedSearch] = useDebounce(search, 500);

  const {
    data: ordersData,
    isFetching: isFetchingOrders,
    isError,
  } = useQuery({
    queryKey: ["orders", filters, debouncedSearch],
    queryFn: ({ signal }) =>
      OrderService.getAll({ ...filters, query: debouncedSearch, signal }),
  });

  return (
    <PageContainer>
      <div className="flex items-center justify-between">
        <TitleSection>Órdenes</TitleSection>
        <FilterQuery
          search={search}
          setSearch={setSearch}
          filters={filters}
          setFilters={setFilters}
        />
      </div>

      {isError ? (
        <p className="text-center">No pudimos cargar las órdenes.</p>
      ) : isFetchingOrders ? (
        <SkeletonTable columns={getOrdersColumns()} />
      ) : (
        <DataTable columns={getOrdersColumns()} data={ordersData?.rows || []} />
      )}

      <div className="flex justify-between pt-10">
        <FilterPage
          filters={filters}
          setFilters={setFilters}
          totalPages={ordersData?.pages || 1}
        />
        <FilterLimit filters={filters} setFilters={setFilters} />
      </div>
    </PageContainer>
  );
};

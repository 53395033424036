import React, { FC } from "react";
import { Controller, useFormState } from "react-hook-form";
import { FormInputProps } from "../../../types/FormInputsProps";

const styleInput = {
  input:
    "h-24 w-full rounded-lg border border-colorBorder pt-6 pl-8 font-bold text-black-100 focus:border-none focus:outline-none  focus:ring-colorBorder",
};

const FormInput: FC<FormInputProps> = ({
  name,
  options,
  label,
  placeholder,
  divClass = "relative w-full mb-6",
  multipleOption = false,
}) => {
  const formState = useFormState();

  return (
    <Controller
      name={name}
      render={({ field }) => (
        <div className={divClass}>
          {label && (
            <p className="absolute left-8 top-4 font-semibold text-gray-100">
              {label}
            </p>
          )}
          <select
            name={name}
            id="filterPage"
            className={styleInput.input}
            value={field.value}
            onChange={field.onChange}
            multiple={multipleOption}
          >
            {/*label || placeholder?<option value="">{placeholder || label}</option>:null*/}
            {options?.map((_, i) => (
              <option value={_.value ? _.value : _} key={i}>
                {_.text ? _.text : _}
              </option>
            ))}
          </select>
          {formState.errors[name] && (
            <p className="text-red-600 dark:text-red-500 mt-2 text-sm text-red">
              {String(
                formState.errors[name] ? formState.errors[name]?.message : ""
              )}
            </p>
          )}
        </div>
      )}
    />
  );
};

export default FormInput;

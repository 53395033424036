import React, { useEffect, useState } from "react";
import { useContext } from "react";
import { StateContext } from "../../../../context/StateProvider";
import { GrClose } from "react-icons/gr";
import { Title } from "../../Title";
import LoaderCircle from "../../../FormElement/LoaderCircle";
import { motion } from "framer-motion";
import { getFullNameOrEmail } from "../../../../utils/form";
import { formatDateTime } from "../../../../utils/format-date";
import { FilterPage } from "../../../table/FilterPage";
import { FilterQtyPage } from "../../../table/FilterQtyPage";
import { ITicketsResponse } from "../../../../types/ITicket";
import { IFilterRequest } from "../../../../types/IFilter";
import { ISendTicketsLogs } from "../../../../types/IEvent";
import { useMutation } from "@tanstack/react-query";
import EventService from "../../../../services/eventService";
import TicketService from "../../../../services/ticketService";
import config from "../../../../config/variables";

const defaultFiltersLogs = {
  to: "user",
  limit: config.filter_default_limit,
  page: 1,
  status: "",
  sortBy: "createdAt",
  sortOrder: "desc",
};

export const PopUpHistory = ({}) => {
  const { state, dispatch } = useContext(StateContext);

  const showHistory = () => {
    dispatch({ type: "showHistory", payload: null });
  };
  const [filtersLogs, setFiltersLogs] = useState<IFilterRequest>({
    ...defaultFiltersLogs,
    event_id: state.eventIDHistory,
    to: state.typeHistory,
  });
  const [searchLogs, setSearchLogs] = useState("");
  const [loadingLogs, setLoadingLogs] = useState(false);
  const [tickets, setTickets] = useState<ITicketsResponse>({
    rows: [],
    total: 0,
    pages: 0,
  });
  const [logs, setLogs] = useState<ISendTicketsLogs>({
    rows: [],
    total: 0,
    pages: 0,
  });

  const getTickets = useMutation(() =>
    TicketService.getAll({ event_id: state.eventIDHistory, type: "Free" })
  );
  const getLogs = useMutation((filters: IFilterRequest) =>
    EventService.getSendTicketsLogs(filters)
  );

  const handleFilterLogs = async (filter: any) => {
    const newFilters = { ...filtersLogs, ...filter };
    await setFiltersLogs(newFilters);
    await getLogs.mutateAsync(newFilters).then((res) => {
      setLogs(res);
    });
  };

  useEffect(() => {
    getTickets.mutateAsync().then((res) => {
      setTickets(res);
    });
  }, []);

  useEffect(() => {
    if (searchLogs != "") {
      const delayDebounceFn = setTimeout(() => {
        loadDataLogs();
      }, 600);
      return () => clearTimeout(delayDebounceFn);
    } else {
      loadDataLogs();
    }
  }, [searchLogs]);

  const loadDataLogs = () => {
    setLoadingLogs(true);
    getLogs.mutateAsync(filtersLogs).then((res) => {
      setLogs(res);
      setLoadingLogs(false);
    });
  };
  const searcherLogs = async (e: any) => {
    setSearchLogs(e.target.value);
    const paramsFilters = { ...filtersLogs, page: 1, query: e.target.value };
    await setFiltersLogs(paramsFilters);
  };

  const columnData = [
    "Líder",
    "Email",
    "Fecha de envío",
    "Ticket",
    "Cantidad de tickets",
  ];
  return (
    <div
      className="fixed  z-50 flex h-screen w-screen items-center justify-center bg-black-100/30 backdrop-blur-md"
      style={{ minHeight: "calc(100vh)" }}
    >
      <div
        className="relative mx-[10%] w-full rounded-2xl bg-white "
        style={{ height: "calc(100vh - 15%)" }}
      >
        <div className="h-full">
          <div className="flex h-[72px] items-center justify-between px-8  pb-4 pt-5 text-center">
            <div />
            <h3 className=" text-lg font-bold">Historial</h3>
            <button className="" onClick={showHistory}>
              <GrClose />
            </button>
          </div>
          <div className="mx-[10%]" style={{ height: "calc(100% - 174px)" }}>
            <div className=" border-b border-colorBorder"></div>

            <div className="mt-2 flex">
              {!!tickets.rows && (
                <select
                  id="filterPage"
                  className="w-[350px] cursor-pointer rounded-lg py-3 pl-4 pr-8 font-medium text-gray-100 outline outline-1 outline-colorBorder"
                  onChange={searcherLogs}
                >
                  <option value="">Seleccione un ticket</option>
                  {tickets.rows.map((_, i) => (
                    <option value={_.id} key={i}>
                      {_.name}
                    </option>
                  ))}
                </select>
              )}
              {loadingLogs && <LoaderCircle width={28} height={28} />}
            </div>
            <div className="max-h-[70%] overflow-y-auto px-1">
              <table className="my-table-spacing h-full w-full border-separate border-spacing-y-2 gap-5 overflow-x-auto pt-5 text-left">
                <thead className=" divide-solid border ">
                  <tr className=" pb-4 text-sm font-bold text-gray-100">
                    {columnData.map((column, index) => (
                      <th
                        className="border-b-[1px] border-colorBorder pb-4"
                        key={column + index}
                      >
                        {column}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {logs.rows.map((_, i) => (
                    <motion.tr
                      key={_.id + i}
                      layout
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      initial={{ opacity: 0.2 }}
                      transition={{ type: "spring", stiffness: 50, bounce: 0 }}
                      className={`h-16  rounded-xl text-start font-medium outline outline-[1px] outline-colorBorder`}
                    >
                      <td className="pl-4 font-bold capitalize">
                        {_.user ? getFullNameOrEmail(_.user) : _.email}
                        {_.user?.status == "deleted" && (
                          <small className="font-normal"> (Eliminado)</small>
                        )}
                      </td>
                      <td className="capitalize">{_.email}</td>
                      <td className="capitalize">
                        {formatDateTime(_.date_send)}
                      </td>
                      <td className="items-center capitalize">
                        {_.ticket.name}
                      </td>
                      <td className="">{_.qty} ticket(s)</td>
                    </motion.tr>
                  ))}
                  {!!logs.rows.length && (
                    <motion.tr
                      key={"total"}
                      layout
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      initial={{ opacity: 0.2 }}
                      transition={{ type: "spring", stiffness: 100, bounce: 0 }}
                      className={`h-16 rounded-xl text-start font-extrabold`}
                    >
                      <td className="pr-4 text-right font-bold" colSpan={4}>
                        TOTAL:{" "}
                      </td>
                      <td className="">{logs.rows[0].total} ticket(s)</td>
                    </motion.tr>
                  )}
                </tbody>
              </table>
            </div>
            {logs.rows.length === 0 ? (
              <p className="text-center">No existen registros disponibles</p>
            ) : null}
            <div className="flex justify-between pt-10">
              <FilterPage
                handleFilter={handleFilterLogs}
                total={logs.total}
                limit={filtersLogs.limit}
                currentPage={filtersLogs.page}
              />
              <FilterQtyPage handleFilter={handleFilterLogs} />
            </div>

            <div
              className=" flex-col flex-wrap overflow-auto px-1 pt-4"
              style={{ height: "calc(100% - 182px)" }}
            ></div>
          </div>
        </div>
      </div>
    </div>
  );
};

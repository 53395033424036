import { Link } from "react-router-dom";
import { QueryObserverResult } from "@tanstack/react-query";
import { ColumnDef } from "@tanstack/react-table";

import { DataTableRowActions } from "../../layout/table/dataTableRowActions";
import { getDropdownOptions } from "../table/dropdownOptions";
// import { Checkbox } from "../../ui/checkbox";
import { Switch } from "../../ui/switch";
import { Label } from "../../ui/label";
import { IEventSimpleResponse } from "../../../types_new/IEvent";
import { formatDate } from "../../../utils_new/formatDate";
import {
  getBgColorFromValue,
  getEventStatus,
  getStatusFromValue,
} from "../../../utils_new/status";

import useActions from "../hooks/useActions";

interface EventsColumnsProps {
  refetch: () => Promise<QueryObserverResult>;
}

export const getEventsColumns = ({
  refetch,
}: EventsColumnsProps): ColumnDef<IEventSimpleResponse>[] => {
  const {
    handleUpdateEventVisibility,
    handleUpdateStatus,
    handleAuthorizeEvent,
    handleDeleteEvent,
    handleReviewEvent,
    handleComments,
    isLoading,
  } = useActions({ refetch });

  const getEventUrl = (event_id: string) => `/admin/events/${event_id}`;

  if (isLoading) {
    return [
      {
        id: "loading",
        cell: () => <div />,
      },
    ];
  }

  return [
    // {
    //   id: "select",
    //   header: ({ table }) => (
    //     <Checkbox
    //       checked={
    //         table.getIsAllPageRowsSelected() ||
    //         (table.getIsSomePageRowsSelected() && "indeterminate")
    //       }
    //       onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
    //       aria-label="Select all"
    //     />
    //   ),
    //   cell: ({ row }) => (
    //     <Checkbox
    //       checked={row.getIsSelected()}
    //       onCheckedChange={(value) => row.toggleSelected(!!value)}
    //       aria-label="Select row"
    //     />
    //   ),
    // },
    {
      accessorKey: "createdAt",
      header: "Fecha de alta",
      cell: ({ row }) => {
        const event_id = row.original.id;
        return (
          <Link to={getEventUrl(event_id)}>
            {formatDate(row.original.createdAt)}
          </Link>
        );
      },
    },
    {
      accessorKey: "createdAt",
      header: "Fecha del evento",
      cell: ({ row }) => {
        const event_id = row.original.id;
        return (
          <Link to={getEventUrl(event_id)}>
            {formatDate(row.original.date_start)}
          </Link>
        );
      },
    },
    {
      accessorKey: "name",
      header: "Evento",
      cell: ({ row }) => {
        const event_id = row.original.id;
        return <Link to={getEventUrl(event_id)}>{row.original.name}</Link>;
      },
    },
    {
      accessorKey: "producer",
      header: "Productora",
      cell: ({ row }) => {
        const event_id = row.original.id;
        return (
          <Link to={getEventUrl(event_id)}>
            {row.original.producer ? row.original.producer.name : ""}
          </Link>
        );
      },
    },
    {
      accessorKey: "client",
      header: "Cliente",
      cell: ({ row }) => {
        const event_id = row.original.id;
        return (
          <Link to={getEventUrl(event_id)}>
            {row.original.client && !Array.isArray(row.original.client)
              ? row.original.client?.first_name +
                " " +
                row.original.client?.last_name
              : ""}
          </Link>
        );
      },
    },
    {
      accessorKey: "status",
      header: "Status",
      cell: ({ row }) => {
        const event_id = row.original.id;
        const eventStatus = getEventStatus(
          row.original.status,
          row.original.temp
        );
        const statusText = getStatusFromValue(eventStatus);
        const statusColor = getBgColorFromValue(eventStatus);

        return (
          <Link
            to={getEventUrl(event_id)}
            className="w-34 flex items-center justify-center gap-3 rounded-full border-[1px] border-colorBorder py-2 text-center"
          >
            <span className={`rounded-full  ${statusColor} p-[.35rem]`} />
            <p className="capitalize">{statusText}</p>
          </Link>
        );
      },
    },
    {
      accessorKey: "hidden",
      header: "Oculto",
      cell: ({ row }) => {
        const event_id = row.original.id;
        return (
          <div className="flex items-center space-x-1">
            <Label htmlFor="hidden-event" className="text-xs">
              No
            </Label>
            <Switch
              id="hidden-event"
              checked={row.original.hidden}
              onCheckedChange={() =>
                handleUpdateEventVisibility(event_id, !row.original.hidden)
              }
            />
            <Label htmlFor="hidden-event" className="text-xs">
              Sí
            </Label>
          </div>
        );
      },
    },
    {
      id: "actions",
      cell: ({ row }) => {
        return (
          <DataTableRowActions
            row={row}
            dropdownOptions={getDropdownOptions({
              event_id: row.original.id,
              event_status: getEventStatus(row.original.status),
              event_temp: !!row.original.temp && row.original.temp.length > 0,
              handleUpdateStatus,
              handleAuthorizeEvent,
              handleDeleteEvent,
              handleReviewEvent,
              handleComments,
            })}
          />
        );
      },
    },
  ];
};

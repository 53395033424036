import { useContext } from "react";
import { useMutation } from "@tanstack/react-query";
import { motion } from "framer-motion";
import { FaRedo, FaTrashAlt } from "react-icons/fa";
import { GoSearch } from "react-icons/go";
import { toast } from "react-toastify";

import DropdownDots from "../../FormElement/DropdownDots";
import EventService from "../../../services/eventService";
import { StateContext } from "../../../context/StateProvider";
import { IEventResponse, IEventsResponse } from "../../../types/IEvent";
import { formatDate } from "../../../utils/format-date";

type selectedProps = {
  data: IEventsResponse;
  updateData: (filter: string) => void;
};

const columnData = [
  "ID",
  "Fecha del evento",
  "Nombre",
  "Productora",
  "Cliente",
];

export const EventRecycler = ({ data, updateData }: selectedProps) => {
  const { dispatch } = useContext(StateContext);

  const deleteItems = useMutation((id: string) => EventService.remove(id), {
    onSuccess(data: any) {
      toast.success("Evento eliminado exitosamente");
    },
  });

  const handleDelete = async (id: string) => {
    if (window.confirm("¿Estás seguro de eliminar este evento?")) {
      dispatch({ type: "showLoaderScreen", payload: true });

      await deleteItems
        .mutateAsync(id)
        .then((res) => {
          dispatch({ type: "showLoaderScreen", payload: false });
          updateData("events");
        })
        .catch((err) => {
          dispatch({ type: "showLoaderScreen", payload: false });
          dispatch({ type: "toastError", payload: err.response.data.message });
        });
    }
  };

  const handleRestoreEvent = async (id: any) => {
    dispatch({ type: "showLoaderScreen", payload: true });

    await EventService.restore(id)
      .then((res) => {
        dispatch({ type: "showLoaderScreen", payload: false });
        dispatch({ type: "toastSuccess", payload: "Evento restaurado" });
        updateData("events");
      })
      .catch((err) => {
        dispatch({ type: "showLoaderScreen", payload: false });
        dispatch({ type: "toastError", payload: err.response.data.message });
      });
  };

  return (
    <table className="my-table-spacing h-full w-full border-separate border-spacing-y-2 gap-5 overflow-x-auto pt-5 text-left">
      <thead className=" divide-solid border ">
        <tr className=" pb-4 text-sm font-bold text-gray-100">
          {columnData.map((column, index) => (
            <th
              className="border-b-[1px] border-colorBorder pb-4"
              key={column + index}
            >
              {column}
            </th>
          ))}
          <th className="border-b-[1px] border-colorBorder pb-4"></th>
        </tr>
      </thead>
      <tbody>
        {data.rows.length ? (
          data.rows.map((item: IEventResponse) => (
            <motion.tr
              key={item.id}
              layout
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              initial={{ opacity: 0.2 }}
              transition={{ type: "spring", stiffness: 100, bounce: 0 }}
              className={`h-16  rounded-xl text-start font-medium outline outline-[1px] outline-colorBorder`}
            >
              <td className="pl-4">{`#${item.id}`}</td>
              <td className="pl-4">{formatDate(item.limit_date)}</td>
              <td className="pl-4">{item.name}</td>
              <td className="pl-4">{item.producer?.name}</td>
              <td className="pl-4">{`${item.client?.first_name} ${item.client?.last_name}`}</td>
              <td className="pl-4">
                <DropdownDots
                  options={[
                    {
                      title: "Eliminar",
                      action: () => handleDelete(item.id),
                      icon: (
                        <FaTrashAlt size={23} className="mx-2" key={item.id} />
                      ),
                    },
                    {
                      title: "Restaurar",
                      action: () => handleRestoreEvent(item.id),
                      icon: <FaRedo size={23} className="mx-2" key={item.id} />,
                    },
                  ]}
                />
              </td>
            </motion.tr>
          ))
        ) : (
          <></>
        )}
      </tbody>
    </table>
  );
};

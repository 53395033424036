import { useState, useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import { GrClose } from "react-icons/gr";

import { StateContext } from "../../../../context/StateProvider";
import TicketService from "../../../../services/ticketService";
import UserService from "../../../../services/userService";
import { SearchAutocomplete } from "../../../rrpp/newSeller/components/SearchAutocomplete/SearchAutocomplete";
import { IFilterRequest } from "../../../../types/IFilter";
import { PictureAndName } from "../../../rrpp/components/PictureAndName/PictureAndName";

const defaultResult = {
  total: 0,
  pages: 0,
  rows: [],
};

const defaultFilters: any = {
  limit: 20,
  page: 1,
  sortBy: "first_name",
  sortOrder: "asc",
  status: "active",
  user_role: ["seller", "client", "user", "leader"],
};

export const PopUpSendTicket = ({}) => {
  const { state, dispatch } = useContext(StateContext);
  const [email, setEmail] = useState("");
  const [selectData, setSelectData] = useState<any>(null);
  const [data, setData] = useState(defaultResult);
  const { user_id } = useParams();
  const [filters, setFilters] = useState<IFilterRequest>(defaultFilters);

  const sendTicket = useMutation((item: any) => TicketService.sendTicket(item));
  const getItems = useMutation(() => UserService.getAll(filters));

  useEffect(() => {
    if (email != "") {
      const delayDebounceFn = setTimeout(() => {
        dispatch({ type: "showLoaderScreen", payload: true });
        getItems.mutateAsync().then((res) => {
          setData(res);
          dispatch({ type: "showLoaderScreen", payload: false });
        });
      }, 1500);
      return () => clearTimeout(delayDebounceFn);
    }
  }, [email]);

  const cleanContext = () => {
    dispatch({ type: "selectedUsers", payload: [] });
    dispatch({ type: "setOrderTicket", payload: null });
    dispatch({ type: "setOrdersTickets", payload: null });
  };

  const closeModal = () => {
    dispatch({ type: "addUsersDetailTicket", payload: null });
    cleanContext();
  };

  const handleChange = async (option: string) => {
    await setEmail(option);
    await setFilters({ ...filters, page: 1, query: option });
  };

  const handleSelect = (data: any) => {
    setSelectData(data);
  };

  const propMethodsSearch = {
    onChange: handleChange,
    handleSelect,
  };

  const handleSelectedOrder = async () => {
    const data = {
      order_ticket_id: state.selectedOrderTicket.id,
      receiver_id: selectData.id,
      sender_id: user_id,
    };
    await sendTicket.mutateAsync(data);
  };

  const handleOrdersTicket = async () => {
    const sendTicketsPromises = state.orders_ticket.map(async (orderTicket) => {
      const data = {
        order_ticket_id: orderTicket.id,
        receiver_id: selectData.id,
        sender_id: orderTicket.user_id,
      };
      return sendTicket
        .mutateAsync(data)
        .then((res) => {
          return Promise.resolve();
        })
        .catch((e) => {
          console.log("error ", e);
          dispatch({ type: "toastError", payload: e.response.data.error });
          return Promise.resolve();
        });
    });
    await Promise.all(sendTicketsPromises);
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    dispatch({ type: "addUsersDetailTicket", payload: null });
    dispatch({ type: "showLoaderScreen", payload: true });
    try {
      if (state.selectedOrderTicket) {
        await handleSelectedOrder();
      } else {
        await handleOrdersTicket();
      }
      dispatch({ type: "reloadOrderTicket", payload: true });
      dispatch({ type: "toastSuccess", payload: "Enviado correctamente" });
    } catch (error) {
      console.log("error ", e);
      dispatch({ type: "toastError", payload: e.response.data.error });
    } finally {
      dispatch({ type: "showLoaderScreen", payload: false });
      cleanContext();
    }
  };

  return (
    <div
      className="fixed  z-50 flex h-screen w-screen items-center justify-center bg-black-100/30 backdrop-blur-md"
      style={{ minHeight: "calc(100vh)" }}
    >
      <div className="absolute top-20 m-auto flex h-[85%] w-[80%] flex-col  rounded-2xl bg-white ">
        <div className="flex w-full items-baseline justify-between border-b border-colorBorder  px-8 pb-4 pt-5 text-center">
          <div />
          <div>
            <h3 className=" text-lg font-bold">Enviar ticket</h3>
            <p>Ingresa un mail y elegí el usuario para enviar el ticket.</p>
          </div>

          <button className="" onClick={closeModal}>
            <GrClose />
          </button>
        </div>
        <div className="w-full flex-col flex-wrap  overflow-auto  px-16 pt-4">
          <form
            className="flex flex-col items-center justify-center gap-5"
            onSubmit={handleSubmit}
          >
            <div className="mx-auto my-5 w-[75%]">
              <SearchAutocomplete
                value={email}
                options={data.rows}
                {...propMethodsSearch}
              />
            </div>

            {selectData && (
              <div className="grid w-full grid-cols-3 items-center rounded-lg border p-2.5">
                <div>
                  <div className="ml-2">
                    <PictureAndName
                      profilePicture={selectData.profile_picture}
                      firstName={selectData.first_name}
                      lastName={selectData.last_name}
                    />
                  </div>
                </div>
                <div>
                  <span className="text-[#7B7B7B]">{selectData.email}</span>
                </div>
                <div>
                  <button
                    type="submit"
                    className="ml-auto mr-2 flex items-center justify-center rounded-full border-[2px] bg-black-100 px-3 py-2 pl-5 pr-5 text-xs text-white"
                  >
                    Enviar ticket
                  </button>
                </div>
              </div>
            )}
          </form>
        </div>
      </div>
    </div>
  );
};

import { FC } from "react";

export const PreloadedFile = ({ file_name, size_file }: any) => {
  return (
    <div className="w-[47rem] flex-col flex-wrap  overflow-auto  border-b px-10 pb-5 pt-5">
      <div className="flex w-full items-center justify-center gap-4">
        <div className="font-sans text-base font-medium">
          {file_name} - {size_file}KB
        </div>
      </div>
    </div>
  );
};

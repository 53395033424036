import React, { useEffect, useState } from "react";
import { useContext } from "react";
import { GrClose } from "react-icons/gr";
import { StateContext } from "../../../../context/StateProvider";
import { IArtistResponse } from "../../../../types/IArtist";
import { Footer } from "../../../footer/Footer";
import { InputSearch } from "../../../rrpp/components/InputSearch/InputSearch";

import { Artists } from "./Artists";
import { FullName } from "../../../../helpers/ArtistHelper";
import { FixedSizeGrid as Grid } from "react-window";

interface ArtistRowProps {
  columnIndex: any;
  rowIndex: any;
  style: any;
}

export const PopUpArtist = ({}) => {
  const { state, dispatch } = useContext(StateContext);
  const [selectedArtistIds, setSelectedArtistIds] = useState<Array<string>>([]);
  const columnCount = 3;
  const [width, setWidth] = useState(window.innerWidth);

  const ArtistRow = ({ columnIndex, rowIndex, style }: ArtistRowProps) => {
    const artistIndex = rowIndex * columnCount + columnIndex;
    if (artistIndex >= artists.length) return null;

    const cellStyle = {
      ...style,
      left: style.left + 150,
      right: style.right + 150,
      top: style.top + 5,
      bottom: style.bottom + 5,
      width: style.width - 20,
      height: style.height - 10,
    };

    const artist = artists[artistIndex];
    return (
      <div style={cellStyle} key={artist.id}>
        <Artists
          name={`${FullName(artist)}`}
          nickname={artist.nickname}
          value={artist.id}
          handleOnChange={() => handleOnChange(artist.id)}
          isSelected={isSelected(artist.id)}
        />
      </div>
    );
  };

  const addArtistEvent = () => {
    dispatch({ type: "addArtistEvent", payload: null });
  };

  const showArtists = () => {
    dispatch({ type: "showArtists", payload: null });
  };

  const setSelectedArtists = () => {
    const selectedArtists: any[] = [];
    state.artists.forEach((artist) => {
      if (selectedArtistIds.includes(artist.id)) {
        selectedArtists.push(artist);
      }
    });
    dispatch({ type: "selectedArtists", payload: selectedArtists });
  };

  const handleOnChange = async (artist_id: string) => {
    if (selectedArtistIds?.includes(artist_id)) {
      const index = selectedArtistIds?.indexOf(artist_id);
      selectedArtistIds?.splice(index, 1);
      setSelectedArtistIds([...selectedArtistIds]);
    } else {
      setSelectedArtistIds([...selectedArtistIds, ...[artist_id]]);
    }
  };

  const isSelected = (value: string) => {
    return selectedArtistIds?.includes(value);
  };

  useEffect(() => {
    setSelectedArtistIds(
      state.selectedArtists.map((item) => {
        return item.id;
      })
    );
  }, []);

  const [query, setQuery] = useState("");
  const [artists, setArtists] = useState<Array<IArtistResponse>>(state.artists);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(e.target.value);
    if (state.artists) {
      const results = state.artists.filter((artist) => {
        if (e.target.value === "") return state;
        if (artist && artist.full_name) {
          const fullName = artist.full_name;
          const nickName = artist.nickname === null ? "" : artist.nickname;
          return (
            fullName.toLowerCase().includes(e.target.value.toLowerCase()) ||
            nickName.toLowerCase().includes(e.target.value.toLocaleLowerCase())
          );
        }
      });
      setArtists(results);
    }
  };

  const handleChangeSelectAll = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked)
      setSelectedArtistIds(artists.map((artist) => artist.id));
    else setSelectedArtistIds([]);
  };

  return (
    <div
      className="fixed  z-50 flex h-screen w-screen items-center justify-center bg-black-100/30 backdrop-blur-md"
      style={{ minHeight: "calc(100vh)" }}
    >
      <div
        className="relative mx-[10%] w-full rounded-2xl bg-white "
        style={{ height: "calc(100vh - 15%)" }}
      >
        <div className="h-full">
          <div className="flex h-[72px] items-center justify-between px-8  pb-4 pt-5 text-center">
            <div />
            <h3 className=" text-lg font-bold">
              Asignación de artista para el evento
            </h3>
            <button className="" onClick={addArtistEvent}>
              <GrClose />
            </button>
          </div>
          <div className="mx-[10%]" style={{ height: "calc(100% - 174px)" }}>
            <div className=" border-b border-colorBorder"> </div>
            <div className="h-[182px] pt-12">
              <InputSearch
                value={query}
                onChange={() => {}}
                handleChange={handleChange}
                content="Buscar un artista"
              />
              <label htmlFor={"all"} className="w-[20rem]">
                <div className="flex w-full items-center gap-5 rounded-2xl py-5 pl-4 font-medium ">
                  <input
                    type="checkbox"
                    name="team_ids[]"
                    id={"all"}
                    onChange={handleChangeSelectAll}
                    className="ml-auto rounded-full p-2 checked:bg-black-100 focus:ring-transparent active:bg-black-100"
                  />
                  Seleccionar todos
                </div>
              </label>
              <p className="pb-4 font-bold">Artistas</p>
            </div>
            <div
              className=" flex-col flex-wrap overflow-auto px-1 pt-4"
              style={{ height: "calc(100% - 182px)" }}
            >
              <form
                className="flex flex-col items-center justify-center gap-5"
                onSubmit={(e) => {
                  e.preventDefault();
                }}
              >
                <Grid
                  columnCount={columnCount}
                  columnWidth={width <= 1540 ? 225 : 300}
                  height={width <= 1540 ? 225 : 400}
                  rowCount={Math.ceil(artists.length / columnCount)}
                  rowHeight={75}
                  width={width <= 1540 ? 900 : 1220}
                  style={{
                    scrollbarWidth: "thin",
                    scrollbarColor: "white transparent",
                  }}
                >
                  {ArtistRow}
                </Grid>
                {artists?.length === 0 && (
                  <p className="text-center">No existen artistas disponibles</p>
                )}
              </form>
            </div>
          </div>
          <div className="flex h-[102px] w-full items-center justify-center border-colorBorder text-center">
            <button
              onClick={() => {
                if (!state.showArtist) showArtists();
                addArtistEvent();
                setSelectedArtists();
              }}
              type="submit"
              className="m-auto w-72 rounded-full border-[2px] border-black-100 bg-black-100 px-12 py-2 font-medium text-white"
            >
              Guardar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

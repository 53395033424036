import React, { FC } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { FormInputProps } from "../../../types/FormInputsProps";

const FormSwitch: FC<FormInputProps> = ({ name, label }) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { name, value, onBlur, ref, onChange } }) => (
        <div className="flex w-full flex-col">
          <h2 className="pb-2 text-base font-medium text-gray-100">{label}</h2>
          <div className="flex w-full flex-wrap gap-4 ">
            <div className="flex flex-row">
              <span className="text-xs font-medium">No</span>
              <label
                htmlFor={name}
                className="relative mx-1 mb-5 inline-flex cursor-pointer items-center"
              >
                <input
                  id={name}
                  type="checkbox"
                  value={value}
                  className="peer sr-only"
                  onChange={onChange}
                  checked={!!value}
                />
                <div className="h-4 w-10 rounded-full bg-gray-200 after:absolute after:left-[1px]  after:top-[-2px]  after:h-5 after:w-5 after:rounded-full after:border after:border-gray-300 after:bg-[#171721] after:transition-all after:content-[''] peer-checked:after:translate-x-full peer-checked:after:border-white peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-green  "></div>
              </label>
              <span className="text-xs font-medium">Si</span>
            </div>
          </div>
          {errors[name] && (
            <p className="text-red-600 dark:text-red-500 mt-2 text-sm text-red">
              {String(errors[name] ? errors[name]?.message : "")}
            </p>
          )}
        </div>
      )}
    />
  );
};

export default FormSwitch;

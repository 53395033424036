import { useMutation } from "@tanstack/react-query";
import { motion } from "framer-motion";
import React, { FC, useContext, useEffect, useState } from "react";
import { FaComment, FaEdit, FaTrashAlt, FaYoutube } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import NewsService from "../../services/newsService";
import jsonToFormData from "@ajoelp/json-to-formdata";
import { INewResponse, INewsResponse } from "../../types/INews";
import { IFilterRequest } from "../../types/IFilter";
import DropdownDots from "../FormElement/DropdownDots";
import { FilterPage } from "../table/FilterPage";
import { FilterQtyPage } from "../table/FilterQtyPage";
import { FilterSection } from "../table/FilterSection";
import { TitleSection } from "../TitleSection";
import { formatDate } from "../../utils/format-date";
import { IUserResponse } from "../../types/IUser";
import { toast } from "react-toastify";
import { getStatusFromValue } from "../../utils/status";
import { FilterOrder } from "../table/FilterOrder";
import ISortOption from "../../types/ISortOption";
import { PageContainer } from "../PageContainer";
import { StateContext } from "../../context/StateProvider";
import TableCellLink from "../FormElement/TableCellLink.tsx";
import config from "../../config/variables";
import { Badge } from "../ui/badge";

type rowProps = {
  rowData: {
    date: string;
    title: string;
    type: string;
    likes: string;
    status: string;
  };
  qty?: number;
};

const defaultResult = {
  total: 0,
  pages: 0,
  rows: [],
};

const defaultFilters = {
  limit: config.filter_default_limit,
  page: 1,
  status: "all",
  sortBy: "createdAt",
  sortOrder: "desc",
  query: "",
};

type propsTable = {
  handleCreateorEdit: (id: string) => void;
  handleSelectItem: (data: IUserResponse) => void;
};

export const TableNews: FC<propsTable> = ({
  handleCreateorEdit,
  handleSelectItem,
}) => {
  const { role, token } = useContext(AuthContext);
  const navigate = useNavigate();

  const [result, setResult] = useState<INewsResponse>(defaultResult);
  const [filters, setFilters] = useState<IFilterRequest>(defaultFilters);
  const [search, setSearch] = useState("");
  const [currentEditOrder, setCurrentEditOrder] = useState<string>();
  const [currentValueOrder, setCurrentValueOrder] = useState<number>();
  const [itemId, setItemId] = useState<string>();
  const { state, dispatch } = useContext(StateContext);
  let paramsFilters: any = { status: "", sortBy: "" };

  const searcher = async (e: any) => {
    setSearch(e.target.value);
    paramsFilters = { ...filters, query: e.target.value };
    await setFilters({ ...filters, page: 1, query: e.target.value });
    await getItems.mutateAsync().then((res) => {
      console.log(res);
      setResult(res);
    });
  };

  let resultado = [];
  resultado = result.rows;

  const getItems = useMutation(() => NewsService.getAll(filters));

  const deleteItems = useMutation(
    (id: string) => NewsService.sendRecycler(id),
    {
      onSuccess(data: any) {
        toast.success(data.message);
      },
    }
  );

  const updateNewsOrder = useMutation((order: number) =>
    NewsService.updateOrder(itemId, order)
  );

  const handleFilter = async (filter: IFilterRequest) => {
    await setFilters({ ...filters, ...filter });
    await getItems.mutateAsync().then((res) => setResult(res));
  };

  const handleFilterSection = async (filter?: string) => {
    const paramsFilters = { ...filters, status: filter };
    await setFilters({ ...filters, page: 1, status: filter });
    await getItems.mutateAsync().then((res) => setResult(res));
  };

  const handleSortSection = async (sortBy: string) => {
    const itemSort = sortOptions.find((s) => s.value == sortBy);
    paramsFilters = { ...filters, page: 1, sortBy: sortBy };
    if (itemSort) paramsFilters["sortOrder"] = itemSort.sortOrder;
    await setFilters(paramsFilters);
    await getItems.mutateAsync().then((res) => setResult(res));
  };

  const handleEdit = (id: string) => {
    navigate(`/admin/news/${id}/edit`);
  };

  const handleNewsDetail = (id: string) => {
    if (window.location.pathname.includes("admin/"))
      navigate(`/admin/news/${id}/edit`);
  };

  const handleDelete = async (id: string) => {
    if (
      window.confirm(
        "Confirma que quiere enviar este registro a la papelera de reciclaje ?"
      )
    ) {
      await deleteItems.mutateAsync(id).then((res) => {
        getItems.mutateAsync().then((res) => setResult(res));
      });
    }
  };
  const handleOnChange = (e: any) => {
    setCurrentValueOrder(e.target.value);
  };

  const handleUpdateOrderNews = async (id: string, row: INewResponse) => {
    await setItemId(id);
    dispatch({ type: "showLoaderScreen", payload: true });

    await updateNewsOrder
      .mutateAsync(currentValueOrder !== undefined ? currentValueOrder : 0)
      .then((res) => {
        row.order = parseInt(currentValueOrder?.toString() || "0");
        dispatch({ type: "showLoaderScreen", payload: false });
        setCurrentEditOrder("");
      })
      .catch((e) => dispatch({ type: "showLoaderScreen", payload: false }));
  };

  const handleComments = (id: string) => {
    if (window.location.pathname.includes("admin/")) {
      navigate(`/admin/news/${id}/comments`);
    }
  };

  useEffect(() => {
    handleFilter(filters);
  }, []);

  const columnData = [
    "Fecha de alta",
    "Titulo",
    "Tipo",
    "Me gusta",
    "Status",
    "Orden",
    "Link",
    "Acciones",
  ];
  const rowData = {
    date: "19 Jun 2021 - 12:43:22",
    title: "Holaa",
    type: 6,
    likes: 100,
    status: "activo",
  };

  const sortOptions: Array<ISortOption> = [
    { value: "createdAt", text: "Fecha", sortOrder: "desc" },
    { value: "title", text: "Nombre", sortOrder: "asc" },
  ];

  const [stateOrder, setState] = useState("asc");
  const handleChangeState = async (state: string) => {
    setState(state);
    await setFilters({ ...filters, sortOrder: state });
    loadData();
  };
  const loadData = () => {
    dispatch({ type: "showLoaderScreen", payload: true });
    getItems.mutateAsync().then((res) => {
      dispatch({ type: "showLoaderScreen", payload: false });
      setResult(res);
    });
  };

  return (
    <PageContainer>
      <div className="flex justify-between">
        <TitleSection>Novedades</TitleSection>
        <button
          onClick={() => navigate("/admin/news/add")}
          className="rounded-full border-[2px] border-black-100 px-12 py-2 font-bold text-black-100 hover:bg-black-100 hover:text-white"
        >
          Crear una nueva novedad
        </button>
      </div>
      <div className="mb-7 flex justify-between border-b-[1px] border-colorBorder pr-5 pt-10  ">
        <FilterSection
          handleFilter={handleFilterSection}
          filterSectionProps={[]}
        />
        <div className="flex items-center gap-5 ">
          {/* <GoSearch className="cursor-pointer text-gray-100" /> */}
          <input
            type="text"
            placeholder="Search..."
            className="hover:text-black rounded-full border-[2px] border-black-100 px-4 py-1 font-bold  text-black-100"
            value={search}
            onChange={searcher}
          />
          <FilterOrder
            setSort={handleSortSection}
            options={sortOptions}
            handleChangeState={handleChangeState}
            state={stateOrder}
          />
        </div>
      </div>
      <table className="my-table-spacing h-full w-full border-separate border-spacing-y-2 gap-5 overflow-x-auto pt-5 text-left">
        <thead className=" divide-solid border ">
          <tr className=" pb-4 text-sm font-bold text-gray-100">
            {columnData.map((column, index) => (
              <th
                className="border-b-[1px] border-colorBorder pb-4"
                key={column + index}
              >
                {column}
              </th>
            ))}
            <th className="border-b-[1px] border-colorBorder pb-4">
              {/* <GoSearch className="cursor-pointer text-gray-100 ml-auto" size={16} /> */}
            </th>
          </tr>
        </thead>
        <tbody>
          {resultado.map((row, i) => (
            <motion.tr
              key={i}
              layout
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              initial={{ opacity: 0.2 }}
              transition={{ type: "spring", stiffness: 100, bounce: 0 }}
              className={`h-16  rounded-xl text-start font-medium outline outline-[1px] outline-colorBorder`}
            >
              <TableCellLink
                href={`/admin/news/${row.id}/edit`}
                className="pl-4"
              >
                {formatDate(row?.createdAt)}
              </TableCellLink>
              <TableCellLink
                href={`/admin/news/${row.id}/edit`}
                className="pl-4"
              >
                {row?.title}
              </TableCellLink>
              <TableCellLink
                href={`/admin/news/${row.id}/edit`}
                className="pl-4"
              >
                {row?.type}
              </TableCellLink>
              <TableCellLink
                href={`/admin/news/${row.id}/edit`}
                className="pl-4"
              >
                {row?.totalLikes}
              </TableCellLink>
              <TableCellLink
                href={`/admin/news/${row.id}/edit`}
                className="pl-4"
              >
                <div className="flex w-32 items-center justify-center gap-3 rounded-full border-[1px] border-colorBorder  py-2 text-center ">
                  <div
                    className={`rounded-full  ${
                      row.status === "active" ? "bg-green" : "bg-red"
                    } p-[.35rem]`}
                  />
                  <p className="capitalize">
                    {getStatusFromValue(row.status || "paused")}
                  </p>
                </div>
              </TableCellLink>
              <td className="pl-4">
                {currentEditOrder == row.id ? (
                  <div className="flex items-center">
                    <input
                      type="number"
                      name={"news_" + row.id}
                      value={currentValueOrder}
                      onChange={handleOnChange}
                      className="hover:text-black w-16 rounded-full border-[1px] border-black-100 px-2 py-1 font-bold text-black-100"
                    />
                    <button
                      onClick={() => handleUpdateOrderNews(row.id, row)}
                      className="ml-1 rounded-full border-[1px] border-black-100 px-3 py-2 font-bold text-black-100 hover:bg-black-100 hover:text-white"
                    >
                      <FaEdit size={12} />
                    </button>
                  </div>
                ) : (
                  <div className="flex items-center">
                    {row.order}
                    <button
                      onClick={() => {
                        setCurrentEditOrder(row.id);
                        setCurrentValueOrder(row.order);
                      }}
                      className="ml-1 rounded-full border-[2px] border-black-100 px-2 py-2 font-bold text-black-100 hover:bg-black-100 hover:text-white"
                    >
                      <FaEdit size={12} />
                    </button>
                  </div>
                )}
              </td>
              {/* <td className="pl-4">
                    <button className="text-gray-100">
                     <BiDotsHorizontalRounded size={23} />
                    </button>

                  </td>  */}
              <td className="pl-4">
                {row?.link ? (
                  <Badge
                    onClick={() => {
                      navigator.clipboard.writeText(row.link ?? "").then(() => {
                        toast.success("Link copiado con éxito!"); // Show success message
                      });
                    }}
                  >
                    Copiar Link
                  </Badge>
                ) : (
                  "Edita y guarda para tener un link"
                )}
              </td>
              <td className="pl-4">
                {
                  <DropdownDots
                    options={[
                      {
                        title: "Editar",
                        action: () => handleEdit(row.id),
                        icon: <FaEdit size={23} />,
                      },
                      {
                        title: "Eliminar",
                        action: () => handleDelete(row.id),
                        icon: <FaTrashAlt size={23} />,
                      },
                      {
                        title: "Ver Comentarios",
                        action: () => {
                          handleComments(row.id);
                        },
                        icon: <FaComment size={23} />,
                      },
                    ]}
                  />
                }
              </td>
            </motion.tr>
          ))}
        </tbody>
      </table>
      <div className="flex justify-between pt-10">
        <FilterPage
          handleFilter={handleFilter}
          total={result.total}
          limit={filters.limit}
          status={filters.status}
          currentPage={filters.page}
        />
        <FilterQtyPage handleFilter={handleFilter} status={filters.status} />
      </div>
    </PageContainer>
  );
};

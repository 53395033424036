import axios from "axios";
import config from "../config/variables";

const apiFront = axios.create({
  baseURL: config.api_url,
  headers: {
    "Content-type": "application/json",
  },
});

export default apiFront;

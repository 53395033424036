interface UpdateUrlProps {
  page?: number;
  limit?: number;
  query?: string;
  sortBy?: string;
  sortOrder?: string;
}

export const updateUrl = ({
  page,
  limit,
  query,
  sortBy,
  sortOrder,
}: UpdateUrlProps) => {
  const urlParams = new URLSearchParams(window.location.search);

  if (page) urlParams.set("page", page.toString());
  if (limit) urlParams.set("limit", limit.toString());
  if (sortBy) urlParams.set("sortBy", sortBy);
  if (sortOrder) urlParams.set("sortOrder", sortOrder);
  if (query) urlParams.set("query", query);
  else urlParams.delete("query");

  const newUrl = `${document.location.pathname}?${urlParams}`;

  window.history.pushState({ path: newUrl }, "", newUrl);
};

import { useContext } from "react";

import { AuthContext } from "../../context/AuthContext";
import { formatMoney } from "../../utils/format-money";
import { ISwapSummary } from "../../types/IOffer";

interface SwapSummaryProps {
  swapSummaryData: ISwapSummary;
}

export const SwapSummary = ({ swapSummaryData }: SwapSummaryProps) => {
  const { permissions } = useContext(AuthContext);

  const { offersQty, soldOffersQty, totalSold, totalService } = swapSummaryData;

  return (
    <div className="my-10 flex flex-col items-center justify-between gap-5 lg:flex-row ">
      <div className="flex flex-wrap justify-center">
        <div>
          <p className="px-4 text-center text-3xl font-bold text-black-200 lg:text-4xl xl:text-5xl">
            {offersQty}
          </p>
          <p className="px-4 text-center font-medium">Total ofertas</p>
        </div>
        <div>
          <p className="px-4 text-center text-3xl font-bold text-black-200 lg:text-4xl xl:text-5xl">
            {soldOffersQty}
          </p>
          <p className="px-4 text-center font-medium">Ofertas vendidas</p>
        </div>
      </div>

      {!(permissions.can_see_balance === false) && (
        <div className="flex flex-wrap justify-center">
          <div>
            <p className="px-4 text-center text-3xl font-bold text-black-200 lg:text-4xl xl:text-5xl">
              {formatMoney(totalSold)}
            </p>
            <p className="px-4 text-center font-medium">Total vendido</p>
          </div>
          <div>
            <p className="px-4 text-center text-3xl font-bold text-black-200 lg:text-4xl xl:text-5xl">
              {formatMoney(totalService)}
            </p>
            <p className="px-4 text-center font-medium">Total servicio</p>
          </div>
        </div>
      )}
    </div>
  );
};

import { useState } from "react";

import { ISectionResponse } from "../../types/ISection";
import { SelectedSection } from "./selected/SelectedSection";
import { TableSections } from "./TableSections";
import { FormSection } from "./Form";

export const SectionsView = () => {
  const [createItem, setCreateItem] = useState(false);
  const [id, setId] = useState<string>("");
  const [showSelected, setShowSelected] = useState(false);
  const [selectedItem, setSelectedItem] = useState<ISectionResponse>();

  const handleCreateorEdit = async (id: string) => {
    await setId(id);
    await setCreateItem(!createItem);
  };

  const handleSelectItem = (data: ISectionResponse) => {
    setShowSelected(!showSelected);
    if (data) {
      setSelectedItem(data);
    }
  };

  const itemsTableProps = {
    handleCreateorEdit,
    handleSelectItem,
  };

  const formSectionProps = {
    handleCreateorEdit,
  };

  const selectedSectionProps = {
    handleSelectItem,
  };

  return (
    <div>
      {showSelected ? (
        <SelectedSection {...selectedSectionProps} data={selectedItem} />
      ) : createItem ? (
        <FormSection {...formSectionProps} id={id} />
      ) : (
        <TableSections {...itemsTableProps} />
      )}
    </div>
  );
};

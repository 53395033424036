import { useEffect, useState } from "react";
import { AiFillCloseCircle } from "react-icons/ai";
import { IUserResponse } from "../../../../types/IUser";
import img from "../../../../assets/profile-default.png";

type ArtistEventProps = {
  data: IUserResponse;
  handleRemoveId: (id: string) => void;
  onClick?: () => void;
};

export const ArtistEvent = ({
  data,
  handleRemoveId,
  onClick,
}: ArtistEventProps) => {
  const [artist, setArtist] = useState<IUserResponse>();
  useEffect(() => {
    if (data) {
      setArtist(data);
    }
  }, [data.id]);

  const ImagenOk = () => {
    // setImage(undefined);
  };

  const getFullNameOrNickname = (user?: IUserResponse) => {
    if (!user) return "-";
    if (user.user_role == "artist" && user.nickname) {
      return user.nickname;
    }
    return user.first_name ? `${user.first_name} ${user.last_name}` : "-";
  };
  return (
    <div className="flex w-72">
      <div
        className={`relative box-border flex cursor-pointer items-center gap-5 p-2 ${
          data.is_main_artist
            ? "outline-red-50 outline-3 rounded-xl outline outline-purple"
            : ""
        }`}
        onClick={onClick}
      >
        <img
          src={artist?.profile_picture ? artist?.profile_picture : img}
          className="h-[64px] w-[64px] rounded-full bg-[#D6D6D6] object-cover"
          alt=""
          onError={() => ImagenOk()}
        />
        <h3 className="text-lg font-medium">{getFullNameOrNickname(artist)}</h3>
        {data.is_main_artist && (
          <div className="absolute bottom-0 right-2 translate-y-2/4 rounded-full bg-purple px-2 py-1 text-[8px] font-bold uppercase tracking-wide text-white">
            Main Artist
          </div>
        )}
      </div>
      <div className="-translate-x-2.5 -translate-y-2.5">
        <button type="button" onClick={() => handleRemoveId(data.id)}>
          <AiFillCloseCircle size={18} />
        </button>
      </div>
    </div>
  );
};

import React, { useContext, useState, FC, useEffect, useRef } from "react";
import { Back } from "../../../../Back";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, FormProvider } from "react-hook-form";
import * as yup from "yup";
import jsonToFormData from "@ajoelp/json-to-formdata";
import FormInput from "../../../../FormElement/FormInput";
import FormDateTimeField from "../../../../FormElement/FormDateTimeField";
import FormCropperImage from "../../../../FormElement/FormCropperImage";
import FormRadio from "../../../../FormElement/FormRadio";
import TicketService from "../../../../../services/ticketService";
import { ITicketRequest, ITicketResponse } from "../../../../../types/ITicket";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import moment from "moment";
import eventService from "../../../../../services/eventService";
import ConfigService from "../../../../../services/configService";
import { toast } from "react-toastify";
import { StateContext } from "../../../../../context/StateProvider";
import { buildISODateTimeString } from "../../../../../utils/format-date";
import { AuthContext } from "../../../../../context/AuthContext";
import FormCheck from "../../../../FormElement/FormCheck";
import FormTextArea from "../../../../FormElement/FormTextArea";
import FormUpload from "../../../../FormElement/FormUpload";
import { uploadToS3 } from "../../../../../utils/s3";
import FormColor from "../../../../FormElement/FormColor";
import UploadService from "../../../../../services/uploadService";
import FormSwitch from "../../../../FormElement/FormSwitch";

type typeProps = {
  handleFormDisplay: (id?: string) => void;
  idEvent: string;
  idTicket: string;
  action?: string;
};

export const FormTicket: FC<typeProps> = ({
  handleFormDisplay,
  idEvent,
  idTicket,
  action,
}) => {
  const [dateTimeEnd, setDateTimeEnd] = useState<any>();

  const schema = yup.object().shape({
    name: yup.string().required("Campo requerido"),
    price: yup
      .number()
      .transform((value) => (isNaN(value) ? undefined : value))
      .required("Campo requerido")
      .min(0, "Ingrese un precio válido"),
    stock: yup
      .number()
      .transform((value) => (isNaN(value) ? undefined : value))
      .required("Campo requerido")
      .min(1, "Ingrese un stock válido")
      .max(2000000000, "El stock no puede ser igual o mayor a 2 billones."),
    expiration_date: yup.string().required("Campo requerido"),
    expiration_time: yup.string().required("Campo requerido"),
    init_date: yup.string().required("Campo requerido"),
    init_time: yup.string().required("Campo requerido"),
    checkin_limit_date: yup
      .string()
      .when("type", {
        is: "Free",
        then: yup.string().required("Campo requerido"),
      })
      .nullable(),
    checkin_limit_time: yup
      .string()
      .when("type", {
        is: "Free",
        then: yup.string().required("Campo requerido"),
      })
      .nullable(),
    /*.test(
            'max-limit-date',
            'La hora límite debe ser menor a la hora de inicio de evento',
            (hour) => {
              if (hour && dateTimeEnd) {
                const value = moment(dateTimeEnd.format("YYYY-MM-DD")+ ' ' + hour);
                return value < dateTimeEnd;
              }
              return true;
            })*/ is_active: yup.string().required("Campo requerido"),
    is_visible: yup.string().required("Campo requerido"),
    max_by_order: yup.string().required("Campo requerido"),
    min_by_order: yup
      .string()
      .when("max_by_order", (max_by_order: string, schema: any) => {
        return schema.test({
          test: (approvedAmount: any) => {
            if (!approvedAmount) return true;
            return parseFloat(approvedAmount) <= parseFloat(max_by_order);
          },
          message:
            "La cantidad mínima por orden no debe ser mayor a la cantidad máxima por orden",
        });
      }),
    interval: yup
      .string()
      .when("max_by_order", (max_by_order: string, schema: any) => {
        return schema.test({
          test: (approvedAmount: any) => {
            if (!approvedAmount) return true;
            return parseFloat(approvedAmount) <= parseFloat(max_by_order);
          },
          message:
            "El intérvalo no debe ser mayor a la cantidad máxima por orden",
        });
      }),
    default_qty: yup
      .string()
      .when("max_by_order", (max_by_order: string, schema: any) => {
        return schema.test({
          test: (approvedAmount: any) => {
            if (!approvedAmount) return true;
            return parseFloat(approvedAmount) <= parseFloat(max_by_order);
          },
          message:
            "El intérvalo no debe ser mayor a la cantidad máxima por orden",
        });
      }),
    type: yup.string().required("Campo requerido"),
    payment_type: yup.string().required("Campo requerido"),
    nft_ticket: yup
      .mixed()
      .test("size", "Tamaño permitido menor a 20MB", (value) => {
        if (value instanceof File) {
          return value.size <= 20000000;
        }
        return true;
      }),
    gender: yup.string().when("type", {
      is: "Free",
      then: yup.string().required("Campo requerido"),
    }),
    // tickets_quantity_included: yup.number().when("type", {
    //   is: "Mesa",
    //   then: yup
    //     .number()
    //     .transform((value) => (isNaN(value) ? undefined : value))
    //     .required("Campo requerido")
    //     .min(1, "Ingrese una cantidad válida"),
    // }),
  });

  const { role } = useContext(AuthContext);
  const [edit, setEdit] = useState(false);
  const [dateStart, setDateStart] = useState("");
  const [dateEnd, setDateEnd] = useState("");
  const [timeStart, setTimeStart] = useState("");
  const [dataPublishStart, setDataPublishStart] = useState("");
  const [timePublishStar, setTimePublishStar] = useState("");
  const [stock, setStock] = useState(0);
  const [ticketsSold, setTicketsSold] = useState(0);
  const [typeTicket, setTypeTicket] = useState("");
  const [reason, setReason] = useState("");
  const { state, dispatch } = useContext(StateContext);
  const activeOptions = [
    { value: "true", label: "Activo" },
    { value: "false", label: "Inactivo" },
  ];
  const freeOptions = [{ value: "false", label: "Oculto" }];
  const statusOptions = [
    { value: "true", label: "Visible" },
    { value: "false", label: "Oculto" },
  ];
  const ticketOptions = [
    { value: "Estandar", label: "Estandar" },
    // { value: "Mesa", label: "Mesa" },
    { value: "Free", label: "Free" },
  ];
  const methodPaymentOptions = [
    { value: "all", label: "Todos" },
    { value: "fiat", label: "Solo fiat" },
    { value: "crypto", label: "Solo crypto" },
  ];
  const genderOptions = [
    { value: "Male", label: "Hombre" },
    { value: "Female", label: "Mujer" },
    { value: "Both", label: "Ambos" },
  ];
  const defaultValues: ITicketRequest = {
    name: "",
    price: 0,
    stock: 0,
    available_stock: 0,
    max_by_order: 0,
    min_by_order: 0,
    interval: 0,
    default_qty: 0,
    init_date: "",
    soldout: "",
    init_time: "",
    expiration_date: "",
    expiration_time: "",
    payment_type: "all",
    description: "",
    is_active: true,
    is_visible: false,
    type: "Estandar",
    // event_picture: "",
    nft_ticket: "",
    event_id: "",
    points: 0,
    checkin_limit_date: "",
    checkin_limit_time: "",
    color: "",
    status: "",
    save_invitation_name: false,
    animation: null,
  };
  let max_by_order: number;

  const [initTime, setInitTime] = useState("");
  const [expirationTime, setExpirationTime] = useState("");

  const createItems = useMutation((item: FormData) =>
    TicketService.create(item)
  );
  const updateItems = useMutation((item: FormData) =>
    TicketService.update(idTicket, item)
  );
  const approveItem = useMutation(() => TicketService.approve(idTicket));
  const rejectItem = useMutation(() => TicketService.reject(idTicket, reason));

  const uploadService = useMutation((item: FormData) =>
    UploadService.ticketAnimation(item)
  );
  const getItemApi = useMutation(() => TicketService.get(idTicket));
  const getEvent = useMutation(() => eventService.get(idEvent));
  const getConfig = useMutation(() => ConfigService.get("0"));

  const form = useForm<ITicketRequest>({
    resolver: yupResolver(schema),
    defaultValues: defaultValues,
  });
  const [dataIni, setDataIni] = useState("");
  const [dataFin, setDataFin] = useState("");
  const [editPrice, setEditPrice] = useState(true);
  const [disableType, setDisableType] = useState(false);
  const [dateStartMin, setDateStartMin] = useState("");
  const statusEvent = useRef("");

  const isEditing: boolean = idTicket !== "new" && action !== "duplicate";
  const isDuplicatingTicket = action === "duplicate";

  useEffect(() => {
    getEvent.mutateAsync().then((res) => {
      console.log(res);
      const date_start = moment(res.date_start).format("YYYY-MM-DD");
      const dataEnd = moment(res.date_end).format("YYYY-MM-DD");
      setDateStart(date_start);
      setDateEnd(dataEnd);
      setTimeStart(res.time_start);
      setDateTimeEnd(moment(res.date_end));

      const datePublishStart = moment(res.publish_date_start) /*.utcOffset(0)*/
        .format("YYYY-MM-DD");

      setDataPublishStart(datePublishStart);
      setTimePublishStar(res.publish_time_start);
    });
    if (isEditing || isDuplicatingTicket) {
      setEdit(true);
      getItemApi.mutateAsync().then((res) => {
        if (res.checkin_limit_date) {
          const clt = moment(res.checkin_limit_date);
          res.checkin_limit_time = clt.format("HH:mm");
          res.checkin_limit_date = clt.format("YYYY-MM-DD");
        }
        if (res.init_date)
          res.init_date = moment(res.init_date).format("YYYY-MM-DD");
        if (res.expiration_date)
          res.expiration_date = moment(res.expiration_date).format(
            "YYYY-MM-DD"
          );
        const resetValues = res?.temp ? JSON.parse(res.temp) : res;
        form.reset(resetValues);
        if (res.tickets_sold) {
          console.log(
            "🚀 ~ file: FormTicket.tsx:278 ~ getItemApi.mutateAsync ~ res.tickets_sold:",
            res.tickets_sold
          );
          setTicketsSold(res.tickets_sold);
        }
        if (res.type == "Free") {
          setDisableType(true);
        }
        const dateStart = moment(res.init_date)
          // .utcOffset(0)
          .format("YYYY-MM-DD");
        setDataIni(dateStart);

        const dateFin = moment(res.expiration_date)
          // .utcOffset(0)
          .format("YYYY-MM-DD");

        setDateStartMin(moment(res.init_date).format("YYYY-MM-DD"));

        setInitTime(res.init_time);
        setExpirationTime(res.expiration_time);
        const stock = res.stock;
        setStock(stock);
      });
    } else {
      const dateStart = moment().utcOffset(0).format("YYYY-MM-DD");
      setDataIni(dateStart);
      form.setValue("init_date", dateStart);

      const dateFin = moment().utcOffset(0).format("YYYY-MM-DD");
      setDataFin(dateFin);
      form.setValue("expiration_date", dateFin);
      setDateStartMin(moment().format("YYYY-MM-DD"));
    }
    getConfig
      .mutateAsync()
      .then((res) => form.setValue("points", res.bombo_points));
  }, [form.reset]);

  const onSubmit = async (data: ITicketRequest) => {
    if (!data.nft_ticket) {
      dispatch({
        type: "toastError",
        payload: "No dejes el campo NFT Ticket en blanco.",
      });
      return;
    }
    if (!data.animation) {
      dispatch({
        type: "toastError",
        payload: "No dejes el campo Animación en blanco.",
      });
      return;
    }

    let flag = true;
    if (edit == false || isDuplicatingTicket) {
      if (data.init_date) {
        const dataChange = moment(data.init_date)
          .utcOffset(0)
          .format("YYYY-MM-DD");
        if (dataChange <= dateStart) {
          const dateSet = moment(dataFin).utcOffset(0).format("YYYY-MM-DD");
          if (dataChange <= dateSet) {
            setDataIni(dataChange);
          } else {
            // form.setValue("expiration_date", "");
            flag = false;
            setDataFin("");
            setDataIni(dataChange);

            dispatch({ type: "showLoaderScreen", payload: false });
            toast.error(
              "la fecha de inicio de ticket no debe de ser mayor a la fecha de expiracion"
            );
          }
        } else {
          flag = false;
          // e.target.value = "";
          setDataIni("");
          dispatch({ type: "showLoaderScreen", payload: false });
          toast.error(
            "la fecha de inicio de ticket no debe de ser mayor a la fecha de inicio del evento"
          );
        }
      }

      if (data.init_time) {
        const dateIniEvent = moment(
          moment(dataPublishStart).utcOffset(0).format("YYYY-MM-DD")
        );
        const timeStartEvent = dateIniEvent.add(timePublishStar);

        const dataChange = moment(
          moment(data.init_date).utcOffset(0).format("YYYY-MM-DD")
        );
        const timeAct = dataChange.add(data.init_time);

        console.log(timeAct);
        if (timeAct >= timeStartEvent) {
          const dateFinSet = moment(data.expiration_date).utcOffset(0);
          const dateFinAdd = dateFinSet.add(data.expiration_time);
          if (timeAct <= dateFinAdd) {
            setInitTime(data.init_time);
          } else {
            flag = false;
            setInitTime(data.init_time);
            dispatch({ type: "showLoaderScreen", payload: false });
            toast.error(
              "la hora de inicio del ticket no debe de ser mayor a la hora de expiracion"
            );
          }
        } else {
          flag = false;
          // e.target.value = "";
          dispatch({ type: "showLoaderScreen", payload: false });
          toast.error(
            "la hora de inicio del ticket no debe de ser menor a la fecha de publicacion del evento"
          );
        }
      }
    }

    if (data.expiration_date) {
      const dataChange = moment(data.expiration_date)
        .utcOffset(0)
        .format("YYYY-MM-DD");

      if (dataChange <= dateStart) {
        const dateSet = moment(dataIni).utcOffset(0).format("YYYY-MM-DD");
        if (dataChange >= dateSet) {
          setDataFin(dataChange);
        } else {
          setDataFin(dataChange);
          // form.setValue("init_date", "");
          setDataIni("");
          flag = false;

          dispatch({ type: "showLoaderScreen", payload: false });
          toast.error(
            "la fecha de expiracion del de ticket no debe de ser menor a la fecha de inicio"
          );
        }
      } else {
        flag = false;
        // e.target.value = "";
        setDataFin("");
        dispatch({ type: "showLoaderScreen", payload: false });
        toast.error(
          "la fecha de expiracion del ticket no debe de ser mayor a la fecha de inicio del evento"
        );
      }
    }

    if (data.expiration_time) {
      const dateIniEvent = moment(
        moment(dateStart).utcOffset(0).format("YYYY-MM-DD")
      );
      const timeStartEvent = dateIniEvent.add(timeStart);

      const dataChange = moment(
        moment(data.expiration_date).utcOffset(0).format("YYYY-MM-DD")
      );
      const timeAct = dataChange.add(data.expiration_time);
      console.log(timeStartEvent, timeAct);

      if (timeAct <= timeStartEvent) {
        if (initTime != "") {
          const dateIniSet = moment(dataIni).utcOffset(0);
          const dateIniAdd = dateIniSet.add(initTime);
          if (timeAct >= dateIniAdd) {
            setExpirationTime(data.expiration_time);
          } else {
            flag = false;
            setExpirationTime(data.expiration_time);
            // form.setValue("expiration_time", "");
            dispatch({ type: "showLoaderScreen", payload: false });
            toast.error(
              "la hora de expiracion de ticket no debe de ser menor a la hora de inicio del ticket"
            );
          }
        } else {
          setExpirationTime(data.expiration_time);
        }
      } else {
        flag = false;
        // e.target.value = "";
        dispatch({ type: "showLoaderScreen", payload: false });
        toast.error(
          "la hora de expiracion del ticket no debe de ser mayor a la hora de inicio del evento"
        );
      }
    }
    if (data.checkin_limit_date && data.checkin_limit_time)
      data.checkin_limit_date = moment(
        data.checkin_limit_date + " " + data.checkin_limit_time
      ).toISOString();
    data.init_date = buildISODateTimeString(data.init_date, data.init_time);
    data.expiration_date = buildISODateTimeString(
      data.expiration_date,
      data.expiration_time
    );
    if (flag == true) {
      if (!isEditing) {
        data = { ...data, event_id: idEvent };
      }

      if (role == "admin") {
        data.status = "active";
      } else {
        data.status = "pending_approval";
      }

      dispatch({ type: "showLoaderScreen", payload: true });

      if (data.animation && data.animation instanceof File) {
        // data.animation = await uploadToS3(data.animation, 'ticket_animation');
        const uploadAnimation = await uploadService.mutateAsync(
          jsonToFormData(
            { ticket_animation: data.animation },
            {
              arrayIndexes: true,
              excludeNull: true,
            }
          )
        );
        data.animation = uploadAnimation.url;
      }

      let formData = jsonToFormData(data, {
        arrayIndexes: true,
        excludeNull: true,
      });

      if (idTicket && idTicket !== "new" && !isDuplicatingTicket) {
        if (data.soldout == null || data.soldout == "") {
          data.soldout = "false";
          formData = jsonToFormData(data, {
            arrayIndexes: true,
            excludeNull: true,
          });
        }
        updateItems.mutateAsync(formData).then((res) => {
          handleFormDisplay();
          dispatch({ type: "showLoaderScreen", payload: false });
          if (role == "admin") {
            dispatch({
              type: "toastSuccess",
              payload: "Datos del Ticket actualizados",
            });
          } else {
            dispatch({
              type: "toastSuccess",
              payload: "Un administrador se encargará de revisar los cambios.",
            });
          }
        });
      } else {
        createItems.mutateAsync(formData).then((res) => {
          handleFormDisplay();
          dispatch({ type: "showLoaderScreen", payload: false });
          if (role == "admin") {
            dispatch({
              type: "toastSuccess",
              payload: "El Ticket se creo correctamente",
            });
          } else {
            dispatch({
              type: "toastSuccess",
              payload:
                "Ticket creado. Un administrador se encargará de revisarlo antes de su publicación.",
            });
          }
        });
      }
    }
  };

  const onError = (error: any) => {
    console.log("error ", error);
  };

  const approveTicket = () => {
    dispatch({ type: "showLoaderScreen", payload: true });
    approveItem.mutateAsync().then((res) => {
      handleFormDisplay();
      dispatch({ type: "showLoaderScreen", payload: false });

      dispatch({
        type: "toastSuccess",
        payload: "Ticket aprobado exitosamente.",
      });
    });
  };

  const formModal = document.getElementById("form-modal")! as HTMLDialogElement;

  const rejectTicket = () => {
    formModal.close();
    dispatch({ type: "showLoaderScreen", payload: true });
    rejectItem.mutateAsync().then((res) => {
      handleFormDisplay();
      dispatch({ type: "showLoaderScreen", payload: false });

      dispatch({
        type: "toastSuccess",
        payload: "Ticket rechazado.",
      });
    });
  };

  const todayString = moment().format("YYYY-MM-DD");

  const handleOnChangeStartDateIni = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (e.target.value) {
      const dataChange = moment(e.target.value)
        .utcOffset(0)
        .format("YYYY-MM-DD");
      setDataIni(dataChange);
    }
  };

  const handleOnChangeStartDateFin = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (e.target.value) {
      const dataChange = moment(e.target.value)
        .utcOffset(0)
        .format("YYYY-MM-DD");
      setDataFin(dataChange);
    }
  };

  const handleOnChangeStartTime = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value) {
      setInitTime(e.target.value);
    }
  };

  const handleOnChangeEndTime = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value) {
      setExpirationTime(e.target.value);
    }
  };

  useEffect(() => {
    let formTicket = form.watch("type");
    if (formTicket == "Free") {
      validateTicket();
      setTypeTicket("Free");
    } else {
      setEditPrice(true);
      setTypeTicket(formTicket);
    }
  }, [form.watch("type")]);

  const validateTicket = () => {
    form.setValue("price", 0);
    form.setValue("is_visible", false);
    form.setValue("soldout", "");
    setEditPrice(false);
  };
  let is_visible = "";

  const pendingApprovalFormState =
    role == "admin" && form.getValues("status") === "pending_approval";

  const openModal = () => {
    formModal.showModal();
  };

  const closeModal = () => {
    formModal.close();
    setReason("");
  };

  return (
    <div className="">
      <Back onClick={() => handleFormDisplay()} />
      <div className="flex flex-col gap-10  px-[18%] xl:px-[14rem]">
        <h1 className="text-center text-3xl font-bold">
          {isEditing
            ? "Editar ticket"
            : isDuplicatingTicket
            ? "Duplicar ticket"
            : "Crear nuevo ticket"}
        </h1>
        <FormProvider {...form}>
          <form onSubmit={form.handleSubmit(onSubmit, onError)}>
            <div className="mx-2 mb-6 w-full">
              <FormRadio
                // name="type"
                // label="Tipo de Ticket"
                // options={ticketOptions}
                name="type"
                label="Tipo de Ticket"
                options={ticketOptions}
                disabled={disableType || pendingApprovalFormState}
                align="around"
              />
            </div>
            <FormInput
              name="name"
              label="Nombre del ticket"
              disabled={pendingApprovalFormState}
            />
            <div className={`flex ${isEditing ? "gap-2" : "gap-10"}`}>
              <FormInput
                type="number"
                name="price"
                label="Precio del ticket"
                min="1"
                readonly={!ticketsSold && editPrice ? "" : "readonly"}
                disabled={pendingApprovalFormState}
              />
              {edit && role != "producer" && (
                <FormInput
                  type="number"
                  name="available_stock"
                  label="Stock disponible"
                  disabled={true}
                />
              )}
              <FormInput
                type="number"
                name="stock"
                label="Stock"
                min="1"
                disabled={pendingApprovalFormState}
              />
            </div>
            {typeTicket != "Free" && (
              <div className="flex gap-10">
                <FormInput
                  type="number"
                  name="min_by_order"
                  label="Cantidad mínima por orden"
                  min="0"
                  disabled={pendingApprovalFormState}
                />
                <FormInput
                  type="number"
                  name="max_by_order"
                  label="Cantidad máxima por orden"
                  min="1"
                  disabled={pendingApprovalFormState}
                />
              </div>
            )}
            {typeTicket != "Free" && (
              <div className="flex gap-10">
                <FormInput
                  type="number"
                  name="interval"
                  label="De X en X"
                  min="0"
                  disabled={pendingApprovalFormState}
                />
                <FormInput
                  type="number"
                  name="default_qty"
                  label="Cantidad por defecto"
                  min="0"
                  disabled={pendingApprovalFormState}
                />
              </div>
            )}

            {/* {typeTicket == "Mesa" && (
              <div className="flex gap-10">
                <FormInput
                  type="number"
                  name="tickets_quantity_included"
                  label="Cantidad de tickets incluídos"
                  disabled={pendingApprovalFormState}
                />
              </div>
            )} */}
            <div className="flex gap-10">
              {edit == true ? (
                <FormDateTimeField
                  name="init_date"
                  type="date"
                  label="Fecha de inicio"
                  onChangeCustom={handleOnChangeStartDateIni}
                  disabled={
                    (ticketsSold > 0 && role != "admin") ||
                    pendingApprovalFormState
                  }
                />
              ) : (
                <FormDateTimeField
                  name="init_date"
                  type="date"
                  label="Fecha de inicio"
                  onChangeCustom={handleOnChangeStartDateIni}
                  disabled={pendingApprovalFormState}
                />
              )}
              <FormDateTimeField
                name="init_time"
                type="time"
                label="Hora de inicio"
                onChangeCustom={handleOnChangeStartTime}
                disabled={
                  (ticketsSold > 0 && role != "admin") ||
                  pendingApprovalFormState
                }
              />
            </div>
            <div className="flex gap-10">
              <FormDateTimeField
                name="expiration_date"
                type="date"
                label="Fecha de expiración"
                onChangeCustom={handleOnChangeStartDateFin}
                min={dateStartMin}
                disabled={
                  (ticketsSold > 0 && role != "admin") ||
                  pendingApprovalFormState
                }
              />
              <FormDateTimeField
                name="expiration_time"
                type="time"
                label="Hora de expiración"
                onChangeCustom={handleOnChangeEndTime}
                disabled={
                  (ticketsSold > 0 && role != "admin") ||
                  pendingApprovalFormState
                }
              />
            </div>
            <FormTextArea
              name="description"
              label="Descripción del ticket"
              disabled={pendingApprovalFormState}
            />
            {typeTicket == "Free" && (
              <div className="flex gap-10">
                <FormDateTimeField
                  name="checkin_limit_date"
                  type="date"
                  label="Checkin de tickets hasta (fecha)"
                  max={dateTimeEnd ? dateTimeEnd.format("YYYY-MM-DD") : ""}
                  disabled={pendingApprovalFormState}
                />
                <FormDateTimeField
                  name="checkin_limit_time"
                  type="time"
                  label={`Checkin de tickets hasta (hora)`}
                  disabled={pendingApprovalFormState}
                />
              </div>
            )}
            <div className="mx-2 flex w-full justify-between pb-10">
              <FormRadio
                name="is_active"
                label="Estado"
                options={activeOptions}
                disabled={pendingApprovalFormState}
              />

              {typeTicket != "Free" && (
                <FormRadio
                  name="payment_type"
                  label="Metodos de pago aceptados"
                  options={methodPaymentOptions}
                  disabled={pendingApprovalFormState}
                />
              )}
            </div>
            <div className="mx-2 flex w-full justify-between pb-10">
              {typeTicket == "Free" ? (
                <FormRadio
                  name="is_visible"
                  label="Modo"
                  options={freeOptions}
                  disabled={pendingApprovalFormState}
                />
              ) : (
                <FormRadio
                  name="is_visible"
                  label="Modo"
                  options={statusOptions}
                  disabled={pendingApprovalFormState}
                />
              )}
              {typeTicket == "Free" && (
                <FormRadio
                  name="gender"
                  label="Genero"
                  options={genderOptions}
                  disabled={pendingApprovalFormState}
                />
              )}
            </div>
            <div className="mx-2 flex w-full justify-between pb-10">
              {typeTicket != "Free" ? (
                <FormCheck
                  name="soldout"
                  label="Sold-out"
                  options={[{ value: "true", label: "Sold-out" }]}
                  disabled={pendingApprovalFormState}
                />
              ) : null}
            </div>
            {typeTicket == "Free" ? (
              <div className="mx-2 flex w-full justify-between pb-10">
                <FormSwitch
                  name="save_invitation_name"
                  label="Guardar nombre de invitación"
                  disabled={pendingApprovalFormState}
                />
                {/*<FormCheck name="save_invitation_name" label="Guardar nombre de invitación" options={[{ value: 'true', label: 'Guardar nombre de invitación' }]} />*/}
              </div>
            ) : null}
            <div className="mx-2 flex w-full justify-between pb-10">
              <FormColor name={"color"} label={"Color"} />
            </div>
            <div className="justfiy-between flex gap-10 pb-10 pt-10 ">
              {/* <FormUploadImage
                name="event_picture"
                label="Imagenes del evento"
                accept="image/png, image/gif, image/jpeg"
              /> */}
              <FormCropperImage
                name="nft_ticket"
                label="NFT ticket (Tamaño: 250 x 250)"
                accept="image/png, image/gif, image/jpeg"
                initialAspectRatio={1}
                cropBoxResizable={false}
                dragMode="move"
                maxWidth={250}
                maxHeight={250}
              />
            </div>
            <div className="justfiy-between flex gap-10 pb-10 pt-10">
              <FormUpload
                name="animation"
                label="Animación"
                accept="video/mp4,video/x-m4v,video/*,image/gif"
              />
            </div>
            <div className="mb-16 flex justify-center gap-10 pt-10">
              <button
                onClick={() => handleFormDisplay()}
                type="button"
                className="rounded-full border-[2px] border-black-100 px-12 py-2 font-medium text-black-100 "
              >
                Cancelar
              </button>

              {pendingApprovalFormState ? (
                <>
                  <button
                    type="button"
                    onClick={approveTicket}
                    className="rounded-full border-[2px] border-black-100 bg-black-100 px-[5.4rem] py-2 font-medium  text-white"
                  >
                    {edit ? "Aprobar Cambios" : "Aprobar Ticket"}
                  </button>
                  <button
                    type="button"
                    onClick={openModal}
                    className="rounded-full border-[2px] border-black-100 bg-black-100 px-[5.4rem] py-2 font-medium  text-white"
                  >
                    {edit ? "Rechazar Cambios" : "Rechazar Ticket"}
                  </button>
                </>
              ) : (
                <button className="rounded-full border-[2px] border-black-100 bg-black-100 px-[5.4rem] py-2 font-medium  text-white">
                  {idTicket && idTicket !== "new" && !isDuplicatingTicket
                    ? "Guardar ticket"
                    : "Crear ticket"}
                </button>
              )}
              <dialog id="form-modal">
                <div className="flex flex-col justify-center p-6">
                  <label htmlFor="form-modal" className="mb-2">
                    Razón del rechazo:
                  </label>
                  <textarea
                    id="form-modal"
                    name="form-modal"
                    placeholder="Indicar la razón..."
                    value={reason}
                    onChange={(evt) => setReason(evt.target.value)}
                    rows={5}
                    className="mb-4"
                  />
                  <div>
                    <button
                      type="button"
                      className="rounded-full border-[2px] border-black-100 px-12 py-2 font-medium text-black-100 "
                      onClick={closeModal}
                    >
                      Cancelar
                    </button>
                    <button
                      type="button"
                      className="rounded-full border-[2px] border-black-100 bg-black-100 px-[5.4rem] py-2 font-medium  text-white"
                      onClick={rejectTicket}
                    >
                      Rechazar
                    </button>
                  </div>
                </div>
              </dialog>
            </div>
          </form>
        </FormProvider>
      </div>
    </div>
  );
};

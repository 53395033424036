import { IPaginationResponse } from "./IPagination";

export enum OfferStatus {
  PAUSED = "paused",
  ACTIVE = "active",
  IN_PROGRESS = "in_progress",
  SOLD = "sold",
  DELETED = "deleted",
  EXPIRED = "expired",
}

export interface IOfferResponse {
  id: string;
  status: OfferStatus;
  event_name: string;
  producer_name: string;
  createdAt: string;
  total: number;
  subtotal: number;
  service: number;
  ticket_name: string;
  trader_name: string;
  order_ticket_id: string;
  ticket_price: number;
}

export interface IOffersResponse extends IPaginationResponse<IOfferResponse> {}

export interface ISwapSummary {
  offersQty: number;
  soldOffersQty: number;
  totalSold: number;
  totalService: number;
}

export interface IGroupedOffersByTicket {
  ticket_name: string;
  ticket_id: string;
  offers: IOfferResponse[];
}

import { useState, useContext, useEffect } from "react";
import { FaTrashAlt } from "react-icons/fa";
import { useMutation } from "@tanstack/react-query";
import { motion } from "framer-motion";

import { TitleSection } from "../TitleSection";
import DropdownDots from "../FormElement/DropdownDots";
import { FilterPage } from "../table/FilterPage";
import { FilterQtyPage } from "../table/FilterQtyPage";
import {
  IEventReviewResponse,
  IEventReviewsResponse,
} from "../../types/IEventReview";
import { IFilterRequest } from "../../types/IFilter";
import EventReviewService from "../../services/eventReviewService";
import { AuthContext } from "../../context/AuthContext";
import { StateContext } from "../../context/StateProvider";
import { formatDate } from "../../utils/format-date";
import { PageContainer } from "../PageContainer";
import config from "../../config/variables";

type TableProducersProps = {
  handleCreateClient: () => void;
  handleSelect: (data?: IEventReviewResponse) => void;
};

type rowProps = {
  rowData: {
    date: string;
    name: string;
    email: string;
    status: string;
  };
  qty?: number;
};

const defaultResult = {
  total: 0,
  pages: 0,
  rows: [],
};

const defaultFilters = {
  limit: config.filter_default_limit,
  page: 1,
  sortBy: "createdAt",
  sortOrder: "desc",
  status: "all",
  user_role: "producer",
  query: "",
};

const columnData = [
  "Fecha",
  "Evento",
  "Cliente",
  "Email",
  "¿Cómo la pasaste?",
  "¿Cómo estuvo el evento?",
  "Acciones",
];

export const ReviewsView = () => {
  const { role } = useContext(AuthContext);
  const { state, dispatch } = useContext(StateContext);

  const getItems = useMutation(() => EventReviewService.getAll(filters));
  const deleteItems = useMutation((id: string) =>
    EventReviewService.remove(id)
  );

  const [result, setResult] = useState<IEventReviewsResponse>(defaultResult);
  const [filters, setFilters] = useState<IFilterRequest>(defaultFilters);
  const [artistColumns, setArtistColumns] = useState<Array<string>>([]);
  const [search, setSearch] = useState("");

  const searcher = async (e: any) => {
    setSearch(e.target.value);
    await setFilters({ ...filters, page: 1, query: e.target.value });
    await getItems.mutateAsync().then((res) => {
      console.log(res);
      setResult(res);
    });
  };

  let resultado = [];
  resultado = result.rows;

  useEffect(() => {
    handleFilter(filters);
  }, []);

  useEffect(() => {
    if (!state.showReview) handleFilter(filters);
  }, [state.showReview]);

  const handleFilter = async (filter: IFilterRequest) => {
    await setFilters({ ...filters, ...filter });
    await getItems.mutateAsync().then((res) => setResult(res));
  };

  const handleDelete = async (id: string) => {
    if (window.confirm("Está seguro de eliminar esta review?")) {
      await deleteItems.mutateAsync(id).then((res) => {
        getItems.mutateAsync().then((res) => setResult(res));
      });
    }
  };

  const showReview = (row: any) => {
    console.log("res table ", row);
    dispatch({ type: "setReviewData", payload: row });
    dispatch({ type: "showReview", payload: null });
  };

  const getName = (users: any) => {
    let user = users[0];
    let fullname = "Desconocido";
    if (user) {
      fullname = `${user.first_name} ${user.last_name}`;
    }
    return fullname;
  };

  return (
    <PageContainer>
      <div className="flex justify-between">
        <TitleSection>Reviews</TitleSection>
        <input
          type="text"
          placeholder="Search..."
          className="hover:text-black rounded-full border-[2px] border-black-100 px-4 py-1 font-bold  text-black-100"
          value={search}
          onChange={searcher}
        />
      </div>
      <table className="my-table-spacing h-full w-full border-separate border-spacing-y-2 gap-5 overflow-x-auto pt-5 text-left">
        <thead className=" divide-solid border ">
          <tr className=" pb-4 text-sm font-bold text-gray-100">
            {columnData.map((column, index) => (
              <th
                className="border-b-[1px] border-colorBorder pb-4"
                key={column + index}
              >
                {column}
              </th>
            ))}
            {artistColumns.map((column, index) => (
              <th
                className="border-b-[1px] border-colorBorder pb-4"
                key={column + index}
              >
                {column}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {resultado.map((row, i) => (
            <motion.tr
              key={i}
              layout
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              initial={{ opacity: 0.2 }}
              transition={{ type: "spring", stiffness: 100, bounce: 0 }}
              className={`h-16  rounded-xl text-start font-medium outline outline-[1px] outline-colorBorder`}
            >
              <td
                onClick={() => showReview(row)}
                className="cursor-pointer pl-4"
              >
                {formatDate(row.date)}
              </td>
              <td
                onClick={() => showReview(row)}
                className="cursor-pointer pl-4"
              >
                {row.event ? row.event.name : ""}
              </td>
              <td
                onClick={() => showReview(row)}
                className="cursor-pointer pl-4"
              >
                {getName(row.user)}
              </td>
              <td
                onClick={() => showReview(row)}
                className="cursor-pointer pl-4"
              >
                {row.user[0]?.email}
              </td>
              <td
                onClick={() => showReview(row)}
                className="cursor-pointer pl-4"
              >
                {row.personal_experience || "-"}
              </td>
              <td
                onClick={() => showReview(row)}
                className="cursor-pointer pl-4"
              >
                {row.event_rating || "-"}
              </td>
              <td className="pl-4">
                {role == "admin" && (
                  <DropdownDots
                    options={[
                      {
                        title: "Eliminar",
                        action: () => handleDelete(row.id),
                        icon: <FaTrashAlt size={23} />,
                      },
                    ]}
                  />
                )}
              </td>
            </motion.tr>
          ))}
        </tbody>
      </table>
      <div className="flex justify-between pt-10">
        <FilterPage
          handleFilter={handleFilter}
          total={result.total}
          limit={filters.limit}
          status={filters.status}
          currentPage={filters.page}
        />
        <FilterQtyPage handleFilter={handleFilter} status={filters.status} />
      </div>
    </PageContainer>
  );
};

import api from "./api";
import { IAuthRequest, IAuthResponse } from "../types/IAuth";
import authHeader from "./authHeader";

const login = async (user: IAuthRequest) => {
  const response = await api.post<IAuthResponse>(`auth/login`, user);
  return response;
};

const googleLogin = async (google_token: string, coordinates: any) => {
  const lat = coordinates?.lat ?? 0;
  const lng = coordinates?.lng ?? 0;
  const response = await api.post<IAuthResponse>(
    `auth/google_sign_in`,
    {
      google_token,
    },
    {
      headers: {
        "x-lat": `${lat}`,
        "x-lng": `${lng}`,
      },
    }
  );
  return response;
};

const getUser = async () => {
  const response = await api.post<IAuthResponse>(
    `auth/user`,
    {},
    {
      headers: authHeader(),
    }
  );
  return response;
};

const logout = async () => {
  /* const response = await api.get<any>(`auth/logout`);
  return response; */
};

const AuthService = {
  login,
  googleLogin,
  getUser,
  logout,
};

export default AuthService;

/* eslint-disable react-hooks/exhaustive-deps */
import { Fragment, useState } from "react";
import { GrClose } from "react-icons/gr";

type propsType = {
  handleCloseModal: () => void;
  handleUpload: (text: string) => void;
};

export const ModalLink = ({ handleCloseModal, handleUpload }: propsType) => {
  const [text, setText] = useState("");
  return (
    <Fragment>
      <div className="fixed inset-0 z-10 overflow-y-auto">
        <div
          className="fixed inset-0 h-full w-full backdrop-blur-md"
          style={{ backgroundColor: "rgba(0, 0, 0, 0.10)" }}
          onClick={handleCloseModal}
        ></div>
        <div className="flex min-h-screen items-center px-[20px] py-8">
          {/*container */}
          <div className="relative mx-auto my-6 w-[800px]">
            {/*content*/}

            <div className="relative flex w-full flex-col rounded-[12px] border-0 bg-white px-7 py-12 shadow-lg outline-none focus:outline-none">
              <div className="mb-2 flex items-start justify-between rounded-t">
                <h3 className="text-[22px] font-black leading-10">
                  Ingresar link
                </h3>
                <GrClose
                  size={20}
                  className="cursor-pointer"
                  onClick={handleCloseModal}
                />
              </div>
              <div className="mx-0 my-[20px] mb-[40px]">
                <input
                  className="w-full rounded-lg border border-colorBorder p-3 pl-8 font-bold text-black-100  "
                  value={text}
                  placeholder="https://www.youtube.com/"
                  onChange={(e) => setText(e.target.value)}
                />
              </div>

              <button
                onClick={() => handleUpload(text)}
                className="shadow-[0px 4px 12px rgba(248, 176, 240, 0.14)] mx-[6.500%] rounded-[25px] bg-[#000000] py-[15px] text-[16px] font-[900] leading-[20px] tracking-[-0.04em] text-white"
              >
                Confirmar
              </button>
            </div>
            {/*content*/}
          </div>
          {/*container*/}
        </div>
      </div>
    </Fragment>
  );
};

import React from "react";

export const Artists = ({
  name = "Marcos Foglia",
  value = "",
  nickname = "",
  handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {},
  isSelected = false,
}) => {
  return (
    <label htmlFor={name} className="w-[20rem]">
      <div className="flex w-full items-center gap-5 rounded-2xl border border-colorBorder py-5 pl-4 font-medium ">
        <input
          type="checkbox"
          name="artist_ids[]"
          id={name}
          value={value}
          checked={isSelected}
          onChange={handleOnChange}
          className="rounded-full p-2 checked:bg-black-100   focus:ring-transparent active:bg-black-100"
        />
        {name}
      </div>
    </label>
  );
};

import { useState, Dispatch, SetStateAction } from "react";
import { DateRange } from "react-day-picker";
import { format } from "date-fns";

import { DatePickerWithRange } from "../../ui/datepicker";
import { IFilterRequest } from "../../../types_new/IFilter";
import { updateUrl } from "../../../utils_new/updateUrl";

interface FilterDateProps {
  filters: IFilterRequest;
  setFilters: Dispatch<SetStateAction<IFilterRequest>>;
}

export const FilterDate = ({ filters, setFilters }: FilterDateProps) => {
  const [date, setDate] = useState<DateRange | undefined>(undefined);

  const handleDateRangeChange = async (date: DateRange | undefined) => {
    setDate(date);

    const { from, to } = date || {};
    if ((!from && to) || (from && !to)) return;

    const filtersToUpdate = {
      page: 1,
      date_from: from ? format(from, "yyyy-MM-dd") : undefined,
      date_to: to ? format(to, "yyyy-MM-dd") : undefined,
    };

    updateUrl(filtersToUpdate);
    setFilters({ ...filters, ...filtersToUpdate });
  };

  return (
    <DatePickerWithRange date={date} onDateChange={handleDateRangeChange} />
  );
};

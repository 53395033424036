import api from "./api";
import authHeader from "./authHeader";
import { IUploadRequest, IUploadResponse } from "../types/IUpload";

const ticketAnimation = async (item: FormData) => {
  const response = await api.post<IUploadResponse>(
    `upload/ticket_animation`,
    item,
    {
      headers: {
        "Content-Type": "multipart/form-data",
        ...authHeader(),
      },
    }
  );
  return response.data;
};

const UploadService = {
  ticketAnimation,
};

export default UploadService;

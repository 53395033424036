import { Fragment, useContext } from "react";
import { IRrppResponse } from "../../../../../types/IRrpp";
import "./CardSeller.css";
import { PictureAndName } from "../../../components/PictureAndName/PictureAndName";
import { useParams } from "react-router";
import { useMutation } from "@tanstack/react-query";
import TeamService from "../../../../../services/teamService";
import jsonToFormData from "@ajoelp/json-to-formdata";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../../../../context/AuthContext";

type propsType = {
  producer_producer_id?: string;
  data: IRrppResponse;
  handleSelectRrpp: (item: IRrppResponse) => void;
};

export const CardSeller = ({ data, handleSelectRrpp }: propsType) => {
  const auth = useContext(AuthContext);

  const navigate = useNavigate();
  const { leader_id, producer_id, producer_producer_id } = useParams();
  const updateItems = useMutation((item: FormData) =>
    TeamService.update("cms", item)
  );

  const handleSubmit = () => {
    const dataBuilder: any = {
      user_id: data.id,
      leader_id: leader_id,
      producer_producer_id: producer_producer_id || auth.producer?.id,
      team_role: "seller",
    };

    let formData = jsonToFormData(dataBuilder, {
      arrayIndexes: true,
      excludeNull: true,
    });
    const redirectSuffix = "?tab=sellers";
    updateItems
      .mutateAsync(formData)
      .then((res) => {
        if (auth.user.user_role == "admin") {
          navigate(
            `/admin/producers/${producer_id}/producer_producer/${producer_producer_id}/rrpp/` +
              leader_id +
              redirectSuffix
          );
        } else {
          navigate("/client/rrpp/" + leader_id + redirectSuffix);
        }
        // navigate('/client/rrpp/'+leader_id+redirectSuffix)
      })
      .catch((reason) => {});
  };

  return (
    <Fragment>
      <table id="SellerContainer" className="w-full table-auto">
        <thead>
          <tr>
            <th align="left">
              <p className="ml-2 font-sans text-[14px] font-[500] leading-[21px] text-[#8083A3]">
                Nombre
              </p>
            </th>
            <th align="left">
              <p className="font-sans text-[14px] font-[500] leading-[21px] text-[#8083A3]">
                Email
              </p>
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <div className="ml-2">
                <PictureAndName
                  profilePicture={data.profile_picture}
                  firstName={data.first_name}
                  lastName={data.last_name}
                />
              </div>
            </td>
            <td>
              <span className="text-[#7B7B7B]">{data.email}</span>{" "}
            </td>
            <td>
              {leader_id ? (
                <button
                  onClick={() => handleSubmit()}
                  className="ml-auto mr-2 flex items-center justify-center rounded-full border-[2px] bg-black-100 px-3 py-2 pl-5 pr-5 text-xs text-white"
                >
                  Agregar
                </button>
              ) : (
                <button
                  onClick={() => handleSelectRrpp(data)}
                  className="ml-auto mr-2 flex items-center justify-center rounded-full border-[2px] bg-black-100 px-3 py-2 pl-5 pr-5 text-xs text-white"
                >
                  Crear Vendedor
                </button>
              )}
            </td>
          </tr>
        </tbody>
      </table>
    </Fragment>
  );
};

import { FC } from "react";
import { Link } from "react-router-dom";

import { ConditionalWrapper } from "../ConditionalWrapper/ConditionalWrapper";

type Field = {
  title: string;
  value?: string;
  linkTo?: string;
};

type DetailsInfoProps = {
  fields: Field[];
  sectionTitle: string;
  fallbackMessage?: string;
};

export const DetailsInfo: FC<DetailsInfoProps> = ({
  fields,
  sectionTitle,
  fallbackMessage = "",
}) => {
  const detailsContent =
    fields.length > 0 ? (
      <div className="mt-[15px] grid w-full grid-cols-1 gap-2 gap-3 rounded-[12px] border-[1px] border-solid border-[#DDDDDD] px-[34px] pb-[34px] pt-[38px] md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
        {fields.map((field) => (
          <div className="p-3" key={field.title}>
            <h3 className="font-sans text-[14px] font-[700] leading-[21px] text-[#8083A3]">
              {field.title}
            </h3>
            <ConditionalWrapper
              condition={!!field.linkTo}
              wrapper={(children) => (
                <Link to={field.linkTo!} className="underline">
                  {children}
                </Link>
              )}
            >
              <p
                className={`w-full overflow-hidden break-all font-sans text-[14px] font-[400] leading-[21px] ${
                  !!field.linkTo ? "text-blue-500 underline" : "text-[#000000]"
                }]`}
              >
                {field.value || "-"}
              </p>
            </ConditionalWrapper>
          </div>
        ))}
      </div>
    ) : (
      <p>{fallbackMessage}</p>
    );

  return (
    <section className="my-8">
      <h2 className="font-sans text-[16px] font-[700] leading-[24px]">
        {sectionTitle}
      </h2>

      {detailsContent}
    </section>
  );
};

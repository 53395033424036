import { useContext, useEffect, useRef, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";

import { Back } from "../../../Back";
import { PageContainer } from "../../../PageContainer";
import { OrderData } from "./orderData";
import OrdesService from "../../../../services/orderService";
import { IOrderResponse } from "../../../../types/IOrder";
import { StateContext } from "../../../../context/StateProvider";

export const SelectedOrder = () => {
  const { order_id } = useParams();
  const navigate = useNavigate();
  const { dispatch } = useContext(StateContext);
  const [data, setData] = useState<IOrderResponse>();

  useEffect(() => {
    loadOrder();
  }, [order_id]);

  const getItem = useMutation(() => OrdesService.get(order_id || ""));

  const loadOrder = () => {
    getItem.mutateAsync().then((res) => {
      dispatch({ type: "setOrder", payload: res });
      dispatch({ type: "setOrdersTickets", payload: res.order_tickets });
      setData(res);
    });
  };

  return (
    <PageContainer>
      {window.history.length > 1 && <Back onClick={() => navigate(-1)} />}
      {data && <OrderData data={data} loadOrder={loadOrder} />}
    </PageContainer>
  );
};

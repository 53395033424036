import React, { useEffect, useState } from "react";
import { TeamLeader } from "./TeamLeader";
import { useContext } from "react";
import { StateContext } from "../../../../context/StateProvider";
import { Footer } from "../../../footer/Footer";
import { GrClose } from "react-icons/gr";
import { GoSearch } from "react-icons/go";
import { InputSearch } from "../../../rrpp/components/InputSearch/InputSearch";
import { ITeamResponse } from "../../../../types/ITeam";
import { useMutation } from "@tanstack/react-query";
import EventService from "../../../../services/eventService";
import { useParams } from "react-router-dom";
import jsonToFormData from "@ajoelp/json-to-formdata";

export const PopUpTeam = ({}) => {
  const { state, dispatch } = useContext(StateContext);
  const [teamIds, setTeamIds] = useState<Array<string>>([]);
  const { event_id = "" } = useParams();
  const updateItems = useMutation((item: FormData) =>
    EventService.update(event_id, item)
  );

  const addTeamMember = () => {
    dispatch({ type: "addTeamMember", payload: null });
  };

  const showTeam = () => {
    dispatch({ type: "showTeam", payload: null });
  };

  const setMembers = () => {
    dispatch({ type: "setMembers", payload: teamIds });
  };

  const handleOnChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const index = teamIds.indexOf(value);
    let teamIdsNew: Array<any> = [];
    if (index > -1) {
      teamIdsNew = [...teamIds.slice(0, index), ...teamIds.slice(index + 1)];
    } else {
      teamIdsNew = [...teamIds, ...[value]];
    }
    setTeamIds(teamIdsNew);
    console.log("handleOnChange", teamIdsNew);
  };

  const matchMultipleLeaderSeller = () => {
    const membersIDS: Array<any> = [];
    const memberRepeat: Array<any> = [];
    const selectedMembers = state.availableMembers.filter((m) =>
      teamIds.includes(m.id)
    );
    const producerProducerId =
      selectedMembers.length > 0
        ? selectedMembers[0].producer_producer_id
        : null;
    for (const leader of selectedMembers) {
      const sellers = leader.sellers.filter(
        (s: ITeamResponse) => s.producer_producer_id == producerProducerId
      );
      for (const seller of sellers) {
        const finded = membersIDS.find(
          (m) => m.seller_user_id == seller.user_id
        );
        if (finded) {
          let isNew = true;
          for (let i = 0; i < memberRepeat.length; i++) {
            if (memberRepeat[i].seller.user_id == finded.seller_user_id) {
              const repeatLeader = memberRepeat[i].leaders.find(
                (l: ITeamResponse) => l.id == leader.id
              );
              if (!repeatLeader) {
                memberRepeat[i].leaders.push(leader);
              }
              isNew = false;
            }
          }
          if (isNew && seller.user && seller.user.length > 0) {
            if (finded.leader?.user_id != leader.user_id) {
              memberRepeat.push({
                seller: seller,
                leaders: [finded.leader, leader],
              });
            }
          }
        } else {
          membersIDS.push({ seller_user_id: seller.user_id, leader: leader });
        }
      }
    }
    console.info("ids", membersIDS);
    console.info("repeat", memberRepeat);
    if (memberRepeat.length > 0) {
      dispatch({ type: "membersToAssign", payload: memberRepeat });
      return true;
    } else {
      dispatch({ type: "membersToAssign", payload: memberRepeat });
      return false;
    }
  };

  const handleSave = () => {
    if (!state.showTeam) showTeam();
    addTeamMember();
    setMembers();
    console.log("saving", state);
    if (matchMultipleLeaderSeller()) {
      dispatch({
        type: "showAssignLeader",
        payload: state.save ? { save: state.save } : null,
      });
    } else {
      if (state.save) {
        saveTeamMembers();
      }
    }
  };

  const saveTeamMembers = () => {
    dispatch({ type: "showLoaderScreen", payload: true });

    setTimeout(() => {
      console.log("state.members", state);
      let dataForm = {
        team_ids: teamIds,
      };

      let formData = jsonToFormData(dataForm, {
        arrayIndexes: true,
        excludeNull: true,
      });

      updateItems
        .mutateAsync(formData)
        .then((res) => {
          dispatch({ type: "showLoaderScreen", payload: false });
          dispatch({ type: "reloadEvent", payload: new Date().getTime() });
        })
        .catch((reason) => {
          dispatch({ type: "showLoaderScreen", payload: false });
        });
    }, 100);
  };

  const isSelected = (value: string) => {
    return teamIds.includes(value);
  };

  useEffect(() => {
    setTeamIds(
      state.members.map((item) => {
        return item.id;
      })
    );
  }, []);

  const [query, setQuery] = useState("");
  const [leaders, setLeaders] = useState<Array<ITeamResponse>>(
    state.availableMembers
  );
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(e.target.value);
    if (state.availableMembers) {
      const results = state.availableMembers.filter((leader) => {
        if (e.target.value === "") return state;
        const fullName =
          leader.user[0]?.first_name + " " + leader?.user[0].last_name;
        return fullName.toLowerCase().includes(e.target.value.toLowerCase());
      });
      setLeaders(results);
    }
  };

  const handleChangeSelectAll = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) setTeamIds(leaders.map((l) => l.id));
    else setTeamIds([]);
  };

  return (
    <div
      className="fixed  z-50 flex h-screen w-screen items-center justify-center bg-black-100/30 backdrop-blur-md"
      style={{ minHeight: "calc(100vh)" }}
    >
      <div
        className="relative mx-[10%] w-full rounded-2xl bg-white "
        style={{ height: "calc(100vh - 15%)" }}
      >
        <div className="h-full">
          <div className="flex h-[72px] items-center justify-between px-8  pb-4 pt-5 text-center">
            <div />
            <h3 className=" text-lg font-bold">
              Asignación de equipo para evento
            </h3>
            <button className="" onClick={addTeamMember}>
              <GrClose />
            </button>
          </div>
          <div className="mx-[10%]" style={{ height: "calc(100% - 174px)" }}>
            <div className=" border-b border-colorBorder"> </div>
            <div className="h-[182px] pt-12">
              <InputSearch
                value={query}
                onChange={() => {}}
                handleChange={handleChange}
                content="Buscar un team leader"
              />
              <label htmlFor={"all"} className="w-[20rem]">
                <div className="flex w-full items-center gap-5 rounded-2xl py-5 pl-4 font-medium ">
                  <input
                    type="checkbox"
                    name="team_ids[]"
                    id={"all"}
                    onChange={handleChangeSelectAll}
                    className="ml-auto rounded-full p-2 checked:bg-black-100 focus:ring-transparent active:bg-black-100"
                  />
                  Seleccionar todos
                </div>
              </label>
              <p className="mt-6 pb-4 font-bold">Team leaders</p>
            </div>

            <div
              className=" flex-col flex-wrap overflow-auto px-1 pt-4"
              style={{ height: "calc(100% - 182px)" }}
            >
              <form
                className="flex flex-col items-center justify-center gap-5"
                onSubmit={(e) => {
                  e.preventDefault();
                }}
              >
                <div className="flex flex-wrap justify-between gap-3 ">
                  {leaders.map((item, index) => (
                    <TeamLeader
                      key={index}
                      name={
                        item.user[0]?.first_name + " " + item.user[0]?.last_name
                      }
                      value={item.id}
                      handleOnChange={handleOnChange}
                      isSelected={isSelected(item.id)}
                    />
                  ))}
                </div>
                {state.availableMembers.length == 0 && (
                  <p className="text-center">No existen miembros disponibles</p>
                )}
              </form>
            </div>
          </div>
          <div className="flex h-[102px] w-full items-center justify-center border-colorBorder text-center">
            <button
              onClick={() => handleSave()}
              type="submit"
              className="m-auto w-72 rounded-full border-[2px] border-black-100 bg-black-100 px-12 py-2 font-medium text-white"
            >
              Guardar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

import { CSVResponse } from "../../components/events/selectedEvent/csv/CSVResponse";
import api from "../api";
import authHeader from "../authHeader";

export const inviteViaCSV = (file: Blob, ticket_id: string) => {
  var formData = new FormData();
  formData.append("file", file);
  return api.post<CSVResponse>(
    `events/send_tickets_invitations/csv/${ticket_id}`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/formdata",
        ...authHeader(),
      },
    }
  );
};

const InvitationsService = {
  inviteViaCSV,
};

export default InvitationsService;

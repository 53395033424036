import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { INewResponse } from "../../../types/INews";
import { useMutation } from "@tanstack/react-query";
import NewsService from "../../../services/newsService";
import { setFormErrorsFromServer } from "../../../utils/form";
import jsonToFormData from "@ajoelp/json-to-formdata";
import { Back } from "../../Back";
import FormInput from "../../FormElement/FormInput";

const schema = yup.object().shape({
  name: yup.string().required("Campo requirido"),
});

const defaultValues = {
  title: "",
  description: "",
  active: "",
  status: "",
  picture: "",
};

type newNewsProps = {
  handleCreateNews: () => void;
  newsID: string;
};

export const NewNews = ({ handleCreateNews, newsID }: newNewsProps) => {
  const [disabledSubmit, setDisabledSubmit] = useState(false);

  const form = useForm<INewResponse>({
    resolver: yupResolver(schema),
    // defaultValues: {...defaultValues},
  });
  const createItems = useMutation((item: FormData) => NewsService.create(item));
  const updateItems = useMutation((item: FormData) =>
    NewsService.update(newsID || "", item)
  );
  const onSubmit = async (data: INewResponse) => {
    setDisabledSubmit(true);
    let formData = jsonToFormData(data, {
      arrayIndexes: true,
      excludeNull: true,
    });
    if (newsID && newsID.length) {
      updateItems
        .mutateAsync(formData)
        .then((res) => {
          setDisabledSubmit(false);
          handleCreateNews();
        })
        .catch((reason) => {
          setDisabledSubmit(false);
          setFormErrorsFromServer(reason, form.setError);
        });
    } else {
      createItems
        .mutateAsync(formData)
        .then((res) => {
          setDisabledSubmit(false);
          handleCreateNews();
        })
        .catch((reason) => {
          setDisabledSubmit(false);
          setFormErrorsFromServer(reason, form.setError);
        });
    }
  };

  const getItem = useMutation(() => NewsService.get(newsID || ""));
  useEffect(() => {
    console.info("newsID", newsID);
    if (newsID) {
      getItem.mutateAsync().then((res) => form.reset(res));
    }
  }, [form.reset]);

  const onError = (error: any) => {
    console.log("error ", error);
  };

  return (
    <div>
      <Back onClick={handleCreateNews} />
      <div className="flex flex-col gap-10 xl:px-[23rem]">
        <h1 className="text-center text-3xl font-bold">
          {newsID ? "Editar" : "Crear nuevo"} Novedades
        </h1>
        <FormProvider {...form}>
          <form
            onSubmit={form.handleSubmit(onSubmit, onError)}
            className="flex flex-col gap-10"
          >
            <FormInput name="name" type="text" label="Nombre" />
            <div className="mb-16 flex justify-center gap-10 pt-10">
              <button
                type="submit"
                className="rounded-full border-[2px] border-black-100 bg-black-100 px-[5.4rem] py-2 font-medium  text-white"
              >
                {newsID ? "Guardar" : "Crear"} Novedades
              </button>
            </div>
          </form>
        </FormProvider>
      </div>
    </div>
  );
};

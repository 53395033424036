import logo from "../../assets/logoFooter.svg";
import { BsFacebook, BsInstagram, BsTwitter } from "react-icons/bs";

export const Footer = () => {
  return (
    <footer className="bottom-0 flex h-[4.5rem] w-full items-center justify-between bg-black-100 px-24">
      <picture className="w-28">
        <img src={logo} width="100%" />
      </picture>
      <div className="flex gap-5 text-xl text-white">
        <BsTwitter className="cursor-pointer" />
        <BsFacebook className="cursor-pointer" />
        <BsInstagram className="cursor-pointer" />
      </div>
    </footer>
  );
};

import { useMutation } from "@tanstack/react-query";
import { motion } from "framer-motion";
import React, { useEffect, useState, FC, useContext } from "react";
import { FaEdit, FaStar, FaTrashAlt } from "react-icons/fa";
import { GoGraph, GoSearch } from "react-icons/go";
import { toast, ToastContainer } from "react-toastify";
import ArtistService from "../../services/artistService";
import { IArtistsResponse } from "../../types/IArtist";
import { IFilterRequest } from "../../types/IFilter";
import { IUserResponse } from "../../types/IUser";
import DropdownDots from "../FormElement/DropdownDots";
import { FilterPage } from "../table/FilterPage";
import { FilterQtyPage } from "../table/FilterQtyPage";
import { FilterSection } from "../table/FilterSection";
import { TitleSection } from "../TitleSection";
import ISortOption from "../../types/ISortOption";
import { FilterOrder } from "../table/FilterOrder";
import { formatDateTime } from "../../utils/format-date";
import { PageContainer } from "../PageContainer";
import { StateContext } from "../../context/StateProvider";
import { useNavigate } from "react-router-dom";
import config from "../../config/variables";

const defaultResult = {
  total: 0,
  pages: 0,
  rows: [],
};
const defaultFilters = {
  limit: config.filter_default_limit,
  page: 1,
  status: "all",
  is_bombo_artist: "all",
  sortBy: "createdAt",
  sortOrder: "desc",
  query: "",
};

type propsTable = {
  handleCreateorEdit: (id: string) => void;
  handleSelectItem: (data: IUserResponse) => void;
};

export const TableArtists: FC<propsTable> = ({
  handleCreateorEdit,
  handleSelectItem,
}) => {
  const { state, dispatch } = useContext(StateContext);
  const navigate = useNavigate();
  const [result, setResult] = useState<IArtistsResponse>(defaultResult);
  const [filters, setFilters] = useState<IFilterRequest>(defaultFilters);
  console.log(filters);
  const [search, setSearch] = useState("");
  const getItems = useMutation(() => ArtistService.getAll(filters));

  const getCsv = useMutation(() => ArtistService.getCsv({}));

  let paramsFilters: any = { status: "all", sortBy: "createdAt" };

  const deleteItems = useMutation(
    (id: string) => ArtistService.deleteAccount(id),
    {
      onSuccess(data: any) {
        toast.success(data.message);
      },
    }
  );

  const handleFilter = async (filter: IFilterRequest) => {
    await setFilters({ ...filters, ...filter });
    await getItems.mutateAsync().then((res) => setResult(res));
  };

  const handleFilterSection = async (filter: string) => {
    await setFilters({ ...filters, page: 1, is_bombo_artist: filter });
    await getItems.mutateAsync().then((res) => {
      console.log(res);
      setResult(res);
    });
  };

  const handleSortSection = async (sortBy: string) => {
    const itemSort = sortOptions.find((s) => s.value == sortBy);
    paramsFilters = { ...filters, page: 1, sortBy: sortBy };
    if (itemSort) paramsFilters["sortOrder"] = itemSort.sortOrder;
    await setFilters(paramsFilters);
    await getItems.mutateAsync().then((res) => setResult(res));
  };

  const handleDeleteAccount = async (id: string) => {
    if (window.confirm("Esta seguro de eliminar este artista?")) {
      await deleteItems.mutateAsync(id).then((res) => {
        getItems.mutateAsync().then((res) => setResult(res));
      });
    }
  };

  const handleReviews = async (id: string) => {
    navigate(`/admin/artists/${id}/reviews`);
  };

  let resultado = [];
  resultado = result?.rows ? result?.rows : [];

  useEffect(() => {
    if (search != "") {
      const delayDebounceFn = setTimeout(() => {
        loadData();
      }, 400);
      return () => clearTimeout(delayDebounceFn);
    } else {
      loadData();
    }
  }, [search]);

  const loadData = () => {
    dispatch({ type: "showLoaderScreen", payload: true });
    getItems.mutateAsync().then((res) => {
      dispatch({ type: "showLoaderScreen", payload: false });
      setResult(res);
    });
  };

  const searcher = async (e: any) => {
    setSearch(e.target.value);
    paramsFilters = { ...filters, query: e.target.value };
    await setFilters({ ...filters, page: 1, query: e.target.value });
  };

  const columnData = [
    "Fecha de alta",
    "Nombre",
    "Nickname",
    "Email",
    "Link",
    "Verificado por Bombo",
    "",
  ];

  const rowData = {
    date: "19 Jun 2021 - 12:43:22",
    name: "Martins SRL",
    clients: 6,
    email: "administracion@gmail.com",
    status: "Activo",
    link: "www.bombo.com/artista",
  };

  const filterSectionProps = [
    { title: "Todos los artistas", id: "all" },
    { title: "Artistas Bombo", id: "true" },
    { title: "Artistas", id: "false" },
  ];

  const handleCsv = async () => {
    await getCsv.mutateAsync().then((response) => {
      const url = window.URL.createObjectURL(new Blob([response]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "artists.csv");
      document.body.appendChild(link);
      link.click();
    });
  };

  const getFilterSection = () => {
    return filterSectionProps;
  };

  const sortOptions: Array<ISortOption> = [
    { value: "createdAt", text: "Fecha", sortOrder: "desc" },
    { value: "first_name", text: "Nombre", sortOrder: "asc" },
  ];

  const [stateOrder, setState] = useState("asc");
  const handleChangeState = async (state: string) => {
    setState(state);
    await setFilters({ ...filters, sortOrder: state });
    loadData();
  };

  return (
    <PageContainer>
      <div className="relative">
        <TitleSection>Artistas</TitleSection>
        <div className="absolute -top-0 right-0 ">
          <button
            onClick={() => handleCreateorEdit("")}
            className="rounded-full border-[2px] border-black-100 px-12 py-2 font-bold text-black-100 hover:bg-black-100 hover:text-white"
          >
            Crear nuevo Artista
          </button>
        </div>
      </div>

      <div className="mb-7 flex justify-between border-b-[1px] border-colorBorder pr-5 pt-10  ">
        <FilterSection
          filterSectionProps={getFilterSection()}
          handleFilter={handleFilterSection}
        />
        <div className="flex items-center justify-end gap-5 ">
          <button
            className="flex items-center justify-center text-[#3E7EFF]"
            onClick={handleCsv}
          >
            <GoGraph className="mr-1" />
            Exportar a CSV
          </button>
          <input
            type="text"
            placeholder="Search..."
            className="hover:text-black rounded-full border-[2px] border-black-100 px-4 py-1 font-bold  text-black-100"
            value={search}
            onChange={searcher}
          />
          <FilterOrder
            setSort={handleSortSection}
            options={sortOptions}
            handleChangeState={handleChangeState}
            state={stateOrder}
          />
        </div>
      </div>

      <table className="my-table-spacing h-full w-full border-separate border-spacing-y-2 gap-5 overflow-x-auto pt-5 text-left">
        <thead className=" divide-solid border ">
          <tr className=" pb-4 text-sm font-bold text-gray-100">
            {columnData.map((column, index) => (
              <th
                className="border-b-[1px] border-colorBorder pb-4"
                key={column + index}
              >
                {column}
              </th>
            ))}
            {/* <th className="border-b-[1px] border-colorBorder pb-4">
                  <GoSearch className="cursor-pointer text-gray-100 ml-auto" size={16} />
                </th> */}
          </tr>
        </thead>
        <tbody>
          {resultado?.length ? (
            resultado.map((_, i) => (
              <motion.tr
                key={_.id}
                layout
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                initial={{ opacity: 0.2 }}
                transition={{ type: "spring", stiffness: 100, bounce: 0 }}
                className={`h-16  rounded-xl text-start font-medium outline outline-[1px] outline-colorBorder`}
              >
                <td className="pl-4">{formatDateTime(_.createdAt)}</td>
                <td className="pl-4">{`${_.first_name} ${_.last_name}`}</td>
                <td className="pl-4">{`${_.nickname}`}</td>
                <td className="flex h-full items-center gap-2 pl-4 ">
                  {_.email}
                </td>
                <td className="">
                  <div className="flex items-center gap-2 pl-4">
                    {rowData.link}
                  </div>
                </td>
                <td className="pl-4">
                  {(_.status == "active" || _.status == "verified") && (
                    <div className="flex w-32 items-center justify-center gap-3 rounded-full border-[1px] border-colorBorder  py-2 text-center ">
                      <div className="rounded-full  bg-green p-[.35rem] " />
                      <p className="capitalize">Activo</p>
                    </div>
                  )}
                  {_.status == "pending" && (
                    <div className="flex w-60 items-center justify-center gap-3 rounded-full border-[1px] border-colorBorder  py-2 text-center ">
                      <div className="rounded-full  bg-[#F4BE5E] p-[.35rem] " />
                      <p className="capitalize">Pendiente de aprobación</p>
                    </div>
                  )}
                  {_.status == "paused" && (
                    <div className="flex w-32 items-center justify-center gap-3 rounded-full border-[1px] border-colorBorder  py-2 text-center ">
                      <div className="rounded-full  bg-red p-[.35rem] " />
                      <p className="capitalize">Pausado</p>
                    </div>
                  )}
                </td>
                <td>
                  <DropdownDots
                    options={[
                      {
                        title: "Ver Reviews",
                        action: () => handleReviews(_.id),
                        icon: <FaStar size={23} />,
                      },
                      {
                        title: "Editar",
                        action: () => handleCreateorEdit(_.id),
                        icon: <FaEdit size={23} />,
                      },
                      {
                        title: "Eliminar",
                        action: () => handleDeleteAccount(_.id),
                        icon: <FaTrashAlt size={23} />,
                      },
                    ]}
                  />
                </td>
              </motion.tr>
            ))
          ) : (
            <></>
          )}
        </tbody>
      </table>
      <div className="flex justify-between pt-10">
        <FilterPage
          handleFilter={handleFilter}
          total={result.total}
          limit={filters.limit}
          status={filters.status}
          currentPage={filters.page}
        />
        <FilterQtyPage handleFilter={handleFilter} status={filters.status} />
      </div>
    </PageContainer>
  );
};

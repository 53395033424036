import React, { useContext, useEffect, useState, ChangeEvent } from "react";
import { GrClose, GrZoomOut, GrZoomIn } from "react-icons/gr";
import { StateContext } from "../../../context/StateProvider";
import { Cropper } from "react-cropper";
import "cropperjs/dist/cropper.css";

export const ModalCropper = ({}) => {
  const { state, dispatch } = useContext(StateContext);
  const [image, setImage] = useState<string>();
  const [width, setWidth] = useState(1);
  const [cropper, setCropper] = useState<any>();

  const [showOptions, setShowOptions] = useState(false);
  const handleClose = () => {
    dispatch({ type: "showCropper", payload: false });
    dispatch({ type: "currentInputOnChangeCropper", payload: null });
    dispatch({ type: "currentInputImageCropper", payload: null });
  };

  const getCropData = () => {
    if (typeof cropper !== "undefined") {
      // setCropData(cropper.getCroppedCanvas().toDataURL());
      if (state.currentInputOnChangeCropper)
        cropper.getCroppedCanvas().toBlob((blob: Blob) => {
          // const randomName = (new Date()).getTime().toString()
          // const file = blobToFile(blob, randomName);
          state.currentInputOnChangeCropper(blob);
        });
      // state.currentInputOnChangeCropper(cropper.getCroppedCanvas().toDataURL());
    }
    handleClose();
  };

  useEffect(() => {
    if (state.currentInputImageCropper) {
      setImage(state.currentInputImageCropper);
    } else setImage("");
  }, []);

  const changeWidth = (event: ChangeEvent<HTMLInputElement>) => {
    let newWith = parseInt(event.currentTarget.value);
    setWidth(newWith);
  };

  const updateWidth = (value: number) => {
    let newWith = width + value;
    if (newWith >= 0 && newWith <= 1000) {
      setWidth(newWith);
    }
  };

  const blobToFile = (theBlob: Blob, fileName: string): File => {
    return new File(
      [theBlob as any], // cast as any
      fileName,
      {
        lastModified: new Date().getTime(),
        type: theBlob.type,
      }
    );
  };

  return (
    <div className="fixed z-50 flex h-screen w-screen items-center justify-center bg-black-100/30 backdrop-blur-sm">
      <div className="absolute top-20 m-auto flex flex-col justify-center rounded-2xl bg-white ">
        <div className="flex w-full justify-between pl-14 pr-10 pt-5 text-center">
          <h3 className=" text-lg font-bold">Ajustar imagen</h3>
          <button className="" onClick={() => handleClose()}>
            <GrClose />
          </button>
        </div>

        <div className=" h-auto w-[47rem] flex-col flex-wrap  overflow-auto">
          <Cropper
            style={{ height: 400, width: "100%" }}
            zoomTo={width / 100}
            initialAspectRatio={
              state.cropperOptions.initialAspectRatio || 3 / 4
            }
            preview=""
            src={image}
            viewMode={1}
            minCropBoxHeight={10}
            minCropBoxWidth={10}
            background={false}
            responsive={true}
            autoCropArea={1}
            checkOrientation={false}
            onInitialized={(instance) => {
              setCropper(instance);
            }}
            guides={true}
            cropBoxResizable={state.cropperOptions.cropBoxResizable}
            dragMode={state.cropperOptions.dragMode}
          />
          <div className="mt-2 flex w-full items-center justify-center gap-2">
            <GrZoomOut
              size={16}
              className="cursor-pointer"
              onClick={() => updateWidth(-50)}
            />
            <input
              type="range"
              onChange={changeWidth}
              min={1}
              max={1000}
              step={10}
              value={width}
              className="accent-[black]"
            />
            <GrZoomIn
              size={16}
              className="cursor-pointer"
              onClick={() => updateWidth(50)}
            />
          </div>
          <div className="mb-2 mt-12 flex w-full  justify-between px-8 pt-5 text-center">
            <button
              onClick={getCropData}
              type="button"
              className="m-auto w-72 rounded-full border-[2px] border-black-100 bg-black-100 px-12 py-2 font-medium text-white"
            >
              Guardar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

import { useState, FC } from "react";
import { Back } from "../../Back";
import { FilterOption } from "../../table/FilterSection";
import { BannerArtist } from "../BannerArtist";
import { IArtistResponse } from "../../../types/IArtist";
import { SongsArtist } from "./songs/SongsArtist";

type typeProps = {
  handleSelectItem: (data: IArtistResponse) => void;
  data: IArtistResponse | undefined;
};
export const SelectedArtist: FC<typeProps> = ({ handleSelectItem, data }) => {
  const filterSectionProps = [{ title: "Songs", id: "songs" }];

  const [formValue, setFormValue] = useState("ordenes");

  const handleFormValue = (event: React.ChangeEvent<HTMLFormElement>) => {
    setFormValue(event.target.id);
  };

  const showFormValue = () => {
    switch (formValue) {
      case "songs":
        return <SongsArtist />;
      default:
        return <SongsArtist />;
    }
  };

  return (
    <div>
      <Back onClick={handleSelectItem} />
      <div className="pt-6">
        <BannerArtist edit={true} data={data} />
      </div>
      <div className="w-full overflow-auto border-b pt-10">
        <form
          className="flex gap-10 font-medium text-gray-100"
          onChange={handleFormValue}
        >
          {filterSectionProps.map((option) => (
            <FilterOption title={option.title} id={option.id} />
          ))}
        </form>
      </div>
      <div className="pt-10">{showFormValue()}</div>
    </div>
  );
};

import { useState } from "react";
import { Pie } from "react-chartjs-2";

import { Card, CardContent, CardHeader } from "../../../ui/card";
import { IGroupedOffersByTicket } from "../../../../types/IOffer";
import { OffersTable } from "./offersTable";
import { buildPieChartData } from "../../../../utils/buildPieChartData";

interface RowProps {
  group: IGroupedOffersByTicket;
}

export const Row = ({ group }: RowProps) => {
  const [showOffers, setShowOffers] = useState<boolean>(false);

  const offersQty = group.offers.length;
  const totalSoldOffers = group.offers.filter(
    (offer) => offer.status === "sold"
  ).length;

  return (
    <li className="mb-2">
      <Card
        className={`py-0 transition-colors duration-300 ease-in-out ${
          !showOffers ? "hover:bg-gray-300" : ""
        }`}
      >
        <CardHeader
          className="flex cursor-pointer items-center gap-4 py-2 font-medium"
          onClick={() => setShowOffers((prevState) => !prevState)}
        >
          <p className="mt-0 grow">{group.ticket_name}</p>
          <p style={{ marginTop: 0 }} className="basis-[100px] text-center">
            {offersQty}
          </p>
          <p style={{ marginTop: 0 }} className="basis-[100px] text-center">
            {totalSoldOffers}
          </p>
          <div className="mt-0 hidden basis-[100px] items-center justify-center md:grid">
            <div className="w-[50px]">
              <Pie
                data={buildPieChartData([
                  totalSoldOffers,
                  offersQty - totalSoldOffers,
                ])}
                className="relative bottom-[4px]"
              />
            </div>
          </div>
        </CardHeader>
        <div
          className={`overflow-hidden transition-[max-height] duration-500 ease-in-out ${
            showOffers ? "max-h-[3000px]" : "max-h-0"
          }`}
        >
          <CardContent className="overflow-y-auto">
            <OffersTable offers={group.offers} />
          </CardContent>
        </div>
      </Card>
    </li>
  );
};

import api from "./api";
import authHeader from "./authHeader";
import { ITeamResponse, ITeamsResponse } from "../types/ITeam";
import { IPaginationResponse } from "../types/IPagination";

const getAll = async (params = {}) => {
  const response = await api.get<ITeamsResponse>(`teams/list`, {
    headers: authHeader(),
    params: params,
  });
  return response.data;
};

const get = async (id: string) => {
  const response = await api.get<ITeamResponse>(`teams/detail/${id}`, {
    headers: authHeader(),
  });
  return response.data;
};

const create = async (item: FormData) => {
  const response = await api.post<ITeamResponse>(`teams/create`, item, {
    headers: {
      "Content-Type": "multipart/form-data",
      ...authHeader(),
    },
  });
  return response.data;
};

const update = async (id: string | undefined, item: FormData) => {
  const response = await api.put<ITeamResponse>(`teams/update/${id}`, item, {
    headers: {
      "Content-Type": "multipart/form-data",
      ...authHeader(),
    },
  });
  return response.data;
};

const remove = async (id: string) => {
  const response = await api.delete<ITeamResponse>(`teams/delete/${id}`, {
    headers: authHeader(),
  });
  return response.data;
};

const TeamService = {
  getAll,
  get,
  create,
  update,
  remove,
};

export default TeamService;

import * as yup from "yup";

export const editPriceSchema = yup.object().shape({
  subtotal: yup
    .number()
    .transform((value) => (isNaN(value) ? null : value))
    .min(0, "Ingrese un precio válido"),
  total: yup
    .number()
    .transform((value) => (isNaN(value) ? null : value))
    .min(0, "Ingrese un precio válido"),
});

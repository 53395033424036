import { FC } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { FormInputProps } from "../../../types/FormInputsProps";

const FormCheck: FC<FormInputProps> = ({
  name,
  label,
  options,
  handleCheck,
}) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const checked = (value: any, options: any) => {
    if (Array.isArray(value)) return value.includes(options);
    return String(value) === String(options);
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { name, value, onBlur, ref, onChange } }) => (
        <div className="flex w-full flex-col">
          <h2 className="pb-2 text-base font-medium text-gray-100">{label}</h2>
          <div className="flex w-full flex-wrap gap-4 ">
            {options?.map(
              ({ label: optionLabel, value: optionValue }, index) => (
                <label
                  htmlFor={optionLabel}
                  className="flex w-1/3 items-center gap-2 "
                  key={index}
                >
                  <input
                    type="checkbox"
                    id={optionLabel}
                    name={name}
                    onBlur={onBlur}
                    value={optionValue}
                    ref={ref}
                    checked={
                      handleCheck ? handleCheck : checked(value, optionValue)
                    }
                    onChange={(event) => {
                      if (Array.isArray(value)) {
                        const array = [...value];
                        const index = array.indexOf(event.currentTarget.value);
                        if (index > -1) {
                          array.splice(index, 1);
                        } else {
                          array.push(event.currentTarget.value);
                        }
                        onChange(array);
                      } else {
                        onChange(
                          event.currentTarget.checked
                            ? event.currentTarget.value
                            : null
                        );
                      }
                    }}
                    className="rounded-full p-[.60rem] checked:bg-black-100 focus:ring-transparent active:bg-black-100"
                  />
                  <p className="font-bold">{optionLabel}</p>
                </label>
              )
            )}
          </div>
          {errors[name] && (
            <p className="text-red-600 dark:text-red-500 mt-2 text-sm text-red">
              {String(errors[name] ? errors[name]?.message : "")}
            </p>
          )}
        </div>
      )}
    />
  );
};

export default FormCheck;

import { useState } from "react";

import { IClientResponse } from "../../types/IClient";
import { FormClient } from "./FormClient";
import { SelectedClient } from "./selected/SelectedClient";
import { TableClients } from "./TableClients";

export const ClientsView = () => {
  // Btn create new event
  const [createItem, setCreateItem] = useState(false);

  const [id, setId] = useState<string>("");

  // Manager of event selected
  const [showSelected, setShowSelected] = useState(false);

  const [selectedItem, setSelectedItem] = useState<IClientResponse>();

  const handleCreateorEdit = async (id: string) => {
    await setId(id);
    await setCreateItem(!createItem);
  };

  const handleSelectItem = (data: IClientResponse) => {
    setShowSelected(!showSelected);
    if (data) {
      setSelectedItem(data);
    }
  };

  const itemsTableProps = {
    handleCreateorEdit,
    handleSelectItem,
  };

  const formUserProps = {
    handleCreateorEdit,
  };

  const selectedItemsProps = {
    handleSelectItem,
  };

  return (
    <div>
      {showSelected ? (
        selectedItem && (
          <SelectedClient {...selectedItemsProps} data={selectedItem} />
        )
      ) : !createItem ? (
        <TableClients {...itemsTableProps} />
      ) : (
        <FormClient {...formUserProps} id={id} />
      )}
    </div>
  );
};

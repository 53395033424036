import { useMutation } from "@tanstack/react-query";
import { motion } from "framer-motion";
import { FaRedo, FaTrashAlt } from "react-icons/fa";
import { GoSearch } from "react-icons/go";
import { toast } from "react-toastify";
import NewsService from "../../../services/newsService";
import { INewsResponse } from "../../../types/INews";
import DropdownDots from "../../FormElement/DropdownDots";

type selectedProps = {
  data: INewsResponse;
  updateData: (id: string) => void;
  filter: string;
};

const columnData = ["ID", "Titulo", "Descripcion", "Me Gusta"];

export const NewsRecycler = ({ data, updateData, filter }: selectedProps) => {
  const deleteItems = useMutation((id: string) => NewsService.remove(id), {
    onSuccess(data: any) {
      // toast.success(data.message);
      const option = filter === "news" ? "Novedad" : "Rumor";
      toast.success(`${option} eliminado exitosamente`);
    },
  });

  const handleDelete = async (id: string) => {
    if (window.confirm("Esta seguro de eliminar este usuario?")) {
      await deleteItems.mutateAsync(id).then((res) => {
        // getItems.mutateAsync().then(res => setResult(res));
        updateData(filter);
      });
    }
  };

  const handleRestoreUser = async (id: any) => {
    const formData = new FormData();
    formData.append("is_deleted", "false");
    formData.append("id", id);

    await NewsService.update(id, formData).then((res) => {
      // getItems.mutateAsync().then(res => setResult(res));
      updateData(filter);
    });
  };

  return (
    <table className="my-table-spacing h-full w-full border-separate border-spacing-y-2 gap-5 overflow-x-auto pt-5 text-left">
      <thead className=" divide-solid border ">
        <tr className=" pb-4 text-sm font-bold text-gray-100">
          {columnData.map((column, index) => (
            <th
              className="border-b-[1px] border-colorBorder pb-4"
              key={column + index}
            >
              {column}
            </th>
          ))}
          <th className="border-b-[1px] border-colorBorder pb-4"></th>
        </tr>
      </thead>
      <tbody>
        {data.rows.length ? (
          data.rows.map((item, i) => (
            <motion.tr
              key={item.id}
              layout
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              initial={{ opacity: 0.2 }}
              transition={{ type: "spring", stiffness: 100, bounce: 0 }}
              className={`h-16  rounded-xl text-start font-medium outline outline-[1px] outline-colorBorder`}
            >
              <td className="pl-4">{`#${item.id}`}</td>
              <td className="pl-4">{item.title}</td>
              <td className="pl-4">{item.description}</td>
              <td className="pl-4">{item.totalLikes}</td>
              <td className="pl-4">
                <DropdownDots
                  options={[
                    {
                      title: "Eliminar",
                      action: () => handleDelete(item.id),
                      icon: (
                        <FaTrashAlt size={23} className="mx-2" key={item.id} />
                      ),
                    },
                    {
                      title: "Restaurar",
                      action: () => handleRestoreUser(item.id),
                      icon: <FaRedo size={23} className="mx-2" key={item.id} />,
                    },
                  ]}
                />
              </td>
            </motion.tr>
          ))
        ) : (
          <></>
        )}
      </tbody>
    </table>
  );
};

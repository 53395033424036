import { TrashIcon, Pencil1Icon, Pencil2Icon } from "@radix-ui/react-icons";

import { IDropdownOption } from "../../../types_new/IOption";
import { IOfferResponse } from "../../../types_new/IOffer";

interface DropdownOptionsParams {
  offer_id: string;
  order_ticket_id: string;
  handleEditPrice: (offer_id: string) => void;
  handleDelete: (offer_id: string) => void;
  handleSeeTraceability: (order_ticket_id: string) => void;
}

export const getDropdownOptions = ({
  offer_id,
  order_ticket_id,
  handleEditPrice,
  handleDelete,
  handleSeeTraceability,
}: DropdownOptionsParams): IDropdownOption<IOfferResponse>[] => {
  const options: IDropdownOption<IOfferResponse>[] = [
    {
      value: "price",
      text: "Editar Precio",
      callback: handleEditPrice,
      icon: <Pencil1Icon />,
    },
    {
      value: "delete",
      text: "Eliminar Oferta",
      callback: handleDelete,
      icon: <TrashIcon />,
    },
    {
      value: "traceability",
      text: "Ver trazabilidad",
      callback: () => handleSeeTraceability(order_ticket_id),
      icon: <Pencil2Icon />,
    },
  ];

  return options;
};

import { useState, useEffect, useContext } from "react";
import { useMutation } from "@tanstack/react-query";

import { IFilterRequest } from "../../types/IFilter";
import ISortOption from "../../types/ISortOption";
import { PageContainer } from "../PageContainer";
import { FilterOrder } from "../table/FilterOrder";
import { FilterPage } from "../table/FilterPage";
import { FilterQtyPage } from "../table/FilterQtyPage";
import { FilterSection } from "../table/FilterSection";
import { TitleSection } from "../TitleSection";
import { StateContext } from "../../context/StateProvider";
import NewsService from "../../services/newsService";
import UserService from "../../services/userService";
import EventService from "../../services/eventService";
import { UserReported } from "./partials/UsersReported";
import { CommentsReported } from "./partials/CommentsReported";
import { PicturesReported } from "./partials/PicturesReported";
import config from "../../config/variables";

const defaultResult = {
  total: 0,
  pages: 0,
  rows: [],
};
const defaultFilters = {
  limit: config.filter_default_limit,
  page: 1,
  status: "all",
  recycler: true,
  sortBy: "createdAt",
  sortOrder: "desc",
};

const filterSectionProps = [
  { title: "Usuarios", id: "users" },
  { title: "Comentarios de eventos", id: "event_comments" },
  { title: "Comentarios de novedades", id: "news_comments" },
  { title: "Imágenes", id: "pictures" },
];

export const TableReported = () => {
  const getItems = useMutation((option: string) => {
    switch (option) {
      case "users":
        return UserService.getReported(filters);
      case "event_comments":
        return EventService.getCommentReports(filters);
      case "news_comments":
        return NewsService.getCommentReports(filters);
      case "pictures":
        return EventService.getPictureReports({ ...filters });
    }

    return Promise.resolve();
  });

  let paramsFilters: any = { status: "", sortBy: "" };

  useEffect(() => {
    handleFilter(filters);
  }, []);

  const [result, setResult] = useState<any>(defaultResult);
  const [filters, setFilters] = useState<IFilterRequest>(defaultFilters);
  const [filterSectionOption, setFilterSectionOption] = useState("users");
  const [search, setSearch] = useState("");

  const searcher = async (e: any) => {
    setSearch(e.target.value);

    paramsFilters = { ...filters, query: e.target.value };
    await setFilters({ ...filters, page: 1, query: e.target.value });
  };

  useEffect(() => {
    if (search != "") {
      const delayDebounceFn = setTimeout(() => {
        getItems.mutateAsync(filterSectionOption).then((res) => {
          setResult(res);
        });
      }, 600);
      return () => clearTimeout(delayDebounceFn);
    } else {
      getItems.mutateAsync(filterSectionOption).then((res) => {
        setResult(res);
      });
    }
  }, [search]);

  const sortOptions: Array<ISortOption> = [
    { value: "createdAt", text: "Fecha", sortOrder: "desc" },
  ];

  const handleFilter = async (filter: IFilterRequest) => {
    await setFilters({ ...filters, ...filter });
    getItems.mutateAsync(filterSectionOption).then((res) => setResult(res));
  };

  let filterString: string;

  const handleFilterSection = async (filter: string) => {
    filterString = filter;
    await setFilters({ ...filters, page: 1 });
    await getItems.mutateAsync(filter).then(async (res) => {
      await setResult(res);
    });
    setFilterSectionOption(filter);
  };

  const handleSortSection = async (sortBy: string) => {
    const itemSort = sortOptions.find((s) => s.value == sortBy);
    paramsFilters = { ...filters, page: 1, sortBy: sortBy };
    if (itemSort) paramsFilters["sortOrder"] = itemSort.sortOrder;
    await setFilters(paramsFilters);
    await getItems
      .mutateAsync(filterSectionOption)
      .then((res) => setResult(res));
  };

  const { state, dispatch } = useContext(StateContext);
  const [stateOrder, setState] = useState("desc");

  const handleChangeState = async (state: string) => {
    setState(state);

    await setFilters({ ...filters, sortOrder: state });

    dispatch({ type: "showLoaderScreen", payload: true });
    getItems.mutateAsync(filterSectionOption).then((res) => {
      dispatch({ type: "showLoaderScreen", payload: false });
      setResult(res);
    });
  };

  const showTables = () => {
    switch (filterSectionOption) {
      case "users":
        return <UserReported data={result} updateData={handleFilterSection} />;
      case "event_comments":
        return (
          <CommentsReported data={result} updateData={handleFilterSection} />
        );
      case "news_comments":
        return (
          <CommentsReported
            data={result}
            updateData={handleFilterSection}
            from="news"
          />
        );
      case "pictures":
        return (
          <PicturesReported data={result} updateData={handleFilterSection} />
        );
      default:
        return <UserReported data={result} updateData={handleFilterSection} />;
    }
  };

  return (
    <PageContainer>
      <div className="relative">
        <TitleSection>Reportados</TitleSection>
      </div>
      <div className="mb-7 flex justify-between border-b-[1px] border-colorBorder pr-5 pt-10">
        <FilterSection
          filterSectionProps={filterSectionProps}
          handleFilter={handleFilterSection}
          defaultSelected="users"
        />
        <div className="flex items-center gap-5 ">
          <input
            type="text"
            placeholder="Search..."
            className="hover:text-black rounded-full border-[2px] border-black-100 px-4 py-1 font-bold  text-black-100"
            value={search}
            onChange={searcher}
          />
          <FilterOrder
            setSort={handleSortSection}
            options={sortOptions}
            handleChangeState={handleChangeState}
            state={stateOrder}
          />
        </div>
      </div>
      <div>{showTables()}</div>
      <div className="flex justify-between pt-10">
        <FilterPage
          handleFilter={handleFilter}
          total={result.total}
          limit={filters.limit}
          status={filters.status}
          currentPage={filters.page}
        />
        <FilterQtyPage handleFilter={handleFilter} status={filters.status} />
      </div>
    </PageContainer>
  );
};

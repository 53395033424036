import React, { useEffect, useState, FC, MouseEventHandler } from "react";
import { MdKeyboardArrowRight } from "react-icons/md";
import { IFilterRequest } from "../../types/IFilter";

type filterProps = {
  page?: number;
  id?: string;
  checked?: boolean;
};

type filterOptions = {
  total?: number;
  limit?: number;
  status?: string;
  handleFilter: (filter: IFilterRequest) => void;
  currentPage?: number;
};

export const FilterPage: FC<filterOptions> = ({
  total = 0,
  limit = 0,
  handleFilter = () => {},
  currentPage = 1,
}) => {
  // const filterOptions = [];
  const [itemsPages, setItemsPages] = useState<Array<any>>([]);
  const siblingCount = 1;
  let totalPages = Math.ceil(total / limit);
  /*for (let i = 1; i <= totalPages; i++) {
    filterOptions[i-1] = {
      page: i,
      id: i.toString()
    }
  }*/
  const handlePageNumber = (e: React.ChangeEvent<HTMLFormElement>) => {
    let page = e.target.id;
    let filter = {
      limit: limit,
      page: parseInt(page),
      // status: status
    };
    handleFilter(filter);
  };

  const handleNextPage = () => {
    if (currentPage >= totalPages) return;
    let page = currentPage + 1;
    let filter = {
      limit: limit,
      page: page,
      // status: status
    };
    handleFilter(filter);
  };

  const range = (start: number, end: number) => {
    let length = end - start + 1;
    return Array.from({ length }, (_, idx) => idx + start);
  };

  const calculateSiblings = () => {
    const totalPageNumbers = siblingCount + 5;
    if (totalPageNumbers >= totalPages) {
      setItemsPages(range(1, totalPages));
      return;
    }
    const leftSiblingIndex = Math.max(currentPage - siblingCount, 1);
    const rightSiblingIndex = Math.min(currentPage + siblingCount, totalPages);
    const shouldShowLeftDots = leftSiblingIndex > 2;
    const shouldShowRightDots = rightSiblingIndex < totalPages - 2;
    const firstPageIndex = 1;
    const lastPageIndex = totalPages;
    if (!shouldShowLeftDots && shouldShowRightDots) {
      let leftItemCount = 3 + 2 * siblingCount;
      let leftRange = range(1, leftItemCount);

      setItemsPages([...leftRange, "...", totalPages]);
      return;
    }
    if (shouldShowLeftDots && !shouldShowRightDots) {
      let rightItemCount = 3 + 2 * siblingCount;
      let rightRange = range(totalPages - rightItemCount + 1, totalPages);
      setItemsPages([firstPageIndex, "...", ...rightRange]);
      return;
    }
    if (shouldShowLeftDots && shouldShowRightDots) {
      let middleRange = range(leftSiblingIndex, rightSiblingIndex);
      setItemsPages([
        firstPageIndex,
        "...",
        ...middleRange,
        "...",
        lastPageIndex,
      ]);
      return;
    }
  };

  useEffect(() => {
    calculateSiblings();
  }, [currentPage, totalPages]);

  return (
    <form
      className={`flex items-center gap-2 text-gray-100 ${
        totalPages <= 1 && "hidden"
      }`}
      onChange={handlePageNumber}
    >
      {itemsPages.map((option, index) =>
        option === "..." ? (
          <div
            key={index}
            className="text-md  select-none px-4 py-2  text-center font-medium text-black-100"
          >
            ...
          </div>
        ) : (
          <PageOption
            key={index}
            page={option}
            id={option.toString()}
            checked={option == currentPage}
          />
        )
      )}

      <button
        className="flex  items-center justify-center gap-2 rounded-lg px-4 py-3 text-center font-medium outline outline-1 outline-colorBorder"
        onClick={() => handleNextPage()}
        type="button"
      >
        Próxima <MdKeyboardArrowRight />
      </button>
    </form>
  );
};

const PageOption = ({ page, id, checked }: filterProps) => {
  return (
    <label htmlFor={id} className="h-full cursor-pointer pt-1   ">
      <input
        type="radio"
        name="filterTable"
        id={id}
        className="peer hidden"
        checked={checked}
        onChange={() => {}}
      />
      <div className="text-md  select-none px-4 py-2  text-center font-medium text-black-100  peer-checked:rounded-xl peer-checked:bg-black-100 peer-checked:text-white">
        {page}
      </div>
    </label>
  );
};

import { useState, useContext, Dispatch, SetStateAction } from "react";
import { QueryObserverResult } from "@tanstack/react-query";
import { RowSelectionState } from "@tanstack/react-table";

import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "../../ui/select";
import { Button } from "../../ui/button";
import { IActionOption } from "../../../types_new/IOption";
import { getSubarrayByPositions } from "../../../utils_new/getSubarrayByPositions";
import { StateContext } from "../../../context/StateProvider";

interface BatchActionsProps<T> {
  actionOptions: IActionOption<T>[];
  rowSelection: RowSelectionState;
  setRowSelection: Dispatch<SetStateAction<RowSelectionState>>;
  rows: T[];
  refetch: () => Promise<QueryObserverResult>;
}

export const BatchActions = <T extends { id: string }>({
  actionOptions,
  rowSelection,
  setRowSelection,
  rows,
  refetch,
}: BatchActionsProps<T>) => {
  const { dispatch } = useContext(StateContext);

  const [action, setAction] = useState<IActionOption<T> | undefined>(
    actionOptions ? actionOptions[0] : undefined
  );

  const handleAction = async () => {
    // obtenemos los index de las filas elegidas
    const indexesSelected = Object.keys(rowSelection).map((index) =>
      Number(index)
    );

    // validamos que haya al menos un index
    if (indexesSelected.length === 0) {
      dispatch({
        type: "toastError",
        payload: "Elegí al menos una fila",
      });
      return;
    }

    // obtenemos los id de las filas elegidas
    const selectedRows = getSubarrayByPositions(rows, indexesSelected);
    const selectedRowsIds = selectedRows.map((row) => row.id);

    dispatch({ type: "showLoaderScreen", payload: true });

    const actionPromises = selectedRowsIds.map((id) =>
      action!.callback(id, action!.payload)
    );

    Promise.all(actionPromises)
      .then(() => {
        dispatch({ type: "showLoaderScreen", payload: false });
        dispatch({
          type: "toastSuccess",
          payload: "Acción en lote ejecutada correctamente",
        });
      })
      .catch((error) => {
        console.error("Error al ejecutar la acción en lote:", error);
        dispatch({ type: "showLoaderScreen", payload: false });
        dispatch({
          type: "toastError",
          payload: "Error al ejecutar la acción en lote",
        });
      })
      .finally(() => {
        setRowSelection({});
        refetch();
      });
  };

  const handleActionChange = (actionValue: string) => {
    const actionSelected = actionOptions.find(
      (action) => action.value === actionValue
    );
    setAction(actionSelected);
  };

  return (
    <div className="flex items-center gap-3">
      <span className="font-bold">Acciones por lote:</span>
      <Select
        defaultValue={actionOptions[0]?.value}
        onValueChange={handleActionChange}
      >
        <SelectTrigger className="w-[180px]">
          <SelectValue placeholder="Acciones por lote" />
        </SelectTrigger>
        <SelectContent>
          <SelectGroup>
            <SelectLabel className="font-bold">Acciones por lote</SelectLabel>
            {actionOptions.map((action: IActionOption<T>) => (
              <SelectItem value={action.value} key={action.value}>
                {action.text}
              </SelectItem>
            ))}
          </SelectGroup>
        </SelectContent>
      </Select>
      <Button variant="secondary" type="button" onClick={handleAction}>
        Aplicar acción
      </Button>
    </div>
  );
};

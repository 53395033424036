import { AlertToastify } from ".";

export const CardAlert = ({ ...props }) => {
  return (
    <div className="m-auto px-[1%] xl:px-[1%] 2xl:px-0">
      <AlertToastify />
      {props.children}
    </div>
  );
};

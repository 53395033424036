import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { BiDotsHorizontalRounded } from "react-icons/bi";
import { IoMailOpen } from "react-icons/io5";
import { BsCalendar4Range } from "react-icons/bs";
import { Title } from "../../../events/Title";
import { ITeamsResponse } from "../../../../types/ITeam";
import { useMutation } from "@tanstack/react-query";
import TeamService from "../../../../services/teamService";
import { getTeamRoleFromValue } from "../../../../utils/roles";
import { formatDate } from "../../../../utils/format-date";
import { getStatusFromValue } from "../../../../utils/status";
import { FormTeam } from "../../../events/selectedEvent/teams/FormTeam";
import { FaEdit, FaTrashAlt } from "react-icons/fa";
import DropdownDots from "../../../FormElement/DropdownDots";
import { GoSearch } from "react-icons/go";

type TeamEventProps = {
  producerId: string;
  eventId?: string;
};

export const TeamProducer = ({ producerId, eventId }: TeamEventProps) => {
  const [showForm, setShowForm] = useState<boolean>(false);
  const [result, setResult] = useState<ITeamsResponse>({
    rows: [],
    total: 0,
    pages: 0,
  });
  const [idTeam, setIdTeam] = useState<string>("");

  const event_id = eventId || null;
  const getItems = useMutation(() =>
    TeamService.getAll({ producer_id: producerId, event_id: event_id })
  );
  const deleteItems = useMutation((id: string) => TeamService.remove(id));
  useEffect(() => {
    loadItems();
  }, [showForm]);

  const handleFormDisplay = async (id = "") => {
    await setIdTeam(id);
    setShowForm(!showForm);
    if (!showForm) loadItems();
  };

  const loadItems = () => {
    getItems.mutateAsync().then((res) => setResult(res));
  };

  const handleDelete = async (id: string) => {
    if (window.confirm("Está seguro de eliminar este cliente?")) {
      await deleteItems.mutateAsync(id).then((res) => {
        getItems.mutateAsync().then((res) => setResult(res));
      });
    }
  };

  const columnData = [
    "Nombre",
    "Función",
    "Email",
    "Fecha de Alta",
    "Status",
    "",
  ];

  const rowData = {
    name: "Donat Twerski",
    rol: "Team Leader",
    email: "marcos_foglia@gmail.com",
    date: "19 Jun 2021 - 12:43:22",
    status: "Activo",
  };
  return (
    <div>
      {showForm ? (
        <FormTeam
          handleCreateorEdit={handleFormDisplay}
          producerId={producerId}
          id={idTeam}
        />
      ) : (
        <>
          <div className="flex justify-between">
            <Title>Equipo</Title>
            <button
              onClick={() => handleFormDisplay()}
              className="rounded-full border-[2px] border-black-100 px-10 py-2 font-bold  text-black-100 hover:bg-black-100 hover:text-white"
            >
              Crear nuevo miembro
            </button>
          </div>
          <table className="my-table-spacing h-full w-full border-separate border-spacing-y-2 gap-5 overflow-x-auto pt-5 text-left">
            <thead className=" divide-solid border ">
              <tr className="pb-4 text-sm font-bold text-gray-100">
                {columnData.map((column, index) => (
                  <th
                    className="border-b-[1px] border-colorBorder pb-4"
                    key={column + index}
                  >
                    {column}
                  </th>
                ))}
                <th className="border-b-[1px] border-colorBorder pb-4">
                  <GoSearch
                    className="ml-auto cursor-pointer text-gray-100"
                    size={16}
                  />
                </th>
              </tr>
            </thead>
            <tbody>
              {result.rows.map((_, i) => (
                <motion.tr
                  key={i}
                  layout
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  initial={{ opacity: 0.2 }}
                  transition={{ type: "spring", stiffness: 100, bounce: 0 }}
                  className={`h-16  rounded-xl text-start font-medium outline outline-[1px] outline-colorBorder`}
                >
                  <td className="pl-4">
                    {_.user[0]
                      ? _.user[0].first_name + " " + _.user[0].last_name
                      : ""}
                  </td>
                  <td className="">{getTeamRoleFromValue(_.team_role)}</td>
                  <td className="flex h-full items-center gap-2 ">
                    {_.user[0] ? _.user[0].email : ""}
                  </td>
                  <td className="">{formatDate(_.createdAt)}</td>

                  <td>
                    <div className="flex w-32 items-center justify-center gap-3 rounded-full border-[1px] border-colorBorder  py-2 text-center ">
                      <div
                        className={`rounded-full  ${
                          _.status === "active" ? "bg-green" : "bg-red"
                        } p-[.35rem]`}
                      />
                      <p className="capitalize">
                        {getStatusFromValue(_.status)}
                      </p>
                    </div>
                  </td>
                  <td>
                    <DropdownDots
                      options={[
                        {
                          title: "Editar",
                          action: () => handleFormDisplay(_.id),
                          icon: <FaEdit size={23} />,
                        },
                        {
                          title: "Eliminar",
                          action: () => handleDelete(_.id),
                          icon: <FaTrashAlt size={23} />,
                        },
                      ]}
                    />
                  </td>
                </motion.tr>
              ))}
            </tbody>
          </table>
          {result.rows.length === 0 && (
            <p className="text-center">No existen miembros en el equipo</p>
          )}
          {/* <div className="flex justify-between pt-10">
        <FilterPage />
        <FilterQtyPage />
      </div> */}
        </>
      )}
    </div>
  );
};

import { useSearchParams } from "react-router-dom";

export const getUrlParams = () => {
  const [searchParams] = useSearchParams();

  const params: Record<string, string> = {};

  searchParams.forEach((value, key) => {
    params[key] = value;
  });

  return params;
};

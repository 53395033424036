import { useNavigate } from "react-router-dom";
import { QueryObserverResult, useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";

import OfferService from "../../../services_new/offerService";
import { Dispatch, SetStateAction } from "react";
import { IOfferResponse } from "../../../types_new/IOffer";

interface useActionsProps {
  refetch: () => Promise<QueryObserverResult>;
  setRowOnEdit: Dispatch<SetStateAction<IOfferResponse | undefined>>;
  offers: IOfferResponse[];
  setIsDialogOpen: Dispatch<SetStateAction<boolean>>;
}

const useActions = ({
  refetch,
  setRowOnEdit,
  offers,
  setIsDialogOpen,
}: useActionsProps) => {
  const navigate = useNavigate();

  const deleteOffer = useMutation((id: string) => OfferService.remove(id), {
    onSuccess: (res) => {
      toast.success("Oferta eliminada.");
      refetch();
    },
    onError: (err) => {
      toast.error("No pudimos eliminar la oferta.");
    },
  });

  const handleSeeTraceability = (order_ticket_id: string) =>
    navigate(`/admin/orderTicket/${order_ticket_id}`);

  const handleDelete = (id: string) => {
    if (!window.confirm("¿Está seguro de que desea eliminar la oferta?"))
      return;

    deleteOffer.mutate(id);
  };

  const handleEditPrice = (id: string) => {
    setIsDialogOpen(true);
    setRowOnEdit(offers.find((offer) => offer.id === id));
  };

  const isLoading = deleteOffer.isLoading;

  return {
    handleEditPrice,
    handleDelete,
    handleSeeTraceability,
    isLoading,
  };
};

export default useActions;

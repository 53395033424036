import { useEffect, FC, useContext } from "react";
import { Back } from "../../../Back";
import FormInput from "../../../FormElement/FormInput";
import FormUploadImage from "../../../FormElement/FormUploadImage";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, FormProvider } from "react-hook-form";
import * as yup from "yup";
import jsonToFormData from "@ajoelp/json-to-formdata";
import { useMutation } from "@tanstack/react-query";
import TeamService from "../../../../services/teamService";
import FormSelect from "../../../FormElement/FormSelect";
import { ITeamRequest } from "../../../../types/ITeam";
import { teamRoles } from "../../../../utils/roles";
import { setFormErrorsFromServer } from "../../../../utils/form";
import { AuthContext } from "../../../../context/AuthContext";

let verifyAddForm = {
  first_name: yup.string().required("El nombre es requerido"),
  last_name: yup.string(),
  email: yup
    .string()
    .required("El email es requerido")
    .email("El correo electrónico no es válido"),
  team_role: yup.string().required("El rol es requerido"),
  password: yup.string().required("El password es requerido"),
  profile_picture: yup
    .mixed()
    .test("size", "Tamaño permitido menor a 20MB", (value) => {
      if (value) {
        return value.size <= 20000000;
      }
      return true;
    }),
};

let verifyEditForm = {
  first_name: yup.string().required("El nombre es requerido"),
  last_name: yup.string().required("El apellido es requerido"),
  email: yup
    .string()
    .required("El email es requerido")
    .email("El correo electrónico no es válido"),
  team_role: yup.string().required("El rol es requerido"),
  profile_picture: yup
    .mixed()
    .test("size", "Tamaño permitido menor a 20MB", (value) => {
      if (value) {
        return value.size <= 20000000;
      }
      return true;
    }),
};

const defaultValues = {
  first_name: "",
  last_name: "",
  email: "",
  team_role: "",
  password: "",
  profile_picture: "",
  producer_id: "",
  leader_id: "",
};

const statusOptions: Array<object> = [
  { value: "pending", text: "Pendiente" },
  { value: "active", text: "Activo" },
  { value: "paused", text: "Pausado" },
];

type typeProps = {
  handleCreateorEdit: (id: string) => void;
  id?: string;
  producerId?: string;
  leaderId?: string;
  event?: string;
};
export const FormTeam: FC<typeProps> = ({
  handleCreateorEdit,
  id,
  producerId = null,
  leaderId = null,
  event,
}) => {
  const { role } = useContext(AuthContext);
  let schema;
  if (id) {
    schema = yup.object().shape(verifyEditForm);
  } else {
    schema = yup.object().shape(verifyAddForm);
  }

  const createItems = useMutation((item: FormData) => TeamService.create(item));
  const updateItems = useMutation((item: FormData) =>
    TeamService.update(id, item)
  );
  const getTeamApi = useMutation((id: string) => TeamService.get(id));

  const form = useForm<ITeamRequest>({
    resolver: yupResolver(schema),
    defaultValues: defaultValues,
  });

  useEffect(() => {
    if (id) {
      getTeamApi
        .mutateAsync(id)
        .then((res) =>
          form.reset({
            ...res.user[0],
            team_role: res.team_role,
            status: res.status,
          })
        );
    }
  }, [form.reset]);

  const onSubmit = (data: ITeamRequest) => {
    if (producerId) data.producer_id = producerId;
    if (leaderId) data.leader_id = leaderId;
    let formData = jsonToFormData(data, {
      arrayIndexes: true,
      excludeNull: true,
    });
    if (id) {
      updateItems
        .mutateAsync(formData)
        .then((res) => handleCreateorEdit(""))
        .catch((reason) => {
          setFormErrorsFromServer(reason, form.setError);
        });
    } else {
      createItems
        .mutateAsync(formData)
        .then((res) => handleCreateorEdit(""))
        .catch((reason) => {
          setFormErrorsFromServer(reason, form.setError);
        });
    }
  };

  const onError = (error: any) => {
    console.log("error ", error);
  };

  return (
    <div className="">
      <Back onClick={() => handleCreateorEdit("")} />
      <div className="flex flex-col gap-10 xl:px-[23rem]">
        <h1 className="text-center text-3xl font-bold">
          {id ? "Editar Miembro del Equipo" : "Crear Miembro del Equipo"}
        </h1>
        <FormProvider {...form}>
          <form onSubmit={form.handleSubmit(onSubmit, onError)}>
            <FormInput name="first_name" type="text" label="Nombre" />
            <FormInput name="last_name" type="text" label="Apellido" />
            <FormInput name="email" type="text" label="Correo" />
            <FormInput name="password" type="password" label="Contraseña" />
            {(event && event != "event") || role == "admin" ? (
              <FormSelect name="team_role" options={teamRoles} label="Rol" />
            ) : (
              <div></div>
            )}
            {/* <FormSelect
                  name='team_role'
                  options={teamRoles}
                  label="Rol"
              /> */}
            {id && (
              <FormSelect
                name="status"
                options={statusOptions}
                label="Estado del user"
              />
            )}
            <div className="flex justify-center gap-10 ">
              <FormUploadImage name="profile_picture" label="Picture" />
            </div>
            <div className="mb-16 flex justify-center gap-10 pt-10">
              <button
                type="submit"
                className="rounded-full border-[2px] border-black-100 bg-black-100 px-[5.4rem] py-2 font-medium  text-white"
              >
                {id ? "Editar Miembro del Equipo" : "Crear Miembro del Equipo"}
              </button>
            </div>
          </form>
        </FormProvider>
      </div>
    </div>
  );
};

import api from "./api";
import authHeader from "./authHeader";
import { IOrdersResponse } from "../types_new/IOrder";
import { IFilterRequest } from "../types_new/IFilter";

const getAll = async (params: IFilterRequest) => {
  const response = await api.get<IOrdersResponse>(`orders/listOrders`, {
    headers: authHeader(),
    params: params,
    signal: params.signal,
  });
  return response.data;
};

const OrderService = {
  getAll,
};

export default OrderService;

import axios from "axios";
import config from "../config/variables";

const api = axios.create({
  baseURL: config.api_admin_url,
  headers: {
    "Content-type": "application/json",
  },
});

// Add a response interceptor
api.interceptors.response.use(
  (response) => {
    // If the response is successful, just return the response
    return response;
  },
  (error) => {
    // If the response status code is 401 or 403, clear localStorage
    if (error.response && error.response.status === 401) {
      localStorage.clear();
      // Check if window exists and redirect to "/"
      if (typeof window !== "undefined") {
        window.location.href = "/";
      }
    }
    // Always reject the promise, so the calling code can handle the error
    return Promise.reject(error);
  }
);

export default api;

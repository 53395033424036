import React, { useState, useRef, FC } from "react";
import "./LoaderScreen.css";
import { Oval } from "react-loader-spinner";

export default function LoaderScreen({}) {
  return (
    <div className="fixed left-0 top-0 z-50 flex h-screen w-screen items-center justify-center bg-black-100/30 backdrop-blur-sm">
      <Oval
        height={64}
        width={64}
        color="#000000"
        wrapperStyle={{}}
        wrapperClass=""
        visible={true}
        ariaLabel="oval-loading"
        secondaryColor="#E4E6E8"
        strokeWidth={4}
        strokeWidthSecondary={4}
      />
    </div>
  );
}

import { useContext } from "react";

import { AsideLink } from "./asideLink";
import { AuthContext } from "../../../context/AuthContext";
import {
  menuSuperAdmin,
  menuAdminTicketera,
  menuAdminSocialMedia,
  // menuProducer,
  // menuCoproducer,
} from "../constants/asideMenu";

export const Aside = () => {
  const auth = useContext(AuthContext);
  const { user } = auth;

  return (
    <aside className=" flex w-72 flex-col">
      <nav>
        <ul className="flex h-full select-none flex-col justify-between pb-20 pl-10 pt-16 text-sm">
          {["super_admin", "ticketera"].includes(
            user.permissions.admin_access
          ) && (
            <div className="mb-4 border-b-[1px]">
              <p className="font-black text-gray-100">TICKETERA</p>
              {menuAdminTicketera.map((option) => (
                <AsideLink option={option} key={option.text} />
              ))}
            </div>
          )}

          {["super_admin", "social_media"].includes(
            user.permissions.admin_access
          ) && (
            <div className="mb-4 border-b-[1px]">
              <p className="font-black text-gray-100">CONTENIDO</p>
              {menuAdminSocialMedia.map((option) => (
                <AsideLink option={option} key={option.text} />
              ))}
            </div>
          )}

          {["super_admin"].includes(user.permissions.admin_access) && (
            <div className="mb-4 border-b-[1px]">
              <p className="font-black text-gray-100">OTROS</p>
              {menuSuperAdmin.map((option) => (
                <AsideLink option={option} key={option.text} />
              ))}
            </div>
          )}
        </ul>
      </nav>
    </aside>
  );
};

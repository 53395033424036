import { Fragment, useContext, useEffect, useState } from "react";
import { Back } from "../../Back";
import { useNavigate, useParams } from "react-router";
import { SearchAutocomplete } from "./components/SearchAutocomplete/SearchAutocomplete";
import { CardSeller } from "./components/CardSeller/CardSeller";
import { useMutation } from "@tanstack/react-query";
import RrppService from "../../../services/rrppService";
import { IFilterRequest } from "../../../types/IFilter";
import { IRrppsResponse, IRrppResponse } from "../../../types/IRrpp";
import UserService from "../../../services/userService";
import { IUserResponse, IUsersResponse } from "../../../types/IUser";
import { AuthContext } from "../../../context/AuthContext";
import { StateContext } from "../../../context/StateProvider";

type newSellerViewProps = {
  handleSelectRrpp: (item: IRrppResponse) => void;
};

const defaultResult = {
  total: 0,
  pages: 0,
  rows: [],
};

const defaultFilters: any = {
  limit: 50,
  page: 1,
  sortBy: "first_name",
  sortOrder: "asc",
  status: "active",
  user_role: ["seller", "client", "user", "leader"],
};

export const NewSellerView = ({ handleSelectRrpp }: newSellerViewProps) => {
  const navigate = useNavigate();
  const { producer, role } = useContext(AuthContext);
  const { leader_id, producer_id, producer_producer_id } = useParams();
  const [filters, setFilters] = useState<IFilterRequest>({
    ...defaultFilters,
    except_producer_producer_id: producer_producer_id || producer?.id,
    except_leader_id: leader_id,
    rol: "client",
  });
  const [data, setData] = useState(defaultResult);
  const [selectData, setSelectData] = useState<IRrppResponse | null>(null);
  const [text, setText] = useState("");
  const { dispatch } = useContext(StateContext);

  const getItems = useMutation(() => UserService.getAll(filters));

  useEffect(() => {
    if (text != "") {
      const delayDebounceFn = setTimeout(() => {
        dispatch({ type: "showLoaderScreen", payload: true });
        getItems.mutateAsync().then((res) => {
          res.rows = res.rows.filter(
            (_: any) => _.full_name.trim() != "undefined"
          );
          setData(res);
          dispatch({ type: "showLoaderScreen", payload: false });
        });
      }, 1500);
      return () => clearTimeout(delayDebounceFn);
    }
  }, [text]);

  const handleChange = async (option: string) => {
    setText(option);
    await setFilters({ ...filters, page: 1, query: option });
  };

  const handleSelect = (data: any) => {
    setSelectData(data);
  };

  const propMethodsSearch = {
    onChange: handleChange,
    handleSelect,
  };

  const handleBack = () => {
    let prefix = "";
    if (role != "admin") {
      prefix += "/client";
    } else {
      prefix += `/admin/producers/${producer_id}/producer_producer/${producer_producer_id}`;
    }
    navigate(prefix + "/rrpp");
  };

  return (
    <Fragment>
      <Back onClick={handleBack} />
      <h1 className="text-center text-[20px] font-[700px] leading-[32px] tracking-[-0.04em]">
        {leader_id ? "Agregar" : "Crear"} nuevo vendedor
      </h1>
      <div>
        <div className="mx-auto my-5 w-[75%]">
          <SearchAutocomplete
            value={text}
            options={data.rows}
            {...propMethodsSearch}
          />
        </div>
      </div>
      {selectData && (
        <div className="mx-auto mt-[20px] w-[75%]">
          <CardSeller data={selectData} handleSelectRrpp={handleSelectRrpp} />
        </div>
      )}
    </Fragment>
  );
};

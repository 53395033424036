import { useEffect, useState, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import { motion, Reorder } from "framer-motion";
import moment from "moment/moment";
import "moment/locale/es";

import { PageContainer } from "../../../PageContainer";
import { Back } from "../../../Back";
import OrdesService from "../../../../services/orderService";
import UserService from "../../../../services/userService";
import { IOrderResponse } from "../../../../types/IOrder";
import { getPaymentMethodMPFromValue } from "../../../../utils/payments";
import {
  formatDate,
  formatDateHours,
  formatDateTime,
} from "../../../../utils/format-date";
import { getPaymentStatusFromValue } from "../../../../utils/status";
import DropdownDots from "../../../FormElement/DropdownDots";
import { StateContext } from "../../../../context/StateProvider";
import { getFullNameOrNickname } from "../../../../utils/form";

moment.locale("es");

const defaultFilters = {
  status: "active",
  sortBy: "createdAt",
  sortOrder: "-1",
  user_role: ["seller", "artist", "user", "client"],
};

export const OrderTicket = () => {
  const navigate = useNavigate();
  const { state, dispatch } = useContext(StateContext);
  const { order_id, user_id_data } = useParams();
  const [data, setData] = useState<IOrderResponse>();
  const [userOwnerId, setUserOwnerId] = useState("");
  const [haveMainTicket, setHaveMainTicket] = useState(false);

  const getUsers = useMutation(() => UserService.getAll(defaultFilters));
  const getItem = useMutation(() => OrdesService.get(order_id || ""));

  useEffect(() => {
    if (user_id_data) {
      loadUsers();
    }
  }, [user_id_data]);

  useEffect(() => {
    loadDataUserOrder();
  }, [order_id, user_id_data]);

  useEffect(() => {
    if (state.reloadOrderTicket && order_id && user_id_data) {
      loadDataUserOrder();
      dispatch({ type: "reloadOrderTicket", payload: false });
    }
  }, [state.reloadOrderTicket]);

  const loadUsers = async () => {
    await getUsers.mutateAsync().then((result) => {
      let list = result.rows.filter(
        (_: any) =>
          _.id.toString().toLowerCase() !==
          user_id_data?.toString().toLowerCase()
      );
      list = list.filter((_: any) => _.full_name.trim() != "undefined");
      dispatch({ type: "setUsers", payload: list });
    });
  };

  const loadDataUserOrder = () => {
    getItem.mutateAsync().then((res) => {
      setData(res);
      const mainTicket = res.order_tickets.find((x: any) => x.is_main);
      if (mainTicket) {
        setHaveMainTicket(true);
      }
      if (res.user_id) {
        setUserOwnerId(res.user_id);
        dispatch({ type: "selectedUsers", payload: [res.user_id] });
      }
    });
  };

  const compareDate = (date: string) => {
    const dateAdd = new Date(date);
    const today = new Date();
    if (dateAdd < today) {
      return true;
    } else {
      return false;
    }
  };

  let tempLog: any = null;
  const setTempLog = (logs: any, idUser: any) => {
    const log = logs.find((l: any) => l?.sender?.id === idUser);
    console.log(log);

    if (log) {
      tempLog = log;
    }
    return log;
  };

  let tempLogStatus: any = null;
  const setTemplogStatus = (logs: any, idUser: any) => {
    const log = logs.reverse().find((l: any) => l?.sender?.id === idUser);
    if (log) {
      tempLogStatus = log;
    }
    return log;
  };

  let tempLogRec: any = null;
  const setTempLogRec = (logs: any, idUser: any) => {
    const log = logs.reverse().find((l: any) => l?.receiver?.id === idUser);
    if (log) {
      tempLogRec = log;
    }
    return log;
  };

  return (
    <PageContainer>
      <Back onClick={() => navigate(-1)} />
      <div className="mt-4">
        <h2 className="text-center font-sans text-[20px] font-[700] leading-[32px] tracking-[-0.03em]">
          Detalle de tickets
        </h2>

        <h2 className="mt-[24px] font-sans text-[16px] font-[700] leading-[24px]">
          Tickets
        </h2>
        <table className="my-table-spacing h-full w-full border-separate border-spacing-y-2 gap-5 overflow-x-auto pt-5 text-left">
          <thead className=" divide-solid border ">
            <tr>
              <th className="border-b-[1px] border-colorBorder pb-4 font-sans text-[14px] font-[700] leading-[21px] text-[#8083A3]">
                Nombre del evento
              </th>
              <th className="border-b-[1px] border-colorBorder pb-4 font-sans text-[14px] font-[700] leading-[21px] text-[#8083A3]">
                Tipo de ticket
              </th>
              <th className="border-b-[1px] border-colorBorder pb-4 font-sans text-[14px] font-[700] leading-[21px] text-[#8083A3]">
                Usuario
              </th>
              <th className="border-b-[1px] border-colorBorder pb-4 font-sans text-[14px] font-[700] leading-[21px] text-[#8083A3]">
                Status
              </th>
              <th className="border-b-[1px] border-colorBorder pb-4 font-sans text-[14px] font-[700] leading-[21px] text-[#8083A3]"></th>
            </tr>
          </thead>
          <tbody>
            {data?.order_tickets?.map((row: any, i: any) =>
              user_id_data ? (
                user_id_data === row.user_id ||
                user_id_data === row.user_id_send ||
                row.logs.find((x: any) => x?.sender?.id === user_id_data) ? (
                  <motion.tr
                    key={i}
                    layout
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    initial={{ opacity: 0.2 }}
                    transition={{ type: "spring", stiffness: 100, bounce: 0 }}
                    className={`h-16  rounded-xl text-start font-medium outline outline-[1px] outline-colorBorder`}
                  >
                    <td className="pl-4">{row.event.name}</td>
                    <td className="pl-4">{row.ticket[0].type}</td>
                    <td className="pl-4">
                      {user_id_data ? (
                        <div>
                          {user_id_data !== row.user_id ? (
                            <div className="flex items-center">
                              {!!setTempLogRec(row.logs, user_id_data) && (
                                <div className="mx-4">
                                  Recibió ticket de:
                                  {
                                    <p>
                                      {tempLogRec?.sender
                                        ? `${tempLogRec.sender.first_name} ${tempLogRec.sender.last_name}`
                                        : "-"}
                                    </p>
                                  }
                                </div>
                              )}
                              {!!setTempLog(row.logs, user_id_data) && (
                                <div className="mx-4">
                                  Enviado a:
                                  {["received", "swapped"].includes(
                                    tempLog.sent_status
                                  ) ? (
                                    <p className="mr-5 font-sans text-[14px] font-[500] leading-[20px] text-[#009E36]">
                                      Recibido
                                    </p>
                                  ) : (
                                    <p className="mr-5 font-sans text-[14px] font-[500] leading-[20px] text-[#CBA006]">
                                      Pendiente
                                    </p>
                                  )}
                                  {tempLog.receiver ? (
                                    <p>
                                      {getFullNameOrNickname(tempLog?.receiver)}
                                    </p>
                                  ) : (
                                    <p>{tempLog?.email}</p>
                                  )}
                                </div>
                              )}
                            </div>
                          ) : (
                            <div className="flex items-center">
                              {row.is_main == true ? (
                                <p className="mr-5 font-sans text-[14px] font-[500] leading-[20px] text-[#A9935B]">
                                  Main ticket
                                </p>
                              ) : (
                                <p></p>
                              )}
                              {!!setTempLogRec(row.logs, user_id_data) && (
                                <div className="mx-4">
                                  Recibió ticket de:
                                  {
                                    <p>
                                      {tempLogRec?.sender
                                        ? `${tempLogRec.sender.first_name} ${tempLogRec.sender.last_name}`
                                        : "-"}
                                    </p>
                                  }
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                      ) : (
                        <div></div>
                      )}
                    </td>

                    <td className="pl-4">
                      {row?.status === "used" ? (
                        <div className="flex items-center">
                          <div className="mr-[24px] flex w-[150px] items-center justify-center gap-3 rounded-full border-[1px] border-colorBorder  px-5 py-2 text-center">
                            <div
                              className={`rounded-full bg-green p-[.35rem]`}
                            />
                            <p className="capitalize">
                              {getPaymentStatusFromValue("Canjeado")}{" "}
                            </p>
                          </div>
                          <p>{formatDateHours(row.updatedAt)}</p>
                        </div>
                      ) : (
                        <div>
                          {!compareDate(row.event.date_end) ? (
                            <div>
                              <p>Evento no comenzado</p>
                            </div>
                          ) : (
                            <div>
                              <div className="mr-[24px] flex w-[150px] items-center justify-center gap-3 rounded-full border-[1px] border-colorBorder  px-5 py-2 text-center">
                                <div
                                  className={`rounded-full bg-[#CBA006] p-[.35rem]`}
                                />
                                <p className="capitalize">
                                  {getPaymentStatusFromValue("No canjeado")}{" "}
                                </p>
                              </div>
                            </div>
                          )}
                        </div>
                      )}
                    </td>
                    <td>
                      <DropdownDots
                        options={[
                          {
                            title: "Ver trazabilidad del ticket",
                            action: () =>
                              navigate(`/admin/orderTicket/${row.id}`),
                          },
                        ]}
                      />
                    </td>
                  </motion.tr>
                ) : (
                  <div></div>
                )
              ) : (
                <div></div>
              )
            )}
          </tbody>
        </table>

        <h2 className="mt-[24px] font-sans text-[16px] font-[700] leading-[24px]">
          Orden
        </h2>

        <div className="relative mt-[13px] w-full overflow-x-auto rounded-[12px] border-[1px] border-solid border-[#DDDDDD] px-[34px] pb-[34px] pt-[38px]">
          <table className="w-full text-left">
            <thead className=" ">
              <tr>
                <th className="font-sans text-[14px] font-[700] leading-[21px] text-[#8083A3]">
                  Orden Nº
                </th>
                <th className="font-sans text-[14px] font-[700] leading-[21px] text-[#8083A3]">
                  Fecha de pago
                </th>
                <th className="font-sans text-[14px] font-[700] leading-[21px] text-[#8083A3]">
                  Forma de pago
                </th>
              </tr>
            </thead>
            <tbody>
              <motion.tr
                layout
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                initial={{ opacity: 0.2 }}
                transition={{ type: "spring", stiffness: 100, bounce: 0 }}
              >
                <td className="pl-4">{data?.id}</td>
                <td className="pl-4">{formatDate(data?.updatedAt)}</td>
                <td className="pl-4">
                  {user_id_data && user_id_data === data?.user_id ? (
                    <div>
                      {getPaymentMethodMPFromValue(data?.payment_method_id)}
                    </div>
                  ) : (
                    <div></div>
                  )}
                </td>
              </motion.tr>
            </tbody>
          </table>
        </div>
      </div>
    </PageContainer>
  );
};

import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { FaEdit, FaTrashAlt } from "react-icons/fa";
import { GoGraph } from "react-icons/go";
import { useMutation } from "@tanstack/react-query";
import { motion } from "framer-motion";

import { TitleSection } from "../TitleSection";
import { PageContainer } from "../PageContainer";
import DropdownDots from "../FormElement/DropdownDots";
import TableCellLink from "../FormElement/TableCellLink.tsx";
import { FilterPage } from "../table/FilterPage";
import { FilterQtyPage } from "../table/FilterQtyPage";
import { FilterOrder } from "../table/FilterOrder";
import { FilterSection } from "../table/FilterSection";
import { formatDate } from "../../utils/format-date";
import { IClientResponse } from "../../types/IClient";
import ISortOption from "../../types/ISortOption";
import { IFilterRequest } from "../../types/IFilter";
import { IRrppResponse, IRrppsResponse } from "../../types/IRrpp";
import { IProducerResponse } from "../../types/IProducer";
import { getTeamRoleFromValue } from "../../utils/roles";
import { AuthContext } from "../../context/AuthContext";
import { StateContext } from "../../context/StateProvider";
import ProducerService from "../../services/producerService";
import RrppService from "../../services/rrppService";
import { Back } from "../Back";
import config from "../../config/variables";

type TableProducersProps = {
  handleCreateClient: () => void;
  handleSelect?: (data?: IClientResponse) => void;
};

type rowProps = {
  rowData: {
    date: string;
    name: string;
    email: string;
    status: string;
  };
  qty?: number;
};

const defaultFilters: any = {
  limit: config.filter_default_limit,
  page: 1,
  status: "all",
  user_role: ["validator"],
  sortBy: "createdAt",
  sortOrder: "desc",
  query: "",
};

const columnData = [
  "Fecha de alta",
  "Nombre",
  "Email",
  "Rol",
  "Status",
  "Acciones",
];

const filterSectionProps = [
  { title: "Todos", id: "all" },
  { title: "Activos", id: "active" },
  { title: "Pausados", id: "paused" },
];

export const TableValidators = () => {
  const { user, role, producer } = useContext(AuthContext);
  const { dispatch } = useContext(StateContext);
  const navigate = useNavigate();
  const { producer_id } = useParams();

  const getItems = useMutation(() => RrppService.getAll(filters));
  const getCsv = useMutation(() => RrppService.getCsv({}));
  const deleteItems = useMutation((id: string) =>
    RrppService.deleteAccount(id)
  );
  const getProducer = useMutation((id: string) => ProducerService.get(id));

  let paramsFilters: any = { status: "", sortBy: "" };

  const [result, setResult] = useState<IRrppsResponse>({
    rows: [],
    pages: 0,
    total: 0,
  });

  const [currentProducer, setCurrentProducer] = useState<IProducerResponse>();
  const [filters, setFilters] = useState<IFilterRequest>({
    ...defaultFilters,
    producer_producer_id: producer_id || producer?.id,
  });
  const [search, setSearch] = useState("");
  const [timer, setTimer] = useState(0);

  useEffect(() => {
    handleFilter(filters);
    if (producer_id || producer?.id) {
      const pID = producer_id || producer?.id || "";
      getProducer.mutateAsync(pID).then((res) => setCurrentProducer(res));
    }
  }, []);

  useEffect(() => {
    if (search != "") {
      const delayDebounceFn = setTimeout(() => {
        loadData();
      }, 600);
      return () => clearTimeout(delayDebounceFn);
    } else {
      loadData();
    }
  }, [search]);

  const loadData = () => {
    dispatch({ type: "showLoaderScreen", payload: true });
    getItems.mutateAsync().then((res) => {
      dispatch({ type: "showLoaderScreen", payload: false });
      setResult(res);
    });
  };

  const searcher = async (e: any) => {
    setSearch(e.target.value);
    paramsFilters = { ...filters, query: e.target.value };
    await setFilters({ ...filters, page: 1, query: e.target.value });
  };

  const handleFilter = async (filter: IFilterRequest) => {
    await setFilters({ ...filters, ...filter });
    await getItems.mutateAsync().then((res) => setResult(res));
  };

  const handleFilterSection = async (filter?: string) => {
    if (!filter) return;
    paramsFilters = { ...filters, status: filter };
    await setFilters({ ...filters, page: 1, status: filter });
    await getItems.mutateAsync().then((res) => setResult(res));
  };

  const handleSortSection = async (sortBy: string) => {
    const itemSort = sortOptions.find((s) => s.value == sortBy);
    paramsFilters = { ...filters, page: 1, sortBy: sortBy };
    if (itemSort) paramsFilters["sortOrder"] = itemSort.sortOrder;
    await setFilters(paramsFilters);
    await getItems.mutateAsync().then((res) => setResult(res));
  };

  const handleCreate = () => {
    const prefix =
      user.user_role != "admin" ? "/client" : `/admin/producers/${producer_id}`;
    navigate(prefix + `/validators/add`);
  };

  const handleEdit = (id: string) => {
    const prefix =
      user.user_role != "admin" ? "/client" : `/admin/producers/${producer_id}`;
    navigate(prefix + `/validators/${id}/edit`);
  };

  const handleDeleteAccount = async (id: string) => {
    if (window.confirm("Está seguro de eliminar este validador?")) {
      await deleteItems.mutateAsync(id).then((res) => {
        getItems.mutateAsync().then((res) => setResult(res));
      });
    }
  };

  const sortOptions: Array<ISortOption> = [
    { value: "createdAt", text: "Fecha", sortOrder: "desc" },
    { value: "first_name", text: "Nombre", sortOrder: "asc" },
  ];

  const handleCsv = async () => {
    await getCsv.mutateAsync().then((response) => {
      const url = window.URL.createObjectURL(new Blob([response]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "sellers.csv");
      document.body.appendChild(link);
      link.click();
    });
  };

  const getProducerDetailLink = (id: string) => {
    const prefix =
      user.user_role != "admin" ? "/client" : `/admin/producers/${producer_id}`;
    return `${prefix}/validators/${id}`;
  };

  const getTeamStatusForProducer = (row: IRrppResponse): string => {
    if (row.teams && row.teams.length && (producer_id || producer?.id)) {
      const pID = producer_id || producer?.id || "";
      const team = row.teams.find(
        (i) => i.producer_producer_id == producer?.id
      );
      if (team) {
        return team.status;
      }
    }
    return row.status || "pending";
  };

  const [stateOrder, setState] = useState("asc");
  const handleChangeState = async (state: string) => {
    setState(state);
    await setFilters({ ...filters, sortOrder: state });
    loadData();
  };

  const handleBack = () => {
    navigate(`/admin/producers/${currentProducer?.client_id}`);
  };

  return (
    <PageContainer>
      {!!producer_id && (
        <div className="pb-4">
          <Back onClick={handleBack} />
        </div>
      )}

      <div className="flex justify-between">
        <TitleSection>
          Validadores{" "}
          {currentProducer && role == "admin" && `(${currentProducer.name})`}
        </TitleSection>
        {(producer?.id || producer_id) && (
          <button
            onClick={() => handleCreate()}
            className="rounded-full border-[2px] border-black-100 px-12 py-2 font-bold text-black-100 hover:bg-black-100 hover:text-white"
          >
            Crear nuevo validador
          </button>
        )}
      </div>

      <div className="mb-7 flex justify-between border-b-[1px] border-colorBorder pr-5 pt-10  ">
        <FilterSection
          filterSectionProps={filterSectionProps}
          handleFilter={handleFilterSection}
        />
        <div className="flex items-center gap-5 ">
          <button
            className="flex items-center justify-center text-[#3E7EFF]"
            onClick={handleCsv}
          >
            <GoGraph className="mr-1" />
            Exportar a CSV
          </button>
          <input
            type="text"
            placeholder="Search..."
            className="hover:text-black rounded-full border-[2px] border-black-100 px-4 py-1 font-bold  text-black-100"
            value={search}
            onChange={searcher}
          />
          <FilterOrder
            setSort={handleSortSection}
            options={sortOptions}
            handleChangeState={handleChangeState}
            state={stateOrder}
          />
        </div>
      </div>

      <table className="my-table-spacing h-full w-full border-separate border-spacing-y-2 gap-5 overflow-x-auto pt-5 text-left">
        <thead className=" divide-solid border ">
          <tr className=" pb-4 text-sm font-bold text-gray-100">
            {columnData.map((column, index) => (
              <th
                className="border-b-[1px] border-colorBorder pb-4"
                key={column + index}
              >
                {column}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {result.rows.map((row, i) => (
            <motion.tr
              key={i}
              layout
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              initial={{ opacity: 0.2 }}
              transition={{ type: "spring", stiffness: 100, bounce: 0 }}
              className={`h-16  rounded-xl text-start font-medium outline outline-[1px] outline-colorBorder`}
            >
              <TableCellLink
                href={getProducerDetailLink(row.id)}
                className="pl-4"
              >
                {formatDate(row?.createdAt)}
              </TableCellLink>
              <TableCellLink
                href={getProducerDetailLink(row.id)}
                className="pl-4"
              >
                {`${row.first_name} ${row.last_name}`}
              </TableCellLink>
              <TableCellLink
                href={getProducerDetailLink(row.id)}
                className="flex h-full items-center gap-2 pl-4"
              >
                {row.email}
              </TableCellLink>
              <TableCellLink
                href={getProducerDetailLink(row.id)}
                className="pl-4"
              >
                {getTeamRoleFromValue(row.user_role)}
              </TableCellLink>
              <td className="pl-4">
                <div className="flex w-32 items-center justify-center gap-3 rounded-full border-[1px] border-colorBorder  py-2 text-center ">
                  <div
                    className={`rounded-full  ${
                      getTeamStatusForProducer(row) === "active"
                        ? "bg-green"
                        : "bg-red"
                    } p-[.35rem]`}
                  />
                  <p className="capitalize">
                    {getTeamRoleFromValue(getTeamStatusForProducer(row))}
                  </p>
                </div>
              </td>
              <td className="pl-4">
                <DropdownDots
                  options={[
                    {
                      title: "Editar",
                      action: () => handleEdit(row.id),
                      icon: <FaEdit size={23} />,
                    },
                    {
                      title: "Eliminar",
                      action: () => handleDeleteAccount(row.id),
                      icon: <FaTrashAlt size={23} />,
                    },
                  ]}
                />
              </td>
            </motion.tr>
          ))}
        </tbody>
      </table>
      {result.rows.length == 0 && (
        <p className="text-center">No existen validadores disponibles</p>
      )}
      <div className="flex justify-between pt-10">
        <FilterPage
          handleFilter={handleFilter}
          total={result?.total}
          limit={filters.limit}
          status={filters.status}
          currentPage={filters.page}
        />
        <FilterQtyPage handleFilter={handleFilter} status={filters.status} />
      </div>
    </PageContainer>
  );
};

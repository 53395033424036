import { Oval } from "react-loader-spinner";

export default function LoaderCircle({ height = 64, width = 64 }) {
  return (
    <Oval
      height={height}
      width={width}
      color="#000000"
      wrapperStyle={{}}
      wrapperClass=""
      visible={true}
      ariaLabel="oval-loading"
      secondaryColor="#E4E6E8"
      strokeWidth={4}
      strokeWidthSecondary={4}
    />
  );
}

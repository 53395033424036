import api from "./api";
import authHeader from "./authHeader";
import { INotificationResponse } from "../types/INotification";
import { IPaginationResponse } from "../types/IPagination";

const getAll = async (params: object = {}) => {
  const response = await api.get<IPaginationResponse>(`notifications/list`, {
    headers: authHeader(),
    params: params,
  });
  return response.data;
};

const get = async (id: string) => {
  const response = await api.get<INotificationResponse>(
    `notifications/detail/${id}`,
    {
      headers: authHeader(),
    }
  );
  return response.data;
};

const create = async (item: FormData) => {
  const response = await api.post<INotificationResponse>(
    `notifications/create`,
    item,
    {
      headers: {
        "Content-Type": "multipart/form-data",
        ...authHeader(),
      },
    }
  );
  return response.data;
};

const update = async (id: string | undefined, item: FormData) => {
  const response = await api.put<INotificationResponse>(
    `notifications/update/${id}`,
    item,
    {
      headers: {
        "Content-Type": "multipart/form-data",
        ...authHeader(),
      },
    }
  );
  return response.data;
};

const remove = async (id: string) => {
  const response = await api.delete<INotificationResponse>(
    `notifications/delete/${id}`,
    {
      headers: authHeader(),
    }
  );
  return response.data;
};

const NotificationService = {
  getAll,
  get,
  create,
  update,
  remove,
};

export default NotificationService;

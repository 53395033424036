export const ItemErrorCSV = ({ item, error }: any) => {
  return (
    <div className="overflow-wrap w-[47rem] flex-col flex-wrap border-b px-10 pb-5 pt-5">
      <div className="break-words font-sans font-medium">
        {item
          .replaceAll("[", "")
          .replaceAll(",", " – ")
          .replaceAll('"', "")
          .replaceAll("]", "")}
      </div>
      <div className="font-sans font-medium text-red">{error}</div>
    </div>
  );
};

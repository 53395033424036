import * as React from "react";
import { CalendarIcon } from "@radix-ui/react-icons";
import { addDays, format } from "date-fns";
import { DateRange } from "react-day-picker";

import { cn } from "@/lib/utils";
import { Button } from "@/components/ui/button";
import { Calendar } from "@/components/ui/calendar";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";

export function DatePickerWithRange({
  className,
  onDateChange,
  date,
}: React.HTMLAttributes<HTMLDivElement> & {
  date: DateRange | undefined;
  onDateChange: (date: DateRange | undefined) => void;
}) {
  // const [date, setDate] = React.useState<DateRange | undefined>(undefined);

  const selectedClass = date ? "bg-background text-black" : "";
  return (
    <div className={cn("z-10 grid gap-2", className)}>
      <Popover>
        <PopoverTrigger asChild>
          <Button
            id="date"
            variant={"outline"}
            className={cn(
              `${!date && "text-black"} w-[225px] justify-start ${
                !date && "bg-background"
              } text-left font-normal`,
              !date && `${selectedClass}`
            )}
          >
            <CalendarIcon className="mr-2 h-4 w-4" />
            {date?.from ? (
              date.to ? (
                <>
                  {format(date.from, "LLL dd, y")} -{" "}
                  {format(date.to, "LLL dd, y")}
                </>
              ) : (
                format(date.from, "LLL dd, y")
              )
            ) : (
              <span>Elegí una fecha</span>
            )}
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-auto p-0" align="start">
          <Select
            onValueChange={(value) =>
              onDateChange({
                from: new Date(),
                to: addDays(new Date(), parseInt(value)),
              })
            }
          >
            <SelectTrigger>
              <SelectValue placeholder="Select" />
            </SelectTrigger>
            <SelectContent position="popper">
              <SelectItem value="1">Hoy</SelectItem>
              <SelectItem value="2">Mañana</SelectItem>
              <SelectItem value="7">Esta Semana</SelectItem>
              <SelectItem value="31">Este Mes</SelectItem>
            </SelectContent>
          </Select>
          <Calendar
            initialFocus
            mode="range"
            defaultMonth={date?.from}
            selected={date}
            onSelect={onDateChange}
            numberOfMonths={2}
          />
          {date && (
            <Button
              id="clear"
              variant={"outline"}
              onClick={() => onDateChange(undefined)}
              className={cn(
                `${!date && "text-black"} ${
                  !date && "bg-background"
                } w-[100%] text-right font-normal`
              )}
            >
              Borrar
            </Button>
          )}
        </PopoverContent>
      </Popover>
    </div>
  );
}

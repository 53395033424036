import { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Controller, FormProvider, useForm } from "react-hook-form";
import "react-quill/dist/quill.snow.css";

import FormInput from "../FormElement/FormInput";
import { ITermRequest } from "../../types/ITerms";
import termsService from "../../services/termsService";
import { StateContext } from "../../context/StateProvider";

const defaultValues = {
  title: "",
  description: "",
};

export const FormTerms = ({}) => {
  const navigate = useNavigate();

  const [disabledSubmit, setDisabledSubmit] = useState(false);

  const form = useForm<ITermRequest>({
    defaultValues: defaultValues,
  });

  useEffect(() => {
    termsService.getAll().then((res) => {
      form.setValue("title", res[0].title);
      form.setValue("description", res[0].description);
    });
  }, [form.reset]);

  const { state, dispatch } = useContext(StateContext);

  const onSubmit = async (data: ITermRequest) => {
    setDisabledSubmit(true);
    dispatch({ type: "showLoaderScreen", payload: true });
    await termsService
      .update("0", data)
      .then((res) => {
        setDisabledSubmit(false);
        dispatch({ type: "showLoaderScreen", payload: false });
        dispatch({ type: "toastSuccess", payload: "Términos actualizados" });
        navigate("/admin/terms");
      })
      .catch((reason) => setDisabledSubmit(false));
  };

  const onError = (error: any) => {
    console.log("error ", error);
  };

  return (
    <div className="flex flex-col gap-10 xl:px-[23rem]">
      <h1 className="text-center text-3xl font-bold"> Editar Términos </h1>
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(onSubmit, onError)}>
          <FormInput name="title" type="text" label="Titulo" />
          <Controller
            name="description"
            render={({ field }) => (
              <textarea
                className="h-24 w-full rounded-lg border border-colorBorder pl-8 pt-6 font-bold text-black-100 focus:border-none focus:outline-none  focus:ring-colorBorder"
                placeholder="descripcion"
                {...field}
              />
            )}
          />
          <div className="mb-16 flex justify-center gap-10 pt-10">
            <button
              type="submit"
              className="rounded-full border-[2px] border-black-100 bg-black-100 px-[5.4rem] py-2 font-medium  text-white"
              disabled={disabledSubmit}
            >
              Guardar Términos
            </button>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

import { ChangeEvent, Dispatch, SetStateAction, useState } from "react";
import { QueryObserverResult, useMutation } from "@tanstack/react-query";
import { yupResolver } from "@hookform/resolvers/yup";

import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import {
  Form,
  FormControl,
  FormDescription,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Button } from "../../ui/button";
import { Input } from "../../ui/input";
import { IOfferResponse } from "../../../types_new/IOffer";
import { useForm } from "react-hook-form";
import { editPriceSchema } from "./editPriceSchema";
import {
  formatMoney,
  roundToTwoDecimals,
} from "../../../utils_new/formatMoney";
import { servicePercentage } from "../constants/servicePercentage";
import OfferService from "../../../services_new/offerService";
import { toast } from "react-toastify";

interface DialogEditPriceProps {
  isDialogOpen: boolean;
  setIsDialogOpen: Dispatch<SetStateAction<boolean>>;
  offer: IOfferResponse;
  refetch: () => Promise<QueryObserverResult>;
}

type formDefaultValues = {
  total: number | null;
  subtotal: number | null;
};

const defaultValues: formDefaultValues = {
  total: null,
  subtotal: null,
};

export const DialogEditPrice = ({
  isDialogOpen,
  setIsDialogOpen,
  offer,
  refetch,
}: DialogEditPriceProps) => {
  const [newSubtotal, setNewSubtotal] = useState<number>(0);
  const [newTotal, setNewTotal] = useState<number>(0);
  const [newService, setNewService] = useState<number>(0);

  const isNewSubtotalValid = !!(
    offer &&
    newSubtotal &&
    newSubtotal >= offer.ticket_price
  );

  const form = useForm<formDefaultValues>({
    resolver: yupResolver(editPriceSchema),
    defaultValues: defaultValues,
  });

  const handleChangeSubtotal = (evt: ChangeEvent<HTMLInputElement>) => {
    const enteredSubtotal = roundToTwoDecimals(+evt.target.value);
    const total = roundToTwoDecimals(
      +evt.target.value / (1 - servicePercentage)
    );
    setNewSubtotal(enteredSubtotal);
    setNewTotal(total);
    setNewService(roundToTwoDecimals(total - enteredSubtotal));
  };

  const handleChangeTotal = (evt: ChangeEvent<HTMLInputElement>) => {
    const enteredTotal = roundToTwoDecimals(+evt.target.value);
    const service = roundToTwoDecimals(+evt.target.value * servicePercentage);
    setNewTotal(enteredTotal);
    setNewService(service);
    setNewSubtotal(roundToTwoDecimals(enteredTotal - service));
  };

  const updateOfferPrice = useMutation(
    ({
      id,
      newSubtotal,
      newService,
      newTotal,
    }: {
      id: string;
      newSubtotal: number;
      newService: number;
      newTotal: number;
    }) =>
      OfferService.update(id, {
        subtotal: newSubtotal,
        service: newService,
        total: newTotal,
      }),
    {
      onSuccess: (res) => {
        toast.success("Oferta editada correctamente.");
        refetch();
      },
      onError: (err) => {
        toast.error("No pudimos editar la oferta.");
      },
    }
  );

  const onEditPrice = () => {
    updateOfferPrice.mutate({
      id: offer.id,
      newTotal,
      newSubtotal,
      newService,
    });
    setIsDialogOpen(false);
  };

  return (
    <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
      <DialogContent className="text-center">
        <DialogHeader>
          <DialogTitle className="text-center text-3xl">
            Editar Precio
          </DialogTitle>
        </DialogHeader>

        {!isNewSubtotalValid && (
          <p className="mt-[-10px] text-sm text-red">
            El precio debe ser mayor a {formatMoney(offer.ticket_price)} (precio
            original del ticket)
          </p>
        )}

        <Form {...form}>
          <form className="space-y-8">
            <div className="grid grid-cols-3">
              <FormItem>
                <FormLabel>Subtotal</FormLabel>
                <FormControl>
                  <Input
                    type="number"
                    className="h-9 w-full"
                    placeholder="Subtotal..."
                    value={newSubtotal}
                    onChange={handleChangeSubtotal}
                  />
                </FormControl>
                <FormDescription>Lo que recibe el vendedor</FormDescription>
                <FormDescription>
                  Subtotal actual
                  <br />
                  {formatMoney(offer.subtotal)}
                </FormDescription>
                <FormMessage />
              </FormItem>

              <FormItem>
                <FormLabel>Servicio</FormLabel>
                <p className="grid h-9 place-items-center">
                  {formatMoney(newService ?? 0)}
                </p>
                <FormDescription>Comisión BOMBO</FormDescription>
                <FormDescription>
                  Servicio actual
                  <br />
                  {formatMoney(offer.service)}
                </FormDescription>
                <FormMessage />
              </FormItem>

              <FormItem>
                <FormLabel>Total</FormLabel>
                <FormControl>
                  <Input
                    type="number"
                    className="h-9 w-full"
                    placeholder="Total..."
                    value={newTotal}
                    onChange={handleChangeTotal}
                  />
                </FormControl>
                <FormDescription>Lo que paga el comprador</FormDescription>
                <FormDescription>
                  Total actual
                  <br />
                  {formatMoney(offer.total)}
                </FormDescription>
                <FormMessage />
              </FormItem>
            </div>

            <Button
              type="button"
              onClick={onEditPrice}
              disabled={!isNewSubtotalValid}
            >
              Confirmar edición
            </Button>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
};

import React, { useEffect, useState, useContext } from "react";
import { motion } from "framer-motion";
import { Title } from "../../../events/Title";
import { useMutation } from "@tanstack/react-query";
import RrppService from "../../../../services/rrppService";
import { IFilterRequest } from "../../../../types/IFilter";
import { useNavigate, useParams } from "react-router-dom";
import { FilterPage } from "../../../table/FilterPage";
import { FilterQtyPage } from "../../../table/FilterQtyPage";
import { IRrppResponse, IRrppsResponse } from "../../../../types/IRrpp";
import { getTeamRoleFromValue } from "../../../../utils/roles";
import DropdownDots from "../../../FormElement/DropdownDots";
import { FaTrashAlt } from "react-icons/fa";
import { PictureAndName } from "../../components/PictureAndName/PictureAndName";
import notFound from "../../../../assets/notFound.png";
import { StateContext } from "../../../../context/StateProvider";
import { AuthContext } from "../../../../context/AuthContext";
import TeamService from "../../../../services/teamService";
import LoaderCircle from "../../../FormElement/LoaderCircle";
import config from "../../../../config/variables";
import TableCellLink from "../../../FormElement/TableCellLink";

const defaultResult = {
  total: 0,
  pages: 0,
  rows: [],
};

const defaultFilters: any = {
  limit: config.filter_default_limit,
  page: 1,
};

export const LeaderSeller = () => {
  const auth = useContext(AuthContext);
  const { seller_id, event_id } = useParams();
  const navigate = useNavigate();

  const [result, setResult] = useState<IRrppsResponse>(defaultResult);
  const [filters, setFilters] = useState<any>({
    ...defaultFilters,
    leader_id: seller_id,
    producer_producer_id: auth.producer?.id,
    event_id: event_id,
  });
  const [showLoader, setShowLoader] = useState(false);
  const [viewLoader, setViewLoader] = useState(true);
  const getItems = useMutation(() => RrppService.getAll(filters));

  const deleteItems = useMutation((id: string) => TeamService.remove(id));

  useEffect(() => {
    handleFilter({});
  }, []);

  const handleFilter = async (filter: IFilterRequest) => {
    setShowLoader(true);
    await setFilters({ ...filters, ...filter });
    await getItems.mutateAsync().then((res) => {
      setResult(res);
      setShowLoader(false);
    });
  };

  const { dispatch } = useContext(StateContext);

  const handleDelete = async (row: IRrppResponse) => {
    if (window.confirm("Esta seguro de eliminar al vendedor del equipo?")) {
      if (row.teams && row.teams.length) {
        const team = row.teams.find(
          (i) =>
            i.user_id == row.id && i.producer_producer_id == auth.producer?.id
        );
        if (team) {
          await deleteItems.mutateAsync(team.id).then((res) => {
            handleFilter(filters);
            dispatch({
              type: "toastSuccess",
              payload: "El registro ha sido eliminado",
            });
          });
        }
      }
    }
  };
  const columnData = [
    "Nombre",
    "Tipo",
    "Email",
    "Entradas vendidas",
    "Eventos",
    "",
    "",
  ];

  const getDetailUrl = (idDetail: string) => {
    const prefix = auth.user.user_role != "admin" ? "/client" : "/admin";
    return prefix + "/rrpp/" + idDetail;
  };

  const handleDetail = async (id: string) => {
    setViewLoader(false);
    dispatch({ type: "showLoaderScreen", payload: true });
    await handleFilter({ ...filters, leader_id: id }).then((res) => {
      navigate(getDetailUrl(id));
      dispatch({ type: "showLoaderScreen", payload: false });
    });
  };

  const getTeamRoleForProducer = (row: IRrppResponse) => {
    if (row.teams && row.teams.length) {
      const team = row.teams.find(
        (i) => i.producer_producer_id == auth.producer?.id
      );
      if (team) {
        return team.team_role;
      }
    }
    return row.user_role;
  };

  return (
    <div>
      <Title>Vendedores en su team</Title>
      <table className="my-table-spacing h-full w-full border-separate border-spacing-y-2 gap-5 overflow-x-auto pt-10 text-left">
        <thead className=" divide-solid border ">
          <tr className=" pb-4 text-sm font-bold text-gray-100">
            {columnData.map((column, index) => (
              <th
                className="border-b-[1px] border-colorBorder pb-4"
                key={column + index}
              >
                {column}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {result?.rows?.map((row) => (
            <motion.tr
              key={row.id}
              layout
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              initial={{ opacity: 0.2 }}
              transition={{ type: "spring", stiffness: 100, bounce: 0 }}
              className={`h-16  cursor-pointer rounded-xl text-start font-medium outline outline-[1px] outline-colorBorder`}
            >
              <TableCellLink className="pl-4" href={getDetailUrl(row.id)}>
                <PictureAndName
                  profilePicture={row.profile_picture}
                  firstName={row.first_name}
                  lastName={row.last_name}
                />
              </TableCellLink>
              <TableCellLink className="pl-4" href={getDetailUrl(row.id)}>
                {getTeamRoleFromValue(getTeamRoleForProducer(row))}
              </TableCellLink>
              <TableCellLink className="pl-4" href={getDetailUrl(row.id)}>
                {row.email}
              </TableCellLink>
              <TableCellLink className="pl-4" href={getDetailUrl(row.id)}>
                {row.tickets_sold}
              </TableCellLink>
              <TableCellLink className="pl-4" href={getDetailUrl(row.id)}>
                {row.events_count}
              </TableCellLink>
              <TableCellLink
                className="flex h-full items-center justify-start gap-5 pr-5"
                href={getDetailUrl(row.id)}
              >
                {row.status == "active" && (
                  <div className="flex w-32 items-center justify-center gap-3 rounded-full border-[1px] border-colorBorder  py-2 text-center ">
                    <div className="rounded-full  bg-green p-[.35rem] " />
                    <p className="capitalize">Activos</p>
                  </div>
                )}
                {row.status == "pending" && (
                  <div className="flex w-60 items-center justify-center gap-3 rounded-full border-[1px] border-colorBorder  py-2 text-center ">
                    <div className="rounded-full  bg-[#F4BE5E] p-[.35rem] " />
                    <p className="capitalize">Pendiente de aprobación</p>
                  </div>
                )}
                {row.status == "paused" && (
                  <div className="flex w-32 items-center justify-center gap-3 rounded-full border-[1px] border-colorBorder  py-2 text-center ">
                    <div className="rounded-full  bg-red p-[.35rem] " />
                    <p className="capitalize">Pausado</p>
                  </div>
                )}
              </TableCellLink>
              <td>
                <DropdownDots
                  options={[
                    {
                      title: "Eliminar del equipo",
                      action: () => handleDelete(row),
                      icon: <FaTrashAlt size={23} />,
                    },
                  ]}
                />
              </td>
            </motion.tr>
          ))}
        </tbody>
      </table>
      {showLoader && viewLoader ? (
        <div className="flex items-center justify-center">
          <LoaderCircle />
        </div>
      ) : (
        result.rows.length == 0 && (
          <div className="my-12 text-center">
            <img
              src={notFound}
              alt="check"
              className="m-auto mb-4 text-center"
            />
            <p className="text-center">Aún no tiene vendedores asignados</p>
            <button
              onClick={() => navigate("/client/rrpp/new-seller/" + seller_id)}
              className="m-auto mt-1 rounded-full border-[2px] border-black-100 bg-black-100 px-12 py-2 font-medium text-white"
            >
              Agregar vendedor
            </button>
          </div>
        )
      )}
      {result.rows.length > 0 && (
        <div className="flex justify-between pt-10">
          <FilterPage
            handleFilter={handleFilter}
            total={result.total}
            limit={filters.limit}
            status={filters.status}
            currentPage={filters.page}
          />
          <FilterQtyPage handleFilter={handleFilter} status={filters.status} />
        </div>
      )}
    </div>
  );
};

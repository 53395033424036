import React, { useEffect, useState } from "react";

type FilterSectionProps = {
  filterSectionProps: Array<optionProps>;
  handleFilter: (id: string) => void;
  defaultSelected?: string;
};

type optionProps = {
  title: string;
  id: string;
  action?: (filter: string) => void;
  selected?: boolean;
  handleChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

export const FilterSection = ({
  filterSectionProps,
  handleFilter,
  defaultSelected = "all",
}: FilterSectionProps) => {
  const [selected, setSelected] = useState(defaultSelected);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // console.log(e.target.value);
    setSelected(e.target.value);
  };

  return (
    // <form className="relative flex font-medium text-gray-100">
    <div className="relative flex font-medium text-gray-100">
      {filterSectionProps?.map((option: optionProps) => (
        <FilterOption
          key={option.id}
          title={option.title}
          id={option.id}
          action={handleFilter}
          selected={selected === option.id}
          handleChange={handleChange}
        />
      ))}
    </div>
    // </form>
  );
};

export const FilterOption = ({
  title,
  id,
  action,
  selected,
  handleChange,
}: optionProps) => {
  return (
    <label htmlFor={id} className="h-full cursor-pointer select-none  ">
      <input
        type="radio"
        name="filterTable"
        id={id}
        value={id}
        className="peer hidden"
        checked={selected}
        onChange={handleChange}
      />
      <div
        className="h-12 px-6 text-center  peer-checked:border-b-[4px] peer-checked:border-black-200 peer-checked:text-black-100"
        onClick={() => (action ? action(id) : () => {})}
      >
        {title}
      </div>
    </label>
  );
};

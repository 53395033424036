import { Fragment, useContext, useEffect, useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { motion } from "framer-motion";
import { toast } from "react-toastify";
import jsonToFormData from "@ajoelp/json-to-formdata";

import { IUserResponse } from "../../../../types/IUser";
import { IOrderResponse } from "../../../../types/IOrder";
import { IEventResponse } from "../../../../types/IEvent";
import { IOrderTicketResponse } from "../../../../types/IOrderTicket";
import { getPaymentMethodMPFromValue } from "../../../../utils/payments";
import { formatDate, formatHumanDateTime } from "../../../../utils/format-date";
import { getFullNameOrNickname } from "../../../../utils/form";
import { getPaymentStatusFromValue } from "../../../../utils/status";
import { formatMoney } from "../../../../utils/format-money";
import USerService from "../../../../services/userService";
import OrderService from "../../../../services/orderService";
import OrdesService from "../../../../services/orderService";
import EventService from "../../../../services/eventService";
import { StateContext } from "../../../../context/StateProvider";
import { AuthContext } from "@/context/AuthContext";
import variables from "../../../../config/variables";
import { PictureAndName } from "../../../rrpp/components/PictureAndName/PictureAndName";
import { SearchAutocomplete } from "../../../rrpp/newSeller/components/SearchAutocomplete/SearchAutocomplete";
import TableCellLink from "@/components/FormElement/TableCellLink";
import { capitalize } from "lodash";

type selectOrderProps = {
  data: IOrderResponse;
  loadOrder: () => void;
};

const options: Array<any> = [
  { value: "pending", text: "Pendiente" },
  { value: "approved", text: "Aprobado" },
  { value: "in_process", text: "En proceso" },
  { value: "rejected", text: "Rechazado" },
];

export const OrderData = ({ data, loadOrder }: selectOrderProps) => {
  const { state, dispatch } = useContext(StateContext);
  const { role } = useContext(AuthContext);
  const [status, setStatus] = useState<any>("");
  const [afipCae, setAfipCae] = useState<any>(data.afip_cae || "");
  const [dataUser, setDataUser] = useState<IUserResponse>();
  const [dataRefund, setDataRefund] = useState<IOrderResponse>();
  const [dataOrder, setDataOrder] = useState<IOrderResponse>();
  const [refunded, setRefunded] = useState<boolean>(true);
  const [eventData, setEventData] = useState<IEventResponse>();
  const [showSellers, setShowSellers] = useState<boolean>(false);
  const [textSeller, setTextSeller] = useState("");
  const [selectDataSeller, setSelectDataSeller] =
    useState<IUserResponse | null>(null);
  const [seller, setSeller] = useState<any>(null);
  const [teamMembers, setTeamMembers] = useState<any>([]);
  const [filteredTeamMembers, setFilteredTeamMembers] = useState<any>([]);

  useEffect(() => {
    if (data.user_id) {
      setStatus(data.status);
      loadDataUserOrder();
    }
  }, [data.user_id]);

  useEffect(() => {
    if (data.external_reference) {
      loadDataRefund();
    } else {
      setRefunded(false);
    }
  }, [data.external_reference]);
  useEffect(() => {
    if (data.external_reference) {
      loadDataOrder();
    }
  }, [data.id]);
  useEffect(() => {
    if (!state.showRequestRefund && data.external_reference) {
      loadDataRefund();
    }
  }, [state.showRequestRefund]);
  useEffect(() => {
    getEvent.mutateAsync().then((res) => {
      setEventData(res);
      setTeamMembers(res.members?.map((member) => member?.user[0]));
    });
  }, []);

  const loadDataUserOrder = () => {
    getUser.mutateAsync().then((res: any) => {
      setDataUser(res);
    });
  };
  const loadDataRefund = () => {
    getRefund.mutateAsync().then((res: any) => {
      setDataRefund(res);
      if (!res || res.status != "refunded") setRefunded(false);
      else {
        setRefunded(true);
        setStatus(res.status);
        data.status = res.status;
        getOrder.mutateAsync().then((res: any) => {
          data.order_tickets = res.order_tickets;
        });
      }
    });
  };

  const loadDataOrder = () => {
    getOrderData.mutateAsync().then((res: any) => {
      setDataOrder(res);
    });
  };

  const { event_id, rrpp_id } = data;

  const getEvent = useMutation(() => EventService.get(event_id || ""));

  const getUser = useMutation(() => USerService.get(data.user_id));

  const getRefund = useMutation(() =>
    OrderService.getRefunds(data.external_reference)
  );

  const assignSeller = useMutation(() =>
    OrderService.assignSeller(data.id, selectDataSeller?.id)
  );

  const getOrder = useMutation(() => OrdesService.get(data.id || ""));

  const getOrderData = useMutation(() => OrdesService.getDataOrder(data.id));

  const handleViewMercadoPago = () => {
    OrdesService.getMpOrderURL(data.id)
      .then((res: any) => {
        if (res) {
          window.open(res, "_blank");
        } else {
          toast.error("La orden aún no está disponible");
        }
      })
      .catch((e) => {
        toast.error("La orden aún no está disponible");
      });
  };

  const handleDownloadInvoice = (order_id: string) => {
    window.location.href =
      variables.api_admin_url + "orders/invoice_pdf/" + order_id;
  };

  const createInvoice = () => {
    dispatch({ type: "showLoaderScreen", payload: true });
    const formData = jsonToFormData(
      { order_ids: [data.id] },
      {
        arrayIndexes: true,
        excludeNull: true,
      }
    );
    OrdesService.createInvoice(formData)
      .then((res: any) => {
        let msg = "Factura generada correctamente";
        let toastType = "toastSuccess";
        if (res.message["success"] != undefined) {
          msg = "Factura generada correctamente";
          if (res.message["failed"] || res.message["having"]) {
            msg =
              "No se puede generar la factura. La orden debe estar aprobada.";
          }
        }
        setAfipCae("true");
        dispatch({ type: "showLoaderScreen", payload: false });
        dispatch({ type: toastType, payload: msg });
      })
      .catch((e) => {
        dispatch({ type: "showLoaderScreen", payload: false });
        dispatch({
          type: "toastError",
          payload: "Ocurrió un error. Inténtelo de nuevo.",
        });
      });
  };

  const handleChangeStatus = (statuschange: string) => {
    if (statuschange == "refunded") {
      handleViewRefund();
      if (data.user_id) setStatus(data.status);
      return;
    }
    var formData = new FormData();
    formData.append("status", statuschange);
    setStatus(statuschange);
    OrdesService.update(data.id, formData).then((res: any) => {
      data.status = statuschange;
      dispatch({ type: "showLoaderScreen", payload: false });
      toast.success("Cambio de estado correctamente");
      loadOrder();
    });
  };

  const handleViewRefund = () => {
    dispatch({ type: "showRequestRefund", payload: true });
  };

  const getInvoiceData = () => {
    if (dataUser) {
      return (
        <>
          <p>{`${dataUser.first_name} ${dataUser.last_name}`}</p>
          {dataUser.address && <p>{dataUser.address}</p>}
        </>
      );
    }
  };
  let eventTitle = "";

  const ticketQuantities: any = {};

  data.order_tickets.forEach(
    (
      orderTicket: { ticket: any[]; qty: number; price: number; event: any },
      index: number
    ) => {
      if (!eventTitle) {
        eventTitle = orderTicket.ticket[0].name_event;
      }
      if (eventTitle === orderTicket.ticket[0].name_event) {
        orderTicket.ticket.forEach((ticket) => {
          if (ticketQuantities[ticket.name]) {
            ticketQuantities[ticket.name].qty += orderTicket?.qty;
            ticketQuantities[ticket.name].total +=
              orderTicket?.qty * ticket?.price;
            ticketQuantities[ticket.name].producer_name =
              orderTicket.event?.producer?.name;
          } else {
            ticketQuantities[ticket.name] = {
              qty: orderTicket.qty,
              price: ticket.price,
              total: orderTicket.qty * ticket.price,
              producer_name: orderTicket?.event?.producer?.name,
            };
          }
        });
      }
    }
  );
  let service = data?.service;

  let total_amount = data?.subtotal;

  const getOptions = (): Array<any> => {
    const basicOptions = options;
    if (data.total > 0) {
      return [...basicOptions, { value: "refunded", text: "Reembolsado" }];
    }
    return basicOptions;
  };

  useEffect(() => {
    if (rrpp_id) {
      const newSeller = teamMembers.find((member: any) => {
        return member?.id === rrpp_id;
      });
      setSeller(newSeller);
    }
  }, [teamMembers, rrpp_id]);

  const handleChangeSeller = async (option: string) => {
    setTextSeller(option);
    if (option !== "") {
      setFilteredTeamMembers(
        teamMembers.filter((member: any) => {
          return (
            member?.email?.toLowerCase().includes(textSeller.toLowerCase()) ||
            member?.full_name?.toLowerCase().includes(textSeller.toLowerCase())
          );
        })
      );
    } else {
      setFilteredTeamMembers([]);
    }
  };

  const handleSelectSeller = (data: any) => {
    setSelectDataSeller(data);
  };

  const propMethodsSearchSeller = {
    onChange: handleChangeSeller,
    handleSelect: handleSelectSeller,
  };

  const handleCloseSelectSeller = () => {
    setShowSellers(false);
    setTextSeller("");
    setSelectDataSeller(null);
  };

  const handleSetOrderSeller = () => {
    dispatch({ type: "showLoaderScreen", payload: true });
    assignSeller
      .mutateAsync()
      .then((res) => {
        setShowSellers(false);
        setSelectDataSeller(null);
        dispatch({ type: "showLoaderScreen", payload: false });
        dispatch({ type: "toastSuccess", payload: "Vendedor actualizado" });
        loadOrder();
      })
      .catch((err) => {
        dispatch({ type: "showLoaderScreen", payload: false });
        dispatch({
          type: "toastError",
          payload: "Error al actualizar el vendedor",
        });
      });
  };

  return (
    <div className="mt-4">
      <h1 className="text-center font-sans text-[20px] font-[700] leading-[32px] tracking-[-0.03em]">
        Detalle del pedido
      </h1>

      <h2 className="font-sans text-[16px] font-[700] leading-[24px]">
        Detalle de la orden
      </h2>
      <div className="mt-[15px] w-full rounded-[12px] border-[1px] border-solid border-[#DDDDDD] px-[34px] pb-[34px] pt-[38px]">
        <div className="grid grid-cols-1 gap-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
          <div className="my-[10px] w-full">
            <h3 className="font-sans text-[14px] font-[700] leading-[21px] text-[#8083A3]">
              Orden Nº
            </h3>
            <p className="w-full overflow-hidden font-sans text-[14px] font-[400] leading-[21px] text-[#000000]">
              #{data.id}
            </p>
          </div>
          <div className="my-[10px]">
            <h3 className="font-sans text-[14px] font-[700] leading-[21px] text-[#8083A3]">
              Fecha de pago
            </h3>
            <p className="font-sans text-[14px] font-[400] leading-[21px] text-[#000000]">
              {formatDate(data.updatedAt)}
            </p>
          </div>
          <div className="my-[10px]">
            <h3 className="font-sans text-[14px] font-[700] leading-[21px] text-[#8083A3]">
              Forma de pago
            </h3>
            <p className="font-sans text-[14px] font-[400] leading-[21px] text-[#000000]">
              {capitalize((data?.payment_method as string) || "mercado pago")} -{" "}
              {getPaymentMethodMPFromValue(data?.payment_method_id as string) ||
                "-"}
            </p>
          </div>
          {role == "admin" ? (
            <div>
              <div className="relative mr-8 cursor-pointer">
                <p className="absolute left-[20px] top-[11px] font-sans text-[14px] font-[700] leading-[21px] text-[#8083A3]">
                  Estado
                </p>
                <select
                  id="filterPage"
                  className="w-full rounded-xl border border-colorBorder pb-4 pl-[20px] pt-10 font-sans text-[12px] font-[700] leading-[21px] text-[#171721] focus:border-none focus:outline-none focus:ring-colorBorder  2xl:w-[297px]"
                  value={status}
                  onChange={(e) => handleChangeStatus(e.target.value)}
                >
                  {/*label || placeholder?<option value="">{placeholder || label}</option>:null*/}
                  {getOptions()?.map((_, i) => (
                    <option value={_.value ? _.value : _} key={i}>
                      {_.text ? _.text : _}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          ) : (
            <div className="my-[10px]">
              <h3 className="font-sans text-[14px] font-[700] leading-[21px] text-[#8083A3]">
                Estado
              </h3>
              <p className="font-sans text-[14px] font-[400] leading-[21px] text-[#000000]">
                {options.find((opt) => opt.value === data.status).text ?? ""}
              </p>
            </div>
          )}
        </div>
        <div className="mt-[20px] grid grid-cols-1 gap-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
          <div>
            <h3 className="font-sans text-[14px] font-[700] leading-[21px] text-[#8083A3]">
              Facturación
            </h3>
            <p className="font-sans text-[14px] font-[400] leading-[21px] text-[#000000]">
              {data.afip_cae
                ? getInvoiceData()
                : "No se tiene datos de Facturación"}
            </p>
          </div>
          <div>
            <h3 className="font-sans text-[14px] font-[700] leading-[21px] text-[#8083A3]">
              Dirección de correo electrónico
            </h3>
            <p className="font-sans text-[14px] font-[400] leading-[21px] text-[#000000]">
              {dataUser?.email}
            </p>
          </div>
          <div>
            <h3 className="font-sans text-[14px] font-[700] leading-[21px] text-[#8083A3]">
              Usuario Bombo
            </h3>
            {role == "admin" && dataUser?.id ? (
              <TableCellLink
                href={`/admin/users/${dataUser!.id}`}
                className="font-sans text-[14px] font-[400] leading-[21px] text-blue-500 underline"
              >
                {dataUser?.full_name}
              </TableCellLink>
            ) : (
              <p className="font-sans text-[14px] font-[400] leading-[21px] text-[#000000]">
                {dataUser?.full_name}
              </p>
            )}
          </div>
          <div className="grid grid-cols-1 gap-2 xl:grid-cols-2">
            <div>
              <h3 className="font-sans text-[14px] font-[700] leading-[21px] text-[#8083A3]">
                Teléfono
              </h3>
              <p className="font-sans text-[14px] font-[400] leading-[21px] text-[#000000]">
                {dataUser?.phone_number}
              </p>
            </div>

            <div>
              <h3 className="font-sans text-[14px] font-[700] leading-[21px] text-[#8083A3]">
                DNI/CUIT/CUIL
              </h3>
              <p className="font-sans text-[14px] font-[400] leading-[21px] text-[#000000]">
                {dataOrder?.card?.cardholder?.identification?.number}
              </p>
            </div>
          </div>
        </div>
        <div className="mt-[20px]">
          <div>
            <h3 className="font-sans text-[14px] font-[700] leading-[21px] text-[#8083A3]">
              Vendedor
            </h3>

            {!showSellers ? (
              <>
                <p className="mb-2 font-sans text-[14px] font-[400] leading-[21px] text-[#000000]">
                  {seller
                    ? `${seller.first_name} ${seller.last_name}`
                    : "No hay vendedor asignado"}
                </p>
                {role == "admin" && (
                  <button
                    className="rounded-xl border border-colorBorder px-4 py-2 font-sans text-[12px] font-[700] leading-[21px] text-[#171721] focus:border-none focus:outline-none focus:ring-colorBorder"
                    onClick={() => setShowSellers(true)}
                  >
                    {!seller ? "Asignar vendedor" : "Cambiar vendedor"}
                  </button>
                )}
              </>
            ) : (
              <>
                <div className="my-6 w-full">
                  <SearchAutocomplete
                    value={textSeller}
                    options={filteredTeamMembers}
                    {...propMethodsSearchSeller}
                    showEmail={true}
                  />
                  {selectDataSeller && (
                    <div className="mx-auto mt-[20px] w-[75%]">
                      <table id="SellerContainer" className="w-full table-auto">
                        <thead>
                          <tr>
                            <th align="left">
                              <p className="ml-2 font-sans text-[14px] font-[500] leading-[21px] text-[#8083A3]">
                                Nombre
                              </p>
                            </th>
                            <th align="left">
                              <p className="font-sans text-[14px] font-[500] leading-[21px] text-[#8083A3]">
                                Email
                              </p>
                            </th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              <div className="ml-2">
                                <PictureAndName
                                  profilePicture={
                                    selectDataSeller.profile_picture
                                  }
                                  firstName={selectDataSeller.first_name}
                                  lastName={selectDataSeller.last_name}
                                />
                              </div>
                            </td>
                            <td>
                              <span className="text-[#7B7B7B]">
                                {selectDataSeller.email}
                              </span>{" "}
                            </td>
                            <td>
                              <button onClick={handleSetOrderSeller}>
                                Asignar
                              </button>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
                <button
                  className="rounded-xl border border-colorBorder px-4 py-2 font-sans text-[12px] font-[700] leading-[21px] text-[#171721] focus:border-none focus:outline-none focus:ring-colorBorder"
                  onClick={handleCloseSelectSeller}
                >
                  {"Cancelar seleccion"}
                </button>
              </>
            )}
          </div>
        </div>
      </div>

      <h2 className="mt-[24px] font-sans text-[16px] font-[700] leading-[24px]">
        Estado de pago en{" "}
        {capitalize((data?.payment_method as string) || "mercado pago")}
      </h2>

      <div className="mt-[13px] w-full rounded-[12px] border-[1px] border-solid border-[#DDDDDD] pb-[34px]  pt-[38px]">
        <div className="w-full px-[34px]">
          <div className="grid w-full grid-cols-1 xl:grid-cols-4   ">
            <div className="flex items-center">
              <div className="flex items-center justify-center gap-3 rounded-full border-[1px] border-colorBorder px-5  py-2 text-center ">
                <div
                  className={`rounded-full ${
                    data.status === "approved" || data.status === "success"
                      ? "bg-green"
                      : "bg-red"
                  } p-[.35rem]`}
                />
                <p className="capitalize">
                  {getPaymentStatusFromValue(data.status)}
                </p>
              </div>
            </div>
            {data && data.is_free == false ? (
              <>
                {data.status === "approved" ? (
                  <div className="flex items-center justify-center xl:col-span-2">
                    <p className="text-center font-sans text-[14px] font-[400] leading-[21px] text-[#8083A3]">
                      El pago realizado por el comprador ya esta acreditado en
                      la cuenta
                    </p>
                  </div>
                ) : (
                  <div className="flex items-center justify-center xl:col-span-2">
                    <p className="text-center font-sans text-[14px] font-[400] leading-[21px] text-[#8083A3]">
                      {data.status == "refunded"
                        ? "El pago ha sido reembolsado"
                        : "El pago realizado se encuentra pendiente"}
                    </p>
                  </div>
                )}
                <div className="flex w-full items-center justify-end">
                  <div className="text-center">
                    {role == "admin" && (
                      <button
                        onClick={() => handleViewMercadoPago()}
                        className="hidden rounded-full border-[1px] border-black-100 px-[22px] py-[8px] text-sm font-bold text-black-100 hover:bg-black-100 hover:text-white"
                      >
                        Ver orden en Mercado Pago
                      </button>
                    )}
                    <p className="text-sm">
                      Referencia externa <i>(MercadoPago)</i>:
                    </p>
                    <p>
                      <strong>{data.external_reference}</strong>
                    </p>
                  </div>
                </div>
              </>
            ) : null}
          </div>
        </div>

        <div className="mt-[28px] border-t-[1px] border-solid border-[#DDDDDD]"></div>
        <div className="w-full">
          <div className="mt-[32px] w-full grid-cols-1 gap-2 px-[34px] sm:grid xl:grid-cols-2">
            <div>
              <h3 className="font-sans text-[14px] font-[700] leading-[21px] text-[#8083A3]">
                Detalle
              </h3>
              {Object.keys(ticketQuantities).map((name) => (
                <p
                  key={name}
                  className="font-sans text-[14px] font-[400] leading-[21px] text-[#000000]"
                >
                  {name ? name.toUpperCase() : ""}
                  <br />
                  {ticketQuantities[name].producer_name} Presenta-
                  <span>{eventTitle ? eventTitle.toUpperCase() : ""}</span>
                </p>
              ))}
            </div>
            <div className="md:grid-cols-2gap-2 sm:grid lg:grid-cols-4 xl:grid-cols-6">
              <div>
                <h3 className="font-sans text-[14px] font-[700] leading-[21px] text-[#8083A3]">
                  Costo
                </h3>
                {Object.keys(ticketQuantities).map((name) => (
                  <p
                    key={name}
                    className="font-sans text-[14px] font-[400] leading-[21px] text-[#000000]"
                  >
                    {formatMoney(ticketQuantities[name].price)}
                    <br />
                    <br />
                  </p>
                ))}
              </div>
              <div>
                <h3 className="font-sans text-[14px] font-[700] leading-[21px] text-[#8083A3]">
                  Cantidad
                </h3>
                {Object.keys(ticketQuantities).map((name) => (
                  <p
                    key={name}
                    className="font-sans text-[14px] font-[400] leading-[21px] text-[#000000]"
                  >
                    {ticketQuantities[name].qty}
                    <br />
                    <br />
                  </p>
                ))}
              </div>
              <div>
                <h3 className="font-sans text-[14px] font-[700] leading-[21px] text-[#8083A3]">
                  Total
                </h3>
                {Object.keys(ticketQuantities).map((name) => (
                  <p
                    key={name}
                    className="font-sans text-[14px] font-[400] leading-[21px] text-[#000000]"
                  >
                    {formatMoney(
                      ticketQuantities[name].price * ticketQuantities[name].qty
                    )}
                    <br />
                    <br />
                  </p>
                ))}
              </div>
              <div className="xl:col-span-2">
                <h3 className="font-sans text-[14px] font-[700] leading-[21px] text-[#8083A3]">
                  Service
                </h3>
                {Object.keys(ticketQuantities).map((name) => (
                  <p
                    key={name}
                    className="font-sans text-[14px] font-[400] leading-[21px] text-[#000000]"
                  >
                    {isNaN(ticketQuantities[name].price / total_amount)
                      ? "$0.00"
                      : formatMoney(
                          service *
                            (ticketQuantities[name].price / total_amount)
                        )}{" "}
                    x {ticketQuantities[name].qty} ={" "}
                    {isNaN(ticketQuantities[name].price / total_amount)
                      ? "0.00"
                      : formatMoney(
                          service *
                            (ticketQuantities[name].price / total_amount) *
                            ticketQuantities[name].qty
                        )}
                    <br />
                    <br />
                  </p>
                ))}
              </div>
              <div>
                <h3 className="font-sans text-[14px] font-[700] leading-[21px] text-[#8083A3]">
                  Total
                </h3>
                {Object.keys(ticketQuantities).map((name) => (
                  <p
                    key={name}
                    className="font-sans text-[14px] font-[400] leading-[21px] text-[#000000]"
                  >
                    {isNaN(
                      ticketQuantities[name].total +
                        service *
                          (ticketQuantities[name].price / total_amount) *
                          ticketQuantities[name].qty
                    )
                      ? "$0.00"
                      : formatMoney(
                          ticketQuantities[name].total +
                            service *
                              (ticketQuantities[name].price / total_amount) *
                              ticketQuantities[name].qty
                        )}
                    <br />
                    <br />
                  </p>
                ))}
              </div>
            </div>
          </div>
          {data &&
            data.is_free == false &&
            ["success", "approved"].includes(data.status) &&
            !refunded &&
            role == "admin" && (
              <div className="mt-[32px] flex items-center justify-end px-[34px]">
                <button
                  onClick={() => handleViewRefund()}
                  className="rounded-full border-[1px] border-black-100 px-[22px] py-[8px] text-sm font-bold text-black-100 hover:bg-black-100 hover:text-white"
                >
                  Solicitar Reembolso
                </button>
              </div>
            )}
        </div>
        {role === "admin" && (
          <>
            <div className="mt-[28px] border-t-[1px] border-solid border-[#DDDDDD]"></div>
            <div className="mt-[32px] px-[34px]">
              <p className="font-sans text-[14px] font-[700] leading-[21px] text-[#8083A3]">
                Detalles de reembolsos
              </p>

              <table className="w-full text-left">
                {/*{dataRefund && dataRefund.payments?.length && dataRefund.refunded_amount ? (*/}
                {dataRefund &&
                dataRefund.status == "refunded" &&
                data.status == "refunded" ? (
                  <thead>
                    <tr>
                      <th className="text-gray-600 font-sans text-base font-bold leading-6">
                        Nº de Operacion
                      </th>
                      <th className="text-gray-600 font-sans text-base font-bold leading-6">
                        Nombre de Evento
                      </th>
                      <th className="text-gray-600 font-sans text-base font-bold leading-6">
                        Tipo de ticket
                      </th>
                      <th className="text-gray-600 font-sans text-base font-bold leading-6">
                        Usuario
                      </th>
                      <th className="text-gray-600 font-sans text-base font-bold leading-6">
                        Costo
                      </th>
                      <th className="text-gray-600 font-sans text-base font-bold leading-6">
                        Service
                      </th>
                      <th className="text-gray-600 font-sans text-base font-bold leading-6">
                        Total
                      </th>
                    </tr>
                  </thead>
                ) : (
                  <div className="text-center">
                    <p className="font-sans text-[14px] font-[400] leading-[21px] text-[#8083A3]">
                      No hay reembolsos para esta orden
                    </p>
                  </div>
                )}

                {/*{dataRefund && dataRefund.payments?.length && dataRefund.refunded_amount ? (*/}
                {dataRefund &&
                dataRefund.status == "refunded" &&
                data.status == "refunded" ? (
                  <tbody>
                    {data.order_tickets.length ? (
                      data.order_tickets
                        .filter(
                          (ot: IOrderTicketResponse) => ot["refunded_detail"]
                        )
                        .map((item: any, i: any) => (
                          <motion.tr
                            key={i}
                            layout
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                            initial={{ opacity: 0.2 }}
                            transition={{
                              type: "spring",
                              stiffness: 100,
                              bounce: 0,
                            }}
                          >
                            {/*{dataRefund && dataRefund.payments.map((e: any, i: any) => (*/}
                            {/*    <>*/}
                            <td className="pl-4">{item.id}</td>
                            <td className="pl-4">{item.event.name}</td>
                            <td className="pl-4">{item.ticket[0].type}</td>
                            <td className="pl-4">
                              {getFullNameOrNickname(item.user)}
                            </td>
                            <td className="pl-4">
                              {formatMoney(item["refunded_detail"]["price"])}
                            </td>
                            <td className="pl-4">
                              {formatMoney(item["refunded_detail"]["service"])}
                            </td>
                            <td className="pl-4">
                              {formatMoney(item["refunded_detail"]["total"])}
                            </td>
                            {/*    </>*/}
                            {/*))}*/}
                          </motion.tr>
                        ))
                    ) : (
                      <div className="text-center">
                        <p className="font-sans text-[14px] font-[400] leading-[21px] text-[#8083A3]">
                          No hay reembolsos para esta orden
                        </p>
                      </div>
                    )}
                  </tbody>
                ) : null}
              </table>
            </div>
          </>
        )}
      </div>
      <h2 className="mt-[24px] font-sans text-[16px] font-[700] leading-[24px]">
        Tickets
      </h2>

      <div className="relative mt-[13px] w-full overflow-x-auto rounded-[12px] border-[1px] border-solid border-[#DDDDDD] px-[34px] pb-[34px] pt-[38px]">
        <table className="w-full text-left">
          <thead className=" ">
            <tr>
              <th className="font-sans text-[14px] font-[700] leading-[21px] text-[#8083A3]">
                ticket id
              </th>
              <th className="font-sans text-[14px] font-[700] leading-[21px] text-[#8083A3]">
                Nombre del evento
              </th>
              <th className="font-sans text-[14px] font-[700] leading-[21px] text-[#8083A3]">
                DNI
              </th>
              <th className="font-sans text-[14px] font-[700] leading-[21px] text-[#8083A3]">
                Tipo de ticket
              </th>
              <th className="font-sans text-[14px] font-[700] leading-[21px] text-[#8083A3]">
                Poseedor
              </th>
              <th className="font-sans text-[14px] font-[700] leading-[21px] text-[#8083A3]">
                Costo
              </th>
              <th className="font-sans text-[14px] font-[700] leading-[21px] text-[#8083A3]">
                Service
              </th>
              <th className="font-sans text-[14px] font-[700] leading-[21px] text-[#8083A3]">
                Total
              </th>
            </tr>
          </thead>
          <tbody>
            {data.order_tickets.length ? (
              data.order_tickets.map((item: any, i: any) => (
                <motion.tr
                  key={i}
                  layout
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  initial={{ opacity: 0.2 }}
                  transition={{ type: "spring", stiffness: 100, bounce: 0 }}
                >
                  <td className="pl-4">{item.id}</td>
                  <td className="pl-4">{item?.event?.name}</td>
                  <td className="pl-4">{item.dni || "-"}</td>
                  <td className="pl-4">{item?.ticket[0]?.name}</td>
                  {role === "admin" && item.user_id ? (
                    <TableCellLink
                      href={`/admin/users/${item.user_id}`}
                      className="pl-4 capitalize text-blue-500 underline"
                    >
                      {getFullNameOrNickname(item.user)}
                    </TableCellLink>
                  ) : (
                    <td className="pl-4 capitalize">
                      {getFullNameOrNickname(item.user)}
                    </td>
                  )}
                  {Object.keys(ticketQuantities).map((name) => {
                    if (name === item?.ticket[0]?.name) {
                      const price = isNaN(ticketQuantities[name].price)
                        ? 0.0
                        : ticketQuantities[name].price;
                      const serviceCharge = isNaN(
                        service * (price / total_amount)
                      )
                        ? 0.0
                        : service * (price / total_amount);
                      const total = formatMoney(price + serviceCharge);

                      return (
                        <Fragment key={name}>
                          <td className="pl-4">{formatMoney(price)}</td>
                          <td className="pl-4">{formatMoney(serviceCharge)}</td>
                          <td className="pl-4">{total}</td>
                        </Fragment>
                      );
                    }
                  })}
                  {/* <td className="pl-4">{formatMoney(item.ticket[0]?.price ? item?.ticket[0]?.price : 0)}</td>
                                    <td className="pl-4">{formatMoney(item.ticket[0]?.price ? ((item?.ticket[0]?.price) * service) : 0)}</td>
                                    <td className="pl-4">{formatMoney(item.ticket[0]?.price + ((item?.ticket[0]?.price) * service))}</td> */}
                </motion.tr>
              ))
            ) : (
              <></>
            )}
          </tbody>
        </table>
      </div>

      {role == "admin" && (
        <>
          <h2 className="mt-[24px] font-sans text-[16px] font-[700] leading-[24px]">
            Afip
          </h2>

          <div className="mt-[13px] w-full rounded-[12px] border-[1px] border-solid border-[#DDDDDD] pb-[34px]  pt-[38px]">
            <div className="px-[34px]">
              {["approved", "success"].includes(status) &&
                data.service > 0 &&
                !afipCae && (
                  <button
                    onClick={() => createInvoice()}
                    className="rounded-full border-[1px] border-black-100 px-[22px] py-[8px] text-sm font-bold text-black-100 hover:bg-black-100 hover:text-white"
                  >
                    Generar factura
                  </button>
                )}
              {afipCae && ["approved", "success"].includes(status) && (
                <button
                  onClick={() => handleDownloadInvoice(data.id)}
                  className="rounded-full border-[1px] border-black-100 px-[22px] py-[8px] text-sm font-bold text-black-100 hover:bg-black-100 hover:text-white"
                >
                  Descargar factura
                </button>
              )}
              {(!afipCae || !["approved", "success"].includes(status)) && (
                <p className="text-center font-sans text-[14px] font-[400] leading-[21px] text-[#8083A3]">
                  No existe factura disponible{" "}
                  {data.service <= 0 && (
                    <small>(Sin monto de servicio (0))</small>
                  )}
                </p>
              )}
            </div>
          </div>
        </>
      )}

      <h2 className="mt-[24px] font-sans text-[16px] font-[700] leading-[24px]">
        Actividad
      </h2>

      <div className="relative mt-[13px] w-full overflow-x-auto rounded-[12px] border-[1px] border-solid border-[#DDDDDD] px-[34px] pb-[34px]  pt-[38px]">
        {data.logs?.length > 0 ? (
          <table className="w-full text-left">
            <thead className=" ">
              <tr>
                <th className="font-sans text-[14px] font-[700] leading-[21px] text-[#8083A3]">
                  Fecha y Hora
                </th>
                <th className="font-sans text-[14px] font-[700] leading-[21px] text-[#8083A3]">
                  Detalle
                </th>
              </tr>
            </thead>
            <tbody>
              {data.logs.reverse().map((item: any, i: any) => (
                <motion.tr
                  key={i}
                  layout
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  initial={{ opacity: 0.2 }}
                  transition={{ type: "spring", stiffness: 100, bounce: 0 }}
                >
                  <td className="pl-4 capitalize">
                    {formatHumanDateTime(item.createdAt)}
                  </td>
                  <td className="pl-4">{item.detail}</td>
                </motion.tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p className="text-center font-sans text-[14px] font-[400] leading-[21px] text-[#8083A3]">
            No se registró actividad para la orden
          </p>
        )}
      </div>
    </div>
  );
};

import React from "react";
import { IRow, status } from "../../../interfaces/Table";
import { Status } from "../../Status";
import { Layout } from "../../../layout/Layout";
import { IClientResponse } from "../../../types/IClient";
import { formatDate } from "../../../utils/format-date";

type selectedProps = {
  selected: IClientResponse;
};

const styles = {
  titleLi: "font-bold",
  pLi: "font-medium",
  wrapperLi: "flex flex-col justify-between ",
};

export const BannerProducer = ({ selected }: selectedProps) => {
  return (
    <div className="flex h-52 w-full justify-between rounded-xl px-10 py-10 outline outline-2 outline-colorBorder">
      <div className="flex gap-5">
        <div className="flex h-24 w-24 overflow-hidden rounded-full bg-gray-200">
          <img
            src={selected?.profile_picture}
            width="100%"
            height="100%"
            className="object-cover"
          />
        </div>
        <div className="flex flex-col">
          <h1 className="text-2xl font-bold">{`${selected?.full_name}`}</h1>
          <p className="font-medium text-gray-100">#{selected?.id}</p>
        </div>
      </div>

      <ul className="flex gap-32">
        <div className={`${styles.wrapperLi}`}>
          <li>
            <h3 className={`${styles.titleLi}`}>Fecha de alta</h3>
            <p className={`${styles.pLi}`}>{formatDate(selected?.createdAt)}</p>
          </li>
          <li>
            <h3 className={`${styles.titleLi}`}>Email</h3>
            <p className={`${styles.pLi}`}>{selected.email}</p>
          </li>
        </div>
        <div className={`${styles.wrapperLi}`}>
          <li>
            <h3 className={`${styles.titleLi}`}>Contacto</h3>
            <p className={`${styles.pLi}`}>{selected?.phone_number || "-"}</p>
          </li>
          <li>
            <h3 className={`${styles.titleLi}`}>Dirección</h3>
            <p className={`${styles.pLi}`}>
              {selected && selected["address"] ? selected["address"] : "-"}
            </p>
          </li>
        </div>
      </ul>
      <div>
        <Status
          status={
            selected?.status && selected?.status == "active"
              ? "activo"
              : "pendiente"
          }
        />
      </div>
    </div>
  );
};

import { useContext, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useQuery } from "@tanstack/react-query";
import moment from "moment";
import * as yup from "yup";

import { Title } from "../../Title";
import { PageContainer } from "../../../PageContainer";
import FormDatePicker from "../../../FormElement/FormInput";
import { Back } from "../../../Back";
import Option from "./Option";
import Check from "./Check";
import SelectMultiple from "./SelectMultiple";
import { StateContext } from "../../../../context/StateProvider";
import TicketService from "../../../../services/ticketService";
import EventService from "../../../../services/eventService";
import { availableColumns, availableOrderStatus, templates } from "./constants";
import { Button } from "../../../ui/button";

type ExportCsvFormShape = {
  tickets: { value: string; label: string }[];
  columns: {
    buyer_first_name: string;
    buyer_last_name: string;
    order_ticket_id: string;
  };
  status: { value: string; label: string }[];
  start_date: string;
  end_date: string;
  remember_fields: string;
  export_itemized: string;
};

const ExportCsv = () => {
  const navigate = useNavigate();
  const { event_id } = useParams<{ event_id: string }>();
  const { dispatch } = useContext(StateContext);

  const form = useForm<ExportCsvFormShape>({
    resolver: yupResolver(
      yup.object().shape({
        tickets: yup
          .array()
          .min(1, "Elegí al menos un elemento")
          .required("Elegí al menos un elemento"),
        status: yup
          .array()
          .min(1, "Elegí al menos un elemento")
          .required("Elegí al menos un elemento"),
        columns: yup.object().test({
          name: "columns",
          message: "Elegí al menos una opción",
          test: (value: object) =>
            Object.values(value).some((x) => x === "true"),
        }),
      })
    ),
    mode: "all",
  });

  const handlePersistColumns = (flag: boolean, columns: object) => {
    if (flag) {
      localStorage.setItem(
        "export-csv/selected-columns",
        JSON.stringify({ columns, remember_fields: "true" })
      );
    } else {
      localStorage.removeItem("export-csv/selected-columns");
    }
  };

  const { data: tickets } = useQuery(
    ["tickets", event_id],
    async () => TicketService.getAll({ event_id }),
    {
      onError: () =>
        dispatch({
          type: "toastError",
          payload: "Error fetching event tickets",
        }),
    }
  );

  const { data: event } = useQuery(
    ["event", event_id],
    async () => EventService.get(event_id || ""),
    {
      onError: () =>
        dispatch({
          type: "toastError",
          payload: "Error fetching event",
        }),
    }
  );

  const handleSubmit = async (values: ExportCsvFormShape) => {
    if (!event) return;

    handlePersistColumns(values.remember_fields === "true", values.columns);
    const requestedColumns = Object.entries(values.columns)
      .map(([key, value]) => value && key)
      .filter(Boolean);
    const requestedStatuses = values.status?.map(({ value }) => value);
    const requestedTickets = values.tickets?.map(({ value }) => value);
    const exportItemized = values.export_itemized === "true";

    try {
      const response = await EventService.getCsvOrderticketsPurchase({
        ...values,
        columns: requestedColumns,
        status: requestedStatuses,
        tickets: requestedTickets,
        export_itemized: exportItemized,
      });

      const blob = new Blob([response], { type: "text/csv" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = `${event.name} ${moment(new Date()).format(
        "DD-MM-YYYY_hh:mm:ss"
      )}.csv`;
      link.click();
      URL.revokeObjectURL(link.href);
      link.remove();
    } catch (e: unknown) {
      const error = e as { response: { data: { message: string | string[] } } };
      if (Array.isArray(error.response.data.message)) {
        error.response.data.message.forEach((message) =>
          dispatch({
            type: "toastError",
            payload: message,
          })
        );
        return;
      }

      dispatch({
        type: "toastError",
        payload: error.response.data.message,
      });
    }
  };

  const loadPersistedColumns = () => {
    const localStorageState = localStorage.getItem(
      "export-csv/selected-columns"
    );
    if (localStorageState) {
      const parsedState = JSON.parse(localStorageState);
      form.reset(parsedState);
    }
  };

  useEffect(() => {
    loadPersistedColumns();
  }, []);

  const handleTemplate = (fields: string[]) => {
    const currentValues = form.getValues();

    const updatedFields = availableColumns.reduce<
      Record<string, string | null>
    >((acc, column) => {
      acc[column.value] = fields.includes(column.value) ? "true" : null;
      return acc;
    }, {});

    form.reset({
      ...currentValues,
      columns: updatedFields,
    });
  };

  // Synchronize the checkboxes export_itemized and order_ticket_id
  const watchExportItemized = form.watch("export_itemized");
  const watchOrderTicketId = form.watch("columns.order_ticket_id");

  useEffect(() => {
    if (watchExportItemized === "true") {
      form.setValue("columns.order_ticket_id", "true");
    } else {
      form.setValue("columns.order_ticket_id", "");
    }
  }, [watchExportItemized]);

  useEffect(() => {
    if (watchOrderTicketId === "true") {
      form.setValue("export_itemized", "true");
    } else {
      form.setValue("export_itemized", "");
    }
  }, [watchOrderTicketId]);

  return (
    <PageContainer>
      <span className="block pb-6">
        <Back onClick={() => navigate(-1)} />
      </span>
      <Title>Exportar CSV</Title>
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(handleSubmit)}>
          <Option
            title="Tickets"
            description="Seleccioná los tickets que querés exportar a CSV"
          >
            <SelectMultiple
              allowSelectAll
              name="tickets"
              options={tickets?.rows.map(({ id, name, type }) => ({
                value: id,
                label: name,
                type,
              }))}
            />
          </Option>
          <Option
            title="Rango de fecha"
            description="Seleccioná el rango de fecha para exportar a CSV"
          >
            <span className="flex gap-7 [&>*]:mb-0 [&>*]:w-fit">
              <FormDatePicker
                name="start_date"
                type="date"
                label="From"
                min={moment("05/01/2023").utcOffset(0).format("YYYY-MM-DD")}
              />
              <FormDatePicker
                name="end_date"
                type="date"
                label="To"
                min={moment("05/01/2023").utcOffset(0).format("YYYY-MM-DD")}
                max={moment(new Date()).utcOffset(0).format("YYYY-MM-DD")}
              />
            </span>
          </Option>
          {templates.length > 0 &&
            templates.map((template) => (
              <Button
                type="button"
                className="m-3"
                key={template.label}
                onClick={() => handleTemplate(template.fields)}
              >
                {template.label}
              </Button>
            ))}
          <Option
            title="Columnas a mostrar"
            description="Seleccioná qué información querés incluir en el CSV"
          >
            <div className="flex flex-col gap-3">
              {availableColumns.map(({ label, value }, i) => (
                <Check
                  key={`${i}#${value}`}
                  name={`columns.${value}`}
                  options={[{ value: "true", label }]}
                />
              ))}
              <Check
                name="columns.order_ticket_id"
                options={[{ value: "true", label: "Order Ticket Id" }]}
              />
              <p className="text-red-600 dark:text-red-500 text-sm text-red">
                {form.formState.errors.columns?.message ?? ""}
              </p>
            </div>
          </Option>
          <Option
            title="Status de la Orden"
            description="Seleccioná el status de las órdenes a exportar"
          >
            <SelectMultiple
              allowSelectAll
              name="status"
              options={availableOrderStatus}
            />
          </Option>
          <Option title="Exportar ordenes desglosadas (para ticketera)">
            <div className="flex flex-col gap-3">
              <Check
                name="export_itemized"
                options={[{ value: "true", label: "" }]}
              />
            </div>
          </Option>
          <Option
            title="Recordar campos exportados"
            description="Guardar los campos elegidos para la próxima vez"
          >
            <div className="flex flex-col gap-3">
              <Check
                name="remember_fields"
                options={[{ value: "true", label: "" }]}
              />
            </div>
          </Option>
          <button
            type="submit"
            className="mt-4 rounded-full border-[2px] border-black-100 bg-black-100 px-[5.4rem] py-2 font-medium text-white"
          >
            Export
          </button>
        </form>
      </FormProvider>
    </PageContainer>
  );
};

export default ExportCsv;

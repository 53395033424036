import { useState, useRef } from "react";
import ISortOption from "../../types/ISortOption";
import { Menu } from "@headlessui/react";

type sortProps = {
  setSort?: (filter: string) => void;
  options?: Array<ISortOption>;
  title?: String;
  externalCheckedValue?: string;
};

export const FilterDataRadio = ({
  setSort = () => {},
  options,
  title,
  externalCheckedValue,
}: sortProps) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (typeof setSort === "function") {
      setSort(event.target.value);
    }
  };

  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button className="text-gray-700 hover:bg-gray-50 inline-flex w-full items-center justify-center rounded-md border border-none bg-white px-4 py-2 text-sm font-medium focus:outline-none focus:ring-2 focus:ring-gray-100">
          {title}
          <svg
            className="ml-4 h-4 w-4 "
            aria-hidden="true"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M19 9l-7 7-7-7"
            ></path>
          </svg>
        </Menu.Button>
      </div>
      <Menu.Items>
        <div className="">
          <Menu.Item>
            <div
              id="dropdownDefaultRadio"
              className="absolute z-10  max-h-56 w-44 overflow-y-auto rounded-xl bg-white shadow-xl outline outline-2 outline-colorBorder "
            >
              <ul
                className="text-gray-700 space-y-3 p-3 text-sm dark:text-gray-200"
                aria-labelledby="dropdownRadioButton"
              >
                {options &&
                  options.map((row, i) => (
                    <li key={i}>
                      <div className="flex items-center">
                        <input
                          id={"default-radio-" + row.value}
                          type="radio"
                          value={row.value}
                          name={"default-radio-" + i}
                          checked={row.value == externalCheckedValue}
                          className="border-black-400 h-4 w-4 bg-white text-black-100 focus:ring-1 "
                          onChange={handleChange}
                        />
                        <label
                          htmlFor={"default-radio-" + row.value}
                          className="ml-2 pr-4 text-sm font-medium text-black-100"
                        >
                          {row.text}
                        </label>
                      </div>
                    </li>
                  ))}
              </ul>
            </div>
          </Menu.Item>
        </div>
      </Menu.Items>
    </Menu>
  );
};

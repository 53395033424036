import React from "react";
import { motion } from "framer-motion";
import { BiDotsHorizontalRounded } from "react-icons/bi";
import { IoMailOpen } from "react-icons/io5";
import { BsCalendar4Range } from "react-icons/bs";
import { Title } from "../../../events/Title";
import {
  IProducerResponse,
  IProducersResponse,
} from "../../../../types/IProducer";
import { FaCalendar, FaEdit, FaTrashAlt, FaUser } from "react-icons/fa";
import DropdownDots from "../../../FormElement/DropdownDots";
import { useNavigate, useParams } from "react-router-dom";
import { formatDate, formatDateTime } from "../../../../utils/format-date";
import { formatMoney } from "../../../../utils/format-money";

type producerProps = {
  data: IProducersResponse;
  handleEditProducer: (id: string) => void;
  handleDelete: (id: string) => void;
};

export const Producers = ({
  data,
  handleEditProducer,
  handleDelete,
}: producerProps) => {
  const navigate = useNavigate();
  const params = useParams();
  const columnData = [
    "Nombre",
    "Fecha de alta",
    "Cantidad de eventos",
    "Tickets vendidos",
    "Facturación promedio mensual",
    "Status",
    "Acciones",
  ];

  const rowData = {
    name: "The Bow",
    date: "19 Jun 2021 - 12:43:22",
    events: "12 eventos",
    tickets: "7.500 tickets",
    monthBill: "$178.000",
    status: "Activo",
  };

  const handleValidators = (producer_id: string) => {
    navigate(`/admin/producers/${producer_id}/validators`);
  };

  const handleSellers = (producer_producer_id: string) => {
    navigate(
      `/admin/producers/${params.producer_id}/producer_producer/${producer_producer_id}/rrpp`
    );
  };

  const handleEvents = (producer_id: string) => {
    navigate(`/admin/producers/${producer_id}/events`);
  };

  const getMontlyBilling = (producer: IProducerResponse): number => {
    const items: any = [];
    let totalAmount = 0;
    if (producer.events) {
      for (const event of producer.events) {
        for (const ticket of event.tickets || []) {
          for (const spm of ticket["sales_per_month"] || []) {
            const finded = items.find(
              (i: any) => i.year == spm._id.year && i.month == spm._id.month
            );
            if (!finded) {
              items.push({ year: spm._id.year, month: spm._id.month });
            }
            totalAmount += spm.total_sales_month;
          }
        }
      }
      return items.length > 0 ? totalAmount / items.length : 0;
    }

    return 0;
  };

  return (
    <div>
      <Title>Productoras</Title>
      <table className="my-table-spacing h-full w-full border-separate border-spacing-y-2 gap-5 overflow-x-auto pt-5 text-left">
        <thead className=" divide-solid border ">
          <tr className=" pb-4 text-sm font-bold text-gray-100">
            {columnData.map((column, index) => (
              <th
                className="border-b-[1px] border-colorBorder pb-4"
                key={column + index}
              >
                {column}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.rows.map((item, i) => (
            <motion.tr
              key={i}
              layout
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              initial={{ opacity: 0.2 }}
              transition={{ type: "spring", stiffness: 100, bounce: 0 }}
              className={`h-16  rounded-xl text-start font-medium outline outline-[1px] outline-colorBorder`}
            >
              <td className="pl-4 font-bold capitalize">{item.name}</td>
              <td className="">{formatDate(item.createdAt)}</td>
              <td className="flex h-full items-center gap-2 ">
                {item.events_count}
              </td>
              <td className="">{item.tickets_sold} ticket(s)</td>
              <td className="">{formatMoney(getMontlyBilling(item))}</td>
              <td>
                <div className="flex w-32 items-center justify-center gap-3 rounded-full border-[1px] border-colorBorder  py-2 text-center ">
                  <div className="rounded-full  bg-green p-[.35rem] " />
                  <p className="capitalize">{rowData.status}</p>
                </div>
              </td>
              <td className="pl-4">
                {!item.tickets_reserved &&
                item.events_free_count == 0 &&
                item.active_events == 0 ? (
                  <DropdownDots
                    options={[
                      {
                        title: "Eventos",
                        action: () => handleEvents(item.id),
                        icon: <FaCalendar size={20} />,
                      },
                      {
                        title: "Validadores",
                        action: () => handleValidators(item.id),
                        icon: <FaUser size={20} />,
                      },
                      {
                        title: "Vendedores",
                        action: () => handleSellers(item.id),
                        icon: <FaUser size={20} />,
                      },
                      {
                        title: "Editar",
                        action: () => handleEditProducer(item.id),
                        icon: <FaEdit size={23} />,
                      },
                      {
                        title: "Eliminar",
                        action: () => handleDelete(item.id),
                        icon: <FaTrashAlt size={23} />,
                      },
                    ]}
                  />
                ) : (
                  <DropdownDots
                    options={[
                      {
                        title: "Eventos",
                        action: () => handleEvents(item.id),
                        icon: <FaCalendar size={20} />,
                      },
                      {
                        title: "Validadores",
                        action: () => handleValidators(item.id),
                        icon: <FaUser size={20} />,
                      },
                      {
                        title: "Vendedores",
                        action: () => handleSellers(item.id),
                        icon: <FaUser size={20} />,
                      },
                      {
                        title: "Editar",
                        action: () => handleEditProducer(item.id),
                        icon: <FaEdit size={23} />,
                      },
                    ]}
                  />
                )}
              </td>
            </motion.tr>
          ))}
        </tbody>
      </table>
      {data.rows.length === 0 ? (
        <p className="text-center">No existen productoras para el cliente</p>
      ) : null}
    </div>
  );
};

import { FC, useState } from "react";
import { Controller, useFormState } from "react-hook-form";
import { FormInputProps } from "../../../types/FormInputsProps";
import { toast } from "react-toastify";
import onChange = toast.onChange;

const styleInput = {
  input:
    "h-24 w-full rounded-lg border border-colorBorder pt-6 pl-8 font-bold text-black-100 focus:border-none focus:outline-none  focus:ring-colorBorder",
};

const FormInput: FC<FormInputProps> = ({
  name,
  type,
  label,
  placeholder,
  min = "",
  max = "",
  onChangeCustom = (e: any) => {},
  disabled = false,
}) => {
  const formState = useFormState();
  const [email, setEmail] = useState("");

  const getDate = (value: string) => {
    if (value) {
      return value.toString().substring(0, 10);
    }
    return value;
  };

  return (
    <Controller
      name={name}
      render={({ field }) => (
        <div className="relative mb-6 w-full">
          <p className="absolute left-8 top-4 font-semibold text-gray-100">
            {label}
          </p>
          <input
            type={type}
            placeholder={placeholder}
            spellCheck={false}
            className={styleInput.input}
            {...field}
            value={type === "date" ? getDate(field.value) : field.value}
            min={min}
            max={max}
            disabled={disabled}
            onChange={(e) => {
              onChangeCustom(e);
              field.onChange(e);
            }}
          />
          {formState.errors[name] && (
            <p className="text-red-600 dark:text-red-500 mt-2 text-sm text-red">
              {String(
                formState.errors[name] ? formState.errors[name]?.message : ""
              )}
            </p>
          )}
        </div>
      )}
    />
  );
};

export default FormInput;

import api from "./api";
import authHeader from "./authHeader";
import {
  IRrppCsvResponse,
  IRrppResponse,
  IRrppsResponse,
} from "../types/IRrpp";
import { IFilterRequest } from "../types/IFilter";
import { IOrderTicketsResponse } from "../types/IOrderTicket";
import { ICollectablesResponse } from "../types/IUser";
import { IEventsResponse } from "../types/IEvent";

const getAll = async (params: IFilterRequest) => {
  const response = await api.get<IRrppsResponse>(`rrpp/list`, {
    headers: authHeader(),
    params: params,
  });
  return response.data;
};

const getAllTeam = async (params: IFilterRequest) => {
  const response = await api.get<IRrppsResponse>(`rrpp/listRrpp`, {
    headers: authHeader(),
    params: params,
  });
  return response.data;
};

const getTickets = async (id: string) => {
  const response = await api.get<IRrppsResponse>(`rrpp/tickets/${id}`, {
    headers: authHeader(),
  });
  return response.data;
};

const get = async (id: string, params: IFilterRequest = {}) => {
  const response = await api.get<IRrppResponse>(`rrpp/detail/${id}`, {
    headers: authHeader(),
    params: params,
  });
  return response.data;
};

const create = async (item: FormData) => {
  const response = await api.post<IRrppResponse>(`rrpp/create`, item, {
    headers: {
      "Content-Type": "multipart/form-data",
      ...authHeader(),
    },
  });
  return response.data;
};

const update = async (id: string | undefined, item: FormData) => {
  const response = await api.put<IRrppResponse>(`rrpp/update/${id}`, item, {
    headers: {
      "Content-Type": "multipart/form-data",
      ...authHeader(),
    },
  });
  return response.data;
};

export const importCSV = (
  formData: FormData,
  params: any,
  options: any = {}
) => {
  return api.post<IRrppCsvResponse>(`rrpp/import_csv`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
      ...authHeader(),
    },
    params: params,
    ...options,
  });
};

const remove = async (id: string) => {
  const response = await api.delete<IRrppResponse>(`rrpp/delete/${id}`, {
    headers: authHeader(),
  });
  return response.data;
};

const deleteAccount = async (id: string) => {
  const response = await api.post<IRrppResponse>(
    `rrpp/delete_account/${id}`,
    {},
    {
      headers: authHeader(),
    }
  );
  return response.data;
};

const getCsv = async (params: object = {}) => {
  const response = await api.get<any>(`rrpp/csv`, {
    headers: authHeader(),
    params: params,
  });
  return response.data;
};

const getOrderTickets = async (id: string, params: object = {}) => {
  const response = await api.get<IOrderTicketsResponse>(`rrpp/tickets/${id}`, {
    headers: authHeader(),
    params: params,
  });
  return response.data;
};

const removeTicket = async (id: string) => {
  const response = await api.delete<IRrppResponse>(`rrpp/ticket/${id}`, {
    headers: authHeader(),
  });
  return response.data;
};

const getCollectables = async (params: IFilterRequest) => {
  const response = await api.get<ICollectablesResponse>(`rrpp/collectables`, {
    headers: authHeader(),
    params: params,
  });
  return response.data;
};

const getEvents = async (params = {}) => {
  const response = await api.get<IEventsResponse>(`rrpp/eventsTeam`, {
    headers: authHeader(),
    params: params,
  });
  return response.data;
};

const RrppService = {
  getAll,
  getTickets,
  removeTicket,
  get,
  create,
  update,
  remove,
  deleteAccount,
  getCsv,
  getOrderTickets,
  getCollectables,
  getEvents,
  getAllTeam,
  importCSV,
};

export default RrppService;

import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import moment from "moment";
import { useMutation } from "@tanstack/react-query";
import acces from "../../services/eventService";
import eventService from "../../services/eventService";

const ExportarMolinetesCsvDownload = () => {
  const params = useParams();
  const getCsv = useMutation(() =>
    acces.getCsvOrders({
      event_id: params.event_id,
      status: "approved",
    })
  );
  useEffect(() => {
    const handleCsv = async () => {
      const event_id = params.event_id;
      if (event_id) {
        const response = await getCsv.mutateAsync();
        const event = await eventService.get(event_id);
        const url = window.URL.createObjectURL(new Blob([response]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `${event.name} ${moment(new Date()).format(
            "DD-MM-YYYY_hh:mm:ss"
          )}.csv`
        );
        document.body.appendChild(link);
        link.click();
        window.close();
      }
    };

    handleCsv();
  }, [params.event_id]);

  return <div>Downloading CSV...</div>;
};

export default ExportarMolinetesCsvDownload;

import { useState, useEffect } from "react";

interface PaginationArgs {
  currentPage: number;
  totalPages: number;
}

const getIntermediatePages = ({
  currentPage,
  totalPages,
}: PaginationArgs): number[] => {
  if (totalPages <= 6)
    return Array.from({ length: totalPages - 2 }, (_, i) => i + 2);
  if (currentPage <= 3) return [2, 3, 4, 5];
  if (totalPages - currentPage >= 3)
    return [currentPage - 1, currentPage, currentPage + 1];
  else return [totalPages - 4, totalPages - 3, totalPages - 2, totalPages - 1];
};

export const usePagination = ({
  currentPage,
  totalPages,
}: PaginationArgs): {
  intermediatePages: number[];
  showInitialDots: boolean;
  showFinalDots: boolean;
} => {
  const [intermediatePages, setIntermediatePages] = useState<number[]>([]);

  useEffect(() => {
    setIntermediatePages(getIntermediatePages({ currentPage, totalPages }));
  }, [currentPage, totalPages]);

  return {
    intermediatePages,
    showInitialDots: totalPages > 6 && currentPage >= 4,
    showFinalDots: totalPages > 6 && totalPages - currentPage >= 3,
  };
};

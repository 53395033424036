import React from "react";
import ReactDOM from "react-dom/client";
import {
  BrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";
import { GoogleOAuthProvider } from "@react-oauth/google";

import { StateProvider } from "./context/StateProvider";
import Router from "./router";
import "./styles/input.css";
import AuthProvider from "./context/AuthProvider";
import { QueryClientProvider, QueryClient } from "@tanstack/react-query";
import PermissionProvider from "./context/PermissionProvider";
import * as Sentry from "@sentry/react";
import variables from "./config/variables";

Sentry.init({
  dsn: variables.sentry_dns,
  integrations: [
    // See docs for support of different versions of variation of react router
    // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.replayIntegration(),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  tracesSampleRate: 1.0,

  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^\/\/wearebombo\//],

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      retry: 1,
      staleTime: 5 * 1000,
    },
  },
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <AuthProvider>
    <GoogleOAuthProvider clientId="47245323848-m9e6e883ufgau7mlfv6eogsd0jmnqh1f.apps.googleusercontent.com">
      <QueryClientProvider client={queryClient}>
        <PermissionProvider>
          <StateProvider>
            <BrowserRouter>
              <Router />
            </BrowserRouter>
          </StateProvider>
        </PermissionProvider>
      </QueryClientProvider>
    </GoogleOAuthProvider>
  </AuthProvider>
);

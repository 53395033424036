import { motion } from "framer-motion";
import { Title } from "../../../events/Title";
import { GoSearch } from "react-icons/go";

export const SongsArtist = () => {
  const columnData = ["Nombre", "Descripcion", "Url"];

  const rowData = {
    name: "Nombre del song",
    description: "Alguna descripcion del song",
    url: "www.bombo.com/LuisFonsi/despasito",
  };

  return (
    <div>
      <Title>Songs</Title>
      <table className="my-table-spacing h-full w-full border-separate border-spacing-y-2 gap-5 overflow-x-auto pt-5 text-left">
        <thead className=" divide-solid border ">
          <tr className=" pb-4 text-sm font-bold text-gray-100">
            {columnData.map((column, index) => (
              <th
                className="border-b-[1px] border-colorBorder pb-4"
                key={column + index}
              >
                {column}
              </th>
            ))}
            <th className="border-b-[1px] border-colorBorder pb-4">
              <GoSearch
                className="m-auto cursor-pointer text-gray-100"
                size={16}
              />
            </th>
          </tr>
        </thead>
        <tbody>
          {[...Array(8)].map((_, i) => (
            <motion.tr
              layout
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              initial={{ opacity: 0.2 }}
              transition={{ type: "spring", stiffness: 100, bounce: 0 }}
              className={`h-16  rounded-xl text-start font-medium outline outline-[1px] outline-colorBorder`}
            >
              <td className="pl-4">{rowData.name}</td>
              <td className="">{rowData.description}</td>
              <td>{rowData.url}</td>
            </motion.tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

import { useMutation } from "@tanstack/react-query";
import { useEffect, FC, useContext } from "react";
import { FormProvider, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import jsonToFormData from "@ajoelp/json-to-formdata";
import { Back } from "../Back";
import FormInput from "../FormElement/FormInput";
import FormUploadImage from "../FormElement/FormUploadImage";
import FormDateTimeField from "../FormElement/FormDateTimeField";
import ScheduleService from "../../services/scheduleService";
import { IScheduleRequest } from "../../types/ISchedule";
import { StateContext } from "../../context/StateProvider";
import moment from "moment/moment";
import { buildISODateTimeString } from "../../utils/format-date";

let verifyAddForm = {
  title: yup.string().required("El titulo es requerido"),
  subtitle: yup.string().required("El subtitulo es requerido"),
  location: yup.string().required("La locación es requerida"),
  date: yup.string().required("La fecha es requerida"),
  banner: yup.mixed().test("size", "Tamaño permitido menor a 20MB", (value) => {
    if (value instanceof File) {
      return value.size <= 20000000;
    }
    return true;
  }),
};

const defaultValues = {
  title: "",
  subtitle: "",
  location: "",
  date: "",
  banner: "",
};

type typeProps = {
  handleCreateorEdit: (id: string) => void;
  id: string;
};

export const FormSchedule: FC<typeProps> = ({ handleCreateorEdit, id }) => {
  let schema;
  schema = yup.object().shape(verifyAddForm);

  const createItems = useMutation((item: FormData) =>
    ScheduleService.create(item)
  );
  const updateItems = useMutation((item: FormData) =>
    ScheduleService.update(id, item)
  );
  const getScheduleApi = useMutation((id: string) => ScheduleService.get(id));

  const form = useForm<IScheduleRequest>({
    resolver: yupResolver(schema),
    defaultValues: defaultValues,
  });

  useEffect(() => {
    if (id) {
      getScheduleApi.mutateAsync(id).then((res) => {
        form.reset(res);
      });
    }
  }, [form.reset]);

  const { state, dispatch } = useContext(StateContext);
  const onSubmit = async (data: IScheduleRequest) => {
    data.date = buildISODateTimeString(data.date, "00:00"); //moment(data.date).utcOffset(0).format("YYYY-MM-DD");
    let formData = jsonToFormData(data, {
      arrayIndexes: true,
      excludeNull: true,
    });
    dispatch({ type: "showLoaderScreen", payload: true });
    if (id) {
      await updateItems.mutateAsync(formData);
      dispatch({ type: "showLoaderScreen", payload: false });
      dispatch({ type: "toastSuccess", payload: "Registro actualizado" });
    } else {
      await createItems.mutateAsync(formData);
      dispatch({ type: "showLoaderScreen", payload: false });
      dispatch({
        type: "toastSuccess",
        payload: "Registro creado correctamente",
      });
    }
    handleCreateorEdit("");
  };

  const onError = (error: any) => {
    console.log("error ", error);
  };

  return (
    <div className="">
      <Back onClick={() => handleCreateorEdit("")} />
      <div className="flex flex-col gap-10 xl:px-[23rem]">
        <h1 className="text-center text-3xl font-bold">
          {id ? "Editar eventos en agenda" : "Nuevo eventos en agenda"}
        </h1>
        <FormProvider {...form}>
          <form onSubmit={form.handleSubmit(onSubmit, onError)}>
            <div className="mx-auto mb-6 flex text-center md:w-[60%]">
              <FormUploadImage name="banner" label="Banner del evento" />
            </div>
            <FormDateTimeField
              name="date"
              label="Fecha del evento"
              type="date"
            />
            <FormInput name="title" type="text" label="Titulo del mensaje" />
            <FormInput name="subtitle" type="text" label="Subtitulo" />
            <FormInput name="location" type="text" label="Locacion" />
            <div className="mb-16 flex justify-center gap-10 pt-10">
              <button
                type="submit"
                className="rounded-full border-[2px] border-black-100 bg-black-100 px-[5.4rem] py-2 font-medium  text-white"
              >
                {id ? "Guardar" : "Enviar ahora"}
              </button>
            </div>
          </form>
        </FormProvider>
      </div>
    </div>
  );
};

import { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { RowSelectionState } from "@tanstack/react-table";
import { useDebounce } from "use-debounce";

import { SwapSummary } from "./swapSummary";
import { DialogEditPrice } from "./dialogEditPrice";
import { getOffersColumns } from "./table/columns";
import { PageContainer } from "../layout/pageContainer";
import { TitleSection } from "../layout/titleSection";
import { DataTable } from "../layout/table/dataTable";
import { SkeletonTable } from "../layout/table/skeletonTable";
import { BatchActions } from "../layout/table/batchActions";
import { FilterPage } from "../layout/table/filterPage";
import { FilterQuery } from "../layout/table/filterQuery";
import { FilterLimit } from "../layout/table/filterLimit";
import { FilterSort } from "../layout/table/filterSort";
import { FilterStatus } from "../layout/table/filterStatus";
import LoaderCircle from "../layout/loaderCircle";
import { IFilterRequest } from "../../types_new/IFilter";
import { IOfferResponse } from "../../types_new/IOffer";
import OfferService from "../../services_new/offerService";
import { getUrlParams } from "../../utils_new/getUrlParams";
import { statusOptions, sortOptions, actionOptions } from "./constants/options";
import config from "../../config/variables";
import { ISwapSummary } from "../../types/IOffer";

export const OffersPage = () => {
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [rowOnEdit, setRowOnEdit] = useState<IOfferResponse>();

  const {
    limit = config.filter_default_limit,
    page = 1,
    sortBy = "createdAt",
    sortOrder = "desc",
    query,
    status = statusOptions[0].value,
  } = getUrlParams();

  const defaultFilters: IFilterRequest = {
    limit: Number(limit),
    page: Number(page),
    sortBy,
    sortOrder,
    status,
  };
  const [filters, setFilters] = useState<IFilterRequest>(defaultFilters);

  const [search, setSearch] = useState<string>(query);
  const [debouncedSearch] = useDebounce(search, 500);

  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});

  const {
    data: offersData,
    isFetching: isFetchingOffers,
    isError: isErrorOffers,
    refetch: refetchOffers,
  } = useQuery({
    queryKey: ["offers", filters, debouncedSearch],
    queryFn: ({ signal }) =>
      OfferService.getAll({ ...filters, query: debouncedSearch, signal }),
  });

  const {
    data: summaryData,
    isFetching: isFetchingSummary,
    isError: isErrorSummary,
  } = useQuery({
    queryKey: ["offers", "summary"],
    queryFn: () => OfferService.getSummary(),
  });

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [filters, debouncedSearch]);

  return (
    <PageContainer>
      <TitleSection>Swap</TitleSection>

      {isErrorSummary ? (
        <p className="text-center">No pudimos cargar los datos.</p>
      ) : isFetchingSummary ? (
        <div className="my-8 flex justify-center">
          <LoaderCircle />
        </div>
      ) : (
        <SwapSummary swapSummaryData={summaryData || ({} as ISwapSummary)} />
      )}

      <div className="flex items-center justify-between">
        <BatchActions
          actionOptions={actionOptions}
          rowSelection={rowSelection}
          setRowSelection={setRowSelection}
          rows={offersData?.rows || []}
          refetch={refetchOffers}
        />
        <FilterQuery
          search={search}
          setSearch={setSearch}
          filters={filters}
          setFilters={setFilters}
        />
      </div>

      <div className="my-5 flex flex flex-col border-b border-gray-200 xl:flex-row-reverse xl:justify-between">
        <FilterSort
          sortOptions={sortOptions}
          filters={filters}
          setFilters={setFilters}
        />
        <FilterStatus
          statusOptions={statusOptions}
          filters={filters}
          setFilters={setFilters}
        />
      </div>

      {isErrorOffers ? (
        <p className="text-center">No pudimos cargar las ofertas.</p>
      ) : isFetchingOffers ? (
        <SkeletonTable
          columns={getOffersColumns({
            refetch: refetchOffers,
            setRowOnEdit,
            offers: offersData?.rows || [],
            setIsDialogOpen,
            isFetchingOffers,
          })}
        />
      ) : (
        <DataTable
          columns={getOffersColumns({
            refetch: refetchOffers,
            setRowOnEdit,
            offers: offersData?.rows || [],
            setIsDialogOpen,
            isFetchingOffers,
          })}
          data={offersData?.rows || []}
          rowSelection={rowSelection}
          setRowSelection={setRowSelection}
        />
      )}

      <div className="flex justify-between pt-10">
        <FilterPage
          filters={filters}
          setFilters={setFilters}
          totalPages={offersData?.pages || 1}
        />
        <FilterLimit filters={filters} setFilters={setFilters} />
      </div>

      {isDialogOpen && rowOnEdit?.id && (
        <DialogEditPrice
          isDialogOpen={isDialogOpen}
          setIsDialogOpen={setIsDialogOpen}
          offer={rowOnEdit}
          refetch={refetchOffers}
        />
      )}
    </PageContainer>
  );
};

import { IOfferResponse } from "@/types/IOffer";
import { formatDate, formatDateTime } from "@/utils/format-date";
import { formatMoney } from "@/utils/format-money";
import { getPaymentMethodMPFromValue } from "@/utils/payments";
import {
  getOfferStatusFromValue,
  getPaymentStatusFromValue,
} from "@/utils/status";

export const getOrderDetailsFields = (offer: IOfferResponse | undefined) => {
  if (!offer?.order?.id) {
    return [];
  }

  return [
    { title: "Order N°", value: `#${offer?.order.id}` },
    {
      title: "Fecha de pago",
      value: formatDate(offer?.order.updatedAt),
    },
    {
      title: "Forma de pago",
      value: getPaymentMethodMPFromValue(offer?.order.payment_method || ""),
    },
    {
      title: "Estado",
      value: getPaymentStatusFromValue(offer?.order.status || ""),
    },
    {
      title: "Facturación",
      value: offer?.order.afip_cae
        ? `${offer?.order.user.first_name} ${offer?.order.user.last_name} ${
            offer?.order.user.address && offer?.order.user.address
          }`
        : "No se tiene datos de Facturación",
    },
    {
      title: "Dirección de correo electrónico",
      value: offer?.order.user?.email,
    },
    {
      title: "Usuario Bombo",
      value: `${offer?.order.user?.first_name} ${offer?.order.user?.last_name}`,
      linkTo: `/admin/users/${offer?.order.user?.id}`,
    },
    {
      title: "Teléfono",
      value: offer?.order.user?.phone_number,
    },
    {
      title: "Vendedor",
      value: offer?.order.seller
        ? offer?.order.seller.search_name
        : "No hay vendedor asignado",
    },
  ];
};

export const getOfferDetailsFields = (offer: IOfferResponse | undefined) => {
  return [
    { title: "Offer N°", value: `#${offer?._id}` },
    {
      title: "Fecha de publicación",
      value: formatDate(offer?.createdAt),
    },
    {
      title: "Ticket",
      value: offer?.ticket_name,
    },
    {
      title: "Evento",
      value: offer?.event_name,
    },
    {
      title: "Subtotal",
      value: formatMoney(offer?.subtotal!),
    },
    {
      title: "Recargo",
      value: formatMoney(offer?.service!),
    },
    {
      title: "Total",
      value: formatMoney(offer?.total!),
    },
    {
      title: "Estado de la Publicación",
      value: getOfferStatusFromValue(offer?.status!),
    },
    {
      title: "Order ticket id",
      value: offer?.order_ticket_id,
    },
    {
      title: "Trader",
      value: offer?.trader_name,
      linkTo: `/admin/users/${offer?.user_id}`,
    },
  ];
};

export const getTransactionDetailsFields = (
  offer: IOfferResponse | undefined
) => {
  if (!offer?.buyer_id) {
    return [];
  }

  return [
    {
      title: "Id comprador",
      value: offer?.buyer_id,
    },
    {
      title: "Nombre comprador",
      value: offer?.buyer_name,
      linkTo: `/admin/users/${offer?.buyer_id}`,
    },
    {
      title: "Email comprador",
      value: offer?.buyer_email,
    },
    {
      title: "Fecha y hora de compra",
      value: formatDateTime(offer?.payment_date),
    },
    {
      title: "Estado del pago",
      value: getPaymentStatusFromValue(offer?.payment_status ?? ""),
    },
    {
      title: "Id método de pago",
      value: offer?.payment_method_id,
    },
    {
      title: "Id tipo de pago",
      value: offer?.payment_type_id,
    },
    {
      title: "Id pago MP",
      value: offer?.mp_payment_id,
    },
    {
      title: "External reference",
      value: offer?.external_reference,
    },
  ];
};

import {
  IOfferResponse,
  IGroupedOffersByTicket,
  ISwapSummary,
} from "../../../../types/IOffer";

export const getSwapSummaryData = (offers: IOfferResponse[]): ISwapSummary => {
  const soldOffers = offers.filter((offer) => offer.status === "sold");

  const totalSold = soldOffers.reduce((acc, curr) => acc + curr.total, 0);
  const totalService = soldOffers.reduce((acc, curr) => acc + curr.service, 0);

  return {
    offersQty: offers.length,
    soldOffersQty: soldOffers.length,
    totalSold,
    totalService,
  };
};

// esta función devuelve un array de objetos de la forma
// {
//   ticket_name: string;
//   ticket_id: string;
//   offers: IOfferResponse[];
// }
// agrupando así las ofertas por tipo de ticket
export const groupOffersByTicket = (
  offers: IOfferResponse[]
): IGroupedOffersByTicket[] => {
  return Object.values(
    offers.reduce((acc, ticket) => {
      const { ticket_id, ticket_name } = ticket;
      if (!acc[ticket_id]) {
        acc[ticket_id] = { ticket_id, ticket_name, offers: [] };
      }
      acc[ticket_id].offers.push(ticket);
      return acc;
    }, {} as Record<string, IGroupedOffersByTicket>)
  );
};

export const roundToTwoDecimals = (num: number) => Math.round(num * 100) / 100;

export const formatMoney = (value: number) => {
  if (value == null) return value;
  const roundedValue = roundToTwoDecimals(value);
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });
  return formatter.format(roundedValue);
};

import { read } from "@popperjs/core";
import { FC } from "react";
import { Controller, useFormState } from "react-hook-form";
import { FormInputProps } from "../../../types/FormInputsProps";

const styleInput = {
  input:
    "h-24 w-full rounded-lg border border-colorBorder pt-6 pl-8 font-bold text-black-100 focus:border-none focus:outline-none  focus:ring-colorBorder",
};

const FormInput: FC<FormInputProps> = ({
  name,
  type,
  label,
  placeholder,
  disabled = false,
  min = "",
  max = "",
  readonly = "",
}) => {
  const formState = useFormState();

  return (
    <Controller
      name={name}
      render={({ field }) => (
        <div className="relative mb-6 w-full">
          <p className="absolute left-8 top-4 font-semibold text-gray-100">
            {label}
          </p>
          {readonly != "readonly" ? (
            <input
              type={type}
              placeholder={placeholder}
              spellCheck={false}
              className={styleInput.input}
              disabled={disabled}
              {...field}
              onChange={field.onChange}
              min={min}
              max={max}
              autoComplete="new-password"
            />
          ) : (
            <input
              readOnly
              type={type}
              placeholder={placeholder}
              spellCheck={false}
              className={styleInput.input}
              disabled={disabled}
              {...field}
              min={min}
              max={max}
            />
          )}

          {formState.errors[name] && (
            <p className="text-red-600 dark:text-red-500 mt-2 text-sm text-red">
              {String(
                formState.errors[name] ? formState.errors[name]?.message : ""
              )}
            </p>
          )}
        </div>
      )}
    />
  );
};

export default FormInput;

import { useMutation, useQuery } from "@tanstack/react-query";
import { useEffect, FC, useContext, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import jsonToFormData from "@ajoelp/json-to-formdata";
import { Back } from "../Back";
import FormInput from "../FormElement/FormInput";
import SectionService from "../../services/sectionService";
import { ISectionRequest } from "../../types/ISection";
import { StateContext } from "../../context/StateProvider";
import EventService from "../../services/eventService";
import { EventSection } from "./event/eventSection";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { divide } from "lodash";
import FormCheck from "../FormElement/FormCheck";
import NewsService from "../../services/newsService";

let verifyAddForm = {
  name: yup.string().required("El nombre es requerido"),
};

const defaultValues = {
  name: "",
  isNews: false,
  events: [],
  news: [],
};

type typeProps = {
  handleCreateorEdit: (id: string) => void;
  id: string;
};

export const FormSection: FC<typeProps> = ({ handleCreateorEdit, id }) => {
  let schema;
  schema = yup.object().shape(verifyAddForm);
  const { state, dispatch } = useContext(StateContext);

  const getEvents = useMutation(() =>
    EventService.getAll({ upcoming_events: true })
  );

  const createItems = useMutation((item: FormData) =>
    SectionService.create(item)
  );
  const updateItems = useMutation((item: FormData) =>
    SectionService.update(id, item)
  );
  const getEventApi = useMutation((id: string) => SectionService.get(id));

  const form = useForm<ISectionRequest>({
    resolver: yupResolver(schema),
    defaultValues: defaultValues,
  });

  const isNews = form.watch("news");

  useEffect(() => {
    dispatch({ type: "selectedEvents", payload: [] });
    dispatch({ type: "selectedNews", payload: [] });
  }, []);

  useEffect(() => {
    getEvents.mutateAsync().then((result) => {
      dispatch({ type: "setEvents", payload: result.rows });
    });

    if (id) {
      getEventApi.mutateAsync(id).then((res) => {
        form.reset(res);
        dispatch({ type: "selectedEvents", payload: res.events });
        if (res.news) dispatch({ type: "selectedNews", payload: res.news });
      });
    } else {
      dispatch({ type: "selectedEvents", payload: [] });
    }
  }, [form.reset]);

  const onSubmit = async (data: ISectionRequest) => {
    data.isNews = isNews;
    data.isActive = state.selectedSections.includes(id) ? true : false;
    data.events = state.selectedEvents.map((item) => {
      return item.id;
    });
    data.news = state.selectedNews.map((item) => {
      return item.id;
    });
    let formData = jsonToFormData(data, {
      arrayIndexes: true,
      excludeNull: true,
    });
    dispatch({ type: "showLoaderScreen", payload: true });
    if (id) {
      await updateItems.mutateAsync(formData);
      dispatch({ type: "showLoaderScreen", payload: false });
      dispatch({ type: "toastSuccess", payload: "Registro actualizado" });
    } else {
      await createItems.mutateAsync(formData);
      dispatch({ type: "showLoaderScreen", payload: false });
      dispatch({
        type: "toastSuccess",
        payload: "Registro creado correctamente",
      });
    }
    handleCreateorEdit("");
  };

  const onError = (error: any) => {
    console.log("error ", error);
  };

  const addNewsSection = () => {
    dispatch({ type: "addNewsSection", payload: null });
  };

  const addEventSection = () => {
    dispatch({ type: "addEventSection", payload: null });
  };

  const handleRemoveEvent = (id: string) => {
    dispatch({ type: "removeSelectedNews", payload: id });
    dispatch({ type: "removeSelectedEvent", payload: id });
  };

  const reorder = (list: any, startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const onEnd = (result: any) => {
    if (isNews?.length > 0) {
      let newList = reorder(
        state.selectedNews,
        result.source.index,
        result.destination.index
      );
      dispatch({ type: "selectedNews", payload: newList });
    } else {
      let newList = reorder(
        state.selectedEvents,
        result.source.index,
        result.destination.index
      );
      dispatch({ type: "selectedEvents", payload: newList });
    }
  };

  return (
    <div className="">
      <Back onClick={() => handleCreateorEdit("")} />
      <div className="flex flex-col gap-10 px-[20%]">
        <h1 className="text-center text-3xl font-bold">
          {id ? "Editar Sección" : "Crear nueva Sección"}
        </h1>
        <FormProvider {...form}>
          <form onSubmit={form.handleSubmit(onSubmit, onError)}>
            <FormInput name="name" type="text" label="Nombre" />

            {
              <div>
                <FormCheck
                  name="news"
                  options={[{ value: "true", label: "Novedades" }]}
                  handleCheck={state.selectedNews.length !== 0 ?? true}
                />
                <div className="min-h-52 relative mt-5 flex items-center justify-center rounded-lg border border-colorBorder p-10">
                  <p className="absolute left-8 top-4 font-semibold text-gray-100">
                    {isNews?.length !== 0 && isNews ? "Novedades" : "Evento"}
                  </p>

                  <button
                    className="font-bold"
                    type="button"
                    onClick={
                      isNews?.length !== 0 && isNews
                        ? addNewsSection
                        : addEventSection
                    }
                  >
                    + Asignar{" "}
                    {isNews?.length !== 0 && isNews ? "novedades" : "evento"}
                  </button>
                </div>
                {(state.selectedEvents?.length !== 0 ||
                  state.selectedNews?.length !== 0) && (
                  <div className="min-h-52 relative mt-5 flex items-center justify-center rounded-lg border border-colorBorder p-10">
                    <DragDropContext onDragEnd={onEnd}>
                      <Droppable droppableId={"id_drop"}>
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            className="flex flex-col justify-center gap-4 pt-5"
                          >
                            {isNews?.length !== 0 && isNews
                              ? state?.selectedNews?.map((_, i) => (
                                  <div>
                                    {_ ? (
                                      <Draggable
                                        draggableId={_.id}
                                        key={_.id}
                                        index={i}
                                      >
                                        {(provided, snapshot) => (
                                          <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                          >
                                            <EventSection
                                              data={_}
                                              order={i}
                                              handleRemoveId={handleRemoveEvent}
                                              isDrawing={snapshot.isDragging}
                                              isNews={
                                                isNews?.length !== 0 && isNews
                                              }
                                            />
                                          </div>
                                        )}
                                      </Draggable>
                                    ) : (
                                      <div></div>
                                    )}
                                  </div>
                                ))
                              : state?.selectedEvents?.map((_, i) => (
                                  <div>
                                    {_ ? (
                                      <Draggable
                                        draggableId={_.id}
                                        key={_.id}
                                        index={i}
                                      >
                                        {(provided, snapshot) => (
                                          <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                          >
                                            <EventSection
                                              data={_}
                                              order={i}
                                              handleRemoveId={handleRemoveEvent}
                                              isDrawing={snapshot.isDragging}
                                              isNews={
                                                isNews?.length !== 0 && isNews
                                              }
                                            />
                                          </div>
                                        )}
                                      </Draggable>
                                    ) : (
                                      <div></div>
                                    )}
                                  </div>
                                ))}
                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>
                    </DragDropContext>
                  </div>
                )}
              </div>
            }

            <div className="mb-16 flex justify-center gap-10 pt-10">
              <button
                type="submit"
                className="rounded-full border-[2px] border-black-100 bg-black-100 px-[5.4rem] py-2 font-medium  text-white"
              >
                {id ? "Guardar Sección" : "Publicar Sección"}
              </button>
            </div>
          </form>
        </FormProvider>
      </div>
    </div>
  );
};

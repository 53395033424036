import React, { useEffect, useState } from "react";
import { useContext } from "react";
import { StateContext } from "../../../../context/StateProvider";
import { GrClose } from "react-icons/gr";

import { PictureAndName } from "../../../rrpp/components/PictureAndName/PictureAndName";
import FormInput from "../../../FormElement/FormInput";
import FormSelect from "../../../FormElement/FormSelect";
import { teamRoles } from "../../../../utils/roles";
import { FormProvider, useForm } from "react-hook-form";
import { ITeamRequest } from "../../../../types/ITeam";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { setFormErrorsFromServer } from "../../../../utils/form";
import FormSelectFull from "../../../FormElement/FormSelectFull";
import { FilterOption } from "../../../table/FilterSection";
import { toast, ToastContainer } from "react-toastify";
import EventService from "../../../../services/eventService";
import { useMutation } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import jsonToFormData from "@ajoelp/json-to-formdata";

export const PopUpTeamLeader = ({}) => {
  const { state, dispatch } = useContext(StateContext);
  const [teamIds, setTeamIds] = useState<Array<string>>([]);
  const { event_id = "" } = useParams();
  const updateItems = useMutation((item: FormData) =>
    EventService.update(event_id, item)
  );

  const addTeamMember = () => {
    dispatch({ type: "setMembers", payload: [] });
    dispatch({ type: "showAssignLeader", payload: null });
  };

  const showTeam = () => {
    dispatch({ type: "showTeam", payload: null });
  };

  const setMembers = () => {
    dispatch({ type: "setMembers", payload: teamIds });
  };

  const [showOptions, setShowOptions] = useState(false);
  const handleClick = () => {
    setShowOptions(!showOptions);
  };

  const form = useForm<ITeamRequest>({});
  const onSubmit = (data: any) => {
    console.info("data", data);
    const toDispatch = [];
    let hasError = false;
    for (const item in data) {
      if (!data[item]) {
        hasError = true;
        toast.error("Debe seleccionar un líder para todos los vendedores");
        return;
      } else {
        toDispatch.push({ user_id: item, leader_id: data[item] });
      }
    }
    if (!hasError) {
      dispatch({ type: "membersAssign", payload: toDispatch });
      dispatch({ type: "showAssignLeader", payload: null });

      if (state.save) {
        saveTeamMembers();
      }
    }
  };

  const saveTeamMembers = () => {
    dispatch({ type: "showLoaderScreen", payload: true });

    setTimeout(() => {
      let dataForm = {
        team_ids: state.members?.map((item) => {
          return item.id;
        }),
      };

      let formData = jsonToFormData(dataForm, {
        arrayIndexes: true,
        excludeNull: true,
      });

      updateItems
        .mutateAsync(formData)
        .then((res) => {
          dispatch({ type: "showLoaderScreen", payload: false });
          dispatch({ type: "reloadEvent", payload: new Date().getTime() });
        })
        .catch((reason) => {
          dispatch({ type: "showLoaderScreen", payload: false });
        });
    }, 100);
  };

  const onError = (error: any) => {
    console.log("error ", error);
  };
  return (
    <div className="fixed  z-50 flex h-screen w-screen items-center justify-center bg-black-100/30 backdrop-blur-md">
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div className="absolute top-20 m-auto flex flex-col justify-center rounded-2xl bg-white ">
        <div className="flex w-full justify-between pl-14 pr-10 pt-5 text-center">
          <h3 className=" text-lg font-bold">Asignación de Team Leader</h3>
          <button className="" onClick={addTeamMember}>
            <GrClose />
          </button>
        </div>
        <span className="px-14 pb-4 text-sm">
          Seleccione un team leader para este evento para los siguientes
          usuarios.
        </span>
        <div className=" h-[20rem] w-[47rem] flex-col flex-wrap  overflow-auto">
          <FormProvider {...form}>
            <form onSubmit={form.handleSubmit(onSubmit, onError)}>
              {state.membersToAssign?.map((option) => (
                <div
                  key={option.seller.id}
                  className="grid grid-cols-3 gap-3 overflow-auto px-8 py-2"
                >
                  <div className="h-[80%] rounded-2xl border border-colorBorder px-4 py-5 font-medium">
                    <PictureAndName
                      profilePicture={option.seller.user[0]?.profile_picture}
                      firstName={option.seller.user[0]?.first_name}
                      lastName={option.seller.user[0]?.last_name}
                    />
                  </div>
                  <div className="col-start-2 col-end-4">
                    <FormSelectFull
                      name={option.seller.user_id}
                      options={[
                        {
                          text: `Seleccionar Team Leader...`,
                          value: "",
                        },
                        ...option.leaders.map((l: any) => {
                          return {
                            text: `${l.user[0]?.first_name} ${l.user[0]?.last_name}`,
                            value: l.user_id,
                          };
                        }),
                      ]}
                      label="Team Leader"
                      placeholder="Seleccionar un Team Leader"
                    />
                  </div>
                </div>
              ))}

              <div className="border-b- flex w-full justify-between border-colorBorder px-8 pt-5 text-center ">
                <button
                  type="submit"
                  className="m-auto w-72 rounded-full border-[2px] border-black-100 bg-black-100 px-12 py-2 font-medium text-white"
                >
                  Guardar
                </button>
              </div>
            </form>
          </FormProvider>
        </div>
      </div>
    </div>
  );
};

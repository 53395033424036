import React from "react";
import { IoIosArrowBack } from "react-icons/io";

export const Back = ({ ...props }) => {
  return (
    <button {...props} className="flex  select-none items-center gap-3 ">
      <IoIosArrowBack size={18} />
      Volver
    </button>
  );
};

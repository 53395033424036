import { IOption } from "../types_new/IOption";

const statuses: IOption[] = [
  { value: "active", text: "Venta Activa" },
  { value: "activo", text: "Venta Activa" },
  { value: "pending", text: "Pendiente" },
  { value: "pendiente", text: "Pendiente aprobación" },
  { value: "paused", text: "Pausado" },
  { value: "send", text: "Enviado" },
  { value: "sent", text: "Enviado" },
  { value: "pending_approval", text: "Requiere aprobación" },
  { value: "finished", text: "Finalizado" },
];

export const offerStatuses = [
  { value: "active", label: "Activo" },
  { value: "paused", label: "Pausado" },
  { value: "in_progress", label: "En Progreso" },
  { value: "sold", label: "Vendido" },
  { value: "deleted", label: "Eliminado" },
  { value: "expired", label: "Expirado" },
];

const paymentStatuses = [
  { value: "active", label: "Pago Aprobado" },
  { value: "approved", label: "Pago Aprobado" },
  { value: "success", label: "Pago Aprobado" },
  { value: "pending", label: "Pendiente de aprobacion" },
  { value: "rejected", label: "Rechazado" },
  { value: "in_process", label: "en proceso" },
  { value: "refunded", label: "Reembolsado" },
];

export function getPaymentStatusFromValue(status: string) {
  const result = paymentStatuses.find((i) => i.value === status);
  return result ? result.label : status;
}

export const getStatusFromValue = (status: string) => {
  let foundStatus = statuses.find((st) => st.value === status);
  if (!foundStatus) return status;
  return foundStatus.text;
};

export const getEventStatus = (status: string, temp?: string) => {
  if (temp && status != "finished") return "pending_approval";
  return status;
};

export function getOfferStatusFromValue(status: string) {
  const result = offerStatuses.find((i) => i.value === status);
  return result ? result.label : status;
}

export const getBgColorFromValue = (status: string) =>
  ["approved", "success", "active", "finished"].includes(status)
    ? "bg-green"
    : ["pending", "paused", "in_progress"].includes(status)
    ? "bg-orange"
    : "bg-red";

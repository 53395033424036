import { ColumnDef } from "@tanstack/react-table";

import { DataTableHeader } from "./dataTableHeader";
import { Skeleton } from "../../ui/skeleton";

interface SkeletonTableProps<TData, TValue> {
  columns: ColumnDef<TData, TValue>[];
  numRows?: number;
}

export const SkeletonTable = <TData, TValue>({
  columns,
  numRows = 7,
}: SkeletonTableProps<TData, TValue>) => {
  return (
    <div className="mt-8">
      <DataTableHeader columns={columns} />
      {Array.from({ length: numRows }).map((_, index) => (
        <Skeleton
          key={index}
          className="mt-3 h-16 rounded-xl text-start font-medium outline outline-[1px] outline-colorBorder"
        />
      ))}
    </div>
  );
};

import { Link } from "react-router-dom";

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../../components/ui/table";
import { IOfferResponse } from "../../../../types/IOffer";
import { getOfferStatusFromValue } from "../../../../utils/status";
import { formatDate } from "../../../../utils/format-date";

interface OffersTableProps {
  offers: IOfferResponse[];
}

export const OffersTable = ({ offers }: OffersTableProps) => {
  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHead>Fecha de Publicación</TableHead>
          <TableHead>Status</TableHead>
          <TableHead>Trader</TableHead>
          <TableHead>Buyer</TableHead>
          <TableHead>Total</TableHead>
          <TableHead>Subtotal</TableHead>
          <TableHead>Servicio</TableHead>
          <TableHead>Order Ticket Id</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {offers.map((offer) => (
          <TableRow key={offer._id}>
            <TableCell>{formatDate(offer.createdAt)}</TableCell>
            <TableCell>{getOfferStatusFromValue(offer.status)}</TableCell>
            <TableCell className="font-medium text-blue-500 underline">
              <Link to={`/admin/users/${offer.user_id}`}>
                {offer.trader_name}
              </Link>
            </TableCell>
            <TableCell className="font-medium text-blue-500 underline">
              {offer.buyer_name && (
                <Link to={`/admin/users/${offer.buyer_id}`}>
                  {offer.buyer_name}
                </Link>
              )}
            </TableCell>
            <TableCell>{offer.total}</TableCell>
            <TableCell>{offer.subtotal}</TableCell>
            <TableCell>{offer.service}</TableCell>
            <TableCell className="text-blue-500 underline">
              <Link to={`/admin/orderTicket/${offer.order_ticket_id}`}>
                {offer.order_ticket_id}
              </Link>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

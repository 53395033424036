import React, { useEffect, useState } from "react";
import { AiFillCloseCircle } from "react-icons/ai";
import { ITeamResponse } from "../../../../types/ITeam";
import { IUserResponse } from "../../../../types/IUser";
import img from "../../../../assets/profile-default.png";

type MemberTeamProps = {
  data: ITeamResponse;
  handleRemoveId: (id: string) => void;
};

export const MemberTeam = ({ data, handleRemoveId }: MemberTeamProps) => {
  const ImagenOk = () => {
    // setImage(undefined);
  };

  return (
    <div className="flex w-72 ">
      <div className="flex items-center gap-5">
        <img
          src={
            data.user[0]?.profile_picture ? data.user[0]?.profile_picture : img
          }
          className="h-[64px] w-[64px] rounded-full bg-[#D6D6D6] object-cover"
          alt=""
          onError={() => ImagenOk()}
        />
        <h3 className="text-lg font-medium">
          {data.user[0]
            ? data.user[0].first_name + " " + data.user[0].last_name
            : ""}
        </h3>
      </div>
      <div className="mt-2">
        <button type="button" onClick={() => handleRemoveId(data.id)}>
          <AiFillCloseCircle size={18} />
        </button>
      </div>
    </div>
  );
};

import React, { ChangeEvent, FC, useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { ImArrowUpRight2 } from "react-icons/im";
import { Title } from "../Title";
import { SelledTickets } from "./reports/SelledTickets";
import { SellsGroup } from "./reports/SellsGroup";
import { SellsTime } from "./reports/SellsTime";
import {
  IReportRequest,
  IReportResponse,
  IReportSaleDetailResponse,
} from "../../../types/IReport";
import { formatMoney } from "../../../utils/format-money";
import { useMutation } from "@tanstack/react-query";
import ReportService from "../../../services/reportService";
import EventService from "../../../services/eventService";
import acces from "../../../services/eventService";
import eventService from "../../../services/eventService";
import { Tooltip } from "../../Tooltip";
import { IEventsResponse } from "../../../types/IEvent";
import { SelledDetails } from "./reports/SelledDetails";
import { DetailsInvitation } from "./reports/DetailsInvitation";
import { SelledPublic } from "./reports/SelledPublic";
import { DetailsTicketsFree } from "./reports/DetailsTickets";
import { AuthContext } from "../../../context/AuthContext";
import { useParams, useSearchParams } from "react-router-dom";
import { StateContext } from "../../../context/StateProvider";
import { GoArrowUp } from "react-icons/go";
import moment from "moment";
import { bool } from "yup";
import { format } from "date-fns";
import { updateUrl } from "@/utils/updateUrl";

type reportProps = {
  producer_id?: string;
  event_id?: string;
};

export const ReportsEvent: FC<reportProps> = ({ producer_id }) => {
  const [searchParams] = useSearchParams();
  const query =
    searchParams.get("query") !== null ? searchParams.get("query")! : "";
  const { state, dispatch } = useContext(StateContext);
  const auth = useContext(AuthContext);
  const parametro = useParams();
  const [data, setData] = useState({} as IReportResponse);
  const [result, setResult] = useState<Array<IReportSaleDetailResponse>>([]);
  const [params, setParams] = useState<IReportRequest>({ event_id: query });
  const [events, setEvents] = useState<IEventsResponse>({
    rows: [],
    pages: 0,
    total: 0,
  });
  const getItem = useMutation(
    auth.role == "admin"
      ? (params: IReportRequest) =>
          ReportService.getAll({
            period: params.period ?? "days",
            event_id: params.event_id,
          })
      : (params: IReportRequest) =>
          ReportService.getAll({
            period: params.period ?? "days",
            event_id: params.event_id || parametro.event_id,
            producer_id: auth.producer?.id,
          })
  );
  const getEvents = useMutation(() =>
    EventService.getAllSimple(
      auth.role == "producer" || "coproducer" ? { producer_id } : {}
    )
  );
  const getDetail = useMutation((params: IReportRequest) =>
    ReportService.saleDetail({ event_id: params.event_id })
  );
  let resultado: Array<IReportSaleDetailResponse> = result.filter(
    (item) => item.name
  );
  const refreshData = () => {
    getItem.mutate({ ...params });
  };

  useEffect(() => {
    const parameters = {} as IReportRequest;
    if (producer_id) parameters.producer_id = producer_id;
    if (parametro.event_id) parameters.event_id = parametro.event_id;
    setParams({ ...params, ...parameters });
    // dispatch({ type: "showLoaderScreen", payload: true });
    if (params.event_id) {
      getItem.mutateAsync({ ...params, ...parameters }).then((res) => {
        setData(res);
      });
      getDetail.mutateAsync({ ...params, ...parameters }).then((res) => {
        setResult(res);
      });
    }

    getEvents.mutateAsync().then((res) => setEvents(res));
    dispatch({ type: "showLoaderScreen", payload: false });
  }, [/*producer_id,*/ params.event_id]);

  const changePeriod = (period: string) => {
    setParams({ ...params, period: period });
    getItem
      .mutateAsync({ ...params, period: period })
      .then((res) => setData(res));
  };
  const changeEvent = (e: ChangeEvent<HTMLSelectElement>) => {
    updateUrl({ query: e.target.value });
    setParams({ ...params, event_id: e.target.value });
    getItem
      .mutateAsync({ ...params, event_id: e.target.value })
      .then((res) => setData(res));
  };

  const dataRefresh = () => {
    getItem.mutate({ ...params });
  };

  let total = result
    .filter((e) => e?.price && e?.sold)
    .reduce((acc, e) => acc + e?.price * e?.sold, 0);

  const infoTotal = [
    { number: "$72.000,00", subtitle: "Total de ingresos" },
    { number: 3827, subtitle: "Total de entradas vendidas" },
  ];
  const statusOptions: Array<object> = [
    { value: "", text: "Todos los eventos" },
  ];

  const form = useForm({
    defaultValues: {},
  });

  const [exporting, setExporting] = useState<boolean>(false);
  const getCsv = useMutation(() =>
    acces.getCsvOrders({
      event_id: params.event_id,
      status: "approved",
    })
  );
  const handleCsv = async () => {
    const event_id = params.event_id;
    if (event_id) {
      setExporting(true);
      await getCsv.mutateAsync().then(async (response) => {
        console.log(data);
        const event = await eventService.get(event_id);
        const url = window.URL.createObjectURL(new Blob([response]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `${event.name} ${moment(new Date()).format(
            "DD-MM-YYYY_hh:mm:ss"
          )}.csv`
        );
        document.body.appendChild(link);
        link.click();
        setExporting(false);
      });
    }
  };

  return (
    <div className="w-[1200px] px-[2%] sm:px-[2%] 2xl:w-full 2xl:px-0 ">
      <div className="mb-5 flex justify-between">
        <Title>Reportes</Title>

        {params.event_id ? (
          <button
            className="rounded-full border-[2px] border-black-100 bg-black-100 px-10 py-2 font-bold text-white hover:bg-black-100 hover:text-white"
            type="button"
            onClick={exporting ? () => {} : handleCsv}
          >
            <div className="flex items-center justify-center">
              <GoArrowUp className="mr-1" />
              <strong>
                {exporting ? "EXPORTANDO..." : "EXPORTAR A MOLINETES"}
              </strong>
            </div>
          </button>
        ) : (
          <></>
        )}

        <div>
          <button type="button" onClick={dataRefresh}>
            Actualizar
          </button>

          {!parametro.event_id && events.rows.length > 0 && (
            <select
              id="filterPage"
              className="ml-4 w-[350px] cursor-pointer rounded-lg py-3 pl-4 pr-8 font-medium text-gray-100 outline outline-1 outline-colorBorder"
              onChange={changeEvent}
            >
              <option value="">Seleccione un evento</option>
              {events.rows.map((_, i) => (
                <option value={_.id} key={i}>
                  {_.date_start ? format(_?.date_start, "dd.MM") : ""}{" "}
                  {_?.producer_name} {_?.name}
                </option>
              ))}
            </select>
          )}
        </div>
      </div>

      {!(auth.permissions.can_see_balance === false) && (
        <div className="flex flex-col items-center py-10 ">
          <div className="grid w-full grid-cols-2">
            <div className="pl-10">
              <div className="flex items-end gap-3">
                <h1 className="text-5xl font-bold text-black-200">
                  {/*{formatMoney(params.event_id ? total : 0)}*/}
                  {params.event_id ? formatMoney(data.total_revenue) : "0.00"}
                </h1>
                {!!data.total_revenue_percentage && (
                  <p className="flex items-center gap-1 font-bold text-green">
                    +{params.event_id ? data.total_revenue_percentage : 0}%{" "}
                    <ImArrowUpRight2 />
                  </p>
                )}
                {!!data.total_revenue_percentage && (
                  <Tooltip text="Tendencia diaria" />
                )}
              </div>
              <p className="font-medium">Total de ingresos</p>
            </div>
            <div className="pl-14">
              <div className="flex items-end gap-3">
                <h1 className="text-5xl font-bold text-black-200">
                  {/*{params.event_id ? soldTotal | 0 : 0}*/}
                  {params.event_id ? data.tickets_sold : "0"}
                </h1>
                {!!data.tickets_sold_percentage && (
                  <p className="flex items-center gap-1 font-bold text-green">
                    +{params.event_id ? data.tickets_sold_percentage : 0}%{" "}
                    <ImArrowUpRight2 />
                  </p>
                )}
                {!!data.tickets_sold_percentage && (
                  <Tooltip text="Tendencia diaria" />
                )}
              </div>
              <p className="font-medium">Total de entradas vendidas</p>
            </div>
          </div>
        </div>
      )}

      <div className="grid w-full gap-10 pb-8">
        <div className="flex overflow-x-auto whitespace-nowrap py-4">
          {!!params.event_id &&
            resultado.map((e, i) => (
              <div className="" key={i}>
                <SelledTickets
                  key={i}
                  title={params.event_id ? e.name : ""}
                  tickets={params.event_id ? e.sold : 0}
                  stock={params.event_id ? e.stock : 0}
                  dataChart={params.event_id ? e.saleDates : [0]}
                  soldout={e.soldout === "true"}
                  isActive={e.is_active}
                  isVisible={e.is_visible}
                />
              </div>
            ))}
        </div>
        <div className="w-full">
          <SelledDetails
            producer_id={auth.producer?.id}
            event_id={params.event_id || parametro.event_id}
          />
        </div>
        <div className="w-full">
          <SellsTime
            dataChart={
              params.event_id || parametro.event_id ? data.sales_over_time : []
            }
            changePeriod={changePeriod}
            months={data.months}
          />
        </div>
        <div className="w-full">
          <DetailsInvitation
            producer_id={auth.producer?.id}
            event_id={params.event_id || parametro.event_id}
            courtesies="global"
          />
        </div>
        <div className="w-full">
          <SelledPublic event_id={params.event_id || parametro.event_id} />
        </div>
        {/*<div className="w-full">*/}
        {/*  <DetailsInvitation*/}
        {/*      producer_id={auth.producer?.id}*/}
        {/*      event_id={params.event_id || parametro.event_id}*/}
        {/*  />*/}
        {/*</div>*/}
        <div className="mb-24- w-full">
          <DetailsTicketsFree
            event_id={params.event_id || parametro.event_id}
          />
        </div>
        <div className="w-full">
          <SellsGroup data={data.sales_groups} />
        </div>
        <div className="w-full">
          <DetailsInvitation
            producer_id={auth.producer?.id}
            event_id={params.event_id || parametro.event_id}
            courtesies="user"
          />
        </div>
      </div>
    </div>
  );
};

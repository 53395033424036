import { Switch } from "@headlessui/react";
import { Controller, useFormState } from "react-hook-form";

const FormToggle = ({
  name,
  label,
  color,
}: {
  name: string;
  label: string;
  color: string;
}) => {
  const formState = useFormState();

  return (
    <Controller
      name={name}
      render={({ field }) => {
        return (
          <div className="flex w-[100%] flex-row items-center gap-5">
            <span className="text-l font-medium">{label}</span>
            <div className="flex flex-row">
              <label
                // htmlFor={name}
                className="relative mx-1.5 inline-flex cursor-pointer items-center"
              >
                <Switch
                  checked={field.value}
                  className={`${
                    field.value ? `bg-${color}` : "bg-gray-200"
                  } relative inline-flex h-6 w-11 items-center rounded-full`}
                  {...field}
                >
                  <span className="sr-only">{name}</span>
                  <span
                    className={`${
                      field.value ? "translate-x-6" : "translate-x-1"
                    } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                  />
                </Switch>
              </label>
            </div>
            {formState.errors[name] && formState.errors[name]?.message && (
              <p className="text-red-600 dark:text-red-500 mt-2 text-sm text-red">
                {String(
                  formState.errors[name] ? formState.errors[name]?.message : ""
                )}
              </p>
            )}
          </div>
        );
      }}
    />
  );
};

export default FormToggle;

import { useState, Dispatch, SetStateAction } from "react";

import { Label } from "../../ui/label";
import { RadioGroup, RadioGroupItem } from "../../ui/radio-group";
import { IFilterRequest } from "../../../types_new/IFilter";
import { IOption } from "../../../types_new/IOption";
import { updateUrl } from "../../../utils_new/updateUrl";

interface FilterStatusProps {
  filters: IFilterRequest;
  setFilters: Dispatch<SetStateAction<IFilterRequest>>;
  statusOptions: IOption[];
}

export const FilterStatus = ({
  filters,
  setFilters,
  statusOptions,
}: FilterStatusProps) => {
  const [selectedStatus, setSelectedStatus] = useState<string>(
    filters.status || statusOptions[0].value
  );

  const handleStatusChange = (newStatus: string) => {
    setSelectedStatus(newStatus);
    const filtersToUpdate = { page: 1, status: newStatus };
    updateUrl(filtersToUpdate);
    setFilters({ ...filters, ...filtersToUpdate });
  };

  return (
    <RadioGroup
      defaultValue={filters.status || statusOptions[0].value}
      onValueChange={handleStatusChange}
      className="flex gap-8"
    >
      {statusOptions.map((status) => (
        <div key={status.value} className="flex items-center space-x-2">
          <RadioGroupItem
            value={status.value}
            id={status.value}
            className="hidden"
          />
          <Label
            htmlFor={status.value}
            className={`!ml-0 px-2 py-4 ${
              selectedStatus === status.value
                ? "border-y-2 border-b-black-200 border-t-transparent font-bold text-black-200"
                : "cursor-pointer text-gray-100 transition-colors duration-300 hover:text-black-200"
            }`}
          >
            {status.text}
          </Label>
        </div>
      ))}
    </RadioGroup>
  );
};

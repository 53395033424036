import {
  TrashIcon,
  PauseIcon,
  ListBulletIcon,
  EyeOpenIcon,
  // CheckCircledIcon,
  CheckIcon,
} from "@radix-ui/react-icons";

import { IDropdownOption } from "../../../types_new/IOption";
import { IEventSimpleResponse } from "../../../types_new/IEvent";

interface DropdownOptionsParams {
  event_id: string;
  event_status: string;
  event_temp?: boolean;
  handleUpdateStatus: (event_id: string, status: string) => void;
  handleAuthorizeEvent: (event_id: string) => void;
  handleDeleteEvent: (event_id: string, hasOrders?: boolean) => void;
  handleReviewEvent: (event_id: string) => void;
  handleComments: (event_id: string) => void;
}

export const getDropdownOptions = ({
  event_id,
  event_status,
  event_temp,
  handleUpdateStatus,
  // handleAuthorizeEvent,
  handleDeleteEvent,
  handleReviewEvent,
  handleComments,
}: DropdownOptionsParams): IDropdownOption<IEventSimpleResponse>[] => {
  const options: IDropdownOption<IEventSimpleResponse>[] = [
    {
      value: "comments",
      text: "Ver Comentarios",
      callback: handleComments,
      icon: <ListBulletIcon />,
    },
    {
      value: "delete",
      text: "Enviar a la papelera",
      callback: handleDeleteEvent,
      icon: <TrashIcon />,
    },
  ];

  event_status === "active" &&
    options.unshift({
      value: "1",
      text: "Pausar Evento",
      callback: () => handleUpdateStatus(event_id, "paused"),
      icon: <PauseIcon />,
    });

  event_status === "paused" &&
    options.unshift({
      value: "2",
      text: "Activar Evento",
      callback: () => handleUpdateStatus(event_id, "active"),
      icon: <CheckIcon />,
    });

  event_status === "pending_approval" ||
    (event_status !== "finished" &&
      event_temp &&
      options.unshift(
        // {
        //   value: "4",
        //   text: "Autorizar Evento",
        //   callback: () => {
        //     handleAuthorizeEvent(event_id);
        //   },
        //   icon: <CheckCircledIcon />,
        // },
        {
          value: "review",
          text: "Revisar",
          callback: handleReviewEvent,
          icon: <EyeOpenIcon />,
        }
      ));

  return options;
};

import { GoGraph } from "react-icons/go";
import DropdownDots from "../../../FormElement/DropdownDots";
import { motion } from "framer-motion";
import { FaTrashAlt } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { ArcElement, Chart as ChartJS, Legend, Tooltip } from "chart.js";
import { Pie } from "react-chartjs-2";
import { FC, useEffect, useState, useContext } from "react";
import {
  IReportRequest,
  IReportSaleDetailResponse,
} from "../../../../types/IReport";
import { useQuery, useMutation } from "@tanstack/react-query";
import ReportService from "../../../../services/reportService";
import { formatMoney } from "../../../../utils/format-money";
import moment from "moment/moment";
import EventService from "../../../../services/eventService";
import { StateContext } from "../../../../context/StateProvider";

import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";

ChartJS.register(ArcElement, Tooltip, Legend);

type reportProps = {
  producer_id?: string;
  event_id?: string;
};

export const SelledDetails: FC<reportProps> = ({ producer_id, event_id }) => {
  const navigate = useNavigate();
  const [data, setData] = useState<Array<IReportSaleDetailResponse>>([]);
  let array = data.filter((a) => a.name);
  const [params, setParams] = useState<IReportRequest>({});
  const { state, dispatch } = useContext(StateContext);

  const getItems = useMutation(() => ReportService.saleDetail({ event_id }));
  const getCsv = useMutation(() =>
    ReportService.getCsvSaleDetail({ event_id })
  );

  // const getEvent = useMutation(() => EventService.get(event_id || ""));

  const event = useQuery({
    queryKey: ["event", event_id || ""],
    queryFn: () => EventService.get(event_id || ""),
  });

  const applyFilters = () => {
    const parameters = {} as IReportRequest;
    if (producer_id) parameters.producer_id = producer_id;
    if (event_id) parameters.event_id = event_id;
    setParams({ ...params, ...parameters });
    getItems.mutateAsync().then((res) => setData(res));
  };

  useEffect(() => {
    if (event_id) applyFilters();
  }, [producer_id, event_id]);

  const columnData = [
    "Tipo del ticket",
    "Precio del ticket",
    "Cantidad",
    "Ingresadas",
    "Monto recaudado",
    "Porcentaje de venta",
    "",
  ];

  const handleDelete = (id: string) => {};

  const buildPieChartData = (data: Array<number>) => {
    return {
      labels: [],
      datasets: [
        {
          data: data,
          backgroundColor: [
            "rgba(95, 220, 179, 0.5)",
            "rgba(255, 255, 255, 0.5)",
          ],
          borderColor: ["rgba(95, 220, 179, 0.5)", "rgba(0, 0, 10, 0.1)"],
          borderWidth: 2,
        },
      ],
    };
  };

  const porcentageComvert = (num: any) => {
    const convert = Math.trunc(num);
    return convert;
  };

  let total = 0;
  let totalAmount = 0;
  let totalStock = 0;
  let totalUsed = 0;
  const getTotal = (key: string, mul: string) => {
    let result = 0;
    for (const row of array) {
      if (mul) result += row[key] * row[mul];
      else {
        result += row[key];
        if (key == "sold") totalStock += row.stock;
      }
    }
    if (key == "sold" && !mul) total = result;
    if (key == "sold" && mul) totalAmount = result;
    if (key == "used") totalUsed = result;
    return result;
  };

  const handleCsv = async () => {
    if (!event.data) {
      dispatch({
        type: "toastError",
        payload: "Elegí un evento primero.",
      });
      return;
    }

    try {
      await getCsv.mutateAsync().then((response) => {
        const nameFile = `DETALLE_DE_VENTA-${event.data.name}-${event.data.producer.name}.csv`;
        const url = window.URL.createObjectURL(new Blob([response]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", nameFile);
        document.body.appendChild(link);
        link.click();
      });
    } catch (error) {
      dispatch({
        type: "toastError",
        payload: "No pudimos generar el CSV.",
      });
    }
  };
  return (
    <div className="rounded-xl outline outline-2 outline-colorBorder 2xl:h-full ">
      <Accordion type="single" collapsible>
        <AccordionItem value="item-1">
          <AccordionTrigger>
            <div className="flex h-16 w-full items-center justify-between border-b border-colorBorder px-10">
              <h3 className="text-xl font-bold">Detalle de venta</h3>
              <div className="flex gap-5">
                <button
                  className="flex items-center justify-center"
                  onClick={handleCsv}
                >
                  <GoGraph className="mr-1" />
                  <span className="text-xs font-bold">Exportar a CSV</span>
                </button>
              </div>
            </div>
          </AccordionTrigger>
          <AccordionContent>
            <table className="my-table-spacing  w-full border-separate border-spacing-y-2 gap-5 overflow-x-auto px-6 text-left">
              <thead className=" divide-solid border ">
                <tr className=" pb-4 text-sm font-bold text-gray-100">
                  {columnData.map((column, index) => (
                    <th className="pb-4" key={column + index}>
                      {column}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {/*result?.rows?.map((row) => (*/}
                {event_id &&
                  array.map((row) => (
                    <motion.tr
                      key={row.name}
                      layout
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      initial={{ opacity: 0.2 }}
                      transition={{ type: "spring", stiffness: 100, bounce: 0 }}
                      className={`h-16  rounded-xl text-start font-medium outline outline-[1px] outline-colorBorder`}
                    >
                      <td className="pl-4 text-sm font-extrabold">
                        {row.name}
                      </td>
                      <td className="pl-4 text-sm font-bold">
                        {formatMoney(row.price)}
                      </td>
                      <td className="pl-4 text-sm font-normal">
                        {row.sold}/{row.stock}
                      </td>
                      <td className="pl-4 text-sm font-normal">{row.used}</td>
                      <td className="pl-4 text-sm font-bold">
                        {formatMoney(row.sold * row.price)}
                      </td>
                      <td className="flex items-center  pl-4 text-sm font-normal">
                        <div className="w-[50px] ">
                          <Pie
                            data={buildPieChartData([
                              row.sold,
                              row.sold - row.stock,
                            ])}
                          />
                        </div>
                        <span className="pt-2">
                          {porcentageComvert((row.sold / row.stock) * 100)}%
                        </span>
                      </td>
                      <td>
                        <DropdownDots
                          options={[
                            // {title: 'Editar', action: ()=>handleEdit(row.id), icon: (<FaEdit size={23} />)},
                            {
                              title: "Eliminar",
                              action: () => handleDelete(row.id),
                              icon: <FaTrashAlt size={23} />,
                            },
                          ]}
                        />
                      </td>
                    </motion.tr>
                  ))}
                {event_id && !!array?.length && (
                  <motion.tr
                    key={"totals"}
                    layout
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    initial={{ opacity: 0.2 }}
                    transition={{ type: "spring", stiffness: 100, bounce: 0 }}
                    className={`h-16  rounded-xl text-start font-medium outline outline-[1px] outline-colorBorder`}
                  >
                    <td className="pl-4 text-center text-sm font-extrabold">
                      <strong>TOTAL</strong>
                    </td>
                    <td className="pl-4 text-sm font-bold"></td>
                    <td className="pl-4 text-sm font-normal">
                      {getTotal("sold", "")}/{totalStock}
                    </td>
                    <td className="pl-4 text-sm font-normal">
                      {getTotal("used", "")}/{totalStock}
                    </td>
                    <td className="pl-4 text-sm font-bold">
                      {formatMoney(getTotal("sold", "price"))}
                    </td>
                    <td className="flex items-center  pl-4 text-sm font-normal">
                      <div className="w-[50px] ">
                        <Pie
                          data={buildPieChartData([total, total - totalStock])}
                        />
                      </div>
                      <span className="pt-2">
                        {porcentageComvert((total / totalStock) * 100)}%
                      </span>
                    </td>
                    <td></td>
                  </motion.tr>
                )}
              </tbody>
            </table>
          </AccordionContent>
        </AccordionItem>
      </Accordion>
    </div>
  );
};

import { IPrivacyRequest, IPrivacyResponse } from "../types/IPrivacy";
import api from "./api";
import authHeader from "./authHeader";

const getAll = async (params = {}) => {
  const response = await api.get<any>(`privacy`, {
    headers: authHeader(),
    params: params,
  });
  return response.data;
};

const create = async (item: FormData) => {
  const response = await api.post<IPrivacyResponse>(`privacy/create`, item, {
    headers: {
      "Content-Type": "multipart/form-data",
      ...authHeader(),
    },
  });
  return response.data;
};

const update = async (id: string, item: IPrivacyRequest) => {
  const response = await api.put<any>(`privacy/update/${id}`, item, {
    headers: {
      "Content-Type": "multipart/form-data",
      ...authHeader(),
    },
  });
  return response.data;
};

const remove = async (id: string) => {
  const response = await api.delete<IPrivacyResponse>(`terms/delete/${id}`, {
    headers: authHeader(),
  });
  return response.data;
};

const PrivacyService = {
  getAll,
  create,
  update,
  remove,
};

export default PrivacyService;

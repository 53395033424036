import React, { FC, useState } from "react";
import { Controller, useFormState } from "react-hook-form";
import { FormInputProps } from "../../../types/FormInputsProps";
import Select from "react-select";
import Creatable from "react-select/creatable";

const styleInput = {
  input:
    "h-32 w-full rounded-lg border border-colorBorder pt-6- pl-8 font-bold text-black-100 focus:border-none focus:outline-none  focus:ring-colorBorder",
};

const FormSelectMultiple: FC<FormInputProps> = ({
  name,
  options = [],
  label,
  onChangeCustom,
  allowSelectAll = false,
  allowAddingOptions = false,
}) => {
  const formState = useFormState();
  const MainComponent = allowAddingOptions ? Creatable : Select;
  return (
    <Controller
      name={name}
      render={({ field }) => {
        return (
          <div className="relative mb-6 w-full">
            <p className="left-8 top-4 font-semibold text-gray-100">{label}</p>
            <MainComponent
              options={
                allowSelectAll
                  ? [{ value: "all", label: "All" }, ...options]
                  : options
              }
              onChange={
                onChangeCustom
                  ? onChangeCustom
                  : (selectedValues) => {
                      console.log("🚀 ~ selectedValues:", selectedValues);
                      if (
                        field.value?.length === options.length &&
                        selectedValues.findIndex(
                          ({ value }) => value === "all"
                        ) !== -1
                      )
                        return field.onChange([]);
                      if (
                        field.value?.length !== options.length &&
                        selectedValues.findIndex(
                          ({ value }) => value === "all"
                        ) !== -1
                      )
                        return field.onChange(options);
                      return field.onChange(selectedValues);
                    }
              }
              isMulti={true}
              onBlur={field.onBlur}
              value={field.value}
              name={name}
              ref={field.ref}
              theme={(theme) => ({
                ...theme,
                // borderRadius: 0,
                colors: {
                  ...theme.colors,
                  primary25: "silver",
                  primary: "black",
                },
              })}
            />
            {formState.errors[name] && (
              <p className="text-red-600 dark:text-red-500 mt-2 text-sm text-red">
                {String(
                  formState.errors[name] ? formState.errors[name]?.message : ""
                )}
              </p>
            )}
          </div>
        );
      }}
    />
  );
};

export default FormSelectMultiple;

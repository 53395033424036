import { IOption } from "../../../types_new/IOption";
// import { IOption, IActionOption } from "../types_new/IOption";
// import { IEventResponse } from "../types_new/IEvent";
// import EventService from "../services_new/eventService";

export const sortOptions: IOption[] = [
  {
    value: "createdAt",
    text: "Fecha",
  },
  {
    value: "name",
    text: "Nombre",
  },
];

export const statusOptions: IOption[] = [
  {
    value: "all",
    text: "Todos los eventos",
  },
  {
    value: "active",
    text: "Activos",
  },
  {
    value: "paused",
    text: "Pausados",
  },
  {
    value: "pending_approval",
    text: "Esperando aprobación",
  },
  {
    value: "finished",
    text: "Finalizados",
  },
];

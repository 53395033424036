import React, { useContext, useEffect, useState } from "react";
import { motion } from "framer-motion";
import { FilterPage } from "../table/FilterPage";
import { FilterQtyPage } from "../table/FilterQtyPage";
import { FilterSection } from "../table/FilterSection";
import { GoGraph, GoSearch } from "react-icons/go";
import { FilterDate } from "../table/FilterDate";
import { TitleSection } from "../TitleSection";
import { FaEdit, FaTrashAlt } from "react-icons/fa";
import RrppService from "../../services/rrppService";
import { useMutation, useQuery } from "@tanstack/react-query";
import { IRrppResponse, IRrppsResponse } from "../../types/IRrpp";
import { IFilterRequest } from "../../types/IFilter";
import { useNavigate } from "react-router-dom";
import DropdownDots from "../FormElement/DropdownDots";
import ISortOption from "../../types/ISortOption";
import { FilterOrder } from "../table/FilterOrder";
import account from "../../assets/account.png";
import { getTeamRoleFromValue } from "../../utils/roles";
import { PictureAndName } from "./components/PictureAndName/PictureAndName";
import { PageContainer } from "../PageContainer";
import teamService from "../../services/teamService";
import { AuthContext } from "../../context/AuthContext";
import { ITeamResponse, ITeamsResponse } from "../../types/ITeam";
import TeamService from "../../services/teamService";
import { IUserResponse } from "../../types/IUser";
import { StateContext } from "../../context/StateProvider";
import { RowSelected } from "../table/selectedRowProducer/RowSelected";
import TableCellLink from "../FormElement/TableCellLink.tsx";
import config from "../../config/variables";

const defaultResult = {
  total: 0,
  pages: 0,
  rows: [],
};

const defaultFilters = {
  limit: config.filter_default_limit,
  page: 1,
  sortBy: "teams.createdAt",
  sortOrder: "asc",
  team_role: ["leader", "seller"],
  //team_role: 'all',
};

export const RrppView = () => {
  const navigate = useNavigate();
  const auth = useContext(AuthContext);
  const getItems = useMutation(() => RrppService.getAll(filters));

  const getMembers = useMutation(() => RrppService.getAllTeam(filters));
  const getCsv = useMutation(() => RrppService.getCsv(filters));
  const deleteItems = useMutation((id: string) => TeamService.remove(id));
  let paramsFilters: any = {};
  const [result, setResult] = useState<IRrppsResponse>(defaultResult);
  const [filters, setFilters] = useState<IFilterRequest>({
    ...defaultFilters,
    producer_producer_id: auth.producer?.id,
  });
  const [search, setSearch] = useState("");

  const [state, setState] = useState("asc");

  let resultado = [];
  resultado = result.rows;
  const searcher = async (e: any) => {
    setSearch(e.target.value);
    paramsFilters = { ...filters, query: e.target.value };
    await setFilters({ ...filters, page: 1, query: e.target.value });
  };

  useEffect(() => {
    if (search != "") {
      if (auth.user.id) {
        const delayDebounceFn = setTimeout(() => {
          dispatch({ type: "showLoaderScreen", payload: true });
          getItems.mutateAsync().then((res) => {
            dispatch({ type: "showLoaderScreen", payload: false });
            setResult(res);
          });
        }, 1500);
        return () => clearTimeout(delayDebounceFn);
      }
    } else {
      handleFilter(filters);
    }
  }, [search, auth.user.id]);

  const filterSectionProps = [
    { title: "Todos los usuarios", id: "all" },
    { title: "Team leader", id: "leader" },
    { title: "Vendedores", id: "seller" },
  ];

  const columnData = [
    "Nombre",
    "Tipo",
    "Email",
    "Eventos Activos",
    "Vendedores a cargo",
    "Líderes",
    "",
    "",
  ];

  const handleFilter = async (filter: IFilterRequest) => {
    await setFilters({ ...filters, ...filter });
    getItems.mutateAsync().then((res) => {
      setResult(res);
    });
  };

  const handleCsv = async () => {
    await getCsv.mutateAsync().then((response) => {
      const url = window.URL.createObjectURL(new Blob([response]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "sellers.csv");
      document.body.appendChild(link);
      link.click();
    });
  };

  const handleImportCsv = async () => {
    dispatch({
      type: "importCsv",
      payload: { producer_id: auth.producer?.id },
    });
  };

  const handleFilterSection = async (filter?: string) => {
    if (!filter) return;
    await setFilters({ ...filters, page: 1, team_role: filter });
    getItems.mutateAsync().then((res) => {
      console.log(res);

      setResult(res);
    });
  };

  const handleSortSection = async (sortBy: string) => {
    const itemSort = sortOptions.find((s) => s.value == sortBy);
    if (itemSort) console.log(itemSort.sortOrder);

    paramsFilters = { ...filters, page: 1, sortBy: sortBy };
    if (itemSort) paramsFilters["sortOrder"] = itemSort.sortOrder;
    await setFilters(paramsFilters);
    await getItems.mutateAsync().then((res) => setResult(res));
  };

  const handleEdit = (id: string) => {
    navigate(`/client/rrpp/edit-seller/${id}`);
  };

  const { dispatch } = useContext(StateContext);

  const handleDelete = async (row: IRrppResponse) => {
    if (window.confirm("Esta seguro de eliminar al vendedor del equipo?")) {
      if (row.teams && row.teams.length) {
        const team = row.teams.find(
          (i) =>
            i.user_id == row.id && i.producer_producer_id == auth.producer?.id
        );
        if (team) {
          await deleteItems.mutateAsync(team.id).then((res) => {
            handleFilter(filters);
            dispatch({
              type: "toastSuccess",
              payload: "El registro ha sido eliminado",
            });
          });
        }
      }
    }
  };

  const getTeamRoleForProducer = (row: IRrppResponse) => {
    if (row.teams && row.teams.length) {
      const team = row.teams.find(
        (i) => i.producer_producer_id == auth.producer?.id
      );
      if (team) {
        return team.team_role;
      }
    } else if (row.leaders && row.leaders.length) {
    }
    return row.user_role;
  };

  const getLeaderNames = (row: IRrppResponse) => {
    let result = [];
    if (row.teams && row.teams.length) {
      for (const t of row.teams) {
        if (t.leader[0]) {
          const fn = `${
            t.leader[0].first_name == undefined ? "" : t.leader[0].first_name
          } ${t.leader[0].last_name == undefined ? "" : t.leader[0].last_name}`;
          result.push(
            <p>
              {fn}
              {t.leader[0].status == "deleted" && (
                <small className="font-light"> (eliminado)</small>
              )}
            </p>
          );
        }
      }
    }
    return result;
  };
  const sortOptions: Array<ISortOption> = [
    { value: "teams.createdAt", text: "Fecha", sortOrder: "asc" },
    { value: "first_name", text: "Nombre", sortOrder: "asc" },
  ];

  const handleChangeState = async (state: string) => {
    console.log(state);

    setState(state);
    await setFilters({ ...filters, sortOrder: state });
    getItems.mutateAsync().then((res) => {
      setResult(res);
    });
  };

  return (
    <PageContainer>
      <div className="relative">
        <TitleSection>Vendedores</TitleSection>
        <div className="absolute -top-0 right-0 ">
          <button
            onClick={() => navigate("/client/rrpp/new-seller")}
            className="rounded-full border-[2px] border-black-100 px-12 py-2 font-bold text-black-100 hover:bg-black-100 hover:text-white"
          >
            Sumar un nuevo vendedor
          </button>
        </div>
      </div>

      <div className="flex w-full  justify-between overflow-auto border-b pt-10">
        <FilterSection
          filterSectionProps={filterSectionProps}
          handleFilter={handleFilterSection}
        />
        <div className="flex items-center gap-5">
          <button
            className="text-black flex items-center justify-center font-sans font-bold"
            onClick={handleImportCsv}
          >
            {/* <GoGraph className="mr-1" /> */}
            Import a CSV
          </button>
          <button
            className="text-black flex items-center justify-center font-sans font-bold"
            onClick={handleCsv}
          >
            {/* <GoGraph className="mr-1" /> */}
            Exportar a CSV
          </button>
          {/* <GoSearch className="cursor-pointer text-gray-100" /> */}
          <input
            type="text"
            placeholder="Search..."
            className="hover:text-black rounded-full border-[2px] border-black-100 px-4 py-1 font-bold  text-black-100"
            value={search}
            onChange={searcher}
          />

          <FilterOrder
            setSort={handleSortSection}
            options={sortOptions}
            handleChangeState={handleChangeState}
            state={state}
          />
        </div>
      </div>
      <table className="my-table-spacing h-full w-full border-separate border-spacing-y-2 gap-5 overflow-x-auto pt-10 text-left">
        <thead className=" divide-solid border ">
          <tr className=" pb-4 text-sm font-bold text-gray-100">
            {columnData.map((column, index) => (
              <th
                className="border-b-[1px] border-colorBorder pb-4"
                key={index}
              >
                {column}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {resultado?.map((row, index) => (
            <motion.tr
              key={index}
              layout
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              initial={{ opacity: 0.2 }}
              transition={{ type: "spring", stiffness: 100, bounce: 0 }}
              className={`h-16  rounded-xl text-start font-medium outline outline-[1px] outline-colorBorder`}
            >
              <TableCellLink href={`/client/rrpp/${row.id}`} className="pl-4">
                <PictureAndName
                  profilePicture={row.profile_picture}
                  firstName={row.first_name}
                  lastName={row.last_name}
                />
              </TableCellLink>
              <TableCellLink href={`/client/rrpp/${row.id}`} className="pl-4">
                {getTeamRoleFromValue(getTeamRoleForProducer(row))}
              </TableCellLink>
              <TableCellLink href={`/client/rrpp/${row.id}`} className="pl-4">
                {row.email}
              </TableCellLink>
              <TableCellLink href={`/client/rrpp/${row.id}`} className="pl-4">
                {row.events_count}
              </TableCellLink>
              <TableCellLink href={`/client/rrpp/${row.id}`} className="pl-4">
                {row.sellers_count}
              </TableCellLink>
              <TableCellLink href={`/client/rrpp/${row.id}`} className="pl-4">
                {getLeaderNames(row)}
              </TableCellLink>
              <TableCellLink
                href={`/client/rrpp/${row.id}`}
                className="flex h-full items-center justify-start gap-5 pr-5"
              >
                {row.status == "active" && (
                  <div className="flex w-32 items-center justify-center gap-3 rounded-full border-[1px] border-colorBorder  py-2 text-center ">
                    <div className="rounded-full  bg-green p-[.35rem] " />
                    <p className="capitalize">Activo</p>
                  </div>
                )}
                {row.status == "pending" && (
                  <div className="flex w-60 items-center justify-center gap-3 rounded-full border-[1px] border-colorBorder  py-2 text-center ">
                    <div className="rounded-full  bg-red p-[.35rem] " />
                    <p className="capitalize">Pendiente aprobación</p>
                  </div>
                )}
                {row.status == "paused" && (
                  <div className="flex w-32 items-center justify-center gap-3 rounded-full border-[1px] border-colorBorder  py-2 text-center ">
                    <div className="rounded-full  bg-red p-[.35rem] " />
                    <p className="capitalize">Pausado</p>
                  </div>
                )}
              </TableCellLink>
              <td>
                {getTeamRoleForProducer(row) == "seller" ? (
                  <DropdownDots
                    options={[
                      {
                        title: "Editar",
                        action: () => handleEdit(row.id),
                        icon: <FaEdit size={23} />,
                      },
                      {
                        title: "Eliminar",
                        action: () => handleDelete(row),
                        icon: <FaTrashAlt size={23} />,
                      },
                    ]}
                  />
                ) : (
                  <DropdownDots
                    options={[
                      {
                        title: "Eliminar",
                        action: () => handleDelete(row),
                        icon: <FaTrashAlt size={23} />,
                      },
                    ]}
                  />
                )}
              </td>
            </motion.tr>
          ))}
        </tbody>
      </table>
      {result?.rows.length == 0 && (
        <p className="text-center">No existen vendedores disponibles</p>
      )}
      <div className="flex justify-between pt-10">
        <FilterPage
          handleFilter={handleFilter}
          total={result?.total}
          limit={filters.limit}
          status={filters.status}
          currentPage={filters.page}
        />
        <FilterQtyPage handleFilter={handleFilter} status={filters.status} />
      </div>
    </PageContainer>
  );
};

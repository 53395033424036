import { GoGraph } from "react-icons/go";
import DropdownDots from "../../../FormElement/DropdownDots";
import { motion } from "framer-motion";
import { FaTrashAlt } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { ArcElement, Chart as ChartJS, Legend, Tooltip } from "chart.js";
import { Pie } from "react-chartjs-2";
import React, { FC, useContext, useEffect, useState } from "react";
import {
  IReportInvitationsDetailResponse,
  IReportRequest,
} from "../../../../types/IReport";
import { useQuery, useMutation } from "@tanstack/react-query";
import ReportService from "../../../../services/reportService";
import moment from "moment/moment";
import { FilterDataRadio } from "../../../table/FilterDataRadio";
import ISortOption from "../../../../types/ISortOption";
import TicketService from "../../../../services/ticketService";
import EventService from "../../../../services/eventService";
import { StateContext } from "../../../../context/StateProvider";

import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";

ChartJS.register(ArcElement, Tooltip, Legend);

type reportProps = {
  producer_id?: string;
  event_id?: string;
  courtesies?: string;
};

export const DetailsInvitation: FC<reportProps> = ({
  producer_id,
  event_id,
  courtesies = "",
}) => {
  const navigate = useNavigate();
  const [data, setData] = useState<Array<IReportInvitationsDetailResponse>>([]);
  const [params, setParams] = useState<IReportRequest>({});
  const { state, dispatch } = useContext(StateContext);

  const getItems = useMutation((p: IReportRequest) =>
    courtesies
      ? ReportService.courtesyDelivery(p)
      : ReportService.invitationsDetail(p)
  );
  const getCsv = useMutation(() =>
    courtesies
      ? ReportService.getCsvCourtesyDelivery({ event_id, to: courtesies })
      : ReportService.getCsvInvitationsDetail({ event_id })
  );
  const getTickets = useMutation(() =>
    TicketService.getAll({ event_id: event_id, type: "Free" })
  );
  const getHistoryCsv = useMutation((params: object) =>
    EventService.sendTicketLogsCsv(params)
  );

  // const getEvent = useMutation(() => EventService.get(event_id || ""));

  const event = useQuery({
    queryKey: ["event", event_id || ""],
    queryFn: () => EventService.get(event_id || ""),
  });

  const applyFilters = async () => {
    const parameters = {} as IReportRequest;
    if (producer_id) parameters.producer_id = producer_id;
    if (event_id) parameters.event_id = event_id;
    if (courtesies) parameters.to = courtesies;
    await setParams({ ...params, ...parameters });

    getItems
      .mutateAsync({ ...params, ...parameters })
      .then((res) => setData(res));
  };
  const filteredData = courtesies
    ? data
    : data?.filter((item) =>
        item?.order_tickets.some(
          (ticket: { is_free: boolean }) => ticket?.is_free === true
        )
      );

  useEffect(() => {
    if (event_id) applyFilters();
  }, [event_id]);

  const columnData = [
    "Tipo del ticket",
    "Cantidad",
    "Ingresadas",
    "Porcentaje de ingreso",
    "",
  ];

  const handleDelete = (id: string) => {};

  const buildPieChartData = (data: Array<number>) => {
    return {
      labels: [],
      datasets: [
        {
          data: data,
          backgroundColor: [
            "rgba(95, 220, 179, 0.5)",
            "rgba(255, 255, 255, 0.5)",
          ],
          borderColor: ["rgba(95, 220, 179, 0.5)", "rgba(0, 0, 10, 0.1)"],
          borderWidth: 2,
        },
      ],
    };
  };

  const porcentageComvert = (num: any) => {
    const convert = Math.trunc(num);
    return convert;
  };
  const handleCsv = async () => {
    if (!event.data) {
      dispatch({
        type: "toastError",
        payload: "Elegí un evento primero.",
      });
      return;
    }

    try {
      await getCsv.mutateAsync().then((response) => {
        const name =
          courtesies == "user"
            ? "ENVIO_DE_CORTESIAS"
            : "DETALLE_DE_INVITACIONES";
        const nameFile = `${name}-${event.data.name}-${event.data.producer.name}.csv`;
        const url = window.URL.createObjectURL(new Blob([response]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", nameFile);
        document.body.appendChild(link);
        link.click();
      });
    } catch (error) {
      dispatch({
        type: "toastError",
        payload: "No pudimos generar el CSV.",
      });
    }
  };

  const handleHistory = async (e: any) => {
    dispatch({ type: "typeHistory", payload: courtesies });
    dispatch({ type: "eventIDHistory", payload: event_id });
    dispatch({ type: "showHistory", payload: null });
  };

  let total = 0;
  let totalUsed = 0;
  const getTotal = (key: string) => {
    let result = 0;
    for (const row of data) {
      result += row[key];
    }
    if (key == "qty") total = result;
    if (key == "used") totalUsed = result;
    return result;
  };

  return (
    <div className="rounded-xl outline outline-2 outline-colorBorder 2xl:h-full ">
      <Accordion type="single" collapsible>
        <AccordionItem value="item-1">
          <AccordionTrigger>
            <div className="flex h-16 w-full items-center justify-between border-b border-colorBorder px-10">
              <h3 className="text-xl font-bold">
                {courtesies == "user"
                  ? "Envío de cortesías"
                  : "Detalle de Invitaciones"}
              </h3>
              <div className="flex gap-5">
                <button
                  className="flex items-center justify-center"
                  onClick={handleCsv}
                >
                  <GoGraph className="mr-1" />
                  <span className="text-xs font-bold">Exportar a CSV</span>
                </button>
                {courtesies && !!event_id && (
                  <p>
                    <strong>-</strong>
                  </p>
                )}
                {courtesies && !!event_id && (
                  <button
                    className="flex items-center justify-center"
                    onClick={handleHistory}
                  >
                    <span className="text-xs font-bold">Historial</span>
                  </button>
                )}
              </div>
            </div>
          </AccordionTrigger>
          <AccordionContent>
            <table className="my-table-spacing  w-full border-separate border-spacing-y-2 gap-5 overflow-x-auto px-6 text-left">
              <thead className=" divide-solid border ">
                <tr className=" pb-4 text-sm font-bold text-gray-100">
                  {columnData.map((column, index) => (
                    <th className="pb-4" key={column + index}>
                      {column}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {event_id &&
                  filteredData.map((row, index) => (
                    <motion.tr
                      key={index}
                      layout
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      initial={{ opacity: 0.2 }}
                      transition={{ type: "spring", stiffness: 100, bounce: 0 }}
                      className={`h-16  rounded-xl text-start font-medium outline outline-[1px] outline-colorBorder`}
                    >
                      <td className="pl-4 text-sm font-extrabold">
                        {row.name}
                        {row.status == "deleted" && (
                          <small className="font-light"> (eliminado)</small>
                        )}
                      </td>
                      <td className="pl-4 text-sm font-normal">{row.qty}</td>
                      <td className="pl-4 text-sm font-normal">{row.used}</td>
                      <td className="flex items-center  pl-4 text-sm font-normal">
                        <div className="w-[50px] ">
                          {/* <Pie data={buildPieChartData([row.entered, (row.qty - row.entered)])}  /> */}
                          <Pie
                            data={buildPieChartData([
                              row.used,
                              row.qty - row.used,
                            ])}
                          />
                        </div>
                        <span className="pt-2">
                          {row.qty
                            ? porcentageComvert((row.used / row.qty) * 100)
                            : 0}
                          %
                        </span>
                      </td>
                      <td>
                        <DropdownDots
                          options={[
                            // {title: 'Editar', action: ()=>handleEdit(row.id), icon: (<FaEdit size={23} />)},
                            {
                              title: "Eliminar",
                              action: () => handleDelete(row.id),
                              icon: <FaTrashAlt size={23} />,
                            },
                          ]}
                        />
                      </td>
                    </motion.tr>
                  ))}
                {event_id && !!filteredData?.length && (
                  <motion.tr
                    key="totals"
                    layout
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    initial={{ opacity: 0.2 }}
                    transition={{ type: "spring", stiffness: 100, bounce: 0 }}
                    className={`h-16  rounded-xl text-start font-medium outline outline-[1px] outline-colorBorder`}
                  >
                    <td className="pl-4 text-center text-sm font-extrabold">
                      TOTAL:
                    </td>
                    <td className="pl-4 text-sm font-normal">
                      {getTotal("qty")}
                    </td>
                    <td className="pl-4 text-sm font-normal">
                      {getTotal("used")}
                    </td>
                    <td
                      className="flex items-center  pl-4 text-sm font-normal"
                      colSpan={2}
                    >
                      <div className="w-[50px] ">
                        <Pie
                          data={buildPieChartData([
                            totalUsed,
                            total - totalUsed,
                          ])}
                        />
                      </div>
                      <span className="pt-2">
                        {total
                          ? porcentageComvert((totalUsed / total) * 100)
                          : 0}
                        %
                      </span>
                    </td>
                  </motion.tr>
                )}
              </tbody>
            </table>
          </AccordionContent>
        </AccordionItem>
      </Accordion>
    </div>
  );
};

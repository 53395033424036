import { Fragment, MouseEventHandler } from "react";
import { Row } from "@tanstack/react-table";
import { DotsHorizontalIcon } from "@radix-ui/react-icons";

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "../../ui/dropdown-menu";
import { Button } from "../../ui/button";
import { IDropdownOption } from "../../../types_new/IOption";

interface DataTableRowActionsProps<TData> {
  row: Row<TData>;
  dropdownOptions: IDropdownOption<TData>[];
}

export const DataTableRowActions = <TData extends { id: string }>({
  row,
  dropdownOptions,
}: DataTableRowActionsProps<TData>) => {
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button
          variant="ghost"
          className="flex h-8 w-8 p-0 data-[state=open]:bg-muted"
        >
          <DotsHorizontalIcon className="h-4 w-4" />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent>
        {dropdownOptions.map((option, idx) => (
          <Fragment key={option.value}>
            <DropdownMenuItem
              className="flex cursor-pointer gap-1"
              onClick={option.callback.bind(null, row.original.id)}
            >
              {option.icon}
              {option.text}
            </DropdownMenuItem>
            {idx < dropdownOptions.length - 1 && <DropdownMenuSeparator />}
          </Fragment>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

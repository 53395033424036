import React, { useState, useRef, FC } from "react";
import { BiDotsHorizontalRounded } from "react-icons/bi";
import useComponentVisible from "../../../hooks/use-component-visible.hook";
import { Props } from "@headlessui/react/dist/types";
import Restricted from "../../../context/Restricted";
import ImagenIcono from "../../../assets/iconoEventos.svg";

type DropdownDotsProps = {
  options: Array<any>;
  children?: React.ReactNode;
};

export default function Dropdown({
  options = [],
  children,
}: DropdownDotsProps) {
  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible(false);

  return (
    <div className="" ref={ref}>
      <button
        className="flex text-gray-100"
        onClick={() => setIsComponentVisible(!isComponentVisible)}
      >
        {options.length != 0 ? (
          <img
            src={ImagenIcono}
            width="100%"
            height="100%"
            className="object-cover"
          />
        ) : (
          <div></div>
        )}
      </button>

      {options.length != 0 ? (
        <div>
          {isComponentVisible && (
            <div
              className={`max-w-56  border-gray-50 absolute z-10 mt-1 origin-top-right rounded-md border bg-white shadow-lg`}
            >
              {options.map((item: any, index: number) => (
                <div className="p-2" key={index}>
                  <Restricted to={item.restricted || "any"}>
                    <button
                      onClick={item.action}
                      className="hover:text-black-700 block w-full rounded-lg px-4 py-2 text-sm text-black-100 hover:bg-gray-100 hover:bg-opacity-20"
                    >
                      <div
                        className="flex items-center"
                        onClick={() => setIsComponentVisible(false)}
                      >
                        {item.icon}
                        <div>
                          <p className="w-max">
                            {item.name && <strong> {item.name}</strong>}
                          </p>
                        </div>
                      </div>
                    </button>
                  </Restricted>
                </div>
              ))}
            </div>
          )}
        </div>
      ) : (
        <div></div>
      )}
    </div>
  );
}
